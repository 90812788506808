import { Box, Paper } from '@material-ui/core'
import { MainOverview } from './MainOverview'
import { useSharedFolder } from 'hooks'
import { useIsSmallScreenFalse } from 'packages/core'

export const Overview = (props) => {
    const { sharedFolderId, basicAttributes } = props

    const { isLoading: detailsLoading, data: sharedFolder } =
        useSharedFolder(sharedFolderId)
    const isSmallScreen = useIsSmallScreenFalse()

    return (
        <Box>
            <Paper
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                }}
            >
                <MainOverview
                    sharedFolder={sharedFolder}
                    detailsLoading={detailsLoading}
                    basicAttributes={basicAttributes}
                />
            </Paper>
        </Box>
    )
}
