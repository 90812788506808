import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles } from '@material-ui/core'
import { useSharedFolders } from 'hooks'
import { useDebounce } from 'packages/core'
import TableGrid from 'components/TableGrid'
import BooleanValue from 'components/DynamicComponents/BooleanValue'
import Search from 'components/Filters/Search'

const useTableStyles = makeStyles({
    headGrey: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
        height: '80px',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 16px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
    },
    tableHeaderCell: {
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: '#7d7c7c',
    },
})

export const InventoriedPermissions = ({ sharedFolderId }) => {
    const { t } = useTranslation()
    const tableClasses = useTableStyles()

    const [sorting, setSorting] = useState({ sortOrder: null, sortBy: null })
    const [searchKey, setSearchKey] = useState('')

    const handleSort = (sorting) => {
        setSorting(sorting)
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }

    // Will be in used when search is implemented
    // const debouncedSearchValue = useDebounce(searchKey)

    // const queryData = {
    //     searchTerm: debouncedSearchValue,
    //     sorting,
    // }

    //TODO: Change api endpoint when available from back-end
    const { list, loading: isLoading, pagination } = useSharedFolders()

    //TODO: Change attributes names when available from back-end
    const attributes = [
        {
            label: t('Right'),
            name: 'friendlyName',
            sortable: true,
            style: {
                width: '150px',
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            label: t('Assignee'),
            name: 'friendlyName',
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            label: t('Type'),
            name: 'friendlyName',
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            label: t('Inherited'),
            name: 'deleted',
            sortable: true,
            resolve: (item) => (
                <BooleanValue data={{ item }} attribute={{ name: 'deleted' }} />
            ),
        },
        {
            label: t('Allowed'),
            name: 'deleted',
            sortable: true,
            resolve: (item) => (
                <BooleanValue data={{ item }} attribute={{ name: 'deleted' }} />
            ),
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                paddingBottom="16px"
            >
                <Box width="308px" display="flex" alignItems="center">
                    <Search
                        handleSearch={handleSearchChange}
                        placeholder={t('SearchAndFilter')}
                        value={searchKey}
                        handleClear={handleClear}
                        outlined
                    />
                </Box>
            </Box>

            <TableGrid
                loading={isLoading}
                data={list}
                attributes={attributes}
                tableClasses={tableClasses}
                sorting={sorting}
                onSort={handleSort}
            />

            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '40px',
                }}
            >
                <Box>{pagination}</Box>
            </Box>
        </>
    )
}
