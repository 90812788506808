import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { useIsSmallScreenFalse } from 'packages/core'
import { useAppManagementRoles } from 'hooks'
import {
    useEidGrid,
    CardViewLoader,
    ListingGrid,
    GridContainer,
    Filters,
} from 'components'
import {
    AppManagementRoleRow,
    AppManagementRolesTable,
    AppManagementRolesTableHead,
} from './AppManagementRolesTableComponent'
import appManagementRolesAttributes from './appManagementRolesAttributes'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from '../../CreateWorkflowButton'

const AppManagementRoles = ({ applicationId, applicationResourceId }) => {
    const { t } = useTranslation()
    const { canAddNewAppManagementRoles, getAccessBasedAttributes } =
        useSubcomponents()
    const accessBasedAttributes = getAccessBasedAttributes(
        appManagementRolesAttributes,
    )
    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useAppManagementRoles(applicationId)

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? 'card' : 'table'
    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoAppManagementRolesMessage'),
        pagination,
        attributes: accessBasedAttributes,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
        },
        tableViewConfig: {
            TableComponent: AppManagementRolesTable,
            TableHeadComponent: AppManagementRolesTableHead,
            TableRowComponent: AppManagementRoleRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    return (
        <>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                >
                    {canAddNewAppManagementRoles && (
                        <Box
                            data-protectedsubcomponent={
                                canAddNewAppManagementRoles
                            }
                        >
                            <CreateWorkflowButton
                                label={t('AddNewAppManagemetRoles')}
                                workflowName="OnboardManagementRole"
                                resourceId={applicationResourceId}
                                selectedApplicationId={applicationId}
                            />
                        </Box>
                    )}
                    <Box
                        style={{
                            margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                            width: isSmallScreen ? 'auto' : '308px',
                        }}
                    >
                        <Filters.TextSearch outlined />
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '16px 0px',
                        marginTop: '0px',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    {viewToRender}
                </GridContainer>
            </ListingGrid>
        </>
    )
}

export default AppManagementRoles
