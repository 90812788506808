import PeopleListing from 'components/SharedListings/PeopleListing'

const AppRightsPeople = ({ roleId, applicationType, resourceId }) => {
    const queryFilters = { showLocalRights: true }
    return (
        <PeopleListing
            apiUrl={`api/people/allPeopleByResource`}
            roleId={roleId}
            queryFilters={queryFilters}
            applicationType={applicationType}
            resourceId={resourceId}
        />
    )
}

export default AppRightsPeople
