import React, { useEffect } from 'react'
import { useAuthState, useQuery, useSwrApiGet } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import {
    useAccessSubcomponents,
    useTranslations,
    useCurrentPerson,
    useApplicationSettings,
    useTargetPerson,
} from 'hooks'
import moment from 'moment'
import i18n from 'i18next'
import { useAppState } from 'appContext'
import config from 'config'
import { isAppRunningInIframe } from 'packages/core'

const useLoadAccessSubcomponents = () => {
    const [{ access }, dispatchAuthState] = useAuthState()
    const { data } = useAccessSubcomponents()

    useEffect(() => {
        if (data) {
            dispatchAuthState({
                type: 'SET_ACCESS_SUBCOMPONENTS',
                payload: data.data,
            })
        }
    }, [data, dispatchAuthState])

    return access !== null
}

const useLoadTranslations = () => {
    const RESOURCE_SET_NAME = 'ResourceAdmin'

    const query = useQuery()
    const debugLocalization = query.get('debugLocalization') === 'true'

    const { data } = useTranslations()
    const { data: currentPerson } = useCurrentPerson()

    const locale =
        currentPerson && currentPerson.language?.languageCode
            ? currentPerson.language?.languageCode
            : 'en'
    useEffect(() => {
        i18n.changeLanguage(locale)
        moment.locale(locale)
    }, [locale])

    useEffect(() => {
        if (data) {
            const localeData = data.reduce((current, r) => {
                let keyToUse = r.key
                const parts = r.key.split('_')
                if (parts[0] === RESOURCE_SET_NAME) {
                    keyToUse = r.key.substring(RESOURCE_SET_NAME.length + 1)
                }

                if (debugLocalization) {
                    current[keyToUse] = r.key
                } else {
                    current[keyToUse] = r.translatedValue
                }

                return current
            }, {})

            i18n.addResourceBundle(locale, 'translation', localeData)
        }
    }, [data, locale, debugLocalization])

    return Boolean(data)
}

const useLoadApplicationSettings = () => {
    const [{ appConfig }, dispatchAppState] = useAppState()

    let settingsOverride = config?.UI_SETTINGS_OVERRIDE
    if (isAppRunningInIframe()) {
        settingsOverride = 'ServiceNow'
    }
    const { data } = useApplicationSettings(settingsOverride)

    useEffect(() => {
        if (data) {
            dispatchAppState({
                type: 'SET_APP_CONFIG',
                payload: data,
            })
        }
    }, [data, dispatchAppState])

    return appConfig !== null
}

const useSetTargetPerson = () => {
    const [{ currentUserId }] = useAuthState()

    const [
        targetPerson,
        setTargetPerson,
        { getSavedTargetPersonId },
    ] = useTargetPerson()

    const savedTargetPersonId = getSavedTargetPersonId()

    const targetPersonId = savedTargetPersonId
        ? savedTargetPersonId
        : currentUserId

    const { data } = useSwrApiGet(() => {
        return `/api/people?personId=${targetPersonId}`
    })

    useEffect(() => {
        if (data) {
            setTargetPerson(data.data)
        }
    }, [data])

    return targetPerson !== null
}

const withRequiredData = (ChildComponent) => (props) => {
    const translationsLoaded = useLoadTranslations()
    const accessSubcomponentsLoaded = useLoadAccessSubcomponents()
    const appConfigLoaded = useLoadApplicationSettings()
    const setTargetPerson = useSetTargetPerson()

    if (!accessSubcomponentsLoaded || !translationsLoaded || !appConfigLoaded || !setTargetPerson) {
        return <Loader />
    } else {
        return <ChildComponent {...props} />
    }
}

export default withRequiredData
