import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WarningModal } from 'packages/eid-ui'
import { Button } from 'components'
import { useDeleteAccessAssignmentToFolder } from 'hooks'

const DeleteAccessAssignment = ({ item, shareFolderId }) => {
    const { t } = useTranslation()

    const [showConfirm, setShowConfirm] = useState(false)
    const [deleteAssignees, { isLoading: isDeletingAssignment }] =
        useDeleteAccessAssignmentToFolder(shareFolderId)
    const handleDelete = () => {
        deleteAssignees([item.id]).then(()=>{
            setShowConfirm(false)
        })
    }

    return (
        <>
            <Button.Delete
                item={item}
                onClick={() => setShowConfirm(true)}
                disabled={item.isExpired}
            />

            <WarningModal
                title={t('Delete') + ' ' + item?.assigneeFriendlyName}
                description={t('SharedFolder_AssignmentDeleteMessage')}
                yesLabel={t('Delete')}
                noLabel={t('Cancel')}
                open={showConfirm}
                cancel={() => setShowConfirm(false)}
                proceeding={isDeletingAssignment}
                proceed={handleDelete}
            />
        </>
    )
}

DeleteAccessAssignment.defaultProps = {
    drawerView: false,
}
export default DeleteAccessAssignment
