import SensitiveFunctionsListing from 'components/SharedListings/SensitiveFunctionsListing'

const AppManagementRolesSensitiveFunctions = ({ roleId }) => {
    return (
        <SensitiveFunctionsListing
            url={'api/RiskFunctions/byAssigneeId'}
            roleId={roleId}
        />
    )
}

export default AppManagementRolesSensitiveFunctions
