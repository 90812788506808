import React from 'react'
import { styled } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { TableBody, TableCell, TableRow, Box } from '@material-ui/core'
import { MasterPasswordLock } from 'components'

const ListItemSkeleton = styled(Skeleton)({
    width: '100%',
    margin: '8px',
    height: 20,
    borderRadius: '10px',
})

const SkeletonContainer = styled('div')({
    height: 'max-content',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
})

const TableRowLoader = ({ attributes }) => {
    const loader = (
        <Box width="100%" height="100%">
            <SkeletonContainer>
                <ListItemSkeleton animation="wave" variant="rect" />
            </SkeletonContainer>
        </Box>
    )

    return (
        <TableRow>
            <TableCell colSpan={attributes.length}>{loader}</TableCell>
        </TableRow>
    )
}

const EidTable = ({
    data,
    isLoading,
    LoadingIndicatorComponent = TableRowLoader,
    isLoadingMore,
    noDataMessage = 'No data available',
    attributes,
    pagination,
    showMasterPasswordLock,
    masterPasswordLockStyle,
    TableComponent,
    TableHeadComponent,
    TableRowComponent,
    selected,
    handleSelectAllClick,
    rowSelection,
    handleClick,
    selectAllAction,
}) => {
    const loadingIndicator = (
        <LoadingIndicatorComponent attributes={attributes} />
    )
    return (
        <>
            <TableComponent>
                <TableHeadComponent
                    rowSelection={rowSelection}
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selected}
                    data={data}
                    attributes={attributes}
                />
                <TableBody>
                    {isLoading ? (
                        loadingIndicator
                    ) : data?.length === 0 ? (
                        <TableRow>
                            <TableCell
                                style={{ backgroundColor: 'white' }}
                                colSpan={attributes.length + 1}
                            >
                                <Box textAlign="center">{noDataMessage}</Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {showMasterPasswordLock && (
                                <MasterPasswordLock
                                    style={masterPasswordLockStyle}
                                />
                            )}
                            {data?.map((item, index) => (
                                <TableRowComponent
                                    key={index}
                                    item={item}
                                    attributes={attributes}
                                    handleClick={handleClick}
                                    rowSelection={rowSelection}
                                    selected={selected}
                                />
                            ))}
                        </>
                    )}
                    {isLoadingMore && loadingIndicator}
                </TableBody>
            </TableComponent>

            <Box
                style={{
                    padding: '0px',
                    display: 'flex',
                    justifyContent: !selectAllAction?.render
                        ? 'center'
                        : 'space-between',
                    margin: '24px 0px',
                    ...selectAllAction?.style,
                }}
            >
                {selectAllAction?.render && selectAllAction.render()}
                <Box>{pagination}</Box>
            </Box>
        </>
    )
}

export default EidTable
