import { useState } from 'react'
import { Icon } from 'packages/eid-icons'
import { makeStyles, createStyles, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useBlueStyles = makeStyles((theme)=>
    createStyles({
        root: (styles) => ({
            position: 'relative',
            height: '48px',
            maxWidth:'120px',
            width:'120px',
            padding: '8px 12px',
            borderRadius: '4px',
            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',
            lineHeight: 'initial',
            backgroundColor: 'transparent',
            border: `solid 1px ${theme?.palette?.primary?.main}`,

            color: theme?.palette?.primary?.main,
            '&:hover': {
                backgroundColor: theme?.palette?.primary?.main,
                color: 'white',
                cursor: 'hand',
            },
            ...styles,
        }),
        disabled: {
            backgroundColor: '#efeff1 !important',
            color: '#8b909a !important',
            border: 'solid 1px #8b909a !important',
        },
    }),
)

const CancelButton = ({ onClick, disabled, showEndIcon = false, styles }) => {
    const { t } = useTranslation()
    const [actionsButtonIconColor, setActionsButtonIconColor] =
        useState('#215197')

    const buttonClasses = useBlueStyles(styles)

    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            onMouseEnter={() => {
                setActionsButtonIconColor('white')
            }}
            onMouseLeave={() => {
                setActionsButtonIconColor('#215197')
            }}
            classes={buttonClasses}
            endIcon={
                showEndIcon && (
                    <Icon
                        name="DeleteX"
                        style={{ width: '6px', height: '6px' }}
                        color={disabled ? '#8b909a' : actionsButtonIconColor}
                        fill={disabled ? '#8b909a' : actionsButtonIconColor}
                    />
                )
            }
        >
            {t('Cancel')}
        </Button>
    )
}

export default CancelButton
