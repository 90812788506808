import { Button, createStyles, makeStyles } from '@material-ui/core'
import config from 'config'
import { getFullPathFromUrl, useIsSmallScreenFalse, useQuery } from 'packages/core'
import { FC } from 'react'
import { useHistory } from 'react-router'

const useStyles = makeStyles(
    createStyles({
        root: (isSmallScreen) => ({
            height: '40px',
            borderRadius: '4px',
            padding: '0px 15px 0px 10px',
            backgroundColor: '#307fc1',
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '14px',
            margin: isSmallScreen ? '16px 16px 0px 16px' : '',
            '&:hover': {
                backgroundColor: '#307fc1',
            },
            '& svg': {
                marginRight: '8px',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)

export interface CreateWorkflowButtonProps {
    workflowName: string
    resourceId: number
    selectedApplicationId: string
    selectedAssigneeId?: string
    label: string
    iconComponent?: any
}

export const CreateWorkflowButton: FC<CreateWorkflowButtonProps> = ({
    workflowName,
    resourceId,
    selectedApplicationId,
    selectedAssigneeId,
    label,
    iconComponent,
}) => {
    const query = useQuery()
    const history = useHistory()
    const isSmallScreen = useIsSmallScreenFalse()
    const classes = useStyles(isSmallScreen)

    return (
        <Button
            onClick={() => {
                query.set('workflowName', workflowName)
                query.set('returnUrl', getFullPathFromUrl())
                if (resourceId) {
                    query.set('resourceID', `${resourceId}`)
                }
                if (selectedApplicationId) {
                    query.set(
                        'SelectedApplicationID',
                        `${selectedApplicationId}`,
                    )
                }
                if (selectedAssigneeId) {
                    query.set('SelectedAssigneeID', selectedAssigneeId)
                }
                history.push(
                    `${
                        config.APP_SUBPATH
                    }/applications/workflows?${query.toString()}`,
                )
            }}
            className={classes.root}
        >
            {iconComponent}
            {label}
        </Button>
    )
}
