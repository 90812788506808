import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled, createStyles, makeStyles } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { ItemDetails } from 'components'
import { OverviewSection } from './OverviewSection'
import DeletePermission from './DeletePermission'
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '16px',
            [theme.breakpoints.up('sm')]: {
                height: '80px',
                alignItems: 'center',
                position: 'relative',
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                paddingTop: '16px !important',
                paddingBottom: '16px !important',
            },
        },
    }),
)

const PaddedDiv = styled(Box)({
    padding: '0',
})

const PermissionDetails = ({ permission }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState('overview')
    return (
        <>
            {!permission ? (
                <Loader />
            ) : (
                <div style={{ height: '100%' }}>
                    <ItemDetails.Tabs
                        variant="standard"
                        value={activeTab}
                        onChange={(_, value) => setActiveTab(value)}
                    >
                        <ItemDetails.Tabs.Tab
                            value="overview"
                            label={t('Overview')}
                        />
                    </ItemDetails.Tabs>

                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '90%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            {activeTab === 'overview' && (
                                <OverviewSection permission={permission} />
                            )}
                        </Box>
                        <Box>
                            <PaddedDiv className={classes.root}>
                                {/* TODO: Data is not present in DB */}
                                {/* <EditCredential item={permission} />  */}
                                <DeletePermission
                                    item={permission}
                                    drawerView={true}
                                />
                            </PaddedDiv>
                        </Box>
                    </Box>
                </div>
            )}
        </>
    )
}

export default PermissionDetails
