import React, { Fragment, useState } from 'react'
import {
    TableHead,
    TableSortLabel,
    TableRow,
    TableCell,
    Box,
    makeStyles,
    styled,
    Table,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tooltip, ToggleSwitch } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { getFullImageUrl } from 'utils'
import { Button, TextFormatter, Person } from 'components'
import DynamicComponents from 'components/DynamicComponents'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import DeleteScope from './DeleteScope'

const maxCharacters = 100

const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
    black: '#282828',
}

const useHeaderRowStyles = makeStyles({
    root: {
        backgroundColor: '#fbfbfd',
        height: '32px',

        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
})

const useSortLabelStyles = makeStyles({
    root: {
        color: '#7d7c7c !important',
        '& svg': {
            color: `#D2D2D9 !important`,
            height: '14px',
            width: '14px',
        },
        '&:hover': {
            color: `${colors.primary} !important`,

            '& svg': {
                color: `${colors.primary} !important`,
            },
        },
    },

    active: {
        '& svg': {
            color: `${colors.primary} !important`,
        },
    },
    icon: {
        opacity: `${1} !important`,
    },
})

const useHeaderCellStyles = makeStyles({
    root: () => ({
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: 'normal',
        height: '62px',
    }),
})

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const ScopeState = ({ item }) => {
    const [status, setStatues] = useState(item.state)

    return (
        <ToggleSwitch
            onChange={() => {
                setStatues((pre) => !pre)
            }}
            value={status}
            labelColor={status ? 'black' : '#848992'}
            label={status ? 'Enabled' : 'Disabled'}
        />
    )
}

export const ScopesTableHead = ({ attributes }) => {
    const { t } = useTranslation()
    const [{ sortBy, sortOrder }, dispatch] = usePageContext()

    const headerRowClasses = useHeaderRowStyles()
    const headerCellClasses = useHeaderCellStyles()
    const sortLabelClasses = useSortLabelStyles()
    const handleSort = (heading) => {
        const sortByName = heading.sortBy ? heading.sortBy : heading.name
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: {
                sortBy: sortByName,
                sortOrder:
                    sortBy !== sortByName
                        ? 'desc'
                        : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                        ? 'desc'
                        : 'asc',
            },
        })
    }

    return (
        <TableHead>
            <>
                <TableRow classes={headerRowClasses}>
                    {attributes.map((heading, index) => {
                        const sortByName = heading.sortBy
                            ? heading.sortBy
                            : heading.name
                        return (
                            <TableCell
                                data-protectedsubcomponent={heading?.requireAccess?.control ? heading.requireAccess.control : "NotProtected"}
                                key={'pd-head-cell-' + index}
                                classes={headerCellClasses}
                                align="left"
                                style={heading.headerCellStyles}
                            >
                                {!heading.sortable ? (
                                    <span
                                        style={{
                                            display: 'flex',
                                            color: '#7d7c7c',
                                        }}
                                    >
                                        {t(heading.label)}
                                    </span>
                                ) : (
                                    <span style={{ display: 'flex' }}>
                                        <Tooltip
                                            title={
                                                sortBy === sortByName &&
                                                sortOrder === 'desc'
                                                    ? t('SortAscending')
                                                    : t('SortDescending')
                                            }
                                        >
                                            <TableSortLabel
                                                classes={sortLabelClasses}
                                                active={sortBy === sortByName}
                                                direction={
                                                    sortBy === sortByName
                                                        ? sortOrder
                                                        : 'asc'
                                                }
                                                onClick={() =>
                                                    handleSort(heading)
                                                }
                                            >
                                                {t(heading.label)}
                                            </TableSortLabel>
                                        </Tooltip>
                                    </span>
                                )}
                            </TableCell>
                        )
                    })}
                </TableRow>
            </>
        </TableHead>
    )
}
const useRowStyles = makeStyles({
    root: {
        width: '150px',
        backgroundColor: '#ffffff',
        borderTopLeftRadius: '8px !important',
        borderTopRightRadius: '8px !important',
        minHeight: '40px',
        border: ' solid 1px #efeff1',

        '&:hover': {
            backgroundColor: '#f7f8fa',
        },
    },

    image: {
        maxHeight: '48px',
    },
})

const StyledTable = styled(Table)({
    border: ' solid 1px #efeff1 !important',
    boxShadow: 'none !important',
    borderCollapse: 'separate',
})

export const ScopesTable = StyledTable

export const ScopeRow = ({ attributes, item }) => {
    const rowClasses = useRowStyles()
    const history = useHistory()

    const renderButton = (a) => {
        switch (a.button.name) {
            default:
                return <></>
        }
    }
    const renderComponent = (a, item) => {
        if (a.component.componentName === 'actionButtons') {
            return (
                <Fragment>
                    <Button.Details
                        item={item}
                        onClick={() => {
                            history.push(
                                `${history.location.search}&scopeId=${item?.id}`,
                            )
                        }}
                    />
                    <span style={{ marginRight: '10px' }} />
                    <DeleteScope item={item} />
                    <span style={{ marginRight: '10px' }} />

                    <UIActionsGearButton item={item} />
                </Fragment>
            )
        } else if (a.component.componentName === 'ScopeState') {
            return <ScopeState attribute={a} item={item}></ScopeState>
        } else {
            const DynamicComponentToRender =
                DynamicComponents[a.component.componentName]
            return (
                <DynamicComponentToRender
                    attribute={a}
                    data={{ item }}
                    imageClass={rowClasses.image}
                />
            )
        }
    }

    return (
        <React.Fragment>
            <TableRow classes={rowClasses}>
                {attributes.map((a, index) => {
                    return (
                        <TableCell
                            key={item.id + `${index}`}
                            style={a.styles}
                            link={a.link}
                            url={a.url}
                        >
                            <Box
                                display="block"
                                alignItems="center"
                                width={a.styles?.width}
                            >
                                {a.avatar && (
                                    <Person
                                        name={item[a.name]}
                                        imgSrc={getFullImageUrl(
                                            item[a.imgProp],
                                        )}
                                        tooltipProps={tooltipProps}
                                        style={a?.textStyles}
                                    />
                                )}

                                {!a.hideValue && (
                                    <TextFormatter
                                        value={item[a.name]}
                                        tooltipProps={tooltipProps}
                                        style={a.styles}
                                        maxCharacters={maxCharacters}
                                    />
                                )}

                                {a.button && renderButton(a)}

                                {a.component && renderComponent(a, item)}
                            </Box>
                        </TableCell>
                    )
                })}
            </TableRow>
        </React.Fragment>
    )
}
