import PropTypes from 'prop-types'
import { Box, Card, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Avatar, Divider, Spinner, Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useApplicationSettings, useRemoveItemFromCart } from 'hooks'
import { Icon } from 'packages/eid-icons'
import { useCartItemStyles } from './styles'
import CartItemViolation from './CartItemViolation'
import classNames from 'classnames'
import { CartItemComment } from './CartItemComment'
const assignmentTypeLabelKey = {
    Add: 'Common_Add',
    Remove: 'Common_Remove',
}

const MyTypography = withStyles({
    h2: {
        color: '#FFFFFF',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '1.2',
    },
    h3: {
        fontSize: '22px',
        color: '#000000',
        textTransform: 'uppercase',
    },
    h4: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#000000',
    },
    caption: {
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#8a8989',
    },
    subtitle1: {
        position: 'absolute',
        fontSize: '150px',
        right: '17px',
        top: '-100px',
        fontWeight: '300',
        color: '#FFF',
        opacity: '0.1',
    },
})(Typography)

const CartItem = (props) => {
    const { data: appConfig } = useApplicationSettings()
    const classes = useCartItemStyles({
        bgColor: appConfig.style.colors.primary?.header,
        assignmentType: props.item.assignmentType,
    })

    const { t } = useTranslation()
    const { item, index, onCommentChange, risks } = props

    const [removeItemFromCart, { isLoading: isRemoving }] =
        useRemoveItemFromCart()

    return (
        <Card className={classes.cartItem} key={`resadmin-cartitem-${index}`}>
            <Box className={classes.cartItemHeader}>
                <Box className={classes.itemLabel}>
                    <Box className={classes.addOrRemoveLabel}>
                        {t(assignmentTypeLabelKey[props.item.assignmentType])}
                    </Box>
                    <Box className={classes.resourceTypeLabel}>
                        <Icon name={item.resourceType_Name} />
                        <Box component="span" marginLeft="8px">
                            {t(`Common_${item.resourceType_Name}`)}
                        </Box>
                    </Box>
                </Box>
                <Box display='flex' alignItems='center'>
                    <Box style={{
                        marginRight: '10px'
                    }}>
                        {/* TODO: Need to show icon as per resource type */}
                        <Avatar />
                    </Box>
                    <MyTypography
                        variant="h2"
                        style={{ wordBreak: 'break-word' }}
                    >
                        {item.resource_FriendlyName}
                    </MyTypography>
                </Box>
                <Box>
                    <MyTypography
                        variant="h2"
                        style={{ fontSize: '89px', color: '#fff', opacity: '0.1', position: 'absolute', top: '-20px', right: '10%' }}
                    >
                        {index < 10 ? `0${index}` : index}
                    </MyTypography>
                </Box>
                <Tooltip title={t('Common_RemoveFromCart')}>
                    <Box
                        className={classes.deleteIconContainer}
                        onClick={() => {
                            if (isRemoving) return
                            removeItemFromCart(item.id)
                        }}
                    >
                        {isRemoving ? (
                            <Spinner color="#d0021b" size={0.6} />
                        ) : (
                            <Icon className="delete" name="Delete" />
                        )}
                    </Box>
                </Tooltip>
            </Box>
            <Box className={classes.cartItemContent}>
                <Box className={classes.cartCommentContainer}>
                    <Box className={classes.cartComment}>
                        <CartItemComment
                            item={item}
                            containsRisks={risks && risks.length > 0}
                            onCommentChange={onCommentChange}
                        />
                    </Box>
                    <Box className={classes.timeConstraints}>
                        {item.timeConstraintActive ? (
                            <>
                                {item.startDateUtc && (
                                    <Attribute
                                        label={t('Common_StartDateTime')}
                                        value={moment
                                            .utc(item.startDateUtc)
                                            .local()
                                            .format('L LT')}
                                    />
                                )}
                                {item.endDateUtc && (
                                    <Attribute
                                        marginTop="14px"
                                        label={t('Common_EndDateTime')}
                                        value={moment
                                            .utc(item.endDateUtc)
                                            .local()
                                            .format('L LT')}
                                    />
                                )}
                            </>
                        ) : (
                            <Attribute
                                label={t('Common_ExpiresOn')}
                                value="-"
                            />
                        )}
                    </Box>
                </Box>

                {/* Uncomment when needed to show additional attributes */}
                <Box className={classes.moreAttributes}>
                    {item.targetPermissionLevelType_FriendlyName && <Attribute
                        className={classes.horizontalOrVertical}
                        label={t('Common_PermissionLevel')}
                        value={item.targetPermissionLevelType_FriendlyName}
                    />}
                </Box>
            </Box>
            {risks && risks.length > 0 && (
                <>
                    <Divider />
                    <Box className={classes.cartItemContent}>
                        {risks.map((r, index) => (
                            <Box key={`${r.id}_${index}`} py={'4px'}>
                                <CartItemViolation risk={r} showReason={true} />
                            </Box>
                        ))}
                    </Box>
                </>
            )}

            <Box
                className={classNames(classes.borderBox, {
                    [classes.riskBorderBox]: risks && risks.length > 0,
                })}
            />
        </Card>
    )
}

const Attribute = ({ label, value, ...rest }) => {
    return (
        <Box {...rest}>
            <Box
                color="#8b909a"
                fontSize="12px"
                style={{ textTransform: 'uppercase' }}
            >
                {label}
            </Box>
            <Box fontSize="14px">{value}</Box>
        </Box>
    )
}

CartItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number,
    handleDelteItem: PropTypes.func,
}

export default CartItem
