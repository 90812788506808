import EligibilityListing from 'components/SharedListings/EligibilityListing'

const ApplicationAppRightsEligbility = ({
    roleId,
    resourceTypeId,
    resourceId,
}) => {
    return (
        <EligibilityListing
            roleId={roleId}
            resourceTypeId={resourceTypeId}
            workflowName={'ManageAzureAppRoleWizard'}
            resourceId={resourceId}
        />
    )
}

export default ApplicationAppRightsEligbility
