import React from 'react'
import AppManagementRoles from './App Management Roles/AppManagementRoles'
import Members from './Members/Members'
import AccessGranted from './TotalAccessGranted'
import DirectAccessGranted from './DirectAccessGranted'
import { PageContextProvider } from 'pageContext'

const ApplicationManagementRoles = ({
    subActiveTab,
    id,
    applicationId,
    applicationResourceId,
}) => {
    const renderContent = () => {
        if (subActiveTab === 'appManagementRoles') {
            return (
                <PageContextProvider key="RequestViewApplicationManagementRolesListing">
                    <AppManagementRoles
                        applicationResourceId={applicationResourceId}
                        applicationId={applicationId}
                    />
                </PageContextProvider>
            )
        } else if (subActiveTab === 'members') {
            return (
                <PageContextProvider key="RequestViewApplicationManagementRolesMembers">
                    <Members id={id} applicationId={applicationId} />
                </PageContextProvider>
            )
        } else if (subActiveTab === 'accessGranted') {
            return (
                <PageContextProvider key="RequestViewApplicationManagementRolesAccessGranted">
                    <AccessGranted applicationId={applicationId} />
                </PageContextProvider>
            )
        } else if (subActiveTab === 'directAccessGranted') {
            return (
                <PageContextProvider key="RequestViewApplicationManagementRolesDirectAccessGranted">
                    <DirectAccessGranted applicationId={applicationId} />
                </PageContextProvider>
            )
        }
        return <></>
    }
    return renderContent()
}

export default ApplicationManagementRoles
