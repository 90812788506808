import { Box, Typography, styled } from '@material-ui/core'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useApplicationRoleDefinitionsAssignments } from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import { Button } from 'components'
import { Icon } from 'packages/eid-icons'
import moment from 'moment'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { getFullPathFromUrl, useDebounce, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import UnAssignAssignment from '../Assignments/UnAssignAssignment'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const ButtonsBox = styled(Box)({
    display: 'flex',
    paddingRight: '10px',
})

const RoleDefinitionAssignments = ({ roleId, resourceId }) => {
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState('')

    const query = useQuery()
    const history = useHistory()

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }

    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationRoleDefinitionsAssignments(roleId, queryData)

    const attributes = [
        {
            name: 'type',
            label: 'Type',
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return <Icon name="ViewProfile" />
                },
            },
        },
        {
            name: 'assignee',
            sortable: true,
            sortBy: 'assignee',
            label: 'Assignee',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'role',
            sortable: true,
            sortBy: 'role',
            label: 'Role',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'assignedTo',
            sortable: true,
            sortBy: 'assignedTo',
            label: 'AssignedTo',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },

        {
            name: 'tenant',
            sortable: true,
            sortBy: 'tenant',
            label: 'Tenant',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'startDate',
            sortable: true,
            sortBy: 'startDate',
            label: 'StartDate',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <Typography
                        style={{ fontSize: '13px', fontWeight: 'normal' }}
                    >
                        {moment.utc(data.startDate).local().format('lll')}
                    </Typography>
                ),
            },
        },
        {
            name: 'endDate',
            sortable: true,
            sortBy: 'endDate',
            label: 'EndDate',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <Typography
                        style={{ fontSize: '13px', fontWeight: 'normal' }}
                    >
                        {moment.utc(data.endDate).local().format('lll')}
                    </Typography>
                ),
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <ButtonsBox>
                            <UnAssignAssignment
                                assignmentId={data?.id}
                                refetch={refetch}
                            />
                        </ButtonsBox>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="16px 30px"
            >
                <Button
                    onClick={() => {
                        query.delete('applicationId')
                        query.delete('roleDefinitionId')
                        query.set('workflowName', 'AssignAzLocalRoleScope')
                        query.set('returnUrl', getFullPathFromUrl())
                        query.set('resourceID', resourceId)
                        history.push(
                            `${
                                config.APP_SUBPATH
                            }/applications/workflows?${query.toString()}`,
                        )
                    }}
                    style={{
                        borderRadius: '4px',
                        padding: '0px 20px',
                        backgroundColor: '#307fc1',
                        border: 'solid 1px #01ae8f',
                        color: '#ffffff',
                        fontSize: '14px',
                        '&:hover': {
                            backgroundColor: '#307fc1',
                        },
                    }}
                >
                    <Box style={{ display: 'flex' }}>
                        <Icon
                            name={'Plus'}
                            fill="white"
                            style={{
                                width: '16px',
                                height: '16px',
                                marginRight: '5px',
                            }}
                        />
                        {t('AddAssignment')}
                    </Box>
                </Button>

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    sLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={attributes}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default RoleDefinitionAssignments
