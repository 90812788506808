import { Box, Typography, makeStyles } from '@material-ui/core'
import { Button } from 'components'
import { Icon } from 'packages/eid-icons'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
    CloseIconeBox,
    CustomDialog,
} from '../../../../components/ApplicationBanner/AddAssignments/AddAssignment'
import TimeConstraintComp from '../../../../components/AddTimeConstraint/TimeConstraint'
import PeopleSearch from 'components/Filters/PeopleSearch'
import AccessAssignmentAccessLevels from './AccessAssignmentAccessLevels'
import { useSaveAccessAssignmentToFolders } from 'hooks'

const useStyles = makeStyles(() => ({
    AddAccessModalMain: {
        '& .MuiDialog-paper': {
            maxWidth: '440px',
        },
    },
    NewAccessAssignmentModal: {
        '& h2': {
            fontSize: '18px',
            color: '#1b1f1f',
            fontWeight: '600',
        },
    },
    ConstraintModalHeader: {
        padding: '32px',
    },
    AssignmentModalHeader: {
        padding: '32px',
    },
    AssignmentModalHeading: {
        padding: '16px 32px',
        borderTop: '1px solid #0000002e !important',
        borderBottom: '1px solid #0000002e !important',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%) !important',
        backgroundSize: '8.00px 8.00px !important',
    },
    AssignmentModalInner: {
        padding: '32px',
    },
    AssignmentModalFooter: {
        padding: '24px 32px',
        boxShadow: '0 3px 7px 4px rgba(0, 0, 0, 0.08)',
        '& button': {
            border: 'solid 1px #307fc1',
            padding: '9px 24px',
            '&:nth-child(1)': {
                background: '#307fc1',
                color: '#fff',
                maxWidth: '240px',
                '& svg': {
                    marginRight: '15px',
                },
            },
            '&:nth-child(2)': {
                color: '#307fc1',
                marginLeft: '30px',
                maxWidth: '90px',
            },
        },
    },
    AssignmentTimeInner: {
        padding: '20px 32px',
        paddingTop: '0',
    },
    errorMessage: {
        color: 'red',
        fontSize: '12px',
    },
}))

const AddAcessAssignentsFolder = ({ sharedFolder }) => {
    const [selectedPerson, setSelectedPerson] = useState(null)
    const [selectedAccessLevel, setSelectedAccessLevel] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [openAcessAssignmentFolderModal, setOpenAcessAssignmentFolderModal] =
        useState(false)
    const { t } = useTranslation()
    const classes = useStyles({})
    const currentDatetime = moment.utc().second(0)
    const [isTimeContrained, setIsTimeConstrained] = useState(false)

    const [{ selectedStartDate, selectedEndDate }, setSelectedDate] = useState({
        selectedStartDate: currentDatetime,
        selectedEndDate: null,
    })

    const [saveAccessAssignmentToFolder, { isLoading }] =
        useSaveAccessAssignmentToFolders()

    const handleStartDate = (startDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedStartDate: startDateToSet }
        })
    }

    const handleEndDate = (endDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedEndDate: endDateToSet }
        })
    }

    const handleSave = () => {
        if (!selectedPerson && !selectedAccessLevel) {
            setErrorMessage(t('SharedFolder_PersonAndAccessLevelIsRequired'))
        } else if (!selectedPerson) {
            setErrorMessage(t('SharedFolder_PersonIsRequired'))
        } else if (!selectedAccessLevel) {
            setErrorMessage(t('SharedFolder_AccessLevelIsRequired'))
        } else if (isTimeContrained && !selectedEndDate) {
            setErrorMessage(t('SharedFolder_EndDateIsRequired'))
        } else if (
            isTimeContrained &&
            moment(selectedStartDate).isAfter(moment(selectedEndDate))
        ) {
            setErrorMessage(t('SharedFolder_InvalidDate'))
        } else {
            const dataObject = {
                assignmentsToAdd: [
                    {
                        assigneeId: selectedPerson.id,
                        requestableResourceTypeId: sharedFolder.resourceTypeId,
                        requestableResourceId: sharedFolder.id,
                        resourceTypeRoleId: selectedAccessLevel.id,
                        validFrom: isTimeContrained ? selectedStartDate : null,
                        validTo: isTimeContrained ? selectedEndDate : null,
                    },
                ],
            }
            saveAccessAssignmentToFolder(dataObject).then((data) => {
                if (data?.isSuccess) {
                    setOpenAcessAssignmentFolderModal(false)
                }
            })
        }
    }

    return (
        <>
            <Button
                onClick={() => setOpenAcessAssignmentFolderModal(true)}
                style={{
                    borderRadius: '4px',
                    padding: '0px 20px',
                    backgroundColor: '#307fc1',
                    border: 'solid 1px #01ae8f',
                    color: '#ffffff',
                    fontSize: '14px',
                    '&:hover': {
                        backgroundColor: '#307fc1',
                    },
                }}
            >
                {t('AddNewAccessAssignment')}
            </Button>
            <CustomDialog
                open={openAcessAssignmentFolderModal}
                onClose={() => setOpenAcessAssignmentFolderModal(false)}
                title={t('')}
                className={classes.AddAccessModalMain}
            >
                <Box className={classes.NewAccessAssignmentModal}>
                    <Box className={classes.AssignmentModalHeader}>
                        <CloseIconeBox
                            onClick={() =>
                                setOpenAcessAssignmentFolderModal(false)
                            }
                        >
                            <Icon
                                name="Close"
                                width="16px"
                                height="16px"
                                color="#959598"
                            />
                        </CloseIconeBox>
                        <Typography variant="h2">
                            {t('AddNewAccessAssignment')}
                        </Typography>
                    </Box>
                    <Box>
                        <Box className={classes.AssignmentModalHeading}>
                            <Typography variant="h2">
                                {t('SelectToWhomYouWishToGrantAccess')}
                            </Typography>
                        </Box>
                        <Box className={classes.AssignmentModalInner}>
                            <Box>
                                <PeopleSearch
                                    labelKey="PersonNameToSelect"
                                    value={selectedPerson}
                                    onChange={(_, value) =>
                                        setSelectedPerson(value)
                                    }
                                />
                            </Box>
                            <Box marginTop="24px">
                                <AccessAssignmentAccessLevels
                                    labelKey="AccessLevel"
                                    url={`/api/resourceassignments/resourcetyperoles/${sharedFolder?.id}`}
                                    value={selectedAccessLevel}
                                    onChange={(_, value) =>
                                        setSelectedAccessLevel(value)
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box className={classes.AssignmentModalHeading}>
                            <Typography variant="h2">
                                {t('TimeConstraint')}
                            </Typography>
                        </Box>
                        <TimeConstraintComp
                            handleStartDate={handleStartDate}
                            handleEndDate={handleEndDate}
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            hasInvalidDates={true}
                            validationMessage={''}
                            handleTimeConstraint={(value) =>
                                setIsTimeConstrained(value)
                            }
                        />
                    </Box>
                    <Box className={classes.AssignmentModalFooter}>
                        <Button
                            backgroundColor="#307fc1"
                            onClick={() => handleSave()}
                            loading={isLoading}
                        >
                            <Fragment>
                                <Icon
                                    name="Save"
                                    width="16px"
                                    height="16px"
                                    color="#fff"
                                />
                                {t('Save')}
                            </Fragment>
                        </Button>
                        <Button
                            onClick={() =>
                                setOpenAcessAssignmentFolderModal(false)
                            }
                        >
                            {t('Cancel')}
                        </Button>
                    </Box>
                    <Box style={{ padding: '0px 32px', marginBottom: '10px' }}>
                        <Typography className={classes.errorMessage}>
                            {errorMessage}
                        </Typography>
                    </Box>
                </Box>
            </CustomDialog>
        </>
    )
}

export default AddAcessAssignentsFolder
