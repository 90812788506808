import { Fragment, useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Paper, styled } from '@material-ui/core'
import { useManagementRole, useApplicationSettings } from 'hooks'
import { Overview } from './Overview'
import { LeftPaneActions, LeftPaneTabs } from 'components'
import { PeopleAsMembers } from './PeopleAsMembers'
import { AllMembers } from './AllMembers'
import { PageContextProvider } from 'pageContext'
import { useIsSmallScreenFalse, useQuery } from 'packages/core'
import StickyContainer from 'components/StickyContainer'
import { ItemDetails, Button, TextFormatter } from 'components'
import bgHeader from '../../../assets/images/bgHeader.png'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'
import { useHistory } from 'react-router'
import AccessGranted from './AccessGranted'
import { useAppState } from 'appContext'
import TotalAccessGranted from './TotalAccessGranted'
import { Skeleton } from '@material-ui/lab'
import { getAccessibleTabs, updateUrlQueryParams } from 'utils'
const HeaderContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 48px 0px 32px',
    overflow: 'hidden',
    color: '#ffffff',
    fontSize: '22px',
    textAlign: 'center',
})
const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
            width: '100%',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        imgContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            width: '100%',
            minHeight: '125px',
            borderRadius: '8px',
            marginBottom: '20px',
            '& img': {
                maxHeight: '80px',
                maxWidth: '80px',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                minWidth: '116px',
                '& img': {
                    maxHeight: '33px',
                    width: '66px',
                },
            },
        },
        nameContainer: {
            width: '100%',
            minHeight: '50px',
            marginBottom: '20px',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            overflow: 'hidden',
            fontSize: '22px',
            fontWeight: 'bold',
            borderRadius: '8px',
            color: '#3b454d',
            wordBreak: 'break-word',
            padding: '5px',
        },
        tabsContainer: {
            width: '100%',
            borderRadius: '0px',
            marginBottom: '20px',
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'none',
            },
        },
        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        description: {
            [`@media (min-width:1366px)`]: {
                maxWidth: '300px',
            },
        },
        actionsContainer: {
            minWidth: '230px',
            paddingRight: theme.spacing(2),

            [`@media (max-width:445px)`]: {
                alignItems: 'flex-start',
            },
        },
        backButton: {
            '& >button': {
                '&:hover': {
                    backgroundColor: '#307fc1',
                    color: 'white',
                    '& span': {
                        color: theme.palette.common.white,
                    },
                },
            },
        },
        moreInfoButton: {
            marginTop: theme.spacing(3),
            '& >button': {
                backgroundColor: theme.palette.grey[900],
                color: theme.palette.common.white,

                border: `solid 1px ${theme.palette.grey[900]}`,

                '&:hover': {
                    backgroundColor: theme.palette.grey[900],

                    '& span': {},
                },
            },
        },
        actionDropdown: {
            marginTop: '40px',
        },
        showMoreButton: {
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
            position: 'absolute',
            bottom: '-15px',
            left: 'calc(50% - 83px)',
            zIndex: 2,
            '& > button': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffffff',
                border: 'none',
                height: '32px',
                minWidth: '115px',
                color: '#307fc1',
                fontSize: '11px',
                textTransform: 'uppercase',
                borderRadius: '15px',
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                cursor: 'pointer',
                paddingLeft: '12px',
                paddingRight: '6px',
                '& svg': {
                    color: '#307fc1',
                    height: '20px',
                },
            },
        },
        collapsibleContainer: {
            display: 'flex',
            flexDirection: 'column',
            [`@media (min-width:${smallScreenWidth}px)`]: {
                display: 'none',
            },
            '& div': {
                textAlign: 'start',
            },
        },
        actionContainerMobile: {},
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: '#307fc1',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
        commonDeputies: {
            'flex-wrap': 'nowrap',
        },
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
    }),
)
export const TabsColumn = styled(Box)({
    display: 'flex',
    minWidth: '340px',
    flexDirection: 'column',
    margin: '0px 12px 0px 10px',
    marginRight: '20px',
    width: '30%',
    flex: '36%',
})

export const ManagementRoleDetails = (props) => {
    const classes = useStyles()
    const { tabs, basicAttributes, managementRoleId } = props
    const { isLoading: detailsLoading, data: managementRole } =
        useManagementRole(managementRoleId)

    const query = useQuery()
    const isSmallScreen = useIsSmallScreenFalse()
    const { data: appConfig } = useApplicationSettings()
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const history = useHistory()
    const [, dispatch] = useAppState()

    const [activeTab, setActiveTab] = useState(null)
    const [subActiveTab, setSubActiveTab] = useState(null)
    const [allAccessibleTabs, setAllAccessibleTabs] = useState([])

    useEffect(() => {
        if (managementRole) {
            const queryActiveTab = query.get('activeTab')
            const queryActiveSubTab = query.get('subActiveTab')
            const activeTabInfo = getAccessibleTabs(
                query,
                history,
                tabs,
                queryActiveTab,
                queryActiveSubTab,
                hasAccessToControl,
                'activeTab',
                'subActiveTab',
            )
            setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
            setSubActiveTab(activeTabInfo.querySubActiveTab)
            setActiveTab(activeTabInfo.activeTab)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managementRole])

    useEffect(() => {
        if (managementRoleId && managementRole?.id) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: {
                    targetAssigneeId: managementRoleId,
                    targetAssigneeFriendlyName:
                        managementRole?.friendlyName ?? 'Management Roles',
                    targetAssignee_ResourceTypeFriendlyName: 'ManagementRoles',
                },
            })

            dispatch({
                type: 'SET_TARGET_RESOURCE',
                payload: {
                    targetAssigneeId: managementRoleId,
                    targetAssigneeFriendlyName:
                        managementRole?.friendlyName ?? 'Management Roles',
                    targetAssignee_ResourceTypeFriendlyName: 'ManagementRoles',
                },
            })
        }
    }, [managementRoleId, managementRole?.id])

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        updateUrlQueryParams(
            query,
            history,
            [{ key: 'activeTab', value: tab }],
            ['subActiveTab', 'returnedFromWorkflow'],
        )
    }

    if (isSmallScreen) {
        return (
            <>
                <StickyContainer
                    color="#ffffff"
                    styles={{
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Box paddingY="10px" width="100%" maxWidth="260px">
                            <Button.BackButton
                                onClick={() => {
                                    history.push(history.location.pathname)
                                }}
                            >
                                {t('BackToManagementRolesList')}
                            </Button.BackButton>
                        </Box>

                        <HeaderContainer
                            height="80px"
                            textAlign="start"
                            bgcolor={appConfig.style.colors.primary?.header}
                            style={{
                                backgroundImage: `url(${bgHeader})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <TextFormatter
                                value={
                                    managementRole?.name ??
                                    managementRole?.description
                                }
                                maxCharacters={75}
                            />
                        </HeaderContainer>
                    </Box>
                </StickyContainer>
                <ItemDetails.Tabs
                    variant="scrollable"
                    value={activeTab}
                    onChange={(_, value) => handleTabChange(value)}
                    style={{ background: '#fbfbfd' }}
                >
                    {allAccessibleTabs.map((tab, index) => (
                        <ItemDetails.Tabs.Tab
                            key={index + tab.value}
                            value={tab.value}
                            label={t(tab.label)}
                        />
                    ))}
                </ItemDetails.Tabs>
                <Box className={classes.root}>
                    {activeTab === 'overview' && (
                        <PageContextProvider key={'ManagementRolesOverview'}>
                            <Overview
                                managementRoleId={managementRoleId}
                                detailsLoading={detailsLoading}
                                basicAttributes={basicAttributes}
                            />
                        </PageContextProvider>
                    )}
                    {activeTab === 'peopleMembers' && (
                        <PageContextProvider
                            key={'ManagementRolesPeopleMembers'}
                        >
                            {managementRole ? (
                                <PeopleAsMembers
                                    managementRole={managementRole}
                                />
                            ) : (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'allMembers' && (
                        <PageContextProvider key={'ManagementRolesAllMembers'}>
                            <AllMembers managementRole={managementRole} />
                        </PageContextProvider>
                    )}
                    {activeTab === 'accessGranted' && (
                        <PageContextProvider
                            key={'ManagementRolesAccessGranted'}
                        >
                            <AccessGranted
                                managementRoleId={managementRoleId}
                                resourceTypeId={managementRole?.resourceTypeId}
                                resourceId={managementRole?.resourceId}
                            />
                        </PageContextProvider>
                    )}
                    {activeTab === 'totalAccessGranted' && (
                        <PageContextProvider
                            key={'ManagementRolesTotalAccessGranted'}
                        >
                            <TotalAccessGranted
                                managementRoleId={managementRoleId}
                            />
                        </PageContextProvider>
                    )}
                </Box>
            </>
        )
    }
    return (
        <>
            <Box display="flex">
                <TabsColumn>
                    <Paper className={classes.nameContainer}>
                        {managementRole?.friendlyName}
                    </Paper>
                    <Paper className={classes.tabsContainer}>
                        <LeftPaneTabs
                            active={activeTab}
                            onChange={handleTabChange}
                            application={managementRole}
                            tabs={tabs}
                        />
                    </Paper>
                    <LeftPaneActions
                        resource={managementRole}
                        resourceType="managementRoles"
                        activeTab={activeTab}
                    />
                </TabsColumn>
                <Box className={classes.root}>
                    {activeTab === 'overview' && (
                        <PageContextProvider key={'ManagementRolesOverview'}>
                            <Overview
                                managementRoleId={managementRoleId}
                                detailsLoading={detailsLoading}
                                basicAttributes={basicAttributes}
                            />
                        </PageContextProvider>
                    )}
                    {activeTab === 'peopleMembers' && (
                        <PageContextProvider
                            key={'ManagementRolesPeopleMembers'}
                        >
                            {managementRole ? (
                                <PeopleAsMembers
                                    managementRole={managementRole}
                                />
                            ) : (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            )}
                        </PageContextProvider>
                    )}
                    {activeTab === 'allMembers' && (
                        <PageContextProvider key={'ManagementRolesAllMembers'}>
                            <AllMembers managementRole={managementRole} />
                        </PageContextProvider>
                    )}
                    {activeTab === 'accessGranted' && (
                        <PageContextProvider
                            key={'ManagementRolesAccessGranted'}
                        >
                            <AccessGranted
                                managementRoleId={managementRoleId}
                                resourceTypeId={managementRole?.resourceTypeId}
                                resourceId={managementRole?.resourceId}
                            />
                        </PageContextProvider>
                    )}
                    {activeTab === 'totalAccessGranted' && (
                        <PageContextProvider
                            key={'ManagementRolesTotalAccessGranted'}
                        >
                            <TotalAccessGranted
                                managementRoleId={managementRoleId}
                            />
                        </PageContextProvider>
                    )}
                </Box>
            </Box>
        </>
    )
}
