import { Box } from '@material-ui/core'
import TextFieldWithSubmitButton from './TextFieldWithSubmitButton'
import { FC, useState } from 'react'
import { isWhitespace } from 'packages/core'
import { useTargetPerson, useUpdateCartItemComment } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const COMMENT_MAX_LENGTH = 500

export const CartItemComment: FC<any> = (props) => {
    const { t } = useTranslation()

    const { onCommentChange, item, containsRisks } = props

    const [updateCartItemComment]: any = useUpdateCartItemComment()

    const [targetPerson] = useTargetPerson()

    const [comment, setComment] = useState(item.comment ?? '')

    const [focused, setFocused] = useState(false)

    const handleSubmit = () => {
        immediatelySubmit(comment)
    }

    const immediatelySubmit = (comm: any) => {
        let newComment = comm
        if (!newComment) {
            newComment = null
        }

        if (
            newComment &&
            (newComment.length > COMMENT_MAX_LENGTH || isWhitespace(newComment))
        )
            return
        if (item.comment !== newComment) {
            updateCartItemComment({
                cartItemId: item.id,
                comment: newComment,
                targetAssigneeId: item.targetAssigneeId,
            })
        }
        setFocused(false)
    }

    const justificationCommentErrorMessage = containsRisks &&
        !Boolean(comment) && (
            <Box display="flex" color="#d0021b" fontSize="12px" my="13px">
                <Box>
                    <Icon name="CriticalRisk" width="16" height="16" />
                </Box>
                <Box marginLeft="8px">
                    {t('Common_JustificationCommentRequired')}
                </Box>
            </Box>
        )

    const commentField = (
        <Box minHeight="86px">
            <TextFieldWithSubmitButton
                fullWidth
                multiline
                rowsMax={5}
                maxCharacters={COMMENT_MAX_LENGTH}
                margin="none"
                placeholder={t('Common_AddComment')}
                value={comment}
                onChange={(newValue: any) => {
                    setComment(newValue)
                    onCommentChange(item.id, newValue)
                }}
                onSubmit={handleSubmit}
                onBlur={handleSubmit}
                showSubmitButton={focused}
                onFocus={() => {
                    setFocused(true)
                }}
                immediatelySubmit={immediatelySubmit}
                errorMessageComponent={justificationCommentErrorMessage}
                required={containsRisks}
            />
        </Box>
    )

    return <>{commentField}</>
}
