import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { useIsSmallScreenFalse } from 'packages/core'
import { useApplicationPermissions } from 'hooks'
import {
    useEidGrid,
    CardViewLoader,
    ListingGrid,
    GridContainer,
    Filters,
} from 'components'
import {
    PermissionRow,
    PermissionsTable,
    PermissionsTableHead,
} from './PermissionsTableComponent'
import permissionsAttributes from './permissionsAttributes'
import PermissionCard from './PermissionsCard'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from '../CreateWorkflowButton'

const hasAccessToAddPermissionControlName =
    'ResourceAdmin-Application-AddAPIPermission-Control'

const ApplicationPermissions = ({ id, resourceId }) => {
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationPermissions(id)
    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? 'card' : 'table'
    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoPermissionsMessage'),
        pagination,
        attributes: permissionsAttributes,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: PermissionCard,
        },
        tableViewConfig: {
            TableComponent: PermissionsTable,
            TableHeadComponent: PermissionsTableHead,
            TableRowComponent: PermissionRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                {hasAccessToControl(hasAccessToAddPermissionControlName) && (
                    <Box
                        data-protectedsubcomponent={
                            hasAccessToAddPermissionControlName
                        }
                    >
                        <CreateWorkflowButton
                            label={t('AddAPIPermission')}
                            workflowName="UpdateAzureAppAPIPermissions"
                            resourceId={resourceId}
                        />
                    </Box>
                )}
                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        width: isSmallScreen ? 'auto' : '308px',
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                {viewToRender}
            </GridContainer>
        </ListingGrid>
    )
}

export default ApplicationPermissions
