import { Box, styled } from '@material-ui/core'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useApplicationAppRightsAssignments,
    useDeleteApplicationAppRightsAssignments,
} from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import { Button } from 'components'
import { WarningModal } from 'packages/eid-ui'
import AssigneePerson from 'components/AssigneePerson/AssigneePerson'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from 'components/ApplicationBanner/CreateWorkflowButton'
import { useDebounce } from 'packages/core'

const SHOW_CONFIRM_DEFAULT = {
    show: false,
    title: '',
    description: '',
}

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const RemoveSelectedItem = styled(Box)({
    width: '290px',
})

const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})

const ApplicationAppRightsAssignments = ({
    resourceSystemModuleID,
    resourceId,
}) => {
    const { t } = useTranslation()
    const [selectedIds, setSelectedIds] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState([])
    const [selectedItemToDelete, setSelectedItemToDelete] = useState(null)
    const [showConfirm, setShowConfirm] = useState(SHOW_CONFIRM_DEFAULT)

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }

    const {
        list: myList,
        loading,
        loadingMore,
        pagination,
    } = useApplicationAppRightsAssignments(
        `/api/LocalRights/appRoles/membersByApplicationID`,
        resourceSystemModuleID,
        queryData,
    )

    const list = myList?.map((i, index) => {
        return { id: index, ...i }
    })
    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useDeleteApplicationAppRightsAssignments(resourceSystemModuleID)

    const {
        canAddApplicationAppRightAssignments,
        canBulkDeleteApplicationAppRightAssignments,
        canSelectApplicationAppRightAssignments,
        hasAccessToControl,
    } = useSubcomponents()

    const attributes = [
        {
            name: 'assignee',
            sortable: true,
            sortBy: 'assignee',
            label: 'Assignee',
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return <AssigneePerson data={data} />
                },
            },
        },
        {
            name: 'appRight',
            sortable: true,
            sortBy: 'appRight',
            label: 'AppRight',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'rightType',
            sortable: true,
            sortBy: 'rightType',
            label: 'RightType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'assignedTo',
            sortable: true,
            sortBy: 'assignedTo',
            label: 'AssignedTo',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },

        {
            name: 'resourceSystem',
            sortable: true,
            sortBy: 'resourceSystem',
            label: 'ResourceSystem',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-DeleteApplicationAppRightAssignments-Control',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <DeleteButton>
                            <Button.Delete
                                showEndIcon={false}
                                onClick={() => handleSingleDelete(data)}
                            />
                        </DeleteButton>
                    )
                },
            },
        },
    ]

    const handleSingleDelete = (item) => {
        setSelectedItemToDelete([item])
        setShowConfirm({
            show: true,
            title: `${t('Delete')} ${item?.assignee}`,
            description: t('AppRoleDeleteMessage'),
            isBulk: false,
        })
    }

    const handleDeleteConfirm = () => {
        const itemToDelete = showConfirm?.isBulk
            ? selectedItemsToDelete
            : selectedItemToDelete
        deleteAssignments(itemToDelete).then(() => {
            setSelectedIds([])
            setShowConfirm(SHOW_CONFIRM_DEFAULT)
        })
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selectedIds]
        const selectedIndex = selectedIds.findIndex((id) => id === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelectedIds(filteredList)
        } else {
            setSelectedIds([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selectedIds.length === list.length) {
            setSelectedIds([])
        } else {
            const filteredList = list.map((item) => item.id)

            setSelectedIds(filteredList)
        }
    }

    const selectAllAction = canBulkDeleteApplicationAppRightAssignments
        ? {
              style: {
                  padding: '0px !important',
              },
              render: () => {
                  return (
                      <RemoveSelectedItem
                          data-protectedsubcomponent={
                              canBulkDeleteApplicationAppRightAssignments
                          }
                      >
                          <BulkRemoveButton
                              selectedCount={selectedIds.length}
                              label={t('RemoveSelectedAssignments', {
                                  count: selectedIds.length,
                              })}
                              onClick={handleBulkAction}
                          />
                      </RemoveSelectedItem>
                  )
              },
          }
        : null

    const handleBulkAction = () => {
        const selectedItems = list.filter((i) => selectedIds.includes(i.id))
        setSelectedItemsToDelete(selectedItems)
        setShowConfirm({
            show: true,
            title: `${t('DeleteSelectedItems')}`,
            description: t('AppRoleDeleteSelectedMessage'),
            isBulk: true,
        })
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="16px 0px"
            >
                {canAddApplicationAppRightAssignments && (
                    <Box
                        data-protectedsubcomponent={
                            canAddApplicationAppRightAssignments
                        }
                    >
                        <CreateWorkflowButton
                            label={t('AddNewAppRightAssignment')}
                            workflowName="AssignAzLocalRightScope"
                            resourceId={resourceId}
                        />
                    </Box>
                )}

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('Search and Filter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    selectAllAction={selectAllAction}
                    rowSelection={canSelectApplicationAppRightAssignments}
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selectedIds}
                    handleClick={handleRowSelection}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={attributes.filter(
                        (x) =>
                            !x?.requireAccess?.control ||
                            hasAccessToControl(x?.requireAccess?.control),
                    )}
                    list={list}
                />
            </TableOverView>
            {showConfirm?.show && (
                <WarningModal
                    title={showConfirm?.title}
                    description={showConfirm?.description}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={showConfirm?.show}
                    cancel={() => {
                        setShowConfirm(SHOW_CONFIRM_DEFAULT)
                    }}
                    proceeding={isDeletingAssignment}
                    proceed={handleDeleteConfirm}
                />
            )}
        </Box>
    )
}

export default ApplicationAppRightsAssignments
