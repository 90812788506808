import React, { useEffect, useRef, useState } from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
    Checkbox,
    TableSortLabel,
    Box,
} from '@material-ui/core'
import clsx from 'clsx'
import { Loader, Tooltip } from 'packages/eid-ui'
import { Trans, useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
const smallScreenWidth = 960

const useStyles = makeStyles({
    headGrey: {
        color: '#7d7c7c !important',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    tableCell: {
        maxWidth: '220px',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '15px 10px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '20px',
        },
        '&:last-child': {
            paddingRight: '20px',
        },
    },
    tableHeaderCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: '20px',
        fontSize: '12px',
        color: '#307fc1 !important',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },
    },
    tableContainer: {
        scrollBehavior: 'smooth',

        [`@media (max-width:${smallScreenWidth - 1}px)`]: {
            marginTop: '-3px !important',
            overflowX: 'scroll !important',
        },
    },
    scrollableBox: {
        [`@media (max-width:${smallScreenWidth - 1}px)`]: {
            width: 'max-content',
        },
    },
})

const useSortLabelStyles = makeStyles({
    root: {
        color: '#7d7c7c !important',
        '& svg': {
            color: `#D2D2D9 !important`,
            height: '14px',
            width: '14px',
        },
        '&:hover': {
            color: `#307fc1 !important`,

            '& svg': {
                color: `#307fc1 !important`,
            },
        },
    },

    active: {
        '& svg': {
            color: `#307fc1 !important`,
        },
    },
    icon: {
        opacity: `${1} !important`,
    },
})

const TableGrid = (props) => {
    const {
        data,
        attributes,
        loading,
        rowSelection,
        fallbackMessage,
        onSelect,
        onSort,
        selected,
        tableClasses,
        sorting,
        primaryKey,
        isScrollable = false,
    } = props

    const { t } = useTranslation()
    const defaultClasses = useStyles({})
    const classes = tableClasses ?? defaultClasses
    const sortLabelClasses = useSortLabelStyles()
    const containerRef = useRef(null)
    const [containerHeight, setContainerHeight] = useState(0)
    const [containerPositionY, setcontainerPositionY] = useState(0)
    const [showRightScroll, setShowRightScroll] = useState(true)
    const [showLeftScroll, setShowLeftScroll] = useState(false)

    useEffect(() => {
        setcontainerPositionY(containerRef.current.offsetTop)
        setContainerHeight(containerRef.current.clientHeight)
    })

    const handleSort = (attribute) => {
        const sortByName = attribute.sortBy ? attribute.sortBy : attribute.name
        const sortOrder =
            sorting.sortBy !== sortByName
                ? 'desc'
                : !sorting.sortOrder ||
                  sorting.sortOrder === '' ||
                  sorting.sortOrder === 'asc'
                ? 'desc'
                : 'asc'

        onSort({ sortOrder, sortBy: sortByName })
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) =>
                primaryKey ? n[primaryKey] : n.id,
            )
            onSelect(newSelecteds)
            return
        }
        onSelect([])
    }

    const handleClick = (item) => {
        const selectedIndex = selected.indexOf(
            primaryKey ? item[primaryKey] : item.id,
        )
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(
                selected,
                primaryKey ? item[primaryKey] : item.id,
            )
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        onSelect(newSelected)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    const getValue = (attribute, item) => {
        if (attribute.resolve) {
            if (!attribute.showText) {
                return attribute.resolve(item, attribute)
            } else {
                return (
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box style={{ paddingRight: 0 }}>
                            {item[attribute.name] ?? '-'}
                        </Box>
                        <Box
                            style={{
                                position: 'relative',
                            }}
                        >
                            {attribute.resolve(item, attribute)}
                        </Box>
                    </Box>
                )
            }
        }

        return item[attribute.name] ?? '-'
    }
    const handleLeftScrollClick = () => {
        const { y } = containerRef.current.getBoundingClientRect()

        containerRef.current.scrollTo(
            containerRef.current.scrollLeft + window.innerWidth,
            y,
        )
    }
    const handleRightScrollClick = () => {
        const { y } = containerRef.current.getBoundingClientRect()

        containerRef.current.scrollTo(
            -(containerRef.current.scrollLeft + window.innerWidth),
            y,
        )
    }
    const handleScroll = () => {
        const totalWidth =
            containerRef.current.scrollLeft + containerRef.current.clientWidth
        const dif = totalWidth - containerRef.current.scrollWidth
        setShowRightScroll(!(dif >= -3 && dif <= 3))
        setShowLeftScroll(containerRef.current.scrollLeft > 3)
    }
    return (
        <Box
            ref={containerRef}
            className={classes.tableContainer}
            onScroll={() => handleScroll()}
        >
            <Box className={classes.scrollableBox}>
                <Box>
                    {isScrollable && (
                        <Box>
                            {showLeftScroll && (
                                <Box
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: containerPositionY,
                                        height: containerHeight,
                                        background:
                                            'linear-gradient(to left, rgba(248,248,248,0.3) 0%, rgba(229,230,233,1) 100%)',
                                    }}
                                >
                                    <Box
                                        style={{
                                            marginTop: containerHeight / 2,
                                            marginLeft: '5px',
                                            backgroundColor: '#307fc1',
                                            height: '24px',
                                            width: '24px',
                                            borderRadius: '50%',
                                            padding: '3px',
                                        }}
                                        onClick={() => handleRightScrollClick()}
                                    >
                                        <Icon
                                            direction="left"
                                            name="ArrowScroll"
                                        />
                                    </Box>
                                </Box>
                            )}
                            {showRightScroll && (
                                <Box
                                    style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: containerPositionY,
                                        height: containerHeight,
                                        background:
                                            'linear-gradient(to right, rgba(248,248,248,0.3) 0%, rgba(229,230,233,1) 100%)',
                                    }}
                                >
                                    <Box
                                        style={{
                                            marginTop: containerHeight / 2,
                                            marginRight: '5px',
                                            backgroundColor: '#307fc1',
                                            height: '24px',
                                            width: '24px',
                                            borderRadius: '50%',
                                            padding: '3px',
                                        }}
                                        onClick={() => handleLeftScrollClick()}
                                    >
                                        <Icon
                                            direction="right"
                                            name="ArrowScroll"
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                {rowSelection && (
                                    <TableCell
                                        padding="checkbox"
                                        className={classes.headGrey}
                                    >
                                        <Checkbox
                                            icon={<Icon name="CheckBox" />}
                                            checkedIcon={
                                                <Icon name="CheckedBox" />
                                            }
                                            checked={
                                                data?.length > 0 &&
                                                selected.length === data?.length
                                            }
                                            onChange={handleSelectAllClick}
                                        />
                                    </TableCell>
                                )}

                                {attributes.map((heading, index) => {
                                    const sortByName = heading.sortBy
                                        ? heading.sortBy
                                        : heading.name
                                    return (
                                        <TableCell
                                            key={index}
                                            className={clsx(
                                                classes.tableHeaderCell,
                                                classes.tableCell,
                                                classes.headGrey,
                                            )}
                                            style={heading.headerCellStyle}
                                            align={heading.align}
                                        >
                                            {!heading.sortable ? (
                                                heading.label
                                            ) : (
                                                <span
                                                    style={{ display: 'flex' }}
                                                >
                                                    <Tooltip
                                                        title={
                                                            sorting.sortBy ===
                                                                sortByName &&
                                                            sorting.sortOrder ===
                                                                'desc'
                                                                ? t(
                                                                      'SortAscending',
                                                                  )
                                                                : t(
                                                                      'SortDescending',
                                                                  )
                                                        }
                                                    >
                                                        <TableSortLabel
                                                            classes={
                                                                sortLabelClasses
                                                            }
                                                            active={
                                                                sorting.sortBy ===
                                                                sortByName
                                                            }
                                                            direction={
                                                                sorting.sortBy ===
                                                                sortByName
                                                                    ? sorting.sortOrder
                                                                    : 'asc'
                                                            }
                                                            onClick={() =>
                                                                handleSort(
                                                                    heading,
                                                                )
                                                            }
                                                        >
                                                            {t(heading.label)}
                                                        </TableSortLabel>
                                                    </Tooltip>
                                                </span>
                                            )}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && (
                                <TableRow>
                                    <TableCell
                                        colSpan={
                                            rowSelection
                                                ? attributes.length + 1
                                                : attributes.length
                                        }
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Loader />
                                    </TableCell>
                                </TableRow>
                            )}
                            {data &&
                                (data.length === 0 ? (
                                    <TableRow className={classes.tableRow}>
                                        <TableCell
                                            className={classes.tableCell}
                                            colSpan={
                                                rowSelection
                                                    ? attributes.length + 1
                                                    : attributes.length
                                            }
                                            style={{ textAlign: 'center' }}
                                        >
                                            {fallbackMessage}
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {data.map((item) => (
                                            <TableRow
                                                key={
                                                    primaryKey
                                                        ? item[primaryKey]
                                                        : item.id
                                                }
                                                className={classes.tableRow}
                                            >
                                                {rowSelection && (
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            icon={
                                                                <Icon name="CheckBox" />
                                                            }
                                                            checkedIcon={
                                                                <Icon name="CheckedBox" />
                                                            }
                                                            checked={isSelected(
                                                                primaryKey
                                                                    ? item[
                                                                          primaryKey
                                                                      ]
                                                                    : item.id,
                                                            )}
                                                            onClick={() =>
                                                                handleClick(
                                                                    item,
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                )}

                                                {attributes.map((a, index) => (
                                                    <TableCell
                                                        key={`${
                                                            primaryKey
                                                                ? item[
                                                                      primaryKey
                                                                  ]
                                                                : item.id
                                                        }${index}`}
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        style={a.style}
                                                    >
                                                        {getValue(a, item)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        </Box>
    )
}

TableGrid.defaultProps = {
    fallbackMessage: <Trans i18nKey="NoDataFound" />,
}
export default TableGrid
