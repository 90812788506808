import { Box, styled } from '@material-ui/core'
import { useApplicationSettings } from 'hooks'
import ItemCard from 'components/ItemCard'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'packages/core'
import { mobileScreenWidth } from 'utils'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { useTranslation } from 'react-i18next'
import GenericButton from 'components/Button/GenericButton'

const Container = styled(Box)({
    display: 'inline-block',
    width: '33%',

    overflow: 'hidden',
    [`@media (max-width:${mobileScreenWidth})`]: {
        width: '100%',
    },
})

const CardView = ({ item, attributes, onClickDetailButton }) => {
    const { data: appConfig } = useApplicationSettings()
    const history = useHistory()
    const query = useQuery()
    const { t } = useTranslation()

    return (
        <Container>
            <ItemCard
                key={item.key}
                cardStyles={{
                    margin: '14px',
                }}
                headerStyles={{
                    backgroundImage: appConfig.style.colors.primary?.gradient,
                    backgroundColor: appConfig.style.colors.primary?.header,
                    padding: '20px',
                }}
                attributes={attributes}
                item={item}
                onItemClick={() => {
                    query.set('id', item.id)
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
                rightButton={
                    <UIActionsGearButton item={item} noun={'ResourceAdmin'} />
                }
                leftButton={
                    <GenericButton
                        onClick={() => {
                            onClickDetailButton(item)
                        }}
                        color="#01ae8f"
                        rootStylesProp={{
                            borderRadius: '4px',
                            width: '80%',
                            height: '32px',
                        }}
                    >
                        {t('Details')}
                    </GenericButton>
                }
            />
        </Container>
    )
}

export default CardView
