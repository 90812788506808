import {
    Box,
    Button,
    createStyles,
    makeStyles,
    styled,
} from '@material-ui/core'
import { useEligibilityAssignments } from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import useSubcomponents from 'useSubcomponents'
import { getFullPathFromUrl, useDebounce, useIsSmallScreenFalse, useQuery } from 'packages/core'
import TableGrid from 'components/TableGrid'
import { Icon } from 'packages/eid-icons'
import { useHistory } from 'react-router'
import config from 'config'

const useButtonStyles = makeStyles(
    createStyles({
        root: (isSmallScreen) => ({
            height: '32px',
            borderRadius: '4px',
            padding: '0px 15px 0px 10px',
            backgroundColor: '#307fc1',
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '14px',
            margin: isSmallScreen ? '16px 16px 0px 16px' : '',
            '&:hover': {
                backgroundColor: '#307fc1',
            },
            '& svg': {
                marginRight: '8px',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)

const SearchMenuBarFilter = styled(Box)({
    width: '100%',
    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const canConfigureEligibilityControlName =
    'ResourceAdmin-ManagementRoleDetails-Eligibility-ConfigureEligibility-Control'

const Eligibility = ({ roleId, resourceTypeId, resourceId }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const dataObject = {
        AssignmentPointId: roleId,
        ResourceTypeId: resourceTypeId,
    }

    const query = useQuery()
    const history = useHistory()
    const classes = useButtonStyles(isSmallScreen)

    const [searchKey, setSearchKey] = useState('')

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }
    const { list, loading } = useEligibilityAssignments(dataObject, queryData)
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const attributes = [
        {
            name: 'eligibilityType',
            label: t('EligibilityType'),
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Eligibility-EligibilityType',
            },
        },
        {
            name: 'assigneeType',
            label: t('AssigneeType'),
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Eligibility-AssigneeType',
            },
        },
        {
            name: 'assigneeFriendlyName',
            label: t('Assignee'),
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Eligibility-Assignee',
            },
        },
        {
            name: 'resourceTypeFriendlyName',
            label: t('ResourceType'),
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Eligibility-ResourceType',
            },
        },

        {
            name: 'eligibilityAssignmentType',
            label: t('AssignmentType'),
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-Eligibility-AssignmentType',
            },
        },
    ]

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="16px 32px"
                bgcolor="white"
                borderTop="1px solid #eef0f4"
            >
                {hasAccessToControl(canConfigureEligibilityControlName) && (
                    <Box
                        data-protectedSubcomonent={
                            canConfigureEligibilityControlName
                        }
                    >
                        <Button
                            onClick={() => {
                                query.delete('managementRoleId')
                                query.set(
                                    'workflowName',
                                    'ManageManagementRoleWizard',
                                )
                                query.set('resourceID', resourceId)
                                query.set('returnUrl', getFullPathFromUrl())
                                history.push(
                                    `${
                                        config.APP_SUBPATH
                                    }/managementRoles/workflows?${query.toString()}`,
                                )
                            }}
                            className={classes.root}
                        >
                            {<Icon name="EditFile" color="#fff" />}
                            {t('ConfigureEligibility')}
                        </Button>
                    </Box>
                )}

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableGrid
                isScrollable={isSmallScreen}
                headings={getAccessBasedAttributes(attributes).map(
                    (a) => a.label,
                )}
                loading={loading}
                attributes={getAccessBasedAttributes(attributes)}
                data={list}
            />
        </Box>
    )
}

export default Eligibility
