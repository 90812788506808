const appManagementRolesAttributes = [
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        label: 'FriendlyName',
        moreInfoIcon: true,
        isHeader: true,
        showInCard: false,
        requireAccess: {
            control:
                'ResourceAdmin-ApplicationDetails-AppManagementRole-FriendlyName',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
            fontWeight: 'bold',
        },
    },

    {
        name: 'isHighSecurity',
        sortable: true,
        sortBy: 'isHighSecurity',
        label: 'IsHighSecurity',
        component: {
            componentName: 'BooleanValue',
        },

        styles: {
            fontSize: '13px',
        },
        requireAccess: {
            control:
                'ResourceAdmin-ApplicationDetails-AppManagementRole-IsHighSecurity',
        },
    },
    {
        name: 'managementRoleTypeFriendlyName',
        sortable: true,
        sortBy: 'managementRoleTypeFriendlyName',
        label: 'Type',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-ApplicationDetails-AppManagementRole-Type',
        },
    },
    {
        name: 'description',
        sortable: true,
        sortBy: 'description',
        label: 'Description',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control:
                'ResourceAdmin-ApplicationDetails-AppManagementRole-Description',
        },
    },
    {
        hideValue: true,
        showInCard: false,

        component: {
            componentName: 'actionButtons',
        },
        styles: {
            width: '150px',
            minWidth: '150px',
        },

        headerCellStyles: {
            width: '150px',
            minWidth: '150px',
        },
    },
]

export default appManagementRolesAttributes
