import React, { Fragment, useState } from 'react'
import { ArrowSmallIcon, Icon } from 'packages/eid-icons'
import { Box, styled, makeStyles, Typography } from '@material-ui/core'
import { getFullImageUrl } from 'utils'
import BusinessLocationAndRoleChildNode from './ChildTreeNode'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const FolderMain = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    '& img': {
        width: '15px',
        marginLeft: '5px',
        cursor: 'pointer',
    },
})
const BusinesRoleAndLocationTreeBox = styled(Box)({
    marginTop: '10px',
    '& p': {
        fontSize: '12px',
        marginLeft: '8px',
    },
})
const FolderInnerItem = styled(Box)({
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 9px 5px 8px',
    borderRadius: '5px',
    '& svg': {
        marginRight: '8px',
    },
    '& img': {
        width: '15px',
        marginLeft: '5px',
    },
    '& p': {
        fontSize: '12px',
        marginLeft: '8px',
        cursor: 'pointer',
    },
})
const useStyles = makeStyles((theme) => ({
    SelectSection: {
        maxWidth: '300px',
        '& svg': {
            fill: '#fff !important',
            color: '#fff !important',
        },
    },
    selectedNode: {
        background: '#3d3d44',
        padding: '0 3px',
        borderRadius: '4px',
        color: '#fff',
    },
    nodeLabel: {
        cursor: 'pointer',
        '& .search-highlight': {
            color: theme?.palette?.text?.highlightSearchTerm,
            fontWeight: 600,
            textDecoration: 'underline',
        },
    },
    isDisabled: {
        color: '#a3a3aa',
        cursor: 'default',
    },
}))

const highlight = (text, searchKey) => {
    searchKey = new RegExp('(' + searchKey + ')', 'gi')
    return text.split(searchKey).map((o, i) =>
        i % 2 === 1 ? (
            <span className="search-highlight" key={i}>
                {o}
            </span>
        ) : (
            o
        ),
    )
}

const BusinesRoleAndLocationTree = React.memo(
    ({
        explorer,
        handleSelect,
        value,
        isExpanded = false,
        disableAssignableCheck = false,
        highlightText = '',
    }) => {
        const { t } = useTranslation()
        const [isOpen, setIsOpen] = useState(isExpanded)
        const classes = useStyles({})

        return (
            <Fragment>
                {explorer?.children?.length ? (
                    <BusinesRoleAndLocationTreeBox>
                        <FolderMain>
                            <Box
                                onClick={() => setIsOpen(!isOpen)}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <ArrowSmallIcon
                                    color="#60708f"
                                    fill="#60708f"
                                    direction={isOpen ? 'down' : 'right'}
                                />
                                {explorer?.imageUrl && (
                                    <img
                                        alt="folder-icons"
                                        src={getFullImageUrl(
                                            explorer?.imageUrl,
                                        )}
                                    />
                                )}
                                {explorer.icon && (
                                    <Icon
                                        name={explorer.icon}
                                        width="16px"
                                        height="16px"
                                        color="#307fc1"
                                    />
                                )}
                            </Box>

                            <Typography
                                className={classNames(classes.nodeLabel, {
                                    [classes.selectedNode]:
                                        explorer?.id && value === explorer?.id,
                                    [classes.isDisabled]:
                                        explorer?.isAssignable === false &&
                                        !disableAssignableCheck,
                                })}
                                onClick={() => {
                                    if (
                                        explorer?.isAssignable !== false ||
                                        disableAssignableCheck
                                    ) {
                                        handleSelect(explorer?.id)
                                    }
                                }}
                            >
                                {highlightText && highlightText.length
                                    ? highlight(explorer?.title, highlightText)
                                    : explorer?.title}
                            </Typography>
                        </FolderMain>
                        {isOpen && (
                            <Fragment>
                                {explorer?.children?.map((item, index) => {
                                    return (
                                        <div
                                            style={{
                                                paddingLeft: 15,
                                            }}
                                            key={`${item?.id}-${index}`}
                                        >
                                            <BusinessLocationAndRoleChildNode
                                                item={item}
                                                handleSelect={handleSelect}
                                                value={value}
                                                disableAssignableCheck={
                                                    disableAssignableCheck
                                                }
                                                isExpanded={isExpanded}
                                                highlightText={highlightText}
                                            />
                                        </div>
                                    )
                                })}
                            </Fragment>
                        )}
                    </BusinesRoleAndLocationTreeBox>
                ) : explorer?.id ? (
                    <FolderInnerItem classes={{ root: classes.SelectSection }}>
                        <img
                            alt="folder-icons"
                            src={getFullImageUrl(explorer?.imageUrl)}
                        />
                        <Typography
                            className={classNames(classes.nodeLabel, {
                                [classes.selectedNode]:
                                    explorer?.id && value === explorer?.id,
                                [classes.isDisabled]:
                                    explorer?.isAssignable === false &&
                                    !disableAssignableCheck,
                            })}
                            onClick={() => {
                                if (
                                    explorer?.isAssignable !== false ||
                                    disableAssignableCheck
                                ) {
                                    handleSelect(explorer?.id)
                                }
                            }}
                        >
                            {highlightText && highlightText.length
                                ? highlight(explorer?.title, highlightText)
                                : explorer?.title}
                        </Typography>
                    </FolderInnerItem>
                ) : (
                    <Typography>{t('NoDataFound')}</Typography>
                )}
            </Fragment>
        )
    },
)

export default BusinesRoleAndLocationTree
