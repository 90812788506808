import { Box, Typography } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { EidButton } from 'packages/eid-ui'

export const BulkRemoveButton = ({ selectedCount, label, onClick }) => (
    <Box>
        <EidButton.DrawerActionButton
            disabled={selectedCount <= 0}
            fontColor="#ffffff"
            bgColor="#E40000"
            width="100%"
            onClick={onClick}
            loading={false}
            height={'40px'}
        >
            <Box
                display="flex"
                alignItems="center"
                height={'12px'}
                width={'12px'}
            >
                <Icon name="DeleteX" fill="#ffffff" color="#ffffff" />
            </Box>
            <Box display="flex" alignItems="center" marginLeft="10px">
                <Typography style={{ color: '#ffffff' }}>{label}</Typography>
            </Box>
        </EidButton.DrawerActionButton>
    </Box>
)
