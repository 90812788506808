import SensitiveFunctionsListing from 'components/SharedListings/SensitiveFunctionsListing'

const ApplicationAppRightsSensitiveFunctions = ({ roleId }) => {
    return (
        <SensitiveFunctionsListing
            url={'api/RiskFunctions/byLocalRightId'}
            roleId={roleId}
        />
    )
}

export default ApplicationAppRightsSensitiveFunctions
