import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

const CardField = ({
    bold,
    variant,
    label,
    value,
    borderBelow,
    singleField,
    color,
    link,
    wordWrap,
    underlined,
    maxWidth,
    fieldStyles,
}) => {
    return (
        <Box
            style={
                fieldStyles
                    ? fieldStyles
                    : { padding: singleField ? '0 20px' : '' }
            }
        >
            <div
                style={{
                    padding: '10px 0',
                    borderBottom: borderBelow ? '1px solid #efeff1' : '',
                }}
            >
                <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                    {label.toUpperCase()}
                </Typography>
                <Typography
                    variant={variant}
                    style={
                        value
                            ? {
                                  fontWeight: bold ? 'bold' : '',
                                  color: color ? `${color}` : '#282828',
                                  textDecoration: underlined && 'underline',
                                  wordWrap: wordWrap ? wordWrap : 'break-word',
                                  maxWidth: maxWidth ? maxWidth : '',
                              }
                            : {}
                    }
                >
                    {value ? (
                        link ? (
                            <span
                                style={{
                                    color: '#2F7ECE',
                                    textDecoration: 'underline',
                                }}
                            >
                                {value}
                            </span>
                        ) : (
                            <>{value}</>
                        )
                    ) : (
                        <span style={{ color: 'rgb(180, 180, 180)' }}>-</span>
                    )}
                </Typography>
            </div>
        </Box>
    )
}

CardField.propTypes = {
    variant: PropTypes.string,
}

CardField.defaultProps = {
    variant: 'body1',
}

export default CardField
