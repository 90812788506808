import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { ItemDetails } from 'components'
import { Skeleton } from '@material-ui/lab'
import { Overview } from './Overview'
import DeleteRoleDefinition from './DeleteRoleDefinition'
import useSubcomponents from 'useSubcomponents'
import RoleDefinitionPeople from './People'
import RoleDefinitionAppRights from './AppRights'
import RoleDefinitionsEligibility from './Eligibility'
import RoleDefinitionsSensitiveFunctions from './SensitiveFunctions'
import RoleDefinitionAssignments from './Assignments'
import { PageContextProvider } from 'pageContext'
import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import { getAccessibleTabs, updateUrlQueryParams } from 'utils'

const useStyles = makeStyles(() =>
    createStyles({
        deleteBtn: {
            padding: '40px 32px 45px',
            '& div': {
                marginLeft: '0',
                gap: '10px',
            },
            '& button': {
                width: '132px !important',
            },
        },
        itemTabMenuBar: {
            padding: '0 30px',
        },
    }),
)

const RoleDefinitionDetails = ({
    role,
    loading,
    allowRoleDefinitionDirectAssignment,
    resourceId,
    applicationType,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()
    const { hasAccessToControl } = useSubcomponents()
    const query = useQuery()
    const APP_ROLEDEFINITION_ALL_TABS = [
        {
            value: 'appRights',
            label: 'AppRights',
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-RoleDefinition-AppRights-Control',
            },
            display: true,
        },
        {
            value: 'assignments',
            label: 'Assignments',
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-RoleDefinition-Assignments-Control',
            },
            display: allowRoleDefinitionDirectAssignment,
        },
        {
            value: 'people',
            label: 'People',
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-RoleDefinition-People-Control',
            },
            display: allowRoleDefinitionDirectAssignment,
        },
        {
            value: 'eligibility',
            label: 'Eligibility',
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-RoleDefinition-Eligibility-Control',
            },
            display: true,
        },
        {
            value: 'sensitiveFunctions',
            label: 'SensitiveFunctions',
            requireAccess: {
                control:
                    'ResourceAdmin-Application-Details-RoleDefinition-SensitiveFunctions-Control',
            },
            display: true,
        },
    ]

    const [allAccessibleTabs, setAllAccessibleTabs] = useState([])
    const [activeTab, setActiveTab] = useState('')

    useEffect(() => {
        if (resourceId !== null && resourceId !== undefined) {
            const activeTabInfo = getAccessibleTabs(
                query,
                history,
                APP_ROLEDEFINITION_ALL_TABS,
                query.get('drawerActiveTab'),
                null,
                hasAccessToControl,
                'drawerActiveTab',
                '',
            )
            setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
            setActiveTab(activeTabInfo.activeTab)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceId])

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        updateUrlQueryParams(
            query,
            history,
            [{ key: 'drawerActiveTab', value: tab }],
            [],
        )
    }

    return (
        <Fragment>
            {!role && loading ? (
                <Box>
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        height={8}
                        style={{ marginTop: 8 }}
                    />
                </Box>
            ) : (
                <Box>
                    <Box>
                        <Overview role={role} />
                    </Box>
                    <Box className={classes.deleteBtn}>
                        <DeleteRoleDefinition item={role} drawerView={true} />
                    </Box>
                    <div style={{ height: '100%' }}>
                        <ItemDetails.Tabs
                            variant="standard"
                            value={activeTab}
                            onChange={(_, value) => handleTabChange(value)}
                            className={classes.itemTabMenuBar}
                        >
                            {allAccessibleTabs.map((item, index) => (
                                <ItemDetails.Tabs.Tab
                                    key={`app-management-roles-tab-${index}`}
                                    value={item.value}
                                    label={t(item.label)}
                                />
                            ))}
                        </ItemDetails.Tabs>

                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '90%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box>
                                {activeTab === 'appRights' && (
                                    <PageContextProvider key="RequestViewApplicationRoleDefinitionDetailsAppRights">
                                        <RoleDefinitionAppRights
                                            roleId={role?.id}
                                            resourceId={role?.resourceId}
                                        />
                                    </PageContextProvider>
                                )}
                                {activeTab === 'assignments' && (
                                    <PageContextProvider key="RequestViewApplicationRoleDefinitionDetailsAssignments">
                                        <RoleDefinitionAssignments
                                            roleId={role?.id}
                                            resourceId={role?.resourceId}
                                        />
                                    </PageContextProvider>
                                )}
                                {activeTab === 'people' && (
                                    <PageContextProvider key="RequestViewApplicationRoleDefinitionDetailsPeople">
                                        <RoleDefinitionPeople
                                            roleId={role?.id}
                                            resourceId={resourceId}
                                            applicationType={applicationType}
                                        />
                                    </PageContextProvider>
                                )}
                                {activeTab === 'eligibility' && (
                                    <PageContextProvider key="RequestViewApplicationRoleDefinitionDetailsEligibility">
                                        <RoleDefinitionsEligibility
                                            role={role}
                                            resourceId={role?.resourceId}
                                        />
                                    </PageContextProvider>
                                )}
                                {activeTab === 'sensitiveFunctions' && (
                                    <PageContextProvider key="RequestViewApplicationRoleDefinitionDetailsSensitiveFunctions">
                                        <RoleDefinitionsSensitiveFunctions
                                            roleId={role?.id}
                                        />
                                    </PageContextProvider>
                                )}
                            </Box>
                        </Box>
                    </div>
                </Box>
            )}
        </Fragment>
    )
}

export default RoleDefinitionDetails
