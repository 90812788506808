import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreenFalse } from 'packages/core'
import { Box } from '@material-ui/core'
import { useApplicationSecrets } from 'hooks'
import {
    useEidGrid,
    CardViewLoader,
    ListingGrid,
    GridContainer,
    Filters,
} from 'components'
import {
    SecretRow,
    SecretsTable,
    SecretsTableHead,
} from './SecretsTableComponent'
import secretsAttributes from './secretsAttributes'
import SecretCard from './SecretCard'
import { usePageContext } from 'pageContext'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from '../CreateWorkflowButton'

const ApplicationSecrets = ({ azureApplicationId, resourceId }) => {
    const { t } = useTranslation()
    const { canAddClientSecret } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationSecrets(azureApplicationId)

    const isSmallScreen = useIsSmallScreenFalse()
    const [state] = usePageContext()

    const defaultView = isSmallScreen ? 'card' : 'table'

    const masterPasswordLockStyle = {
        height: pagination ? 'calc(100% - 178px)' : 'calc(100% - 138px)',
        width: state.masterPasswordWidth,
        left: state.masterPasswordLeftWidth,
    }

    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoSecretsMessage'),
        pagination,
        attributes: secretsAttributes,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: SecretCard,
        },
        tableViewConfig: {
            TableComponent: SecretsTable,
            TableHeadComponent: SecretsTableHead,
            TableRowComponent: SecretRow,
            showMasterPasswordLock: true,
            masterPasswordLockStyle,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                {canAddClientSecret && (
                    <Box data-protectedsubcomponent={canAddClientSecret}>
                        <CreateWorkflowButton
                            label={t('AddClientSecret')}
                            workflowName="CreateAzureAppClientSecret"
                            resourceId={resourceId}
                        />
                    </Box>
                )}
                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        width: isSmallScreen ? 'auto' : '308px',
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                {viewToRender}
            </GridContainer>
        </ListingGrid>
    )
}

export default ApplicationSecrets
