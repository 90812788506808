import { Fragment, useEffect } from 'react'
import SharedFoldersLayout from './SharedFoldersLayout'
import { useAuthState } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { usePageContext } from 'pageContext'

const allFilters = [
    {
        name: 'OwnedByFilter',
        requireAccess: 'ResourceAdmin-SharedFolder-Filter-OwnedBy',
    },
    {
        name: 'uncPathFilter',
        requireAccess: 'ResourceAdmin-SharedFolder-Filter-UNCPath',
    },
    {
        name: 'sharedFoldersAdvancedSearch',
        requireAccess: 'ResourceAdmin-SharedFolder-Filter-AdvancedSearch',
    },
]

const RequestViewAllSharedFolders = () => {
    const [, dispatch] = usePageContext()

    const [{ currentUserId, friendlyName }] = useAuthState()

    const { trimControlsBasedOnAccess } = useSubcomponents()

    const filters = trimControlsBasedOnAccess(allFilters)
    const hasOwnedByFilterAccess =
        filters.map((f) => f.name).indexOf('OwnedByFilter') >= 0
    useEffect(() => {
        if (!hasOwnedByFilterAccess) {
            dispatch({
                type: 'SET_MULTIPLE_PROPS',
                payload: {
                    ownedBy: {
                        id: currentUserId,
                        friendlyName: friendlyName,
                    },
                    enableOwnedByApiCall: true,
                },
            })
        } else {
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'enableOwnedByApiCall',
                    value: true,
                },
            })
        }
    }, [currentUserId])
    return (
        <Fragment>
            <SharedFoldersLayout filters={filters} />
        </Fragment>
    )
}

export { RequestViewAllSharedFolders }
