import { Box, styled } from '@material-ui/core'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useAppLocalRightsByRoleId,
    useDeleteApplicationAppRightsAssignments,
} from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import { Button } from 'components'
import { WarningModal } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import useSubcomponents from 'useSubcomponents'
import { getFullPathFromUrl, useDebounce, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})
const canAddNewAppRightsControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-AddNewAppRights-Control'
const canDeleteAppRightsControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-DeleteAppRight-Control'

const AppRightsListing = ({ roleId, endpoint, applicationResourceId }) => {
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()

    const [searchKey, setSearchKey] = useState('')
    const [selectedItemToDelete, setSelectedItemToDelete] = useState(null)
    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }
    const { list, loading, loadingMore, pagination } =
        useAppLocalRightsByRoleId(roleId, endpoint, queryData)
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useDeleteApplicationAppRightsAssignments(null, roleId)
    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'DisplayName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AppRights-DisplayName',
            },
        },
        {
            name: 'description',
            sortable: true,
            sortBy: 'description',
            label: 'Description',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AppRights-Description',
            },
        },
        {
            name: 'allowedMemberTypes',
            sortable: true,
            sortBy: 'allowedMemberTypes',
            label: 'AllowedMemberTypes',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AppRights-AllowedMemberTypes',
            },
        },
        {
            name: 'value',
            sortable: true,
            sortBy: 'value',
            label: 'Value',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AppRights-Value',
            },
        },
        {
            name: 'id',
            sortable: true,
            sortBy: 'id',
            label: 'ID',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AppRights-ID',
            },
        },

        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(canDeleteAppRightsControlName) && (
                            <DeleteButton>
                                <Button.Delete
                                    showEndIcon={false}
                                    onClick={() =>
                                        setSelectedItemToDelete(data)
                                    }
                                />
                            </DeleteButton>
                        )
                    )
                },
            },
        },
    ]

    const handleDelete = () => {
        deleteAssignments([selectedItemToDelete])
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="16px 30px"
            >
                {hasAccessToControl(canAddNewAppRightsControlName) && (
                    <Button
                        onClick={() => {
                            query.delete('appManagementRoleId')
                            query.delete('roleDefinitionId')
                            query.set('workflowName', 'AssignAzLocalRightScope')
                            query.set('returnUrl', getFullPathFromUrl())
                            query.set('resourceID', applicationResourceId)
                            query.set('SelectedAssigneeID', roleId)

                            history.push(
                                `${
                                    config.APP_SUBPATH
                                }/applications/workflows?${query.toString()}`,
                            )
                        }}
                        style={{
                            borderRadius: '4px',
                            padding: '0px 20px',
                            backgroundColor: '#307fc1',
                            border: 'solid 1px #307fc1',
                            color: '#ffffff',
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: '#307fc1',
                            },
                        }}
                    >
                        <Box style={{ display: 'flex' }}>
                            <Icon
                                name={'Plus'}
                                fill="white"
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    marginRight: '5px',
                                }}
                            />
                            {t('AddAppRight')}
                        </Box>
                    </Button>
                )}

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    sLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
            {selectedItemToDelete && (
                <WarningModal
                    title={`${t('Delete')} ${
                        selectedItemToDelete?.friendlyName
                    }`}
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemToDelete}
                    cancel={() => setSelectedItemToDelete(null)}
                    proceeding={isDeletingAssignment}
                    proceed={handleDelete}
                />
            )}
        </Box>
    )
}

export default AppRightsListing
