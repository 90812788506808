import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { Avatar, Tooltip } from 'packages/eid-ui'
import PersonCard from './PerosnCard'
import PersonDetails from './PersonDetails'
import { isNilOrEmpty } from 'packages/core'

const useStyles = makeStyles(() => ({
    text: ({ style }) => ({
        fontSize: '16px',
        color: '#5d6870',
        maxWidth: '100%',
        ...style,
    }),
}))

const avatarStyles = {
    small: {
        width: '18px',
    },
    medium: {
        width: '24px',
    },
    large: {
        width: '40px',
    },
}

const Person = (props) => {
    const {
        imgSrc,
        name,
        size = 'small',
        rootStyles,
        style,
        tooltipProps,
    } = props

    const classes = useStyles({ style })

    return (
        <Box position="relative" width="100%" style={rootStyles}>
            {isNilOrEmpty(name) ? (
                <Box
                    marginY="7.5px"
                    display="flex"
                    alignItems="center"
                    style={{ color: '#9b9b9b' }}
                >
                    -
                </Box>
            ) : (
                <Box marginY="7.5px" display="flex" alignItems="center">
                    <Box maxWidth={avatarStyles[size].width}>
                        <Avatar
                            src={imgSrc}
                            style={{
                                width: avatarStyles[size].width,
                                height: avatarStyles[size].width,
                            }}
                        />
                    </Box>

                    <Tooltip
                        title={name ? name : ''}
                        enterDelay={800}
                        enterNextDelay={800}
                        placement="bottom-start"
                        interactive={true}
                        {...tooltipProps}
                    >
                        <Box
                            width={`calc(100% - ${avatarStyles[size].width})`}
                            marginLeft="10px"
                        >
                            <Typography className={classes.text} noWrap>
                                {name}
                            </Typography>
                        </Box>
                    </Tooltip>
                </Box>
            )}
        </Box>
    )
}

Person.Card = PersonCard
Person.Details = PersonDetails
export default Person
