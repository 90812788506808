import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled, Typography } from '@material-ui/core'
import { useIsSmallScreenFalse } from 'packages/core'
import { useAccessManagers } from 'hooks'
import { ListingGrid, GridContainer, Filters, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import { Avatar } from 'packages/eid-ui'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import { getFullImageUrl, imagesDisabled } from 'utils'
import AddAssignment from 'components/ApplicationBanner/AddAssignments/AddAssignment'
import { Icon } from 'packages/eid-icons'
import { PageContextProvider } from 'pageContext'

const RemoveSelectedItem = styled(Box)({
    minWidth: '290px',
})

const canRemoveMultipleMembersControlName =
    'ResourceAdmin-MailboxDetails-AccessManagers-RemoveMultipleMembers-Control'
const canDeleteAccessManagerControlName =
    'ResourceAdmin-MailboxDetails-AccessManagers-DeleteMember-Control'
const canAddNewAccessManagerControlName =
    'ResourceAdmin-MailboxDetails-AccessManagers-AddAccessManagers-Control'

const AccessManagers = ({ mailBoxId, primaryKey = 'id' }) => {
    const { t } = useTranslation()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const [selected, setSelected] = useState([])
    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useAccessManagers(mailBoxId)
    const onSelect = (selectedArray) => {
        setSelected(selectedArray)
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = list.map((n) =>
                primaryKey ? n[primaryKey] : n.id,
            )
            onSelect(newSelecteds)
            return
        }
        onSelect([])
    }

    const handleRowSelection = (item) => {
        const selectedIndex = selected.indexOf(
            primaryKey ? item[primaryKey] : item.id,
        )
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(
                selected,
                primaryKey ? item[primaryKey] : item.id,
            )
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        onSelect(newSelected)
    }

    const isSmallScreen = useIsSmallScreenFalse()
    const handleBulkAction = () => {
        // TODO: Need to integarte the api call
        console.log('Selected Item to delete', selected)
    }
    const selectAllAction = hasAccessToControl(
        canRemoveMultipleMembersControlName,
    )
        ? {
              render: () => {
                  return (
                      <RemoveSelectedItem
                          data-protectedsubcomponent={
                              canRemoveMultipleMembersControlName
                          }
                      >
                          <BulkRemoveButton
                              selectedCount={selected.length}
                              label={t('RemoveSelectedMailboxAccessMembers', {
                                  count: selected.length,
                              })}
                              onClick={handleBulkAction}
                          />
                      </RemoveSelectedItem>
                  )
              },
          }
        : null
    const attributes = [
        {
            label: 'DisplayName',
            name: 'friendlyName',
            sortable: true,
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-AccessManagers-DisplayName',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box style={{ display: 'flex', gap: 10 }}>
                            {!imagesDisabled && (
                                <Avatar
                                    src={getFullImageUrl(data.imageThumbUrl)}
                                />
                            )}
                            <Typography style={{ fontSize: '13px' }}>
                                {data.friendlyName}
                            </Typography>
                        </Box>
                    )
                },
            },
        },

        {
            label: 'Login',
            name: 'login',
            sortable: true,

            requireAccess: {
                control: 'ResourceAdmin-MailboxDetails-AccessManagers-Login',
            },
        },

        {
            name: 'email',
            label: 'Email',
            sortable: true,
            requireAccess: {
                control: 'ResourceAdmin-MailboxDetails-AccessManagers-Email',
            },
        },
        {
            name: 'businessRoleLocation',
            label: 'BusinessRoleAndLocation',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-AccessManagers-BusinessRoleAndLocation',
            },
        },
        {
            name: 'department',
            label: 'Department',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-AccessManagers-Department',
            },
        },
        {
            name: 'title',
            label: 'Title',
            sortable: true,
            requireAccess: {
                control: 'ResourceAdmin-MailboxDetails-AccessManagers-Title',
            },
        },

        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(
                            canDeleteAccessManagerControlName,
                        ) && (
                            <Button.Delete
                                showEndIcon={false}
                                data-protectedsubcomponent={
                                    canDeleteAccessManagerControlName
                                }
                                onClick={() => console.log('Delete Clicked')}
                            />
                        )
                    )
                },
            },
        },
    ]

    return (
        <ListingGrid>
            <Box
                style={{
                    margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {hasAccessToControl(canAddNewAccessManagerControlName) && (
                    <PageContextProvider key={'AddNewAccessManagerAssignment'}>
                        <AddAssignment
                            labelKey={'AddNewAccessManager'}
                            submitButtonLabelKey={'AddNewAccessManager'}
                            searchUrl={`api/people`}
                            targetAssigneeId={mailBoxId}
                            searchPlaceHolder={'SearchAndFilter'}
                            resourceTypeRoleId_ConfigName={
                                'Mailbox_AccessManagers_ResourceTypeRoleId'
                            }
                            startIcon={
                                <Icon
                                    name={'Plus'}
                                    fill="white"
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '5px',
                                    }}
                                />
                            }
                        />
                    </PageContextProvider>
                )}

                <Box
                    style={{
                        margin: isSmallScreen
                            ? '28px 16px'
                            : '0px 0px 0px auto',
                        width: isSmallScreen ? 'auto' : '308px',
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    selectAllAction={selectAllAction}
                    rowSelection={hasAccessToControl(
                        canRemoveMultipleMembersControlName,
                    )}
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selected}
                    handleClick={handleRowSelection}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default AccessManagers
