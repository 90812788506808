import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { ListingGrid, GridContainer, Filters, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import { Icon } from 'packages/eid-icons'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAddItemToCart, useMailboxAssignments } from 'hooks'
import AddAssignment from 'components/ApplicationBanner/AddAssignments/AddAssignment'
import { PageContextProvider } from 'pageContext'
import { useAppState } from 'appContext'
import cartHelpers from 'containers/Cart/cartHelpers'

const canDeleteAssignmentControlName =
    'ResourceAdmin-MailboxDetails-DeleteAssignment-Control'
const canAddAssignmentControlName =
    'ResourceAdmin-MailboxDetails-AddAssignment-Control'

const Assignments = (props) => {
    const { t } = useTranslation()
    const [{ devFeaturesEnabled }] = useAppState()
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useMailboxAssignments(props?.mailBoxId)

    const [addItemToCart] = useAddItemToCart()

    const handleDelete = (item) => {
        const cartItem = cartHelpers.removeAssignmentToCartItem({
            targetAssigneeId: props?.mailBoxId,
            resource: props?.mailbox,
            resourceAssignment: item,
        })

        addItemToCart(cartItem)
    }
    const attributes = [
        {
            name: 'localizedAccessLevelFriendlyName',
            label: 'Permissions',
            sortable: true,

            requireAccess: {
                control: 'ResourceAdmin-MailboxDetails-Assignments-Permissions',
            },
        },
        {
            sortable: true,
            label: 'AssigneePreviewName',
            name: 'assigneePreviewName',
            requireAccess: {
                control: 'ResourceAdmin-MailboxDetails-Assignments-Assignee',
            },
        },
        {
            sortable: true,
            label: 'ResourceDisplayName',
            name: 'resourceSystemFriendlyName',
            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-Assignments-ResourceDisplayName',
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                gap: 10,
                                justifyContent: 'end',
                            }}
                        >
                            {hasAccessToControl(
                                canDeleteAssignmentControlName,
                            ) &&
                                devFeaturesEnabled && (
                                    <Button.Delete
                                        showEndIcon={false}
                                        data-protectedsubcomponent={
                                            canDeleteAssignmentControlName
                                        }
                                        onClick={() => handleDelete(data)}
                                    />
                                )}
                        </Box>
                    )
                },
            },
        },
    ]

    return (
        <ListingGrid>
            <Box display="flex" justifyContent="space-between">
                {hasAccessToControl(canAddAssignmentControlName) ? (
                    <PageContextProvider key={'AddNewMailboxAssignment'}>
                        <Box>
                            <AddAssignment
                                labelKey={'AddNewMailboxAssignment'}
                                searchUrl={`api/people/allUnassignedPeopleByResourceAccessRequestAssignee`}
                                targetAssigneeId={props?.mailBoxId}
                                searchPlaceHolder={'SearchAndFilter'}
                                startIcon={
                                    <Icon
                                        name={'Plus'}
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    />
                                }
                                showPermissionLevel
                                permissionLevelProps={{
                                    permissionLabelProp:
                                        'localizedAccessLevelFriendlyName',
                                }}
                                filterWithAccessLevel={true}
                                queryParams={{
                                    showSuggested: false,
                                    showPreApproved: false,
                                    showEligible: false,
                                }}
                            />
                        </Box>
                    </PageContextProvider>
                ) : (
                    <Box></Box> //Leaving an empty box just to maintain the design
                )}
                <Box width="308px">
                    <Filters.TextSearch
                        outlined
                        placeholder={'SearchAndFilter'}
                    />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: 'transparent',
                }}
            >
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default Assignments
