import { useTranslation } from 'react-i18next'
import { Box, styled } from '@material-ui/core'
import { useIsSmallScreenFalse } from 'packages/core'
import { ListingGrid, GridContainer, Filters } from 'components'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { usePbacResourceTypes } from 'hooks'
import AddNewResourceType from './AddNewResourceType'
import { PageContextProvider } from 'pageContext'
import { Icon } from 'packages/eid-icons'
import DeletePBACResourceType from './DeleteResourceType'

export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '10px',
    },
}))

const ButtonsBox = styled(Box)({
    display: 'flex',
    paddingRight: '10px',
    justifyContent: 'flex-end',
})
const canAddNewPbacResourceTypeControlName =
    'ResourceAdmin-ApplicationDetails-PbacDefinitions-ResourceTypes-Actions-Add'
const canEditPbacResourceTypeControlName =
    'ResourceAdmin-ApplicationDetails-PbacDefinitions-ResourceTypes-Actions-Edit'

const ResourceTypes = ({ applicationId, resourceSystemModuleId }) => {
    const { t } = useTranslation()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        refetch,
    } = usePbacResourceTypes(
        applicationId,
        resourceSystemModuleId,
        Boolean(applicationId) && Boolean(resourceSystemModuleId),
    )

    const isSmallScreen = useIsSmallScreenFalse()
    const attributes = [
        {
            name: 'name',
            sortable: true,
            sortBy: 'name',
            label: 'Name',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-ResourceTypesListing-Name',
            },
        },
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'DisplayName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-ResourceTypesListing-DisplayName',
            },
        },
        {
            name: 'description',
            sortable: true,
            sortBy: 'description',
            label: 'Description',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-ResourceTypesListing-Description',
            },
        },
        {
            hideValue: true,
            label: '',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacDefinitions-ResourceTypesListing-Actions',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <ButtonsBox>
                            {hasAccessToControl(
                                canAddNewPbacResourceTypeControlName,
                            ) && (
                                <PageContextProvider key={data?.id}>
                                    <AddNewResourceType
                                        data={data}
                                        headerKey={'EditPBACResourceType'}
                                        labelKey={'Edit'}
                                        resourceSystemModuleId={
                                            resourceSystemModuleId
                                        }
                                        refetch={refetch}
                                    />
                                </PageContextProvider>
                            )}
                            <Box margin="5px" />

                            {hasAccessToControl(
                                canEditPbacResourceTypeControlName,
                            ) && (
                                <DeletePBACResourceType
                                    item={data}
                                    refetch={refetch}
                                />
                            )}
                        </ButtonsBox>
                    )
                },
            },
        },
    ]

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                {hasAccessToControl(canAddNewPbacResourceTypeControlName) && (
                    <Box
                        data-protectedsubcomponent={
                            canAddNewPbacResourceTypeControlName
                        }
                    >
                        <PageContextProvider key={'AddNewPbacResourceType'}>
                            <AddNewResourceType
                                headerKey={'AddNewPbacResourceType'}
                                labelKey={t('AddNewPbacResourceType')}
                                resourceSystemModuleId={resourceSystemModuleId}
                                refetch={refetch}
                                startIcon={
                                    <Icon
                                        name={'Plus'}
                                        fill="white"
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    />
                                }
                            />
                        </PageContextProvider>
                    </Box>
                )}

                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default ResourceTypes
