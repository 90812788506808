import { Trans, useTranslation } from 'react-i18next'
import TableGrid from 'components/TableGrid'
import { Box } from '@material-ui/core'
import { useApplicationLocalRights } from 'hooks'
import { usePageContext } from 'pageContext'
import { useIsSmallScreenFalse } from 'packages/core'
import { useEffect } from 'react'

export const LocalRights = ({ groupId, queryData }) => {
    const { t } = useTranslation()
    const [_, dispatch] = usePageContext()
    const isSmallScreen = useIsSmallScreenFalse()

    const attributes = [
        {
            name: 'name',
            label: t('FriendlyName'),
            style: {
                width: '296px',
            },
        },
        {
            name: 'description',
            label: t('Description'),
            style: {
                width: '290px',
            },
        },
        {
            name: 'rightTypeName',
            label: t('RightType'),
            style: {
                width: '160px',
            },
        },
        {
            name: 'resourceSystemName',
            label: t('ResourceSystem'),
            style: {
                width: '160px',
            },
        },
    ]

    const {
        list: localRights,
        loading: localRightsLoading,
        pagination: localRightsPagination,
    } = useApplicationLocalRights(groupId, true, queryData ?? '')

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
    }, [queryData])

    return (
        <>
            <TableGrid
                loading={localRightsLoading}
                data={localRights}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
                fallbackMessage={<Trans i18nKey="NoRightsFound" />}
                isScrollable={isSmallScreen}
            />
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '36px',
                }}
            >
                <span style={{ background: '#fff', borderRadius: '5px' }}>
                    {localRightsPagination}
                </span>
            </Box>
        </>
    )
}
