import SensitiveFunctionsListing from 'components/SharedListings/SensitiveFunctionsListing'

const RoleDefinitionsSensitiveFunctions = ({ roleId }) => {
    return (
        <SensitiveFunctionsListing
            url={'api/RiskFunctions/byLocalRightId'}
            roleId={roleId}
        />
    )
}

export default RoleDefinitionsSensitiveFunctions
