import { Box } from '@material-ui/core'
import { Button } from 'components'
import { useAssignAppRights, useUnAssignAppRights } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import { ApplicationTypes } from 'utils'
const AssignUnAssignAppRight = ({
    roleId,
    refetch,
    item,
    applicationType,
    resourceId,
}) => {
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const [assignRights, { isLoading: isAssigning }] =
        useAssignAppRights(refetch)

    const [unAssignRights, { isLoading: isUnAssigning }] =
        useUnAssignAppRights(refetch)

    const handleButtonClick = () => {
        if (
            applicationType ===
                ApplicationTypes.PbacHasAppResourcesWithoutFieldType ||
            applicationType ===
                ApplicationTypes.PbacHasAppResourcesWithFieldType
        ) {
            query.delete('applicationId')
            query.delete('appRoleId')
            query.set('workflowName', 'AssignAZLocalRightScope')
            query.set('returnUrl', getFullPathFromUrl())
            query.set('resourceID', resourceId)
            history.push(
                `${
                    config.APP_SUBPATH
                }/applications/workflows?${query.toString()}`,
            )
        } else if (item?.localRightAssigned) {
            unAssignRights({ assigneeId: item.id, roleIds: [roleId] })
        } else {
            assignRights({ assigneeId: item.id, roleIds: [roleId] })
        }
    }
    return (
        <Button
            loading={isAssigning || isUnAssigning}
            onClick={handleButtonClick}
            style={{
                borderRadius: '4px',
                backgroundColor: '#307fc1',
                color: '#ffffff',
                fontSize: '14px',
                height: '100%',
                '&:hover': {
                    backgroundColor: '#307fc1',
                },
            }}
        >
            <Box style={{ display: 'flex' }}>
                {t(item?.localRightAssigned ? 'UnAssign' : 'Assign')}
            </Box>
        </Button>
    )
}

export default AssignUnAssignAppRight
