import React from 'react'
import { Box, Grid, makeStyles, Avatar } from '@material-ui/core'
import { Divider } from 'packages/eid-ui'
import GenericCard from './Cards/GenericCard'
import GenericCardContent from './Cards/GenericCardContent'
import CardFieldGroup from './Cards/CardFieldGroup'
import CardField from './Cards/CardField'
import { DefaultAvatarIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { getFullImageUrl, imagesDisabled } from 'utils'

const useStyles = makeStyles({
    title: {
        fontSize: '24px',
        fontWeight: 'normal',
    },
    boldText: { fontWeight: 'bold', fontSize: '24px' },
    overlayWrapper: {
        width: '195px',
        height: '195px',
        marginBottom: 30,
        marginTop: '2em',
        position: 'relative',
    },
    bigAvatar: {
        width: '195px',
        height: '195px',
        '&:hover': {
            '& + span': {
                display: 'flex',
            },
        },
    },
    editOverlay: {
        display: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.3)',
        borderRadius: '50%',
        top: 0,
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            display: 'flex',
        },
    },
})

const BasicInfoCard = (props) => {
    const { t } = useTranslation()

    const { basicInfo, config } = props
    const classes = useStyles()

    const handleBasicInfoExpand = () => {
        // No action item for now.
    }
    return (
        <GenericCard
            onClick={() => handleBasicInfoExpand()}
            editable={config.editable}
            editIcon={DefaultAvatarIcon}
            showEditOption={config.showEditOption}
        >
            {!imagesDisabled && (
                <Grid container justify="center" alignItems="center">
                    <Box className={classes.overlayWrapper}>
                        <Avatar
                            alt="Basic Info Avatar"
                            src={getFullImageUrl(basicInfo.imageUrl)}
                            className={classes.bigAvatar}
                        />
                    </Box>
                </Grid>
            )}

            <GenericCardContent avatarCard>
                <Box>
                    <CardFieldGroup>
                        <CardField
                            label={t('FirstName')}
                            value={basicInfo.firstName}
                            maxWidth={130}
                            bold
                            variant="h5"
                        />
                        <Divider vertical color="#ffffff" width={23} />
                        <CardField
                            label={t('LastName')}
                            value={basicInfo.lastName}
                            maxWidth={130}
                            variant="h5"
                        />
                    </CardFieldGroup>

                    <CardField
                        label={t('JobTitle')}
                        value={basicInfo.title}
                        singleField
                    />
                </Box>
            </GenericCardContent>
        </GenericCard>
    )
}

export default BasicInfoCard
