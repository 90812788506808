import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { ItemDetails } from 'components'
import { OverviewSection } from './OverviewSection'
import DeleteAzureApplicationRight from './DeleteAzureApplicationRight'
import { Skeleton } from '@material-ui/lab'
import ApplicationAssignments from './AppRightsDetailsDrawer/Assignments'
import AppRightsPeople from './AppRightsDetailsDrawer/People'
import useSubcomponents from 'useSubcomponents'
import {
    defaultDataProtectedSubcomponent,
    getAccessibleTabs,
    updateUrlQueryParams,
} from 'utils'
import ApplicationAppRightsEligbility from './AppRightsDetailsDrawer/Eligibility'
import ApplicationAppRightsSensitiveFunctions from './AppRightsDetailsDrawer/SensitiveFunctions'
import { useAppState } from 'appContext'
import { PageContextProvider } from 'pageContext'
import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'

const useStyles = makeStyles(() =>
    createStyles({
        deleteBtn: {
            padding: '40px 32px 45px',
            '& div': {
                marginLeft: '0',
                gap: '10px',
            },
            '& button': {
                width: '132px !important',
            },
        },
        itemTabMenuBar: {
            padding: '0 30px',
        },
    }),
)

const AppRightDetails = ({
    role,
    allowAppRoleDirectAssignment,
    applicationType,
    resourceId,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const query = useQuery()
    const history = useHistory()
    const {
        hasAccessToControl,
        canDeleteApplicationAppRight,
    } = useSubcomponents()

    const APP_RIGHTS_TABS = [
        {
            value: 'assignments',
            label: 'Assignments',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationAppRightAssignmentsTab-Control',
            },
            display: allowAppRoleDirectAssignment,
        },
        {
            value: 'people',
            label: 'People',
            requireAccess: {
                control: 'ResourceAdmin-ApplicationAppRightPeopleTab-Control',
            },
            display: allowAppRoleDirectAssignment,
        },
        {
            value: 'eligibility',
            label: 'Eligibility',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationAppRightEligibilityTab-Control',
            },
            display: true,
        },
        {
            value: 'sensitiveFunctions',
            label: 'SensitiveFunctions',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationAppRightSensativeFunctionsTab-Control',
            },
            display: true,
        },
    ]

    const [allAccessibleTabs, setAllAccessibleTabs] = useState([])
    const [activeTab, setActiveTab] = useState('')

    useEffect(() => {
        if (resourceId !== null && resourceId !== undefined) {
            const activeTabInfo = getAccessibleTabs(
                query,
                history,
                APP_RIGHTS_TABS,
                query.get('drawerActiveTab'),
                null,
                hasAccessToControl,
                'drawerActiveTab',
                '',
            )
            setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
            setActiveTab(activeTabInfo.activeTab)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceId])

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        updateUrlQueryParams(
            query,
            history,
            [{ key: 'drawerActiveTab', value: tab }],
            [],
        )
    }

    return (
        <Fragment>
            {/* Overview section start*/}
            {!role ? (
                <Box>
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        height={8}
                        style={{ marginTop: 8 }}
                    />
                </Box>
            ) : (
                <Box>
                    <Box>
                        <OverviewSection role={role} />
                    </Box>
                    {canDeleteApplicationAppRight && (
                        <Box
                            data-protectedsubcomponent={
                                canDeleteApplicationAppRight
                            }
                            className={classes.deleteBtn}
                        >
                            <DeleteAzureApplicationRight
                                item={role}
                                drawerView={true}
                            />
                        </Box>
                    )}
                </Box>
            )}
            {/* Overview section end*/}

            {/* App rights tabs start */}
            <div style={{ height: '100%' }}>
                <ItemDetails.Tabs
                    variant="standard"
                    value={activeTab}
                    onChange={(_, value) => handleTabChange(value)}
                    className={classes.itemTabMenuBar}
                >
                    {allAccessibleTabs.map((item, index) => (
                        <ItemDetails.Tabs.Tab
                            key={`rights-tab-${index}`}
                            value={item.value}
                            label={t(item.label)}
                            data-protectedsubcomponent={
                                item?.requireAccess?.control ||
                                defaultDataProtectedSubcomponent
                            }
                        />
                    ))}
                </ItemDetails.Tabs>

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '90%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        {activeTab === 'assignments' && (
                            <PageContextProvider key="RequestViewAppRightsDetailsAssignments">
                                <ApplicationAssignments
                                    role={role}
                                    roleId={role.id}
                                />
                            </PageContextProvider>
                        )}
                        {activeTab === 'eligibility' && (
                            <PageContextProvider key="RequestViewAppRightsDetailsEligibility">
                                <ApplicationAppRightsEligbility
                                    resourceTypeId={role.resourceTypeId}
                                    roleId={role.id}
                                    resourceId={role.resourceId}
                                />
                            </PageContextProvider>
                        )}
                        {activeTab === 'sensitiveFunctions' && (
                            <PageContextProvider key="RequestViewAppRightsDetailsSensitiveFunctions">
                                <ApplicationAppRightsSensitiveFunctions
                                    roleId={role.id}
                                />
                            </PageContextProvider>
                        )}
                        {activeTab === 'people' && (
                            <PageContextProvider key="RequestViewAppRightsDetailsPeople">
                                <AppRightsPeople
                                    roleId={role.id}
                                    applicationType={applicationType}
                                    resourceId={resourceId}
                                />
                            </PageContextProvider>
                        )}
                    </Box>
                </Box>
            </div>
            {/* App rights tabs end */}
        </Fragment>
    )
}

export default AppRightDetails
