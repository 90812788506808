import React, { Fragment } from 'react'

import {
    US,
    AC,
    AD,
    AE,
    AF,
    AG,
    AU,
    CA,
    DE,
    FR,
    GB,
    IN,
    JP,
    ZW,
} from 'country-flag-icons/react/1x1'
import { Box, makeStyles, styled, Typography } from '@material-ui/core'

const CountryFlag = styled(Box)({
    '& svg': {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
    },
})

const COUNTRY_CODE_OPTIONS = [
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <US />
                </CountryFlag>
                <Typography>+1</Typography>
            </Fragment>
        ),
        value: '+1',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <GB />
                </CountryFlag>
                <Typography>+44</Typography>
            </Fragment>
        ),
        value: '+44',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <CA />
                </CountryFlag>
                <Typography>+1</Typography>
            </Fragment>
        ),
        value: '+1',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <AU />
                </CountryFlag>
                <Typography>+61</Typography>
            </Fragment>
        ),
        value: '+61',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <DE />
                </CountryFlag>
                <Typography>+49</Typography>
            </Fragment>
        ),
        value: '+49',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <IN />
                </CountryFlag>
                <Typography>+91</Typography>
            </Fragment>
        ),
        value: '+91',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <JP />
                </CountryFlag>
                <Typography>+81</Typography>
            </Fragment>
        ),
        value: '+81',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <FR />
                </CountryFlag>
                <Typography>+33</Typography>
            </Fragment>
        ),
        value: '+33',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <ZW />
                </CountryFlag>
                <Typography>+263</Typography>
            </Fragment>
        ),
        value: '+263',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <AC />
                </CountryFlag>
                <Typography>+247</Typography>
            </Fragment>
        ),
        value: '+247',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <AD />
                </CountryFlag>
                <Typography>+376</Typography>
            </Fragment>
        ),
        value: '+376',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <AE />
                </CountryFlag>
                <Typography>+971</Typography>
            </Fragment>
        ),
        value: '+971',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <AF />
                </CountryFlag>
                <Typography>+93</Typography>
            </Fragment>
        ),
        value: '+93',
    },
    {
        label: (
            <Fragment>
                <CountryFlag>
                    <AG />
                </CountryFlag>
                <Typography>+1</Typography>
            </Fragment>
        ),
        value: '+1',
    },
]

const useStyles = makeStyles({
    customPhoneDropDown: {
        position: 'absolute',
        top: '24px',
        background: '#fff',
        listStyle: 'none',
        padding: '10px 0',
        left: '0',
        boxShadow: ' 0 2px 4px 0 rgba(48, 127, 193, 0.15)',
        maxHeight: '150px',
        overflowY: 'auto',
        cursor: 'pointer',
        '& li': {
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            borderBottom: '1px solid #d4d4d8',
            padding: '4px 10px',
            cursor: 'pointer',
        },
    },
})

const CountryOptionWithFlag = React.memo(({ handleCountrySelect }) => {
    const classes = useStyles()
    return (
        <ul className={classes.customPhoneDropDown}>
            {COUNTRY_CODE_OPTIONS.map((option, index) => (
                <li key={index} onClick={() => handleCountrySelect(option)}>
                    {option.label}
                </li>
            ))}
        </ul>
    )
})

export default CountryOptionWithFlag
