import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTargetPerson, useRemoveCart } from 'hooks'
import { WarningModal } from 'packages/eid-ui'
import { makeStyles, Typography, Link } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { useAppState } from 'appContext'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        backgroundColor: '#faf7f6',
        color: '#d0021b',
        border: '1px solid #ffdacd',
        minWidth: '100px',
        minHeight: '48px',
        paddingLeft: '16px',
        paddingRight: '16px',
        '& svg': {
            marginLeft: '8px',
            color: '#d0021b',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#d0021b',
            color: '#ffffff',
            '& svg': {
                color: '#ffffff',
            },
        },
        transition: 'background-color 0.5s ease',
    },
    label: {
        fontSize: '16px',
        fontWeight: 'normal',
    },
})

const EmptyCartButton = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [{ targetResource }] = useAppState()
    const [removeCartItems, { isLoading: isRemoveCartItems }] = useRemoveCart(
        targetResource?.targetAssigneeId,
    )

    const [showConfirm, setConfirm] = useState(false)

    const emptyTheCart = () => {
        removeCartItems().then(() => {
            setConfirm(false)
        })
    }

    return (
        <>
            <Link
                component="button"
                className={classes.root}
                underline="none"
                onClick={() => setConfirm(true)}
            >
                <Typography className={classes.label}>
                    {t('Common_EmptyCart')}
                </Typography>
                <Icon name="Delete" />
            </Link>
            <WarningModal
                title={t('Common_Confirm')}
                description={t('Common_CartResetMessage')}
                yesLabel={t('Common_Yes')}
                noLabel={t('Common_No')}
                open={showConfirm}
                cancel={() => setConfirm(false)}
                proceeding={isRemoveCartItems}
                proceed={emptyTheCart}
            />
        </>
    )
}

export default EmptyCartButton
