import { Box, styled } from '@material-ui/core'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAppManagementRoleAccessGranted } from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import useSubcomponents from 'useSubcomponents'
import { useDebounce } from 'packages/core'
import DateTimeGenericComponent from 'components/DynamicComponents/DateTimeGenericComponent'
import { Icon } from 'packages/eid-icons'
import AddTimeConstraint from 'components/AddTimeConstraint/AddTimeConstraint'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const EditTimeConstraint = styled(Box)({
    height: '28px',
    width: '28px',
    borderRadius: '4px',
    padding: '4px 7px',
    backgroundColor: '#307fc1',

    '& svg': {
        marginRight: '8px',
        '& path': {
            stroke: '#fff',
        },
    },
})
const AccessGranted = ({ roleId, resourceTypeId }) => {
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState('')

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }
    const { list, loading, loadingMore, pagination } =
        useAppManagementRoleAccessGranted(roleId, resourceTypeId, queryData)
    const { getAccessBasedAttributes } = useSubcomponents()

    const attributes = [
        {
            name: 'assigneeType',
            sortable: true,
            sortBy: 'assigneeType',
            label: 'AssigneeType',

            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-AssigneeType',
            },
        },
        {
            name: 'assigneeFriendlyName',
            sortable: true,
            sortBy: 'assigneeFriendlyName',
            label: 'Assignee',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-Assignee',
            },
        },
        {
            name: 'assigneeTechnicalName',
            sortable: true,
            sortBy: 'assigneeTechnicalName',
            label: 'AssigneeTechnicalName',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-AssigneeTechnicalName',
            },
        },
        {
            name: 'resourceFriendlyName',
            sortable: true,
            sortBy: 'resourceFriendlyName',
            label: 'Resource',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-Resource',
            },
        },
        {
            name: 'resourceTypeFriendlyName',
            sortable: true,
            sortBy: 'resourceTypeFriendlyName',
            label: 'ResourceType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-ResourceType',
            },
        },

        {
            label: 'TimeConstraint',
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-TimeConstraint',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box style={{ display: 'flex', gap: 20 }}>
                            <Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 10,
                                    }}
                                >
                                    <strong>{t('Starts')}:</strong>
                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'startDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 17,
                                        marginTop: '5px',
                                    }}
                                >
                                    <strong>{t('Ends')}:</strong>

                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'endDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                            </Box>
                            {/* Unhide this when backend functionality is implemented */}
                            {/* <AddTimeConstraint
                                data={data}
                                iconName={'EditFile'}
                                onSave={(data) => {
                                    console.log(data)
                                }}
                                startTimeProp="startDateUtc"
                                endTimeProp="endDateUtc"
                            /> */}
                        </Box>
                    )
                },
            },
        },
    ]

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
                padding="16px 30px"
            >
                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default AccessGranted
