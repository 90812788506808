import React from 'react'
import { Box, styled } from '@material-ui/core'
import { ItemCardSkeleton, Button } from 'components'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import StickyContainer from 'components/StickyContainer'
import Filters from './Filters'
import { mobileScreenWidth, tabletScreenWidth, smallScreenWidth } from 'utils'
import { useHistory } from 'react-router-dom'
import { useRouteOptions } from 'hooks'
import { DropdownMenu } from 'components/DropdownMenu'
import { HorizontalTabs } from 'packages/eid-ui'
import SubHeader from 'components/SubHeader'
import MobileSortingMenu from './MobileSortingMenu'
import config from 'config'
import { SubPathLevel } from 'appConfiguration'

export const masonaryOptions = {
    transitionDuration: 0,
    horizontalOrder: true,
}

export const maxWidth = '100%'
export const filtersColumnWidth = 337

export const TableContainer = styled(({ openFilters, ...rest }) => (
    <Box {...rest} />
))(({ openFilters }) => ({
    position: 'relative',
    marginLeft: !openFilters && -filtersColumnWidth + -20,
    marginTop: 28,
    transition: `all 0.5s ease-out`,
}))

export const GridContainer = styled(({ openFilters, filterWidth, ...rest }) => (
    <Box {...rest} />
))(({ openFilters, filterWidth }) => ({
    position: 'relative',
    marginLeft: !openFilters && -filterWidth + -20,
    marginTop: 28,
    transition: `all 0.5s ease-out`,
}))

export const DesktopContainer = styled(Box)({
    display: 'flex',
    paddingTop: '30px',
})

export const TabletContainer = styled(Box)(({ theme }) => ({
    padding: '24px 0px',
    [theme.breakpoints.down('md')]: {
        marginLeft: '70px',
    },
}))

export const SmallScreenContainer = styled(Box)({
    [`@media (min-width:${tabletScreenWidth})`]: {
        marginLeft: '70px',
    },
})

export const PaddedDiv = styled(Box)({
    padding: '25px 40px',
    [`@media (max-width:${mobileScreenWidth})`]: {
        padding: '25px 28px',
    },
})

export const ItemsContainerTablet = styled(Box)({
    height: 'inline-block',
    width: '100%',
    overflow: 'hidden',

    '@media (min-width:0px) and (orientation: landscape)': {
        width: '50%',
    },
})

export const ItemsContainerMobile = styled(Box)(({ top }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: top ? top : '16px',
    maxWidth: maxWidth,
    margin: 'auto',
    paddingLeft: '6px',
    paddingRight: '6px',
}))

export const FiltersColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 12px 0px 8px',
    marginRight: '20px',
    minWidth: filtersColumnWidth,
    flex: '27%',
    maxWidth: '370px',
})

export const FilterContainer = styled(Box)({
    marginBottom: '28px',
})

export const ListingGrid = styled(Box)({
    width: '100%',
})

export const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#307fc1',
        height: '4px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
    },
}))

export const CardViewLoader = () => (
    <Box display="flex" flexWrap="wrap" width="100%" overflow="hidden">
        <ItemCardSkeleton />
        <ItemCardSkeleton />
        <ItemCardSkeleton />
        <ItemCardSkeleton />
    </Box>
)

export const styles = {
    listTitle: {
        fontSize: '12px',
        color: '#307fc1',
        padding: '0px 14px 6px 14px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    divider: {
        backgroundColor: '#e8e8e8',
        margin: '6px 8px',
    },
    title: {
        color: '#363636',
        fontSize: '20px',
        fontWeight: 'bold',
        paddingLeft: '3px',
        lineHeight: 1.33,
    },
    subTitle: { color: '#363636', fontSize: '14px', lineHeight: 1.33 },
    icon: {
        transform: 'rotate(90deg)',
    },
    dropdownLabel: {
        color: '#307fc1',
        fontSize: '27px',
        fontWeight: 'bold',
        [`@media (max-width:${smallScreenWidth})`]: {
            fontSize: '12px',
        },
    },

    filtersScreenHeader: {
        margin: 'auto',
        [`@media (max-width:${mobileScreenWidth})`]: {
            backgroundColor: '#ffffff',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
        },
    },

    flexCenterStyles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    filterCardHeader: {
        borderRadius: '5px',
        height: '40px',
    },

    mobileFiltersContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingTop: '20px !important',
        margin: 'auto',
        padding: '0px 20px',
        maxWidth: '600px',
        marginBottom: '28px',
        marginTop: '20px',
    },
}

const TabsSubHeader = styled(Box)({
    paddingBottom: '24px',
    [`@media (max-width:${mobileScreenWidth})`]: {
        display: 'none',
    },
})

const MobileSubHeader = styled(Box)({
    [`@media (min-width:${tabletScreenWidth})`]: {
        display: 'none',
    },
})

const FiltersSearchContainer = styled(Box)({
    width: '100%',
    padding: '16px',
    flexDirection: 'column',
    '& .FiltersButton': {
        maxHeight: '62px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '20px',
        '& button': {
            width: '48%',
            minWidth: '100px',
        },
    },
    '& .Search': {
        width: '100%',
    },

    [`@media (min-width:${tabletScreenWidth})`]: {
        flexDirection: 'row',
        '& .FiltersButton': {
            paddingTop: '0px',
            paddingLeft: '14px',
            width: '50%',
        },
        '& .Search': {
            width: '50%',
            paddingRight: '14px',
        },
    },
})

export const MobileStickyContentListing = ({
    title: areaName,
    subTitle: routeName,
    handleApplyFilters,
    totalCount,
    showSearch = true,
}) => {
    const { t } = useTranslation()
    const { routeOptions } = useRouteOptions()
    const history = useHistory()

    const {
        location: { pathname },
    } = history

    let selectedRoute
    let selectedParentRoute

    for (let i = 0; i < routeOptions.length; i++) {
        const foundRoute = routeOptions[i].children.find((c) => {
            return (
                config.APP_SUBPATH + c.route.toLowerCase() ===
                pathname.toLowerCase()
            )
        })

        if (foundRoute) {
            selectedRoute = foundRoute
            selectedParentRoute = routeOptions[i]
            break
        }
    }
    const selectedTab = pathname.split('/')[2 + SubPathLevel]
        ? pathname.split('/')[2 + SubPathLevel]
        : 'viewAll'

    return (
        <>
            <TabsSubHeader>
                <SubHeader totalCount={totalCount} />
            </TabsSubHeader>

            <MobileSubHeader>
                <Box
                    padding="0px 16px"
                    height="80px"
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Box>
                        {/* Drop Down Menu Here */}
                        <DropdownMenu
                            routeOptions={routeOptions}
                            selectedRoute={selectedRoute}
                            selectedParentRoute={selectedParentRoute}
                            totalCount={totalCount}
                            labelClass={styles.dropdownLabel}
                        />
                    </Box>
                </Box>
                <Box
                    padding="0px 16px"
                    height="80px"
                    display="flex"
                    borderTop="solid 1px #d8dadd"
                    borderBottom="solid 1px #d8dadd"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    width="100%"
                    bgcolor="white"
                >
                    <SubHeaderTabs
                        variant="standard"
                        value={selectedTab}
                        onChange={(_, value) => {
                            if (value === 'viewAll') {
                                history.push(
                                    `${config.APP_SUBPATH}/${areaName}`,
                                )
                            } else {
                                history.push(
                                    `${config.APP_SUBPATH}/${areaName}/${value}`,
                                )
                            }
                        }}
                    >
                        <HorizontalTabs.Tab
                            key={1}
                            value="viewAll"
                            label={t('Common_ViewAll')}
                        />
                        <HorizontalTabs.Tab
                            key={2}
                            value="workflows"
                            label={t('Workflows')}
                        />
                    </SubHeaderTabs>
                </Box>
            </MobileSubHeader>

            <FiltersSearchContainer display={showSearch ? 'flex' : 'none'}>
                <Box className="Search">
                    <Filters.TextSearch />
                </Box>
                <Box className="FiltersButton">
                    <Button.Filters
                        label={t('Filters')}
                        subLabel={t('ApplyFilters')}
                        onClick={handleApplyFilters}
                    />

                    <MobileSortingMenu />
                </Box>
            </FiltersSearchContainer>
        </>
    )
}

export const MobileFiltersScreen = ({
    filters,
    handleApplyFilters,
    totalCount,
}) => {
    const { t } = useTranslation()
    return (
        <>
            <StickyContainer styles={styles.filtersScreenHeader}>
                {/* <TabsSubHeader>
                    <SubHeader totalCount={totalCount} />
                </TabsSubHeader> */}

                <Box width="100%" maxWidth="600px">
                    <PaddedDiv
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#363636',
                            }}
                        >
                            {t('Filters')}
                        </Typography>

                        <Box>
                            <Button.ApplyFilters onClick={handleApplyFilters}>
                                {t('ApplyFilters')}
                            </Button.ApplyFilters>
                        </Box>
                    </PaddedDiv>
                </Box>
            </StickyContainer>

            {filters.map((f) => {
                const FilterToRender = Filters.filtersMap[f.name]
                return (
                    <Box data-protectedsubcomponent={f.requireAccess} key={f.name} style={styles.mobileFiltersContainer}>
                        <FilterToRender />
                    </Box>
                )
            })}
        </>
    )
}
