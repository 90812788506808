import { Box, Typography } from '@material-ui/core'
import { useAppState } from 'appContext'
import AddTimeConstraint from 'components/AddTimeConstraint/AddTimeConstraint'
import moment from 'moment'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const AddEditAccessAssignmentToFolder = ({ item }) => {
    const { t } = useTranslation()
    const [{ devFeaturesEnabled }] = useAppState()
    return (
        <Box>
            {item?.startTimeUTC && item?.endTimeUTC ? (
                <Box display="flex" alignItems="center" gridGap="24px">
                    <Box>
                        <Typography>
                            <strong>{`${t('Begins')}: `}</strong>
                            {`${moment
                                .utc(item.startTimeUTC)
                                .local()
                                .format('L LT')}`}
                        </Typography>
                        <Typography>
                            <strong>{`${t('Ends')}: `}</strong>
                            {`${moment
                                .utc(item.endTimeUTC)
                                .local()
                                .format('L LT')}`}
                        </Typography>
                    </Box>
                    {devFeaturesEnabled && (
                        <AddTimeConstraint iconName="EditFile" data={item} />
                    )}
                </Box>
            ) : (
                <Fragment>
                    {devFeaturesEnabled ? (
                        <AddTimeConstraint
                            btnLabel="AddTimeConstraint"
                            data={item}
                        />
                    ) : (
                        <></>
                    )}
                </Fragment>
            )}
        </Box>
    )
}

export default AddEditAccessAssignmentToFolder
