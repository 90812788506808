import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles } from '@material-ui/core'
import { useManagementRoleAllMembers } from 'hooks'
import { Avatar } from 'packages/eid-ui'
import { useDebounce } from 'packages/core'
import TableGrid from 'components/TableGrid'
import { getFullImageUrl, imagesDisabled } from 'utils'
import Search from 'components/Filters/Search'

const useTableStyles = makeStyles({
    headGrey: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
        height: '80px',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 10px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: '#7d7c7c',
    },
})

export const AllMembers = ({ managementRole }) => {
    const { t } = useTranslation()
    const tableClasses = useTableStyles()

    const [searchKey, setSearchKey] = useState('')
    const [sorting, setSorting] = useState({ sortOrder: null, sortBy: null })

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const handleSort = (sorting) => {
        setSorting(sorting)
    }

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
        sorting,
    }

    const {
        list,
        loading: isLoading,
        pagination,
    } = useManagementRoleAllMembers(managementRole?.id, queryData)

    const attributes = [
        {
            label: t(''),
            name: 'image',
            image: true,
            headerCellStyle: {
                width: '8%',
            },
            style: {
                width: '8%',
            },
            resolve: (item) => (
                <Avatar
                    size="medium"
                    src={getFullImageUrl(item.imageThumbUrl)}
                    style={{ boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px' }}
                />
            ),
        },
        {
            label: t('DisplayName'),
            name: 'friendlyName',
            sortable: true,
            style: {
                fontWeight: 'bold',
                width: '46%',
            },
            headerCellStyle: {
                width: '46%',
            },
        },
        {
            label: t('Login'),
            name: 'login',
            sortable: true,
            style: {
                width: '46%',
            },
            headerCellStyle: {
                width: '46%',
            },
        },
    ]

    const filteredAttributes = attributes.filter(
        (x) => !x.image || !imagesDisabled,
    )

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                paddingBottom="16px"
            >
                {/* TODO: Need to incorporate logic for adding members when endpoint available */}
                <Box width="308px" display="flex" alignItems="center">
                    <Search
                        handleSearch={handleSearchChange}
                        placeholder={t('SearchFilterMembers')}
                        value={searchKey}
                        handleClear={handleClear}
                        outlined
                    />
                </Box>
            </Box>

            <TableGrid
                loading={isLoading}
                data={list}
                attributes={filteredAttributes}
                tableClasses={tableClasses}
                sorting={sorting}
                onSort={handleSort}
            />

            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '40px',
                }}
            >
                <Box>{pagination}</Box>
            </Box>
        </>
    )
}
