import { useTranslation } from 'react-i18next'
import TableGrid from 'components/TableGrid'
import { Box, Paper, createStyles, makeStyles } from '@material-ui/core'
import { useApplicationsOptionalClaims } from 'hooks'
import { PageContextProvider, usePageContext } from 'pageContext'
import { useEffect, useState } from 'react'
import { useDebounce, useIsSmallScreenFalse, useQuery } from 'packages/core'
import Button from 'components/Button'
import { useHistory } from 'react-router'
import OptionalClaimDrawer from './OptionalClaimDrawer'
import Search from 'components/Filters/Search'
import { smallScreenWidth } from 'utils'

export const getItemFromList = (id, list) => {
    let item = null
    item = list.find((i) => i.id.toString() === id)
    return item
}

const useStyles = makeStyles(() =>
    createStyles({
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
        paperContainer: {
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

export const OptionalClaims = ({ id }) => {
    const { t } = useTranslation()
    const [_, dispatch] = usePageContext()
    const isSmallScreen = useIsSmallScreenFalse()
    const classes = useStyles()
    const history = useHistory()
    const query = useQuery()
    const optionalClaimId = query.get('optionalClaimId')

    const [searchKey, setSearchKey] = useState('')

    const debouncedSearchValue = useDebounce(searchKey)

    let queryData = debouncedSearchValue

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }

    const attributes = [
        {
            name: 'name',
            label: t('Claim'),
        },
        {
            name: 'claimTypeFriendlyName',
            label: t('TokenType'),
        },
        {
            name: 'definitionDescription',
            label: t('Description'),
        },

        {
            name: 'actions',
            label: '',
            style: {
                textAlign: 'end',
            },
            resolve: (item) => (
                <Button.Details
                    onClick={() => {
                        history.push(
                            `${history.location.search}&optionalClaimId=${item?.id}`,
                        )
                    }}
                />
            ),
        },
    ]

    const {
        list: optionalClaims,
        loading: optionalClaimsLoading,
        pagination: optionalClaimsPagination,
    } = useApplicationsOptionalClaims(id, true, queryData ?? '')

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
    }, [queryData])

    return (
        <>
            {optionalClaimId && optionalClaims && (
                <PageContextProvider key="RequestViewOptionalClaims">
                    <OptionalClaimDrawer
                        id={optionalClaimId}
                        item={getItemFromList(optionalClaimId, optionalClaims)}
                        onClose={() => {
                            query.delete('optionalClaimId')
                            history.push(`${history.location.pathname}`)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                    />
                </PageContextProvider>
            )}

            <Paper
                className={classes.paperContainer}
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >
                <Box
                    style={{
                        paddingBottom: '17px',
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    {!isSmallScreen && (
                        <Box width="308px" display="flex" alignItems="center">
                            <Search
                                handleSearch={handleSearchChange}
                                placeholder={t('SearchAndFilter')}
                                value={searchKey ? searchKey : ''}
                                handleClear={handleClear}
                                outlined
                            />
                        </Box>
                    )}
                </Box>
            </Paper>
            <TableGrid
                loading={optionalClaimsLoading}
                data={optionalClaims}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
                fallbackMessage={t('NoClaimsMessage')}
                isScrollable={isSmallScreen}
            />
            <Box style={{ justfyContent: 'center', display: 'flex' }}>
                {optionalClaimsPagination}
            </Box>
        </>
    )
}
