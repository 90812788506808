import React from 'react'
import { Icon, EidLogoIcon } from 'packages/eid-icons'
import { Header } from 'packages/eid-ui'
import { styled, Typography, Box, useTheme } from '@material-ui/core'
import LanguageMenu from './LanguageMenu'
import AccountDropdown from './AccountDropdown'
import { useEnvironment } from 'hooks'
import appConfig from 'config'
import ShoppingCartMenu from './ShoppingCartMenu'
import { useQuery } from 'packages/core'
import { useAppState } from 'appContext'

const styles = {
    environmentText: {
        fontSize: '8px',
        padding: '0px 1.5px',
        maxWidth: '58px',
    },
    dummyText: {
        fontSize: '8px',
        padding: '0px 1.5px',
        maxWidth: '58px',
        opacity: 0,
    },
}

const StyledChip = styled('div')({
    position: 'absolute',
    top: '11px',
    left: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '58px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
})

const TitleContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width:1023px)': {
        display: 'none',
    },
})

const ResponsiveContainer = styled(Box)({
    '@media (max-width:1023px)': {
        //display: 'none',
    },
})

const OpenCartmenu = styled(Box)({
    display: 'flex',
    background: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    padding: '15px 30px',
    position: 'fixed',
    top: '0',
    right: '0',
    zIndex: '9999999',
    animationDelay: '-0.6s',
    borderRadius: '4px',

    '& hr': {
        display: 'none',
    },
    '& div': {
        minWidth: 'auto !important',
        marginRight: 'auto !important',
    },
})

const AppDesktopHeader = ({ config }) => {
    const { data: envName } = useEnvironment()
    const theme = useTheme()
    const [{ devFeaturesEnabled }] = useAppState()
    const query = useQuery()
    const cartOpen = query.get('cart') === 'visible'

    return (
        <>
            <Header
                bgColor={config.bgColor}
                height={config.desktop?.height}
                borderBottom={
                    config?.bottomColor
                        ? { borderBottom: `2px solid ${config.bottomColor}` }
                        : {}
                }
            >
                <Header.IconLink
                    logo
                    href={`${appConfig.APP_SUBPATH}/`}
                    rightDivider={config.showEidLogo}
                >
                    {config.showEidLogo && <EidLogoIcon />}
                </Header.IconLink>

                <ResponsiveContainer
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Header.IconLink logo href={`${appConfig.APP_SUBPATH}/`}>
                        {config.appLogo.type === 'icon' ? (
                            <Icon
                                style={config.appLogo.style}
                                name={config.appLogo.value}
                                color={theme.palette.primary.main}
                            />
                        ) : (
                            <img
                                style={config.appLogo.style}
                                src={config.appLogo.value}
                                alt={config.appLogo.name}
                            />
                        )}
                    </Header.IconLink>
                </ResponsiveContainer>

                <TitleContainer>
                    {config.appTitle.type === 'text' ? (
                        <Header.Title
                            style={{
                                ...config.appTitle.style,
                                color: theme.palette.primary.main,
                            }}
                        >
                            {config.appTitle.value}
                        </Header.Title>
                    ) : (
                        <Header.IconLink
                            logo
                            href={`${appConfig.APP_SUBPATH}/`}
                        >
                            <img
                                style={config.appTitle.style}
                                src={config.appTitle.value}
                                alt={config.appTitle.name}
                            />
                        </Header.IconLink>
                    )}
                </TitleContainer>

                {config.showEnvironment && envName && (
                    <Box position="relative" paddingX="8px" title={envName}>
                        <Box component="span" opacity={0}>
                            <Typography noWrap style={styles.dummyText}>
                                {envName}
                            </Typography>
                        </Box>

                        <StyledChip>
                            <Typography noWrap style={styles.environmentText}>
                                {envName}
                            </Typography>
                        </StyledChip>
                    </Box>
                )}
                {devFeaturesEnabled && (
                    <Box position="relative">
                        <StyledChip>
                            <Typography
                                noWrap
                                style={{
                                    fontSize: '8px',
                                    padding: '0px 1.5px',
                                }}
                            >
                                - WIP
                            </Typography>
                        </StyledChip>
                    </Box>
                )}

                <Header.RightSection>
                    <Header.Item
                        style={{ minWidth: '32px', padding: '0px 6px' }}
                    >
                        <LanguageMenu
                            anchorPosition={{
                                right: '-32px',
                                top: '54px',
                            }}
                        />
                    </Header.Item>
                    <Header.Item
                        style={{
                            minWidth: '32px',
                            padding: '0px 34px 0px 16px',
                        }}
                    >
                        <AccountDropdown />
                    </Header.Item>
                    <ShoppingCartMenu />
                </Header.RightSection>
            </Header>
            {cartOpen && devFeaturesEnabled && (
                <OpenCartmenu>
                    <ShoppingCartMenu showDrawer={false} />
                </OpenCartmenu>
            )}
        </>
    )
}
export default AppDesktopHeader
