import { PageContextProvider } from 'pageContext'
import ApplicationRoleDefinitionAssignments from './Assignments'
import ApplicationRoleDefinitionPeople from './People'
import ApplicationRoleDefinitions from './RoleDefinitions'

const RoleDefinitions = ({
    subActiveTab,
    resourceSystemModuleId,
    applicationId,
    applicationFriendlyName,
    applicationType,
    resourceId,
    applicationResourceId,
}) => {
    const renderContent = () => {
        if (subActiveTab === 'roleDefinitions') {
            return (
                <PageContextProvider key="RequestViewApplicationRoleDefinitionsListing">
                    <ApplicationRoleDefinitions
                        applicationId={applicationId}
                        resourceSystemModuleId={resourceSystemModuleId}
                    />
                </PageContextProvider>
            )
        } else if (subActiveTab === 'assignments') {
            return (
                <PageContextProvider key="RequestViewApplicationRoleDefinitionsAssignments">
                    <ApplicationRoleDefinitionAssignments
                        applicationResourceId={applicationResourceId}
                        resourceSystemModuleId={resourceSystemModuleId}
                    />
                </PageContextProvider>
            )
        } else if (subActiveTab === 'people') {
            return (
                <PageContextProvider key="RequestViewApplicationRoleDefinitionsPeople">
                    <ApplicationRoleDefinitionPeople
                        applicationId={applicationId}
                        applicationFriendlyName={applicationFriendlyName}
                        applicationType={applicationType}
                        resourceId={resourceId}
                    />
                </PageContextProvider>
            )
        }
        return <></>
    }
    return renderContent()
}

export default RoleDefinitions
