import { Checkbox } from '@material-ui/core'
import React, { Fragment } from 'react'

const FormCheckboxElement = ({item}) => {
    return <Fragment>{item?.localeKey} 
    <Checkbox  />
    </Fragment>
}

export default FormCheckboxElement
