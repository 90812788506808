import {
    Box,
    FormControl,
    InputBase,
    InputLabel,
    makeStyles,
} from '@material-ui/core'
import { isNilOrEmpty, isWhitespace } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { Fragment, useEffect, useState } from 'react'
import { IconButton, Select } from 'packages/eid-ui'
import { ReactComponent as ResizeSvg } from './resize.svg'
import { useTranslation } from 'react-i18next'

const useInputStyles = makeStyles((theme) => ({
    root: {
        width: '100% !important',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: '5px',
        position: 'relative',
        backgroundColor: '#ffffff !important',
        border: '1px solid #d8d8dd;',
        fontSize: '14px',
        padding: '10px 12px',
        paddingRight: '36px',
        lineHeight: 1.25,
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        '&:hover': {
            borderColor: '#307fc1',
            backgroundColor: theme.palette.common.white,
        },
        '&:focus': {
            borderColor: '#307fc1',
            backgroundColor: theme.palette.common.white,
        },

        // for IE
        overflowY: 'auto',
        '&::placeholder': {
            color: '#b4b4b4',
            opacity: 1,
        },
    },
    multiline: {
        padding: 0,
    },
    focused: {
        backgroundColor: theme.palette.common.white,
    },
    error: {
        '& input, & textarea': {
            border: '1px solid #f44336 !important',
        },
    },
}))

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        position: 'relative',
    },
    arrowIcon: {
        position: 'absolute',
        right: '12px',
        bottom: '8px',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#dfe2e6',
            borderRadius: '4px',
        },
    },
})

const getOptionLabel = (option) => option.value

const TextField = (props) => {
    const { t } = useTranslation()
    const {
        label,
        multiline,
        value,
        maxCharacters,
        required,
        variant = 'dark',
        onSubmit,
        immediatelySubmit,
        showSubmitButton,
        errorMessageComponent,
        onFocus,
        onChange,
        ...rest
    } = props

    const classes = useStyles()

    const error =
        value?.length > maxCharacters ||
        isWhitespace(value) ||
        (required && isNilOrEmpty(value))

    const inputClasses = useInputStyles()
    const [options, setOptions] = useState([])

    // TODO: Uncomment when real api
    // const { data, isLoading } = usePredefinedJustifications()
    const data = [
        {
            id: 7423,
            name: 'ITShop_Justifications_JobPositionChange',
            value: 'Job or position change for an employee',
        },
        {
            id: 7426,
            name: 'ITShop_Justifications_New_Application_Rollout',
            value: 'New application roll-out',
        },
        {
            id: 7424,
            name: 'ITShop_Justifications_NewAdditionalResponsibilitie',
            value: 'New or additional responsibilities',
        },
        {
            id: 7422,
            name: 'ITShop_Justifications_AccessForNewEmployee',
            value: 'Requesting access for new employee',
        },
        {
            id: 7425,
            name: 'ITShop_Justifications_SubstitutionColleague',
            value: 'Substitution for a colleague',
        },
    ]
    const isLoading = false

    const [selectedJustification, setSelectedJustification] = useState('')

    useEffect(() => {
        if (data) {
            const allOptions = [
                ...data,
                {
                    id: 'other',
                    name: 'Other',
                    value: t('Common_Other'),
                },
            ]
            setOptions(allOptions)
        }
        // TODO: Uncomment when real api
        // }, [data])
    }, [])

    useEffect(() => {
        if (options.length > 0) {
            const opToSelect = options.find((o) => o.value === value)

            if (opToSelect) {
                setSelectedJustification(opToSelect)
            } else {
                setSelectedJustification(
                    options.find((o) => o.name === 'Other'),
                )
            }
        }
    }, [options])

    return (
        <Fragment>
            {options.length > 1 && (
                <Select
                    placeholder={t('Common_PleaseSelectJustification')}
                    options={options}
                    loading={isLoading}
                    onChange={(selectedItem) => {
                        setSelectedJustification(selectedItem)
                        if (selectedItem && selectedItem.name !== 'Other') {
                            onChange(selectedItem.value)
                            immediatelySubmit(selectedItem.value)
                        }
                    }}
                    value={selectedJustification}
                    getOptionLabel={getOptionLabel}
                />
            )}

            {selectedJustification && selectedJustification.name === 'Other' && (
                <Fragment>
                    <FormControl className={classes.root}>
                        {required && (
                            <Box
                                position="absolute"
                                right={6}
                                top={4}
                                color="#D0021b"
                                zIndex={1}
                                fontSize="17px"
                            >
                                *
                            </Box>
                        )}
                        {label && (
                            <InputLabel
                                shrink
                                style={{
                                    textTransform: 'uppercase',
                                    color: '#b4b4b4',
                                    paddingLeft: '15px',
                                }}
                            >
                                {label}
                            </InputLabel>
                        )}

                        <InputBase
                            classes={inputClasses}
                            multiline={multiline}
                            value={value}
                            error={error}
                            onChange={(e) => onChange(e.target.value)}
                            onFocus={onFocus}
                            {...rest}
                        />

                        {multiline && (
                            <Box position="absolute" right="4px" bottom="-2px">
                                <ResizeSvg />
                            </Box>
                        )}
                    </FormControl>
                    {maxCharacters && (
                        <Box
                            display="flex"
                            flexDirection="row-reverse"
                            justifyContent="space-between"
                            fontSize="10px"
                            color={error ? '#f44336' : 'inherit'}
                            mt="2px"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-end"
                            >
                                <Box>
                                    {value?.length}/{maxCharacters}
                                </Box>
                                {showSubmitButton && (
                                    <Box mt="6px" display="flex">
                                        <SuccessButton
                                            onClick={onSubmit}
                                            color={colors[variant].icon}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {errorMessageComponent}
                        </Box>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export default TextField

const colors = {
    light: {
        text: '#ffffff',
        icon: 'red',
    },
    dark: {
        text: '#b4b4b4',
        icon: '#3b454d',
    },
}

const SuccessButton = ({ onClick, color, disabled }) => {
    const iconColor = disabled ? '#b4b4b4' : color

    return (
        <Box
            marginLeft="10px"
            style={{
                backgroundColor: '#ebebed',
                padding: '4px',
                borderRadius: '5px',
            }}
        >
            <IconButton onClick={onClick} size="small" disabled={disabled}>
                <Icon
                    name="Approve"
                    height="12px "
                    width="12px"
                    color={iconColor}
                />
            </IconButton>
        </Box>
    )
}
