import React from 'react'
import ApplicationRoleDefinitionAssignments from 'components/SharedListings/ApplicationRoleDefinitionAssignments'
import ApplicationAppRightsAssignments from 'components/SharedListings/ApplicationAppRightsAssignments'
import AppRightsPeople from 'components/SharedListings/AppRightsPeople'
import RoleDefinitionPeople from 'components/SharedListings/RoleDefinitionPeople'
import { PageContextProvider } from 'pageContext'

const PbacAccessAssignments = ({
    subActiveTab,
    applicationId,
    resourceSystemModuleID,
    applicationFriendlyName,
    applicationType,
    resourceId,
}) => {
    const renderContent = () => {
        if (subActiveTab === 'appRightsAssignments') {
            return (
                <PageContextProvider key={'appRightsAssignments'}>
                    <ApplicationAppRightsAssignments
                        resourceSystemModuleID={resourceSystemModuleID}
                        resourceId={resourceId}
                    />
                </PageContextProvider>
            )
        }
        if (subActiveTab === 'roleDefinitionAssignments') {
            return (
                <PageContextProvider key={'roleDefinitionAssignments'}>
                    <ApplicationRoleDefinitionAssignments
                        resourceId={resourceId}
                        resourceSystemModuleID={resourceSystemModuleID}
                    />
                </PageContextProvider>
            )
        }
        if (subActiveTab === 'appRightsPeople') {
            return (
                <PageContextProvider key={'appRightsPeople'}>
                    <AppRightsPeople
                        applicationId={applicationId}
                        applicationType={applicationType}
                        resourceId={resourceId}
                    />
                </PageContextProvider>
            )
        }
        if (subActiveTab === 'roleDefinitionPeople') {
            return (
                <PageContextProvider key={'roleDefinitionPeople'}>
                    <RoleDefinitionPeople
                        applicationId={applicationId}
                        applicationFriendlyName={applicationFriendlyName}
                        applicationType={applicationType}
                        resourceId={resourceId}
                    />
                </PageContextProvider>
            )
        }
        return <></>
    }
    return renderContent()
}

export default PbacAccessAssignments
