import { Fragment, useEffect } from 'react'
import { queryCache } from 'react-query'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export const RemainingTime = ({ endTime }) => {
    const { t } = useTranslation()
    const currentTime = new Date().toISOString()
    const duration = moment.duration(
        moment.utc(endTime).diff(moment.utc(currentTime)),
    )

    useEffect(() => {
        const updateCredential = setTimeout(() => {
            queryCache.invalidateQueries(
                (c) =>
                    c.queryKey.includes('LIST_MY_APPLICATION_SECRETS') ||
                    c.queryKey.includes('LIST_MY_APPLICATION_CERTIFICATES'),
            )
        }, duration.asMilliseconds())
        return () => {
            clearTimeout(updateCredential)
        }
    }, [])

    const getRemainingTime = (currentTime, endTime) => {
        if (!endTime) {
            return '-'
        }
        if (currentTime > endTime) {
            return t('Expired')
        }

        const hours = duration.hours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
        const minutes = duration.minutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
        const time = `${hours} ${t('hours')} ${minutes} ${t('mins')}`
        const daysLabel = duration.days() > 1 ? t('days') : t('day')

        const durationInDays = duration.days() + ' ' + daysLabel + ' ' + time

        return duration.days() > 0 ? durationInDays : time
    }
    return <Fragment>{getRemainingTime(currentTime, endTime)}</Fragment>
}

const CredentialRemainingTime = ({ data }) => {
    const { item } = data

    if (item.canCheckIn) {
        return (
            <div>
                <RemainingTime endTime={item.currentCheckOutEndTimeUtc} />
            </div>
        )
    } else {
        return <></>
    }
}

export default CredentialRemainingTime
