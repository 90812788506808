import { useTranslation } from 'react-i18next'
import TableGrid from 'components/TableGrid'
import { Box } from '@material-ui/core'
import { getFullImageUrl } from 'utils'
import { useManagementRoleLinkedApplications } from 'hooks'
import { usePageContext } from 'pageContext'
import { useEffect } from 'react'

export const LinkedApplications = ({ managementRoleId, queryData }) => {
    const { t } = useTranslation()
    const [_, dispatch] = usePageContext()
    const defaultApplicationImageUrl = '/webcdn/Images/AppLogos/Genric-1.png'
    const attributes = [
        {
            label: '',
            style: {
                width: '40px',
            },
            resolve: (item) => (
                <img
                    style={{ maxHeight: '40px' }}
                    src={getFullImageUrl(
                        item.applicationLogoImageUrl ||
                            defaultApplicationImageUrl,
                    )}
                    alt={t('ApplicationImage')}
                />
            ),
        },
        {
            name: 'friendlyName',
            label: t('FriendlyName'),
        },
    ]

    const {
        list: linkedApplications,
        loading: linkedApplicationsLoading,
        pagination: linkedApplicationsPagination,
    } = useManagementRoleLinkedApplications(
        managementRoleId,
        true,
        queryData ?? '',
    )

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
    }, [queryData])

    return (
        <>
            <TableGrid
                loading={linkedApplicationsLoading}
                data={linkedApplications}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
            />
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '36px',
                }}
            >
                <span style={{ background: '#fff', borderRadius: '5px' }}>
                    {linkedApplicationsPagination}
                </span>
            </Box>
        </>
    )
}
