import { Fragment } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { Owners } from '../../../../components/Owners/Owners'
import { useAccessRequestPolicy } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import DynamicComponents from 'components/DynamicComponents'
import { ResponsibleParty } from 'components/ApplicationBanner/ResponsibleParties'
import useSubcomponents from 'useSubcomponents'

const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',

            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },
    }),
)

export const MainOverview = (props) => {
    const classes = useStyles()

    const { hasAccessToControl } = useSubcomponents()
    const { basicAttributes, detailsLoading, sharedFolder } = props
    const policy = useAccessRequestPolicy(
        sharedFolder && sharedFolder.requestPolicyId,
    )

    const { t } = useTranslation()

    const renderComponent = (a, item) => {
        const DynamicComponentToRender =
            DynamicComponents[a.component.componentName]
        return <DynamicComponentToRender attribute={a} data={{ item }} />
    }

    const sharedFolderDetails = (
        <Fragment>
            <Box className={classes.basicInfo}>
                {basicAttributes &&
                    basicAttributes
                        .filter((att) => att.firstPane)
                        .map((att, i) => {
                            return (
                                <Attribute key={att.value + i}>
                                    <AttributeLabel>
                                        {t(att.label)}
                                    </AttributeLabel>
                                    <AttributeValue>
                                        {att.component &&
                                            renderComponent(att, sharedFolder)}
                                        {sharedFolder &&
                                            !att.hideValue &&
                                            (sharedFolder[att.value] ?? '-')}
                                    </AttributeValue>
                                </Attribute>
                            )
                        })}
            </Box>
        </Fragment>
    )
    const policyDetails = (
        <Box>
            <Attribute>
                <AttributeLabel>{t('PublishInIAMShop')}</AttributeLabel>
                <AttributeValue>
                    {sharedFolder?.requestable ? t('Yes') : t('No')}
                </AttributeValue>
            </Attribute>
            <Attribute>
                <AttributeLabel>{t('AccessRequestPolicy')}</AttributeLabel>
                <AttributeValue>{policy && policy.name}</AttributeValue>
            </Attribute>
            {basicAttributes &&
                basicAttributes
                    .filter((att) => !att.firstPane)
                    .map((att, i) => {
                        return (
                            <Attribute key={att.value + i}>
                                <AttributeLabel>{t(att.label)}</AttributeLabel>
                                <AttributeValue>
                                    {att.component &&
                                        renderComponent(att, sharedFolder)}
                                    {sharedFolder &&
                                        !att.hideValue &&
                                        (sharedFolder[att.value] ?? '-')}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
        </Box>
    )
    const showApplicationDetails = hasAccessToControl(
        'ResourceAdmin-SharedFolder-Overview-Details-Control',
    )
    const showPolicyDetails = hasAccessToControl(
        'ResourceAdmin-SharedFolder-Overview-Policies-Details-Control',
    )
    const showOwnersDetails = hasAccessToControl(
        'ResourceAdmin-SharedFolder-Overview-Owners-Control',
    )
    const showResponsiblePartyDetails = hasAccessToControl(
        'ResourceAdmin-SharedFolder-Overview-ResponsibleParty-Control',
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                {showApplicationDetails && (
                    <Box className={classes.section}>{sharedFolderDetails}</Box>
                )}

                <Box className={classes.section}>
                    {showPolicyDetails ? policyDetails : null}
                </Box>

                <Box className={classes.section}>
                    {showOwnersDetails && (
                        <Attribute>
                            <AttributeLabel>
                                {t('SharedFolders_Owners')}
                            </AttributeLabel>
                            <Owners resourceId={sharedFolder?.id} />
                        </Attribute>
                    )}
                    {showResponsiblePartyDetails && (
                        <Attribute>
                            <AttributeLabel>
                                {t('ResponsibleParty')}
                            </AttributeLabel>
                            <ResponsibleParty
                                responsiblePersonId={sharedFolder?.ownerId}
                            />
                        </Attribute>
                    )}
                </Box>
            </Box>
        </Fragment>
    )

    return (
        <>
            {detailsLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.section}>{renderContent()}</Box>
                </Fragment>
            )}
        </>
    )
}
