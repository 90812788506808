import { FC } from 'react'
import { Box, createStyles, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { isNilOrEmpty } from 'packages/core'
import { NativeDateTimePicker } from 'packages/eid-ui/DateTimePickers'

export interface EndDateTimePickerProps {
  selectedEndDate: any
  setSelectedEndDate: any
  hasInvalidDates: any
  validationMessage: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
   
    policyViolationMessage: {
      position: 'absolute',
      fontSize: '12px',
      color: theme.palette.error.main,
      marginRight: '12px',
      whiteSpace: 'nowrap',
    },
 
  }),
)

export const EndDateTimePicker: FC<EndDateTimePickerProps> = ({
  selectedEndDate,
  setSelectedEndDate,
  hasInvalidDates,
  validationMessage,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <NativeDateTimePicker
        minDate={

          moment(new Date()).format('YYYY-MM-DDTHH:mm')
        }
        value={moment(selectedEndDate)
          .clone()
          .local()
          .format('YYYY-MM-DDTHH:mm')}
        handleChange={(e: any) => {
          if (isNilOrEmpty(e)) {
            setSelectedEndDate(null)
          } else {
            const date = moment(e)
            const dateToSet = date.clone().utc().format()
            setSelectedEndDate(dateToSet)
          }
        }}
        pickerStyles={{ top: '22px', right: '10px', width: 'auto' }}
        customTimeControl={false}
        disabledTimeSlots={[]}
        clearBtnLabel={t("Clear")}
        todayBtnLabel={t("Today")}
      >
        <TextField
          type="text"
          value={
            selectedEndDate
              ? moment(selectedEndDate)
                .local()
                .format('L LT')
              : ''
          }
          label={t('EndDateTime')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </NativeDateTimePicker>
      {hasInvalidDates && validationMessage && (
        <Box className={classes.policyViolationMessage}>
          {validationMessage}
        </Box>
      )}
    </>
  )
}
