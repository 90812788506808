import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { useIAmShopAssignments } from 'hooks'
import DeleteAssignment from './DeleteAssignment'
import AddAssignment from 'components/ApplicationBanner/AddAssignments/AddAssignment'
import { PageContextProvider } from 'pageContext'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Filters } from 'components'

const canAddIAMShopAssignmentName =
    'ResourceAdmin-SharedFolderDetails-IAMShopAssignments-AddAssignment-Control'
const canDeleteIAMShopAssignmentName =
    'ResourceAdmin-SharedFolderDetails-IAMShopAssignments-DeleteAssignment-Control'

export const IAmShopAssignments = ({ resource }) => {
    const { t } = useTranslation()
    const {
        list,
        loading: isLoading,
        pagination,
    } = useIAmShopAssignments(resource.id)

    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const attributes = [
        {
            label: t('IAmShopPermissionLevel'),
            name: 'localizedAccessLevelFriendlyName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignments-PermissionLevel',
            },
        },
        {
            label: t('Assignee'),
            name: 'assigneePreviewName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignments-Assignee',
            },
        },
        {
            label: t('AssigneeTechnicalName'),
            name: 'assigneeTechnicalPreviewName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignments-AssigneeTechnicalName',
            },
        },
        {
            label: t('AccessFulfilledByAssignee'),
            name: 'parentAssigneePreviewName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignments-AccessFulfilledByAssignee',
            },
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('ResourceDisplayName'),
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolderDetails-IAMShopAssignments-Resource',
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <Box display={'flex'}>
                            {hasAccessToControl(
                                canDeleteIAMShopAssignmentName,
                            ) &&
                                item.assigneeType === 'Person' && (
                                    <DeleteAssignment
                                        resource={resource}
                                        item={item}
                                        drawerView={false}
                                    />
                                )}
                        </Box>
                    )
                },
            },
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                {hasAccessToControl(canAddIAMShopAssignmentName) && (
                    <PageContextProvider key="AddNewIAmShopAssignments">
                        <AddAssignment
                            resourceType={'SharedFolder'}
                            labelKey={'AddNewAccessAssignment'}
                            searchUrl={`api/people/allUnassignedPeopleByResourceAccessRequestAssignee`}
                            targetAssigneeId={resource.id}
                            showPermissionLevel={true}
                            permissionLevelProps={{
                                permissionLabelProp:
                                    'localizedAccessLevelFriendlyName',
                            }}
                            filterWithAccessLevel={true}
                            queryParams={{
                                showSuggested: false,
                                showPreApproved: false,
                                showEligible: false,
                            }}
                        />
                    </PageContextProvider>
                )}

                <Box width="308px" display="flex" alignItems="center">
                    <Filters.TextSearch outlined />
                </Box>
            </Box>

            <EidTableGrid
                pagination={pagination}
                isLoading={isLoading}
                list={list}
                attributes={getAccessBasedAttributes(attributes)}
                headings={getAccessBasedAttributes(attributes).map(
                    (a) => a.label,
                )}
            />
        </>
    )
}
