import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled, Typography } from '@material-ui/core'
import { isNilOrEmpty, useIsSmallScreenFalse } from 'packages/core'
import { useDeleteManagementRoleMember, useMembersByApplicationId } from 'hooks'
import { ListingGrid, GridContainer, Filters } from 'components'
import useSubcomponents from 'useSubcomponents'
import { Avatar, ToggleSwitch, WarningModal } from 'packages/eid-ui'
import { ArrowIcon, Icon } from 'packages/eid-icons'
import { EidAutocomplete } from 'packages/eid-controls'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { getFullImageUrl, imagesDisabled } from 'utils'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'label',
    labelProp: 'friendlyName',
})
export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '10px',
    },
}))
const RemoveSelectedItem = styled(Box)({
    width: '290px',
})

const canChangeBetweenDirectAndResultantMembersControlName =
    'ResourceAdmin-ApplicationDetails-Members-ChangeMembersType-Control'
const canSelectAppManagementRoleControlName =
    'ResourceAdmin-ApplicationDetails-Members-SelectAppManagementRole-Control'
const canRemoveMultipleMembersControlName =
    'ResourceAdmin-ApplicationDetails-Members-RemoveMultipleMembers-Control'
const hasAccessToUIActionsControlName =
    'ResourceAdmin-ApplicationDetails-Members-UIActionsButton-Control'

const Members = ({ applicationId, primaryKey }) => {
    const { t } = useTranslation()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const [isDirect, setIsDirect] = useState(true)
    const [selected, setSelected] = useState([])
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [selectedRole, setSelectedRole] = useState()
    const queryParams = {
        take: 10,
    }
    const getOptionLabel = (option) => option && option.friendlyName

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useMembersByApplicationId(
        applicationId,
        selectedRole?.id ? selectedRole.id : null,
        isDirect,
    )

    useEffect(() => {
        setSelected([])
    }, [list])

    const [deleteMember, { isLoading: isDeletingMember, isSuccess }] =
        useDeleteManagementRoleMember(null, applicationId)

    const handleDelete = () => {
        let personMgmtRolesToRemove = []
        selected.forEach((personId) => {
            let itemIndex = list.findIndex((x) => x.id === personId)
            if (itemIndex > -1) {
                personMgmtRolesToRemove.push({
                    personID: list[itemIndex].id,
                    managementRoleID: list[itemIndex].assigneeId,
                })
            }
        })
        deleteMember(personMgmtRolesToRemove).then(() => {
            setShowWarningModal(false)
        })
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredList = list.map((item) => item.id)
            setSelected(filteredList)
        }
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item.id])
        }
    }

    const isSmallScreen = useIsSmallScreenFalse()

    const handleBulkAction = () => {
        setShowWarningModal(true)
    }
    const selectAllAction = hasAccessToControl(
        canRemoveMultipleMembersControlName,
    )
        ? {
              render: () => {
                  return (
                      <RemoveSelectedItem
                          data-protectedsubcomponent={
                              canRemoveMultipleMembersControlName
                          }
                      >
                          <BulkRemoveButton
                              selectedCount={selected.length}
                              label={t('RemoveSelectedMembers', {
                                  count: selected.length,
                              })}
                              onClick={handleBulkAction}
                          />
                      </RemoveSelectedItem>
                  )
              },
          }
        : null
    const attributes = [
        {
            label: 'DisplayName',
            name: 'friendlyName',
            sortable: true,
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-DisplayName',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box style={{ display: 'flex', gap: 10 }}>
                            {!imagesDisabled && (
                                <Avatar
                                    src={getFullImageUrl(data.imageThumbUrl)}
                                />
                            )}
                            <Typography style={{ fontSize: '13px' }}>
                                {data.friendlyName}
                            </Typography>
                        </Box>
                    )
                },
            },
        },
        {
            label: 'AppManagementRole',
            name: 'assigneeFriendlyName',
            sortBy: 'assigneeFriendlyName',

            sortable: true,
            headerCellStyles: {
                width: '162px',
                minWidth: '162px',
                fontSize: '13px',
            },
            styles: {
                minWidth: '162px',
                width: '162px',
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Members-AppManagementRole',
            },
        },
        {
            label: 'Login',
            name: 'login',
            sortable: true,
            headerCellStyles: {
                width: '162px',
                minWidth: '162px',
                fontSize: '13px',
            },
            styles: {
                minWidth: '162px',
                width: '162px',
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-Login',
            },
        },
        {
            name: 'company',
            label: 'Company',
            sortable: true,
            headerCellStyles: {
                width: '162px',
                minWidth: '162px',
                fontSize: '13px',
            },
            styles: {
                minWidth: '162px',
                width: '162px',
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-Company',
            },
        },
        {
            name: 'email',
            label: 'Email',
            sortable: true,
            headerCellStyles: {
                width: '162px',
                minWidth: '162px',
                fontSize: '13px',
            },
            styles: {
                minWidth: '162px',
                width: '162px',
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-Email',
            },
        },
        {
            hideValue: true,
            label: 'Enabled',
            sortable: true,
            component: {
                componentName: 'renderer',
                render: (data) => (data.enabled === true ? t('Yes') : t('No')),
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-Enabled',
            },
        },

        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(hasAccessToUIActionsControlName) && (
                            <UIActionsGearButton item={data} />
                        )
                    )
                },
            },
        },
    ]

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    {hasAccessToControl(
                        canChangeBetweenDirectAndResultantMembersControlName,
                    ) && (
                        <Box
                            data-protectedsubcomponent={
                                canChangeBetweenDirectAndResultantMembersControlName
                            }
                        >
                            <SwitchContainer value={isDirect}>
                                <Typography className="Label">
                                    {t('Resultant')}
                                </Typography>
                                <ToggleSwitch
                                    value={isDirect}
                                    onChange={() => {
                                        setIsDirect((p) => !p)
                                    }}
                                />
                                {t('Direct')}

                                <Typography className="SubLabel"></Typography>
                            </SwitchContainer>
                        </Box>
                    )}
                    {hasAccessToControl(
                        canSelectAppManagementRoleControlName,
                    ) && (
                        <Box
                            data-protectedsubcomponent={
                                canSelectAppManagementRoleControlName
                            }
                            width={'100%'}
                        >
                            <EidAutocomplete.New
                                placeholder={t('AppManagementRole')}
                                url={`api/ManagementRoles/application/${applicationId}`}
                                queryParams={queryParams}
                                dedupingInterval={60000}
                                value={selectedRole ? selectedRole : null}
                                getOptionLabel={getOptionLabel}
                                clearIcon={
                                    !isNilOrEmpty(selectedRole) && (
                                        <Icon
                                            name="Close"
                                            color="#959598"
                                            onClick={() =>
                                                setSelectedRole(null)
                                            }
                                        />
                                    )
                                }
                                onChange={(_, data) => {
                                    setSelectedRole(
                                        data?.value === 'all' ? null : data,
                                    )
                                }}
                                optionComponent={OptionComponent}
                                clearFilterOption={{
                                    friendlyName: 'All',
                                    value: 'all',
                                }}
                                inputWrapperStyleProps={{
                                    height: '36px',
                                    width: 'fit-content',
                                }}
                                rootStyleProps={{ height: '40px !important' }}
                            />
                        </Box>
                    )}
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    selectAllAction={isDirect && selectAllAction}
                    rowSelection={
                        hasAccessToControl(
                            canRemoveMultipleMembersControlName,
                        ) && isDirect
                    }
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selected}
                    handleClick={handleRowSelection}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
                <WarningModal
                    title={t('DeleteMembers')}
                    description={t('MembersDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={showWarningModal}
                    cancel={() => setShowWarningModal(false)}
                    proceeding={isDeletingMember}
                    proceed={handleDelete}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default Members
