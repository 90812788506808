import { Trans, useTranslation } from 'react-i18next'
import TableGrid from 'components/TableGrid'
import { Box } from '@material-ui/core'
import { useClaimsByClaimsMappingPolicyId, useUnassignClaim } from 'hooks'
import { usePageContext } from 'pageContext'
import { useEffect } from 'react'
import { useIsSmallScreenFalse, useModalState } from 'packages/core'
import GenericButton from 'components/Button/GenericButton'
import { WarningModal } from 'packages/eid-ui'

const DeleteButton = ({ item, claimMappingPolicyId }) => {
    const { t } = useTranslation()

    const [modalOpen, openModal, closeModal] = useModalState()

    const [unassignClaim, { isLoading: isUnassigning }] = useUnassignClaim(
        claimMappingPolicyId,
        item.id,
    )

    return (
        <>
            <WarningModal
                title={t('Remove') + item.friendlyName}
                description={t('DeleteClaimConfirmationMessage')}
                yesLabel={t('Yes')}
                noLabel={t('No')}
                open={modalOpen}
                cancel={closeModal}
                proceeding={isUnassigning}
                proceed={() => {
                    unassignClaim()
                    closeModal()
                }}
            />
            <GenericButton
                onClick={openModal}
                color="#ffffff"
                rootStylesProp={{
                    borderRadius: '4px',
                    height: '32px',
                    fontSize: '14px',
                    padding: '6px',
                    border: 'solid 1px #ff4d5f',
                    color: '#ff4d5f',
                    '&:hover': {
                        boxShadow: 'none',
                        backgroundColor: '#ff4d5f',
                        color: '#ffffff',
                        cursor: 'pointer',
                    },
                }}
            >
                {t('Remove')}
            </GenericButton>
        </>
    )
}

export const Claims = ({ policyId, queryData }) => {
    const { t } = useTranslation()
    const [, dispatch] = usePageContext()

    const attributes = [
        {
            name: 'name',
            label: t('Claim'),
        },
        {
            name: 'description',
            label: t('Description'),
        },
        {
            name: 'jwtClaimType',
            label: t('JWTClaimType'),
        },
        {
            name: 'samlClaimType',
            label: t('SAMLClaimType'),
        },
        {
            name: 'samlNameFormat',
            label: t('SAMLNameFormat'),
        },
        {
            label: '',
            name: 'actions',
            resolve: (item) => (
                <DeleteButton claimMappingPolicyId={policyId} item={item} />
            ),
            style: {
                textAlign: 'end',
            },
        },
    ]

    const {
        list: claims,
        loading: claimsLoading,
        pagination: claimsPagination,
    } = useClaimsByClaimsMappingPolicyId(policyId, true)
    const isSmallScreen = useIsSmallScreenFalse()
    useEffect(() => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
    }, [queryData])

    return (
        <>
            <TableGrid
                loading={claimsLoading}
                data={claims}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
                fallbackMessage={<Trans i18nKey="NoClaimsMessage" />}
                isScrollable={isSmallScreen}
            />
            <Box style={{ justfyContent: 'center', display: 'flex' }}>
                {claimsPagination}
            </Box>
        </>
    )
}
