import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled, createStyles, makeStyles } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { ItemDetails, DeleteCredential } from 'components'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import CheckIn from 'components/DynamicComponents/CheckIn'
import CheckOut from 'components/DynamicComponents/CheckOut'
import { UIActionsList } from 'components/UIActions/UIActionsList'
import { CheckoutHistory } from './CheckoutHistory'
import useSubcomponents from 'useSubcomponents'
import { getAccessibleTabs, updateUrlQueryParams } from 'utils'
import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'

const TABS = [
    {
        value: 'overview',
        label: 'Overview',
        display: true,
    },
    {
        value: 'additionalInformation',
        label: 'MoreInformation',
        display: true,
        requireAccess: {
            control:
                'ResourceAdmin-Application-Details-ClientSecrets-Detail-MoreInformation',
        },
    },
    {
        value: 'checkouts',
        label: 'Checkouts',
        display: true,
        requireAccess: {
            control:
                'ResourceAdmin-Application-Details-ClientSecrets-Detail-Checkouts',
        },
    },
]

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '16px',
            [theme.breakpoints.up('sm')]: {
                height: '80px',
                alignItems: 'center',
                position: 'relative',
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                paddingTop: '16px !important',
                paddingBottom: '16px !important',
            },
        },
    }),
)

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const SecretDetails = ({ secret }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { hasAccessToControl } = useSubcomponents()
    const query = useQuery()
    const history = useHistory()
    const [allAccessibleTabs, setAllAccessibleTabs] = useState([])
    const [activeTab, setActiveTab] = useState('')

    useEffect(() => {
        const activeTabInfo = getAccessibleTabs(
            query,
            history,
            TABS,
            query.get('drawerActiveTab'),
            null,
            hasAccessToControl,
            'drawerActiveTab',
            '',
        )
        setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
        setActiveTab(activeTabInfo.activeTab)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleTabChange = (tab) => {
        setActiveTab(tab)
        updateUrlQueryParams(
            query,
            history,
            [{ key: 'drawerActiveTab', value: tab }],
            [],
        )
    }

    return (
        <>
            {!secret ? (
                <Loader />
            ) : (
                <>
                    <Box style={{ backgroundColor: '#fbfbfd' }}>
                        <ItemDetails.Tabs
                            variant="standard"
                            value={activeTab}
                            onChange={(_, value) => handleTabChange(value)}
                        >
                            {allAccessibleTabs.map((tab, index) => (
                                <ItemDetails.Tabs.Tab
                                    key={index + tab.value}
                                    value={tab.value}
                                    label={t(tab.label)}
                                    data-protectedsubcomponent={
                                        tab.requireAccess?.control
                                    }
                                />
                            ))}
                        </ItemDetails.Tabs>
                    </Box>

                    {activeTab === 'overview' && (
                        <OverviewSection secret={secret} />
                    )}
                    {activeTab === 'additionalInformation' && (
                        <MoreInformationSection secret={secret} />
                    )}
                    {activeTab === 'checkouts' && (
                        <CheckoutHistory secret={secret} />
                    )}
                    {!secret.isExpired && (
                        <>
                            <UIActionsList item={secret} />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <PaddedDiv className={classes.root}>
                                    {secret.canCheckOut && (
                                        <CheckOut
                                            data={{ item: secret }}
                                            drawerView={true}
                                        />
                                    )}
                                    {secret.canCheckIn && (
                                        <CheckIn
                                            data={{ item: secret }}
                                            drawerView={true}
                                        />
                                    )}
                                    <Box
                                        style={{
                                            marginLeft:
                                                !secret.canCheckIn &&
                                                !secret.canCheckOut
                                                    ? '-40px '
                                                    : '0px',
                                        }}
                                    >
                                        <DeleteCredential
                                            item={secret}
                                            drawerView={true}
                                        />
                                    </Box>
                                </PaddedDiv>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default SecretDetails
