import { Box, Typography, makeStyles, styled, Dialog } from '@material-ui/core'
import { Button } from 'components'
import { Icon } from 'packages/eid-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import TimeConstraintComp from './TimeConstraint'
import { Spinner } from 'packages/eid-ui'

const useStyles = makeStyles(() => ({
    NewAccessAssignmentModal: {
        '& h2': {
            fontSize: '18px',
            color: '#1b1f1f',
            fontWeight: '600',
        },
    },
    ConstraintModalHeader: {
        padding: '32px',
    },
    AssignmentModalHeader: {
        padding: '32px',
    },
    AssignmentModalHeading: {
        padding: '16px 32px',
        borderTop: '1px solid #0000002e !important',
        borderBottom: '1px solid #0000002e !important',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%) !important',
        backgroundSize: '8.00px 8.00px !important',
    },
    AssignmentModalInner: {
        padding: '32px',
    },
    AssignmentModalFooter: {
        padding: '24px 32px',
        boxShadow: '0 3px 7px 4px rgba(0, 0, 0, 0.08)',
        '& button': {
            border: 'solid 1px #307fc1',
            padding: '9px 24px',
            '&:nth-child(1)': {
                background: '#307fc1',
                color: '#fff',
                maxWidth: '240px',
                '& svg': {
                    marginRight: '10px',
                },
            },
            '&:nth-child(2)': {
                color: '#307fc1',
                marginLeft: '30px',
                maxWidth: '90px',
            },
        },
    },
    AssignmentTimeInner: {
        padding: '20px 32px',
        paddingTop: '0',
    },
}))

const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '430px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-20px',
        right: '-20px',
        cursor: 'pointer',
    },
})

const AddTimeConstraint = ({
    btnLabel,
    iconName,
    data,
    startTimeProp = 'startTimeUTC',
    endTimeProp = 'endTimeUTC',
    onSave = () => {},
    saving = false,
}) => {
    const [openAddTimeConstraintModal, setOpenAddTimeConstraintModal] =
        useState(false)
    const { t } = useTranslation()
    const classes = useStyles({})
    const [{ selectedStartDate, selectedEndDate }, setSelectedDate] = useState({
        selectedStartDate: null,
        selectedEndDate: null,
    })
    useEffect(() => {
        const currentDatetime = data[startTimeProp]
            ? data[startTimeProp]
            : moment.utc().second(0)
        const endDatetime = data[endTimeProp] ? data[endTimeProp] : null
        setSelectedDate({
            selectedStartDate: currentDatetime,
            selectedEndDate: endDatetime,
        })
    }, [data])

    const handleStartDate = (startDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedStartDate: startDateToSet }
        })
    }

    const handleEndDate = (endDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedEndDate: endDateToSet }
        })
    }

    return (
        <>
            <Button
                onClick={() => setOpenAddTimeConstraintModal(true)}
                style={{
                    borderRadius: '4px',
                    padding: '6px 7px',
                    backgroundColor: '#307fc1',
                    color: '#ffffff',
                    fontSize: '14px',
                    minWidth: '24px',
                    '&:hover': {
                        backgroundColor: '#307fc1',
                    },
                    '& svg': {
                        width: '16px',
                        height: '16px',
                        '& path': {
                            stroke: '#fff',
                        },
                    },
                }}
            >
                {btnLabel ? (
                    t(btnLabel)
                ) : iconName ? (
                    <Icon name={iconName} />
                ) : (
                    ''
                )}
            </Button>
            <CustomDialog
                open={openAddTimeConstraintModal}
                onClose={() => setOpenAddTimeConstraintModal(false)}
                title={t('')}
            >
                <Box className={classes.NewAccessAssignmentModal}>
                    <Box className={classes.ConstraintModalHeader}>
                        <CloseIconeBox
                            onClick={() => setOpenAddTimeConstraintModal(false)}
                        >
                            <Icon
                                name="Close"
                                width="16px"
                                height="16px"
                                color="#959598"
                            />
                        </CloseIconeBox>
                        <Typography variant="h2">
                            {t('TimeConstraint')}
                        </Typography>
                    </Box>
                    <TimeConstraintComp
                        handleStartDate={handleStartDate}
                        handleEndDate={handleEndDate}
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        hasInvalidDates={true}
                        validationMessage={''}
                    />
                    <Box className={classes.AssignmentModalFooter}>
                        <Button
                            onClick={() => {
                                onSave({
                                    selectedStartDate: moment(selectedStartDate)
                                        .utc()
                                        .format(),
                                    selectedEndDate: moment(selectedEndDate)
                                        .utc()
                                        .format(),
                                })
                            }}
                            backgroundColor="#307fc1"
                        >
                            {!saving ? (
                                <Icon
                                    name="Save"
                                    width="16px"
                                    height="16px"
                                    color="#fff"
                                />
                            ) : (
                                <Spinner
                                    style={{ marginRight: '10px' }}
                                    size={0.8}
                                    color={'white'}
                                />
                            )}
                            {t('Save')}
                        </Button>
                        <Button
                            onClick={() => setOpenAddTimeConstraintModal(false)}
                        >
                            {t('Cancel')}
                        </Button>
                    </Box>
                </Box>
            </CustomDialog>
        </>
    )
}

export default AddTimeConstraint
