const cartHelpers = {
    isItemAlreadyInCart: (cart, resourceId, locationId) => {
        if (cart && resourceId) {
            let item = []
            if (locationId) {
                item = cart.cartItems.filter(
                    (el) =>
                        el.requestableResourceId === resourceId &&
                        el.locationId === locationId,
                )
            } else {
                item = cart.cartItems.filter(
                    (el) => el.requestableResourceId === resourceId,
                )
            }

            if (item && item.length > 0) {
                return true
            }
        }
        return false
    },
    assignResourceToAssignee: ({
        targetAssigneeId,
        assignmentType,
        resource,
        selectedList,
        timeConstrained = false,
        startDateUtc = null,
        endDateUtc = null,
        identifier = 'id',
        resourceTypeRoleId,
    }) => {
        let itemsToAddToCart = []
        selectedList.forEach((item) => {
            const itemToAdd = {
                // Type of assignment (Add or Remove)
                assignmentType,
                // Target resource id for which we are shopping for
                targetAssigneeId: targetAssigneeId,
                // What we are adding to cart
                requestableResourceId: item[identifier],
                resource_FriendlyName: item.friendlyName,
                // For resouce we are dealing with
                resourceTypeId: item?.resourceTypeId,
                resourceType_Name: item?.resourceTypeName ?? 'Person',
                resourceTypeRoleId: resourceTypeRoleId,
                // resourceTypeRole_FriendlyName: item.friendlyName,
            }

            if (timeConstrained) {
                if (!endDateUtc) {
                    return undefined
                }
                itemToAdd.timeConstraintActive = true
                itemToAdd.startDateUtc = startDateUtc
                itemToAdd.endDateUtc = endDateUtc
            } else {
                if (startDateUtc) {
                    itemToAdd.startDateUtc = startDateUtc
                }
                if (endDateUtc) {
                    itemToAdd.endDateUtc = endDateUtc
                }
                itemToAdd.timeConstraintActive =
                    itemToAdd.endDateUtc && itemToAdd.startDateUtc
                        ? true
                        : false
            }
            itemsToAddToCart.push(itemToAdd)
        })

        return itemsToAddToCart
    },

    removeAssignmentToCartItem: ({
        resource,
        requestableResourceIdentifier = 'assigneeId',
        resourceAssignment,
        targetAssigneeId,
    }) => {
        const cartItem = {
            assignmentType: 'Remove',
            requestableResourceId:
                resourceAssignment[requestableResourceIdentifier],
            resource_FriendlyName: resource.friendlyName,
            resourceTypeId: resource.resourceTypeId,
            resourceType_Name: resource.resourceTypeName,
            resourceTypeRoleId: resourceAssignment.id,
            resourceTypeRole_FriendlyName: resourceAssignment.friendlyName,
            targetAssigneeId: targetAssigneeId,
        }

        return cartItem
    },
}

export default cartHelpers
