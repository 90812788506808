import { useTranslation } from 'react-i18next'
import TableGrid from 'components/TableGrid'
import { Box, Paper, createStyles, makeStyles } from '@material-ui/core'
import {
    useClaimsByClaimsMappingPolicyId,
    useClaimsMappingPolicies,
} from 'hooks'
import { usePageContext } from 'pageContext'
import { useDebounce, useIsSmallScreenFalse } from 'packages/core'
import { useEffect, useState } from 'react'
import { Loader } from 'packages/eid-ui'
import Policy from './Policy'
import Search from 'components/Filters/Search'
import { smallScreenWidth } from 'utils'

const useStyles = makeStyles(() =>
    createStyles({
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
        paperContainer: {
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

export const ClaimsMappingPolicies = ({ id, applicationResourceId }) => {
    const { t } = useTranslation()
    const [, dispatch] = usePageContext()
    const classes = useStyles()
    const isSmallScreen = useIsSmallScreenFalse()

    const { list: policy, loading: policyLoading } =
        useClaimsMappingPolicies(id)

    const [searchKey, setSearchKey] = useState('')

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }

    const debouncedSearchValue = useDebounce(searchKey)

    let queryData = debouncedSearchValue

    const attributes = [
        {
            name: 'name',
            label: t('Claim'),
            style: {
                width: '160px',
            },
        },
        {
            name: 'description',
            label: t('Description'),
            style: {
                width: '160px',
            },
        },
        {
            name: 'jwtClaimType',
            label: t('JWTClaimType'),
            style: {
                width: '160px',
            },
        },
        {
            name: 'samlClaimType',
            label: t('SAMLClaimType'),
            style: {
                width: '290px',
            },
        },
        {
            name: 'samlNameFormat',
            label: t('SAMLNameFormat'),
            style: {
                width: '160px',
            },
        },
    ]

    const {
        list: claimsMappingPolicies,
        loading: claimsMappingPoliciesLoading,
        pagination: claimsMappingPolicesPagination,
    } = useClaimsByClaimsMappingPolicyId(
        policy?.length > 0 && policy[0]?.id,
        policy?.length > 0,
        queryData ?? '',
    )

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
    }, [queryData])

    return (
        <>
            <Paper
                className={classes.paperContainer}
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >
                {policyLoading && <Loader height={80} />}
                {!policyLoading && (
                    <Policy
                        policyId={policy?.length > 0 ? policy[0].id : null}
                        applicationResourceId={applicationResourceId}
                    />
                )}
                <Box
                    style={{
                        paddingBottom: '17px',
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    {!isSmallScreen && (
                        <Box width="308px" display="flex" alignItems="center">
                            <Search
                                handleSearch={handleSearchChange}
                                placeholder={t('SearchAndFilter')}
                                value={searchKey ? searchKey : ''}
                                handleClear={handleClear}
                                outlined
                            />
                        </Box>
                    )}
                </Box>
            </Paper>

            <TableGrid
                loading={
                    policy?.length > 0 &&
                    policy[0]?.id &&
                    claimsMappingPoliciesLoading
                }
                data={
                    policy?.length > 0 && policy[0]?.id
                        ? claimsMappingPolicies
                        : []
                }
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
                fallbackMessage={t('NoClaimsMessage')}
                isScrollable={isSmallScreen}
            />
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '36px',
                }}
            >
                <span style={{ background: '#fff', borderRadius: '5px' }}>
                    {claimsMappingPolicesPagination}
                </span>
            </Box>
        </>
    )
}
