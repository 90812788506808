import React from 'react'
import { Box, styled } from '@material-ui/core'
import { useApplicationSettings } from 'hooks'
import ItemCard from 'components/ItemCard'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'packages/core'
import { imagesDisabled, isApplicationAzureApp, mobileScreenWidth } from 'utils'

import GenericButton from 'components/Button/GenericButton'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)({
    height: 'inline-block',
    width: '33%',

    overflow: 'hidden',
    [`@media (max-width:${mobileScreenWidth})`]: {
        width: '100%',
    },
})

const ApplicationCard = ({ item, attributes }) => {
    const { data: appConfig } = useApplicationSettings()
    const history = useHistory()
    const query = useQuery()
    const { t } = useTranslation()

    const isClaimsPolicyCard =
        attributes.findIndex((x) => x.isClaimPolicyAttributeOnly) > -1

    const isAzureApplication = isApplicationAzureApp(item?.applicationType)

    const getActionVerb = () => {
        if (isAzureApplication || isClaimsPolicyCard) return 'AzureApps'
        return 'Apps'
    }

    return (
        <Container>
            <ItemCard
                key={item.key}
                cardStyles={{
                    margin: '14px',
                }}
                headerStyles={{
                    backgroundImage: appConfig.style.colors.primary?.gradient,
                    backgroundColor: appConfig.style.colors.primary?.header,
                    padding: '20px',
                }}
                attributes={attributes}
                item={item}
                onItemClick={() => {
                    query.set('id', item.id)
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
                showImage={!imagesDisabled}
                rightButton={
                    <UIActionsGearButton
                        item={item}
                        noun={
                            isClaimsPolicyCard
                                ? 'ResourceAdminClaims'
                                : 'ResourceAdmin'
                        }
                        verb={getActionVerb()}
                    />
                }
                leftButton={
                    <GenericButton
                        onClick={() => {
                            history.push(
                                (isClaimsPolicyCard
                                    ? `?claimsMappingPolicyId=`
                                    : `?applicationId=`) + item?.id,
                            )
                        }}
                        color="#01ae8f"
                        rootStylesProp={{
                            borderRadius: '4px',
                            width: '80%',
                            height: '32px',
                        }}
                    >
                        {t('Details')}
                    </GenericButton>
                }
            />
        </Container>
    )
}

export default ApplicationCard
