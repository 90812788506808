import React from 'react'
import { ViewIndicator } from 'packages/eid-ui'
import { useAppState } from 'appContext'

const ViewSwitcher = () => {
    const [state, dispatch] = useAppState()
    const onCardViewClick = () => {
        dispatch({
            type: 'SET_ITEMS_VIEW',
            payload: 'card',
        })
    }

    const onListViewClick = () => {
        dispatch({
            type: 'SET_ITEMS_VIEW',
            payload: 'table',
        })
    }
    return (
        <ViewIndicator
            view={state.itemsView}
            onCardViewClick={onCardViewClick}
            onListViewClick={onListViewClick}
        />
    )
}

export default ViewSwitcher
