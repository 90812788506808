import { useDebounce } from 'packages/core'
import { Box, Dialog, makeStyles, styled, Typography } from '@material-ui/core'
import { Button } from 'components'
import BusinesRoleAndLocationTree from 'components/BusinesRoleAndLocationTree/BusinesRoleAndLocationTree'
import { InputBox } from 'components/ApplicationBanner/AddAssignments/AddAssignment'
import { useBusinessLocationsTree } from 'hooks'
import { Icon } from 'packages/eid-icons'
import { Loader } from 'packages/eid-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
    AddAccessModalMain: {
        '& .MuiDialog-paper': {
            maxWidth: '440px',
        },
    },
    NewAccessAssignmentModal: {
        '& h2': {
            fontSize: '24px',
            color: '#1b1f1f',
            fontWeight: 'normal',
        },
    },

    AssignmentModalHeader: {
        padding: '32px 40px',
        '& svg': {
            top: '-14px !important',
        },
    },

    AssignmentModalFooter: {
        display: 'flex',
        padding: '32px 40px',
        gap: '16px',

        '& button': {
            border: 'solid 1px #307fc1',
            padding: '8px 12px',
            height: '32px',
            fontSize: '14px',
            '&:nth-child(1)': {
                background: '#307fc1',
                color: '#fff',
            },
            '&:nth-child(2)': {
                color: '#307fc1',
                maxWidth: '90px',
            },
        },
    },

    newDirectAssigned: {
        padding: ' 0 40px',
        borderBottom: '1px solid #d8d8d8 !important',
    },
    searchInput: {
        ' & input': {
            height: '40px',
            marginTop: '12px',
            fontSize: '16px',
            '&::placeholder': {
                color: '#b4b4b4',
            },
        },
    },
}))

const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '560px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-20px',
        right: '-20px',
        cursor: 'pointer',
    },
})

const RoleBoxCardInner = styled(Box)({
    maxHeight: '200px',
    overflowY: 'auto',
    width: '100%',
    margin: '24px 0',
})

const MIN_CHARACTERS_REQUIRED_TO_SEARCH = 3

const AddDirectAssignedLocation = () => {
    const { t } = useTranslation()
    const classes = useStyles({})
    const [searchedLocation, setSearchedLocation] = useState('')
    const [selectedLocation, setSelectedLocation] = useState(null)
    const debouncedSearchLocationValue = useDebounce(searchedLocation)

    const [openModal, setOpenModal] = useState(false)
    const { data: locationTree, isLoading } = useBusinessLocationsTree(
        openModal,
        debouncedSearchLocationValue?.length >=
            MIN_CHARACTERS_REQUIRED_TO_SEARCH
            ? debouncedSearchLocationValue
            : '',
    )
    const handleSelect = (id) => {
        setSelectedLocation(id)
    }
    const handleSave = () => {
        // TODO: Need to call api
    }
    return (
        <>
            <Button
                onClick={() => setOpenModal(true)}
                style={{
                    borderRadius: '4px',
                    padding: '8px 12px',
                    height: '36px',
                    maxWidth: '250px',
                    width: '100%',
                    backgroundColor: '#307fc1',
                    color: '#ffffff',
                    fontSize: '14px',
                    '&:hover': {
                        backgroundColor: '#307fc1',
                    },
                }}
            >
                {t('AddNewDirectAssignedLocation')}
            </Button>
            <CustomDialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                title={t('')}
                className={classes.AddAccessModalMain}
            >
                <Box className={classes.NewAccessAssignmentModal}>
                    <Box className={classes.AssignmentModalHeader}>
                        <CloseIconeBox onClick={() => setOpenModal(false)}>
                            <Icon
                                name="Close"
                                width="16px"
                                height="16px"
                                color="#959598"
                            />
                        </CloseIconeBox>
                        <Typography variant="h2">
                            {t('AddNewDirectAssignedLocation')}
                        </Typography>
                    </Box>
                    <Box className={classes.newDirectAssigned}>
                        <Box className={classes.searchInput}>
                            <InputBox>
                                <Typography variant="subtitle1">
                                    {t('SelectLocation')}
                                </Typography>
                                <input
                                    type="text"
                                    placeholder={t('SearchLocation')}
                                    value={searchedLocation}
                                    onChange={(e) =>
                                        setSearchedLocation(e.target.value)
                                    }
                                ></input>
                            </InputBox>
                        </Box>
                        <Box>
                            <RoleBoxCardInner>
                                {isLoading ? (
                                    <Box>
                                        <Loader />
                                    </Box>
                                ) : locationTree ? (
                                    <BusinesRoleAndLocationTree
                                        explorer={locationTree}
                                        handleSelect={handleSelect}
                                        value={selectedLocation}
                                        isExpanded={
                                            searchedLocation?.length >=
                                            MIN_CHARACTERS_REQUIRED_TO_SEARCH
                                        }
                                        highlightText={searchedLocation}
                                    />
                                ) : (
                                    <Box>{t('NoLocationFound')}</Box>
                                )}
                            </RoleBoxCardInner>
                        </Box>
                    </Box>
                    <Box className={classes.AssignmentModalFooter}>
                        <Button
                            backgroundColor="#307fc1"
                            onClick={() => handleSave()}
                        >
                            {t('AddNewDirectAssignedLocation')}
                        </Button>
                        <Button onClick={() => setOpenModal(false)}>
                            {t('Cancel')}
                        </Button>
                    </Box>
                </Box>
            </CustomDialog>
        </>
    )
}

export default AddDirectAssignedLocation
