import { Box, Dialog, makeStyles, styled, Typography } from '@material-ui/core'
import { Button } from 'components'
import {
    useAccessRequestPolicyToFolder,
    useChangeAccessRequestPolicy,
} from 'hooks'
import { Icon } from 'packages/eid-icons'
import { Select } from 'packages/eid-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
    AddAccessModalMain: {
        '& .MuiDialog-paper': {
            maxWidth: '440px',
        },
    },
    NewAccessAssignmentModal: {
        '& h2': {
            fontSize: '24px',
            color: '#1b1f1f',
            fontWeight: 'normal',
        },
    },

    AccessRequestModalHeader: {
        padding: '32px 40px',
        '& svg': {
            top: '-14px !important',
        },
    },

    AccessRequestModalFooter: {
        display: 'flex',
        padding: '32px 40px',
        gap: '16px',

        '& button': {
            border: 'solid 1px #307fc1',
            padding: '8px 12px',
            height: '32px',
            fontSize: '14px',
            '&:nth-child(1)': {
                background: '#307fc1',
                color: '#fff',
            },
            '&:nth-child(2)': {
                color: '#307fc1',
                maxWidth: '90px',
            },
        },
    },

    newDirectAssigned: {
        padding: ' 0 40px 32px',
        borderBottom: '1px solid #d8d8d8 !important',
    },
    searchInput: {
        ' & input': {
            height: '40px',
            marginTop: '12px',
            fontSize: '16px',
            '&::placeholder': {
                color: '#b4b4b4',
            },
        },
    },
}))

const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '560px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-20px',
        right: '-20px',
        cursor: 'pointer',
    },
})

const ChangeAccessRequestPolicy = ({ sharedFolderId }) => {
    const { t } = useTranslation()
    const classes = useStyles({})
    const [selectedPolicy, setSelectecPolicy] = useState(null)
    const [requestPolicyList, setRequestPolicyList] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const { isLoading, data } = useAccessRequestPolicyToFolder(sharedFolderId)

    const [changeRequestPolicy, { isLoading: isChangeLoading }] =
        useChangeAccessRequestPolicy(sharedFolderId)

    useEffect(() => {
        if (data?.length) {
            const policyDropdownOptions = data.map((item) => ({
                id: item.id,
                value: item.friendlyName,
                isSelectableInUi: item.isSelectableInUi,
            }))
            setRequestPolicyList(
                policyDropdownOptions?.length
                    ? policyDropdownOptions.filter((x) => x.isSelectableInUi)
                    : [],
            )
        }
    }, [data])

    const handlePolicyChange = (e) => {
        setSelectecPolicy(e)
    }

    const getOptionLabel = (option) => option.value

    return (
        <Box>
            <Button
                onClick={() => setOpenModal(true)}
                style={{
                    borderRadius: '4px',
                    padding: '3px 13px',
                    backgroundColor: '#307fc1',
                    color: '#ffffff',
                    fontSize: '14px',
                    '&:hover': {
                        backgroundColor: '#307fc1',
                    },
                }}
            >
                {t('ChangePolicy')}
            </Button>
            <CustomDialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                title={t('')}
                className={classes.AddAccessModalMain}
            >
                <Box className={classes.NewAccessAssignmentModal}>
                    <Box className={classes.AccessRequestModalHeader}>
                        <CloseIconeBox onClick={() => setOpenModal(false)}>
                            <Icon
                                name="Close"
                                width="16px"
                                height="16px"
                                color="#959598"
                            />
                        </CloseIconeBox>
                        <Typography variant="h2">
                            {t('ChangePolicy')}
                        </Typography>
                    </Box>
                    <Box className={classes.newDirectAssigned}>
                        <Typography variant="subtitle1">
                            {t('SelectAccessRequestPolicy')}
                        </Typography>
                        <Select
                            options={requestPolicyList}
                            getOptionLabel={getOptionLabel}
                            onChange={(e) => handlePolicyChange(e)}
                            value={selectedPolicy}
                            isLoading={isLoading}
                        />
                    </Box>
                    <Box className={classes.AccessRequestModalFooter}>
                        <Button
                            disabled={isChangeLoading}
                            onClick={() =>
                                changeRequestPolicy({
                                    policyId: selectedPolicy.id,
                                })
                            }
                            backgroundColor="#307fc1"
                        >
                            {t('ChangePolicy')}
                        </Button>
                        <Button onClick={() => setOpenModal(false)}>
                            {t('Cancel')}
                        </Button>
                    </Box>
                </Box>
            </CustomDialog>
        </Box>
    )
}

export default ChangeAccessRequestPolicy
