import React from 'react'
import {
    MenuItem,
    makeStyles,
    Typography,
    ListItemText,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles({
    root: {
        minWidth: '160px',
        fontSize: '13px',
        position: 'relative',
        minHeight: '40px',
        height: '40px',
    },
    selected: {
        color: '#307fc1',
        backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        borderLeft: '2px solid #307fc1',
        '& img': {
            opacity: 1,
            filter: 'grayscale(0%) ',
        },
    },
    sortLabelText: {
        color: '#aab0b4',
        fontSize: '12px',
        marginRight: '8px',
    },
})

const SortingMenuItem = ({ displayName, selected, onClick, order }) => {
    const classes = useStyles()

    return (
        <MenuItem onClick={onClick} classes={classes} selected={selected}>
            <ListItemText>{displayName}</ListItemText>

            {selected && (
                <>
                    <Typography className={classes.sortLabelText}>
                        {order === 'asc' ? 'A-Z' : 'Z-A'}
                    </Typography>

                    <Icon
                        name="ArrowLong"
                        width="16px"
                        height="16px"
                        color="#307fc1"
                        direction={order === 'asc' ? 'down' : 'up'}
                    />
                </>
            )}
        </MenuItem>
    )
}

export default SortingMenuItem
