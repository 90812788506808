import { Fragment } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Paper } from '@material-ui/core'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useAccessRequestPolicy, useOwnersApproversDeputies } from 'hooks'
import { useTranslation } from 'react-i18next'
import { PeopleListCard } from 'components/PeopleListCard/PeopleListCard'
import { smallScreenWidth } from 'utils'
import PersonDetailsLazy from 'components/PersonDetailsLazy'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: 'auto',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        azureApplicationDetailsLabel: {
            color: '#1b1f1f',
            textDecoration: 'underline',
        },

        azureApplicationDetailsIdValues: {
            color: '#307fc1',
        },

        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                display: 'block',
            },
        },
        attribute: {
            marginTop: '-8px',
        },
        attributeLabel: {
            minWidth: '265px',
        },
        attributeValue: {
            wordBreak: 'unset',
        },
    }),
)

export const Overview = (props) => {
    const { role } = props

    const classes = useStyles()
    const { t } = useTranslation()

    const policy = useAccessRequestPolicy(role.requestPolicyId)
    const { data: ownersAndApprovers, isLoading: loading } =
        useOwnersApproversDeputies(role.id)

    const attributes = [
        {
            label: 'Role',
            value: 'friendlyName',
        },
        {
            label: 'RoleType',
            value: 'roleTypeFriendlyName',
        },
        {
            label: 'SystemType',
            value: 'resourceSystemTypeFriendlyName',
        },
        {
            label: 'Tenant',
            value: 'resourceSystemName',
        },
        {
            label: 'Description',
            value: 'description',
        },
        {
            label: 'PublishInIAMShop',
            value: 'requestable',
            render: (data) => (data && data.requestable ? t('Yes') : t('No')),
        },
        {
            label: 'FulfillmentGroup',
            value: 'fulfillmentGroupFriendlyName',
        },
    ]

    const roleDetail = (
        <Fragment>
            <Box className={classes.basicInfo}>
                {attributes &&
                    attributes.map((att, i) => {
                        return (
                            <Attribute
                                className={classes.attribute}
                                orientation="horizontal"
                                key={att.value + i}
                            >
                                <AttributeLabel
                                    className={classes.attributeLabel}
                                >
                                    {t(att.label)}
                                </AttributeLabel>
                                <AttributeValue
                                    className={classes.attributeValue}
                                >
                                    {att.render
                                        ? att.render(role)
                                        : role && role[att.value]}
                                </AttributeValue>
                            </Attribute>
                        )
                    })}
            </Box>
        </Fragment>
    )
    const policyDetails = (
        <Box>
            <Attribute className={classes.attribute} orientation="horizontal">
                <AttributeLabel className={classes.attributeLabel}>
                    {t('AccessRequestPolicy')}
                </AttributeLabel>
                <AttributeValue className={classes.attributeValue}>
                    {policy && policy.name}
                </AttributeValue>
            </Attribute>
            <Attribute className={classes.attribute} orientation="horizontal">
                <AttributeLabel className={classes.attributeLabel}>
                    {t('AllowsTimeConstrainedAccess')}
                </AttributeLabel>
                <AttributeValue className={classes.attributeValue}>
                    {policy && policy.isTimeConstrained ? t('Yes') : t('No')}
                </AttributeValue>
            </Attribute>
            <Attribute className={classes.attribute} orientation="horizontal">
                <AttributeLabel className={classes.attributeLabel}>
                    {t('DefaultAccessDuration')}
                </AttributeLabel>
                <AttributeValue className={classes.attributeValue}>
                    {policy && policy.defaultValueInMinutes}
                </AttributeValue>
            </Attribute>
            <Attribute className={classes.attribute} orientation="horizontal">
                <AttributeLabel className={classes.attributeLabel}>
                    {t('MaxAccessDuration')}
                </AttributeLabel>
                <AttributeValue className={classes.attributeValue}>
                    {policy && policy.maximumValueInMinutes}
                </AttributeValue>
            </Attribute>
        </Box>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                <Box className={classes.section}>
                    <Box>
                        {roleDetail}
                        {policyDetails}
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <Attribute>
                        <AttributeLabel>
                            {t('RoleDefinitions_Owners')}
                        </AttributeLabel>
                        <PeopleListCard
                            data={ownersAndApprovers?.owners}
                            isLoading={loading}
                            pageSize={3}
                        />
                    </Attribute>
                </Box>
                <Box className={classes.section}>
                    <Attribute>
                        <AttributeLabel>{t('ResponsibleParty')}</AttributeLabel>

                        <PersonDetailsLazy
                            personId={role.responsiblepartyId}
                            friendlyName={role.responsiblepartyFriendlyName}
                            imageThumbUrl={role.responsiblepartyImageThumbUrl}
                        />
                    </Attribute>
                </Box>
            </Box>
        </Fragment>
    )
    return (
        <>
            <Paper
                style={{
                    padding: '32px',
                    width: 'fit-content',
                    minWidth: '100%',
                }}
            >
                <Box className={classes.section}>{renderContent()}</Box>
            </Paper>
        </>
    )
}
