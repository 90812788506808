import { useTranslation } from 'react-i18next'
import { usePageContext } from 'pageContext'
import { isNilOrEmpty, useAuthState } from 'packages/core'
import Search from './Search'
import DateTimeFilter from './DateTimeFilter'
import PersonFilter from './PersonFilter'
import TargetSystemFilter from './TargetSystemFilter'
import { Card } from 'packages/eid-ui'
import AdvancedFilter from './EidFilter/AdvancedFilter'
import { Icon } from 'packages/eid-icons'
import { Box } from '@material-ui/core'
import ShowAzureAppsFilter from './ShowOnlyAzureAppsFilter'
import GlobalRightsFilter from './GlobalRightsFilter'
import ApplicationsFilter from './ApplicationsFilter'
import UNCPathFilter from './UNCPathFilter'
import LocationFilter from './LocationFilter'
import BusinessFunctionFilter from './BusinessFunctionsFilter'
import { useGroupTypes, useHighLevelClassifications } from 'hooks'
import IncludeBasicClaimSetFilter from './IncludeBasicClaimSet'
import MailboxTypeFilter from './Mailboxes/MailboxTypeFilter'
import useSubcomponents from 'useSubcomponents'

const TextSearchFilter = ({ outlined = false, placeholder = 'Search' }) => {
    const [state, dispatch] = usePageContext()

    return (
        <Search
            outlined={outlined}
            handleSearch={(searchKey) =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'searchTerm',
                        value: searchKey,
                    },
                })
            }
            handleClear={(searchKey) =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'searchTerm',
                        value: searchKey,
                    },
                })
            }
            value={state.searchTerm}
            placeholder={placeholder}
        />
    )
}

const StartDateFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <DateTimeFilter
            disableFuture
            showLabelWithin
            placeholder={t('BetweenDate')}
            value={
                isNilOrEmpty(state.startDate)
                    ? null
                    : state.startDate.clone().local()
            }
            onChange={(date) => {
                const dateToSet = date.clone()
                dateToSet.startOf('day')
                dateToSet.utc()

                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'startDate',
                        value: dateToSet,
                    },
                })
            }}
            handleClear={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'startDate',
                        value: null,
                    },
                })
            }
        />
    )
}

const EndDateFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()

    return (
        <DateTimeFilter
            disableFuture
            showLabelWithin
            placeholder={t('AndDate')}
            value={
                isNilOrEmpty(state.endDate)
                    ? null
                    : state.endDate.clone().local()
            }
            onChange={(date) => {
                const dateToSet = date.clone().endOf('day')
                dateToSet.utc()

                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'endDate',
                        value: dateToSet,
                    },
                })
            }}
            handleClear={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'endDate',
                        value: null,
                    },
                })
            }
        />
    )
}

const dateFilterStyles = {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '15px',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #ebebed',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    color: '#7D7C7C',
}

const DateFilter = ({ label }) => {
    return (
        <Box style={dateFilterStyles}>
            <Box minWidth="120px">{label}</Box>
            <StartDateFilter />
            <EndDateFilter />
        </Box>
    )
}

const OwnedByFilter = () => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const [{ currentUserId, friendlyName }] = useAuthState()

    return (
        <Card
            cardTitle={t('OwnedBy')}
            collapsible
            expanded={state.ownedBy !== null}
        >
            <PersonFilter
                value={state.ownedBy}
                onClear={() =>
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'ownedBy',
                            value: {
                                id: currentUserId,
                                friendlyName: friendlyName,
                            },
                        },
                    })
                }
                onChange={(payload) => {
                    dispatch({
                        type: 'SET_PROP',
                        payload: {
                            key: 'ownedBy',
                            value: payload,
                        },
                    })
                }}
            />
        </Card>
    )
}

const ManagementRolesLocationFilter = () => {
    // NOTE: Added version for management role location tree only to test the new component
    return (
        <LocationFilter
            version="v2"
            url="/api/Lookups/managementRoles/Locations"
            queryKey={['MANAGEMENT ROLES', 'LOCATIONS']}
        />
    )
}

const GroupsLocationFilter = () => {
    // NOTE: Added version for group location tree only to test the new component
    return (
        <LocationFilter
            version="v2"
            url="/api/Lookups/applicationRoles/Locations"
            queryKey={['GROUPS', 'LOCATIONS']}
        />
    )
}

const PeopleLocationFilter = () => {
    return (
        <LocationFilter
            url="/api/Lookups/people/Locations"
            queryKey={['PEOPLES', 'LOCATIONS']}
        />
    )
}

const ApplicationsAdvancedSearchFilters = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()
    const { trimControlsBasedOnAccess } = useSubcomponents()

    let applicationsAdvancedSearchforms = {
        title: 'Common_Forms',
        colorCode: {
            primary: '#2920ad',
            secondary: '#ebeaf7',
        },
        filters: [
            {
                name: 'friendlyName',
                type: 'text',
                label: 'Common_Name',
                placeholder: 'Common_Name',
                contextProp: 'advancedSearch.forms.friendlyName',
                requireAccess:
                    'ResourceAdmin-ApplicationFilters-AdvanceSearch-FriendlyName',
            },
            {
                name: 'description',
                type: 'text',
                label: 'Common_Description',
                placeholder: 'Common_Description',
                contextProp: 'advancedSearch.forms.description',
                requireAccess:
                    'ResourceAdmin-ApplicationFilters-AdvanceSearch-Description',
            },
        ],
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearchTags',
        },
    }

    applicationsAdvancedSearchforms = {
        ...applicationsAdvancedSearchforms,
        filters: trimControlsBasedOnAccess(
            applicationsAdvancedSearchforms.filters,
        ),
    }

    return (
        <Card
            cardTitle={t('Common_AdvancedSearch')}
            collapsible
            icon={<Icon name="AdvancedSearch" color="#959598" />}
            expanded={
                state['advancedSearch.forms.friendlyName'] ||
                state['advancedSearch.forms.description'] ||
                state['advancedSearchTags']
            }
        >
            <Box padding="20px 20px 0px 20px ">
                <AdvancedFilter
                    forms={applicationsAdvancedSearchforms}
                    tags={applicationsAdvancedSearchforms.tags}
                />
            </Box>
        </Card>
    )
}

const ClaimMappingPoliciesAdvancedSearchFilters = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()

    const advancedSearchforms = {
        title: 'Common_Forms',
        colorCode: {
            primary: '#2920ad',
            secondary: '#ebeaf7',
        },
        filters: [
            {
                name: 'friendlyName',
                type: 'text',
                label: 'FriendlyName',
                placeholder: 'FriendlyName',
                contextProp: 'advancedSearch.forms.friendlyName',
            },
            {
                name: 'name',
                type: 'text',
                label: 'TechnicalName',
                placeholder: 'TechnicalName',
                contextProp: 'advancedSearch.forms.technicalName',
            },
        ],
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearchTags',
        },
    }

    return (
        <Card
            cardTitle={t('Common_AdvancedSearch')}
            collapsible
            icon={<Icon name="AdvancedSearch" color="#959598" />}
            expanded={
                state['advancedSearch.forms.friendlyName'] ||
                state['advancedSearch.forms.technicalName'] ||
                state['advancedSearch.forms.description'] ||
                state['advancedSearchTags']
            }
        >
            <Box padding="20px 20px 0px 20px ">
                <AdvancedFilter
                    forms={advancedSearchforms}
                    tags={advancedSearchforms.tags}
                />
            </Box>
        </Card>
    )
}

const GroupsAdvancedSearchFilters = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()
    const { data: groupTypes } = useGroupTypes()
    const { data: highLevelClassifications } = useHighLevelClassifications()
    const groupsAdvancedSearchforms = {
        title: 'Common_Forms',
        colorCode: {
            primary: '#2920ad',
            secondary: '#ebeaf7',
        },
        filters: [
            {
                name: 'friendlyName',
                type: 'text',
                label: 'Common_Name',
                placeholder: 'Common_Name',
                contextProp: 'advancedSearch.forms.friendlyName',
            },
            {
                name: 'name',
                type: 'text',
                label: 'TechnicalName',
                placeholder: 'TechnicalName',
                contextProp: 'advancedSearch.forms.name',
            },
            {
                name: 'highLevelClassification',
                type: 'autocomplete',
                label: 'ByHighLevelClassification',
                placeholder: 'HighLevelClassification',
                contextProp: 'highLevelClassification',
                defaultOptions: highLevelClassifications
                    ? highLevelClassifications
                    : [],
                optionConfig: { labelProp: 'friendlyName', type: 'label' },
                supportsServerSideSearch: false,
            },

            {
                name: 'groupType',
                type: 'autocomplete',
                label: 'ByGroupType',
                placeholder: 'GroupType',
                contextProp: 'groupType',
                defaultOptions: groupTypes ? groupTypes : [],
                optionConfig: { labelProp: 'friendlyName', type: 'label' },
                supportsServerSideSearch: false,
            },
            {
                name: 'description',
                type: 'text',
                label: 'Common_Description',
                placeholder: 'Common_Description',
                contextProp: 'advancedSearch.forms.description',
            },
        ],
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearchTags',
        },
    }

    return (
        <Card
            cardTitle={t('Common_AdvancedSearch')}
            collapsible
            icon={<Icon name="AdvancedSearch" color="#959598" />}
            expanded={
                state['advancedSearch.forms.friendlyName'] ||
                state['advancedSearch.forms.description'] ||
                state['advancedSearch.forms.name'] ||
                state['groupType'] ||
                state['highLevelClassification'] ||
                state['advancedSearchTags']
            }
        >
            <Box padding="20px 20px 0px 20px ">
                <AdvancedFilter
                    forms={groupsAdvancedSearchforms}
                    tags={groupsAdvancedSearchforms.tags}
                />
            </Box>
        </Card>
    )
}

const SharedFoldersAdvancedSearchFilters = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()
    const groupsAdvancedSearchforms = {
        title: 'Common_Forms',
        colorCode: {
            primary: '#2920ad',
            secondary: '#ebeaf7',
        },
        filters: [
            {
                name: 'friendlyName',
                type: 'text',
                label: 'Common_Name',
                placeholder: 'Common_Name',
                contextProp: 'advancedSearch.forms.friendlyName',
            },
            {
                name: 'computer',
                type: 'text',
                label: 'Computer',
                placeholder: 'Computer',
                contextProp: 'advancedSearch.forms.computer',
            },
            {
                name: 'dnsHostName',
                type: 'text',
                label: 'DNSHostName',
                placeholder: 'DNSHostName',
                contextProp: 'advancedSearch.forms.dnsHostName',
            },
            {
                name: 'description',
                type: 'text',
                label: 'Common_Description',
                placeholder: 'Common_Description',
                contextProp: 'advancedSearch.forms.description',
            },
        ],
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearchTags',
        },
    }

    return (
        <Card
            cardTitle={t('Common_AdvancedSearch')}
            collapsible
            icon={<Icon name="AdvancedSearch" color="#959598" />}
            expanded={
                state['advancedSearch.forms.friendlyName'] ||
                state['advancedSearch.forms.description'] ||
                state['advancedSearch.forms.computer'] ||
                state['advancedSearch.forms.dnsHostName']
            }
        >
            <Box padding="20px 20px 0px 20px ">
                <AdvancedFilter
                    forms={groupsAdvancedSearchforms}
                    tags={groupsAdvancedSearchforms.tags}
                />
            </Box>
        </Card>
    )
}

const MailboxesAdvancedSearchFilters = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()
    const groupsAdvancedSearchforms = {
        title: 'Common_Forms',
        colorCode: {
            primary: '#2920ad',
            secondary: '#ebeaf7',
        },
        filters: [
            {
                name: 'friendlyName',
                type: 'text',
                label: 'FriendlyName',
                placeholder: 'FriendlyName',
                contextProp: 'advancedSearch.forms.friendlyName',
            },
            {
                name: 'logonName',
                type: 'text',
                label: 'Logon',
                placeholder: 'Logon',
                contextProp: 'advancedSearch.forms.logonName',
            },
            {
                name: 'primaryEmailAddress',
                type: 'text',
                label: 'Email',
                placeholder: 'Email',
                contextProp: 'advancedSearch.forms.primaryEmailAddress',
            },
            {
                name: 'alias',
                type: 'text',
                label: 'Alias',
                placeholder: 'Alias',
                contextProp: 'advancedSearch.forms.alias',
            },
        ],
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearchTags',
        },
    }

    return (
        <Card
            cardTitle={t('Common_AdvancedSearch')}
            collapsible
            icon={<Icon name="AdvancedSearch" color="#959598" />}
            expanded={
                state['advancedSearch.forms.friendlyName'] ||
                state['advancedSearch.forms.primaryEmailAddress'] ||
                state['advancedSearch.forms.logonName'] ||
                state['advancedSearch.forms.alias']
            }
        >
            <Box padding="20px 20px 0px 20px ">
                <AdvancedFilter
                    forms={groupsAdvancedSearchforms}
                    tags={groupsAdvancedSearchforms.tags}
                />
            </Box>
        </Card>
    )
}

const PeopleAdvancedSearchFilters = () => {
    const { t } = useTranslation()
    const [state] = usePageContext()
    const enabledOptions = [
        {
            friendlyName: 'No',
            value: 'false',
        },
        {
            friendlyName: 'Yes',
            value: 'true',
        },
    ]
    const peopleAdvancedSearchforms = {
        title: 'Common_Forms',
        colorCode: {
            primary: '#2920ad',
            secondary: '#ebeaf7',
        },
        filters: [
            {
                name: 'enabled',
                type: 'autocomplete',
                label: 'Enabled',
                placeholder: 'Enabled',
                contextProp: 'advancedSearch.forms.enabled',
                defaultOptions: enabledOptions ? enabledOptions : [],
                optionConfig: { labelProp: 'friendlyName', type: 'label' },
                supportsServerSideSearch: false,
            },
            {
                name: 'friendlyName',
                type: 'text',
                label: 'Name',
                placeholder: 'Name',
                contextProp: 'advancedSearch.forms.friendlyName',
            },
            {
                name: 'login',
                type: 'text',
                label: 'Login',
                placeholder: 'Login',
                contextProp: 'advancedSearch.forms.login',
            },
            {
                name: 'email',
                type: 'text',
                label: 'Email',
                placeholder: 'Email',
                contextProp: 'advancedSearch.forms.email',
            },
        ],
    }

    return (
        <Card
            cardTitle={t('Common_AdvancedSearch')}
            collapsible
            icon={<Icon name="AdvancedSearch" color="#959598" />}
            expanded={
                state['advancedSearch.forms.friendlyName'] ||
                state['advancedSearch.forms.login'] ||
                state['advancedSearch.forms.email'] ||
                state['advancedSearch.forms.enabled']
            }
        >
            <Box padding="20px 20px 0px 20px ">
                <AdvancedFilter forms={peopleAdvancedSearchforms} />
            </Box>
        </Card>
    )
}

const Filters = () => <></>

Filters.TextSearch = TextSearchFilter
Filters.StartDate = StartDateFilter
Filters.EndDate = EndDateFilter
Filters.DateFilter = DateFilter
Filters.TargetSystemFilter = TargetSystemFilter
Filters.OwnedByFilter = OwnedByFilter
Filters.ApplicationsAdvancedSearchFilters = ApplicationsAdvancedSearchFilters
Filters.ClaimMappingPoliciesAdvancedSearchFilters =
    ClaimMappingPoliciesAdvancedSearchFilters
Filters.ShowAzureAppsFilter = ShowAzureAppsFilter
Filters.GlobalRightsFilter = GlobalRightsFilter
Filters.ApplicationsFilter = ApplicationsFilter
Filters.ManagementRolesLocationFilter = ManagementRolesLocationFilter
Filters.GroupsLocationFilter = GroupsLocationFilter
Filters.PeopleLocationFilter = PeopleLocationFilter
Filters.BusinessFunctionFilter = BusinessFunctionFilter
Filters.GroupsAdvancedSearchFilters = GroupsAdvancedSearchFilters
Filters.SharedFoldersAdvancedSearchFilters = SharedFoldersAdvancedSearchFilters
Filters.IncludeBasicClaimSet = IncludeBasicClaimSetFilter
Filters.MailboxesAdvancedSearchFilters = MailboxesAdvancedSearchFilters
Filters.MailboxTypeFilter = MailboxTypeFilter

const filtersMap = {
    OwnedByFilter,
    showOnlyAzureApplications: ShowAzureAppsFilter,
    targetSystemFilter: TargetSystemFilter,
    applicationsAdvancedSearch: ApplicationsAdvancedSearchFilters,
    claimMappingPoliciessAdvancedSearch:
        ClaimMappingPoliciesAdvancedSearchFilters,
    globalRightsFilter: GlobalRightsFilter,
    applicationsFilter: ApplicationsFilter,
    managementRolesLocationFilter: ManagementRolesLocationFilter,
    groupsLocationFilter: GroupsLocationFilter,
    peopleLocationFilter: PeopleLocationFilter,
    businessFunctionFilter: BusinessFunctionFilter,
    groupsAdvancedSearch: GroupsAdvancedSearchFilters,
    peopleAdvancedSearch: PeopleAdvancedSearchFilters,
    uncPathFilter: UNCPathFilter,
    sharedFoldersAdvancedSearch: SharedFoldersAdvancedSearchFilters,
    includeBasicClaimSet: IncludeBasicClaimSetFilter,
    mailboxAdvancedSearch: MailboxesAdvancedSearchFilters,
    mailboxType: MailboxTypeFilter,
}

Filters.filtersMap = filtersMap

export default Filters
