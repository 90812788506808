import AppRightsListing from 'components/SharedListings/AppRightsListing'

const AppRights = ({ roleId, applicationResourceId }) => {
    return (
        <AppRightsListing
            roleId={roleId}
            endpoint={'api/LocalRights/byManagementRole'}
            applicationResourceId={applicationResourceId}
        />
    )
}

export default AppRights
