import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreenFalse } from 'packages/core'
import { ItemDetails } from 'components'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
    fontSize: '14px',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ scope }) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreenFalse()
        ? 'vertical'
        : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('ScopeName')}
                    value={scope.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('WhoCanConsent')}
                    value={scope.consentType}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AdminConsentDisplayName')}
                    value={scope.adminConsentDisplayName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AdminConsentDescription')}
                    value={scope.adminConsentDescription}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('UserConsentDisplayName')}
                    value={scope.userConsentDisplayName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('UserConsentDescription')}
                    value={scope.userConsentDescription}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
