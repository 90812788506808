import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@material-ui/core'
import { useAccessAssignmentsToFolder } from 'hooks'
import Search from 'components/Filters/Search'
import { PageContextProvider } from 'pageContext'
import AddAcessAssignentsFolder from './AddAcessAssignentsFolder'
import { GridContainer } from 'components'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useIsSmallScreenFalse } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import AddEditAccessAssignmentToFolder from 'components/DynamicComponents/AddEditAccessAssignmentToFolder'
import { PersonDetails } from 'components/PersonDetails/PersonDetails'
import { useAppState } from 'appContext'
import DeleteAccessAssignment from './DeleteAccessAssignmentToFolder'

const StyledDelete = styled(Box)({
    display: 'flex',
    justifyContent: 'end',
    '& button': {
        borderRadius: '4px',
    },
})

const StyledPersonDetails = styled(Box)({
    '& div:nth-child(1)': {
        margin: '0px',
        gap: '10px',
    },
})

export const AcessAssignmentsFolder = ({ sharedFolder }) => {
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState('')
    const isSmallScreen = useIsSmallScreenFalse()
    const { getAccessBasedAttributes } = useSubcomponents()
    const [{ devFeaturesEnabled }] = useAppState()

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }

    const {
        list,
        loading: isLoading,
        isLoadingMore,
        pagination,
    } = useAccessAssignmentsToFolder(sharedFolder?.id, 'SharedFolder')

    const attributes = [
        {
            label: 'Assignee',
            name: 'assigneeDetails',
            sortable: true,
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-AssigneeDetails',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <StyledPersonDetails>
                            <PersonDetails
                                person={{
                                    friendlyName: data?.assigneeFriendlyName,
                                }}
                            />
                        </StyledPersonDetails>
                    )
                },
            },
        },
        {
            label: 'AccessLevel',
            name: 'resourceTypeFriendlyName',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-AccessLevel',
            },
        },
        {
            label: t('TimeConstraint'),
            name: 'timeconstarint',
            sortable: true,
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-TimeConstraint',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return <AddEditAccessAssignmentToFolder item={data} />
                },
            },
        },
        {
            name: 'requestId',
            label: 'RequestId',
            sortable: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-RequestId',
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-SharedFolder-AccessAssignmentsForFolder-ActionButton',
            },
            component: {
                componentName: 'renderer',
                render: (item) => {
                    return (
                        <StyledDelete>
                            <DeleteAccessAssignment
                                item={item}
                                shareFolderId={sharedFolder?.id}
                            />
                        </StyledDelete>
                    )
                },
            },
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                <PageContextProvider key="AddAccessAssignmentsFodler">
                    {devFeaturesEnabled && (
                        <AddAcessAssignentsFolder sharedFolder={sharedFolder} />
                    )}
                </PageContextProvider>

                <Box width="308px" display="flex" alignItems="center">
                    <Search
                        handleSearch={handleSearchChange}
                        placeholder={t('SearchFilterAssignments')}
                        value={searchKey}
                        handleClear={handleClear}
                        outlined
                    />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </>
    )
}
