import { Box, Dialog, makeStyles, styled, Typography } from '@material-ui/core'
import { WithCloseButton } from 'packages/eid-ui'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const MessageModal = styled(Dialog)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const statusColors = {
    Success: 'green',
    Error: 'red',
}
const useStyles = makeStyles(() => ({
    updateModal: {
        '& .MuiDialog-paper': {
            padding: '1.9rem 3.2rem',
            maxWidth: '86rem !important',
        },
    },
    headerModal: {
        borderBottom: '1px solid #d8d8d8 ',
        paddingBottom: '1.6rem',
        marginBottom: '1rem',
        '& p': {
            fontSize: '1.8rem',
            fontWeight: 'bold',
        },
        '& svg': {
            height: '1.3rem',
        },
    },
}))

const StatusInfoMessage = ({
    messages,
    handleClose,
    title = 'ProfileUpdateStatus',
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    return (
        <Fragment>
            <MessageModal
                open={messages?.length}
                onClose={handleClose}
                className={classes.updateModal}
            >
                <Box className={classes.headerModal}>
                    <WithCloseButton
                        iconPosition={{
                            right: '-2.8rem',
                            top: '0.4rem',
                        }}
                        iconColor="rgb(139, 144, 154)"
                        onClose={handleClose}
                    />
                    <Box>
                        <Typography>{t(title)}</Typography>
                    </Box>
                </Box>
                <Box paddingBottom="1.6rem">
                    {messages.map((item, index) => (
                        <Box
                            key={`info-message-${index}`}
                            display="flex"
                            width="100%"
                            marginTop="1.3rem"
                        >
                            <Box display="flex">
                                <Icon
                                    name={
                                        item.isSucess ? 'FilledCheck' : 'Info'
                                    }
                                    color={
                                        statusColors[
                                            item.isSucess ? 'Success' : 'Error'
                                        ]
                                    }
                                />
                            </Box>
                            <Box display="flex" marginX="1.6rem">
                                <Typography
                                    style={{
                                        color: '#3b454d',
                                    }}
                                >
                                    {item?.wentForApproval && (
                                        <strong>
                                            {`${t('WentForApproval')} - `}
                                        </strong>
                                    )}
                                    {item?.message}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </MessageModal>
        </Fragment>
    )
}

export default StatusInfoMessage
