import React, { Fragment, useState } from 'react'

import { Box, makeStyles, styled, Typography } from '@material-ui/core'
import FormTextFieldElement from '../TextFields'
import { ArrowIcon } from 'packages/eid-icons'
import CountryOptionWithFlag from './CountryOptionWithFlag'
import { US } from 'country-flag-icons/react/1x1'

const CountryFlag = styled(Box)({
    '& svg': {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
    },
})
const useStyles = makeStyles({
    customPhoneNumber: {
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    flagSection: {
        position: 'absolute',
        top: '29px',
        left: '1px',
        display: 'flex',
        borderRight: '1px solid #d4d4d8',
        zIndex: '2',
        padding: '5px 8px',
        height: '36px',
        background: '#fff',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        width: '100px',
        // border:'solid 1px #d4d4d8',

        '& .MuiBox-root': {
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
        },
    },
    phoneNumberSection: {
        width: '100%',
        // NOTE: Needed when we enable the country selection dropdown
        // '& input': {
        //     paddingLeft: '110px',
        // },
    },
    selectPhoneNumber: {
        width: '100%',
    },
    phoneNumberLabel: {
        fontSize: '12px',
        color: 'rgb(180, 180, 180)',
        textTransform: 'uppercase',
    },
    dropdownIcon: {
        '& svg': {
            width: '18px',
            height: '18px',
        },
    },
    flagSected: {
        '& .MuiBox-root': {
            width: '18px',
        },
        '& p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '40px !important',
        },
    },
})

const FormPhoneNumberWithCountryCodeElement = ({
    item,
    handleChange,
    value,
    errorMessage,
}) => {
    const classes = useStyles()
    // const [isOpen, setIsOpen] = useState(false)
    // const [selectedOption, setSelectedOption] = useState({
    //     label: (
    //         <Fragment>
    //             <CountryFlag>
    //                 <US />
    //             </CountryFlag>
    //             <Typography>+1</Typography>
    //         </Fragment>
    //     ),
    //     value: '+1',
    // })

    // const toggleDropdown = () => {
    //     setIsOpen(!isOpen)
    // }

    // const handleOptionClick = (option) => {
    //     setSelectedOption(option)
    //     setIsOpen(false)
    // }

    return (
        <Fragment>
            <Box className={classes.selectPhoneNumber}>
                <Box className={classes.customPhoneNumber}>
                    {/* <Box
                        onClick={toggleDropdown}
                        className={classes.flagSection}
                    >
                        <Box className={classes.flagSected}>
                            {selectedOption.label}
                            <Box className={classes.dropdownIcon}>
                                <ArrowIcon direction={isOpen ? 'up' : 'down'} />
                            </Box>
                        </Box>
                        {isOpen && (
                            <CountryOptionWithFlag
                                handleCountrySelect={handleOptionClick}
                            />
                        )}
                    </Box> */}
                    <Box className={classes.phoneNumberSection}>
                        <FormTextFieldElement
                            handleChange={handleChange}
                            value={value}
                            item={item}
                            errorMessage={errorMessage}
                        />
                    </Box>
                </Box>
            </Box>
        </Fragment>
    )
}

export default FormPhoneNumberWithCountryCodeElement
