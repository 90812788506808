import * as React from 'react'
import { Box, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { NativeDateTimePicker } from 'packages/eid-ui/DateTimePickers/'
import { isNilOrEmpty } from 'packages/core'

export interface TimeDurationControlProps {
    selectedStartDate: any
    setSelectedStartDate: any
    minStartDate: any
    selectedDuration: any
    setSelectedDuration: any
    maximumDuration: number
    hasInvalidDates?: boolean
}

export const TimeDurationControl: React.FC<TimeDurationControlProps> = ({
    selectedStartDate,
    setSelectedStartDate,
    minStartDate,
    selectedDuration,
    setSelectedDuration,
    maximumDuration,
}) => {
    const { t } = useTranslation()

    return (
        <Box display="flex">
            <Box flex={1.2}>
                <NativeDateTimePicker
                    minDate={
                        minStartDate
                            ? moment
                                  .utc(minStartDate)
                                  .clone()
                                  .local()
                                  .format('YYYY-MM-DDTHH:mm')
                            : moment(new Date()).format('YYYY-MM-DDTHH:mm')
                    }
                    value={moment
                        .utc(selectedStartDate)
                        .clone()
                        .local()
                        .format('YYYY-MM-DDTHH:mm')}
                    handleChange={(e: any) => {
                        if (isNilOrEmpty(e)) {
                            setSelectedStartDate(null)
                        } else {
                            const date = moment(e)
                            const dateToSet = date.clone().utc().format()
                            setSelectedStartDate(dateToSet)
                        }
                    }}
                    pickerStyles={{
                        top: '22px',
                        right: '10px',
                        width: 'auto',
                    }}
                >
                    <TextField
                        type="text"
                        value={
                            selectedStartDate
                                ? moment
                                      .utc(selectedStartDate)
                                      .local()
                                      .format('L LT')
                                : ''
                        }
                        label={t('StartDateTime')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </NativeDateTimePicker>
            </Box>
            <Box flex="1" className="textfield">
                <TextField
                    style={{ marginTop: '8px', minWidth: '120px' }}
                    type="number"
                    value={selectedDuration}
                    label={t('Duration(Minutes)')}
                    InputProps={{
                        inputProps: { min: 0, max: maximumDuration },
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(event) => {
                        setSelectedDuration(event.target.value)
                    }}
                />
            </Box>
        </Box>
    )
}
