import { Box, makeStyles, createStyles, Typography } from "@material-ui/core"
import { Avatar } from "packages/eid-ui"
import { getFullImageUrl } from "utils"
const useStyles = makeStyles(() =>
    createStyles({
        avatarDetail:{
            display:"flex",
            alignItems:"center",
            gap:"12px",
            "& p":{
                whiteSpace:"nowrap",
                fontSize:"13px",
                fontWeight:"bold",
                color:"#282828",
            }
        },
        avatarContainer: {
            '& >div': {
                height: '30px',
                width: '30px',
            },
        },
    }),
)
const AssigneePerson = ({ data }) => {
    const classes = useStyles()
    return (
        <Box className={classes.avatarDetail}>
            {/* NOTE: Enable when we have key from backend */}
            {/* <Box className={classes.avatarContainer}>
                <Avatar
                    size={'small'}
                    src={getFullImageUrl(data.imageThumbUrl)}
                />
            </Box> */}
            <Typography>{data.assignee}</Typography>
        </Box>
    )
}


export default AssigneePerson