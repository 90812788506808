import { Box, styled } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreenFalse } from 'packages/core'
import { ItemDetails } from 'components'
import { Attribute, AttributeLabel } from 'packages/eid-ui'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'
import { Fragment, useEffect } from 'react'
import {
    useAccessRequestPolicy,
    useApplicationAppRightsDetails,
    useApplicationAppRightsOwners,
} from 'hooks'
import { AzureApplicationDeputies } from '../AzureApplicationDeputies'
import { Skeleton } from '@material-ui/lab'
import { OwnersDeputiesList } from '../OwnersDeputiesList/OwnersDeputiesList'
import { ResponsibleParty } from '../ResponsibleParties'

const useStyles = makeStyles((theme) =>
    createStyles({
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            width: '50%',
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                display: 'none',
            },
        },
        content: {
            width: '100%',
            display: 'flex',
            padding: '32px',
            gap: '30px',
            boxShadow: ' 0 2px 16px 0 rgba(0, 0, 0, 0.11)',
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
        rightSec: {
            width: '50%',
            display: 'flex',
            justifyContent: 'space-Between',
            gap: '0px',
        },
    }),
)

const valueStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
    fontSize: '14px',
    width: '50% !important',
}

const lableStyleProps = {
    width: '50% !impotant',
}

const PaddedDiv = styled(Box)({
    width: '100%',
})
const ItemDetailView = styled(Box)({
    '& div :nth-child(1)': {
        width: '50%',
    },
})

export const OverviewSection = ({ role }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreenFalse()
        ? 'vertical'
        : 'horizontal'
    const { data, isLoading } = useApplicationAppRightsDetails(role.id)
    const policy = useAccessRequestPolicy(role.accessRequestPolicyId)
    const { data: appRightOwner, isLoading: ownerLoading } =
        useApplicationAppRightsOwners(role.id)

    return (
        <Fragment>
            <Box className={classes.content}>
                <Box className={classes.section}>
                    <PaddedDiv>
                        {isLoading ? (
                            <Fragment>
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <ItemDetailView>
                                    <ItemDetails.Attribute
                                        label={t('AllowedMemberTypes')}
                                        value={
                                            data?.rightAllowedAssigeeTypeFriendlyName ||
                                            '-'
                                        }
                                        orientation={attributeOrientation}
                                        valueProps={valueStyleProps}
                                        labelProps={lableStyleProps}
                                    />
                                </ItemDetailView>
                                <ItemDetailView>
                                    <ItemDetails.Attribute
                                        label={t('Value')}
                                        value={data?.name || '-'}
                                        orientation={attributeOrientation}
                                        valueProps={valueStyleProps}
                                        labelProps={lableStyleProps}
                                    />
                                </ItemDetailView>
                                <ItemDetailView>
                                    <ItemDetails.Attribute
                                        label={t('Description')}
                                        value={data?.description || '-'}
                                        orientation={attributeOrientation}
                                        valueProps={valueStyleProps}
                                        labelProps={lableStyleProps}
                                    />
                                </ItemDetailView>
                                <ItemDetailView>
                                    <ItemDetails.Attribute
                                        label={t('PublishInIAMShop')}
                                        value={
                                            data?.publishedInIIShop
                                                ? 'Yes'
                                                : 'No'
                                        }
                                        orientation={attributeOrientation}
                                        valueProps={valueStyleProps}
                                        labelProps={lableStyleProps}
                                    />
                                </ItemDetailView>
                                <ItemDetailView>
                                    <ItemDetails.Attribute
                                        label={t('FullfilmentGroup')}
                                        value={
                                            data?.fulfillmentGroupFriendlyName
                                        }
                                        orientation={attributeOrientation}
                                        valueProps={valueStyleProps}
                                        labelProps={lableStyleProps}
                                    />
                                </ItemDetailView>

                                <ItemDetailView>
                                    <ItemDetails.Attribute
                                        label={t('AccessRequestPolicy')}
                                        value={policy?.friendlyName || '-'}
                                        orientation={attributeOrientation}
                                        valueProps={valueStyleProps}
                                        labelProps={lableStyleProps}
                                    />
                                </ItemDetailView>
                                <ItemDetailView>
                                    <ItemDetails.Attribute
                                        label={t('AllowsTimeConstrainedAccess')}
                                        value={
                                            policy?.isTimeConstrained
                                                ? t('Yes')
                                                : t('No')
                                        }
                                        orientation={attributeOrientation}
                                        valueProps={valueStyleProps}
                                        labelProps={lableStyleProps}
                                    />
                                </ItemDetailView>
                                <ItemDetailView>
                                    <ItemDetails.Attribute
                                        label={t('DefaultAccessDuration')}
                                        value={
                                            policy?.defaultValueInMinutes || '-'
                                        }
                                        orientation={attributeOrientation}
                                        valueProps={valueStyleProps}
                                        labelProps={lableStyleProps}
                                    />
                                </ItemDetailView>
                                <ItemDetailView>
                                    <ItemDetails.Attribute
                                        label={t('MaxAccessDuration')}
                                        value={
                                            policy?.maximumValueInMinutes || '-'
                                        }
                                        orientation={attributeOrientation}
                                        valueProps={valueStyleProps}
                                        labelProps={lableStyleProps}
                                    />
                                </ItemDetailView>
                            </Fragment>
                        )}
                    </PaddedDiv>
                </Box>
                <Box className={classes.rightSec}>
                    <Box className={classes.section}>
                        <Attribute>
                            <AttributeLabel>
                                {t('AppRights_Owners')}
                            </AttributeLabel>
                            {ownerLoading ? (
                                <Fragment>
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                </Fragment>
                            ) : appRightOwner?.length ? (
                                <OwnersDeputiesList
                                    pageSize={3}
                                    data={appRightOwner}
                                />
                            ) : (
                                '-'
                            )}
                        </Attribute>
                    </Box>
                    <Box className={classes.section}>
                        <Attribute>
                            <AttributeLabel>
                                {t('ResponsibleParty')}
                            </AttributeLabel>
                            <ResponsibleParty
                                responsiblePersonId={data?.responsiblePartyId}
                            />
                        </Attribute>
                    </Box>
                </Box>
            </Box>
        </Fragment>
    )
}
