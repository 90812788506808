import { Box, styled } from '@material-ui/core'
import { Fragment } from 'react'
import FormCheckboxElement from './FormElements/CheckBox'
import FormDropdownElement from './FormElements/Dropdown'
import FormCheckboxListElement from './FormElements/ListCheckbox'
import FormMultiSelectDropdownElement from './FormElements/MultiSelectDropdown'
import FormPhoneNumberWithCountryCodeElement from './FormElements/CustomPhoneNumber/PhoneNumberWithCountryCode'
import FormTextFieldElement from './FormElements/TextFields'
import FormTextFieldWithIconElement from './FormElements/TextFieldWithIcon'
import FormTreeElement from './FormElements/Tree'

const FormRow = styled(Box)({
    display: 'flex',
    gap: '20px',
    width: '100%',
    marginTop: '10px',
})

const FormColumn = styled(Box)({
    display: 'flex',
    gap: '20px',
    width: '100%',
})

const FormSubColumn = styled(Box)({
    display: 'flex',
    gap: '20px',
})

const FormRowFields = ({
    columns,
    formData,
    updateFormDataOnChange,
    personDetails,
}) => {
    const bindValue = (fieldName) => {
        const fieldValue = formData.find((x) => x.name === fieldName)
        return fieldValue?.value || ''
    }

    const bindErrorMessage = (fieldName) => {
        const field = formData.find((x) => x.name === fieldName)
        return field?.errorMessage || ''
    }

    const handleChange = (evt) => {
        if (evt && evt.target) {
            const changeData = {
                name: evt.target.name,
                value: evt.target.value,
            }
            updateFormDataOnChange(changeData)
        }
    }

    const handleDropDownChange = (evt, fieldName) => {
        const changeData = {
            name: fieldName,
            value: evt.id,
        }
        updateFormDataOnChange(changeData)
    }

    const handleTreeSelect = (id, fieldName) => {
        const changeData = {
            name: fieldName,
            value: id,
        }
        updateFormDataOnChange(changeData)
    }

    const bindFormElement = (item) => {
        switch (item?.fieldType) {
            case 'text':
                return (
                    <FormTextFieldElement
                        value={bindValue(item?.fieldName)}
                        item={item}
                        handleChange={handleChange}
                        errorMessage={bindErrorMessage(item?.fieldName)}
                    />
                )
            case 'multiSelectedDropdown':
                return (
                    <FormMultiSelectDropdownElement
                        bindValue={bindValue}
                        handleDDChange={handleChange}
                        item={item}
                        errorMessage={bindErrorMessage(item?.fieldName)}
                    />
                )
            case 'tree':
                return (
                    <FormTreeElement
                        value={bindValue(item?.fieldName)}
                        handleSelect={handleTreeSelect}
                        item={item}
                        errorMessage={bindErrorMessage(item?.fieldName)}
                    />
                )
            // NOTE: not in use
            case 'checkbox':
                return <FormCheckboxElement item={item} />
            // NOTE: not in use
            case 'listCheckbox':
                return <FormCheckboxListElement item={item} />
            case 'phoneWithCountryCode':
                return (
                    <FormPhoneNumberWithCountryCodeElement
                        handleChange={handleChange}
                        value={bindValue(item?.fieldName)}
                        item={item}
                        errorMessage={bindErrorMessage(item?.fieldName)}
                    />
                )
            case 'textWithIcon':
                return (
                    <FormTextFieldWithIconElement
                        handleChange={handleChange}
                        value={bindValue(item?.fieldName)}
                        item={item}
                        errorMessage={bindErrorMessage(item?.fieldName)}
                    />
                )
            case 'dropdown':
                return (
                    <FormDropdownElement
                        value={bindValue(item.fieldName)}
                        handleChange={handleDropDownChange}
                        item={item}
                        errorMessage={bindErrorMessage(item?.fieldName)}
                    />
                )
            default:
                return null
        }
    }

    return (
        <FormRow>
            {/* Row Element */}
            {columns.map((item, index) => (
                <FormColumn
                    style={{ width: `${item.width}%` }}
                    key={`row-columns-${item?.localeKey}-${index}`}
                >
                    {item?.subColumn ? (
                        <Fragment>
                            {item.subColumn.map((subItem, subIndex) => (
                                <FormSubColumn
                                    style={{ width: `${subItem.width}%` }}
                                    key={`row-sub-columns-${subItem?.localeKey}-${subIndex}`}
                                >
                                    {bindFormElement(subItem)}
                                </FormSubColumn>
                            ))}
                        </Fragment>
                    ) : (
                        bindFormElement(item)
                    )}
                </FormColumn>
            ))}
        </FormRow>
    )
}

export default FormRowFields
