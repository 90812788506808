import { useState, useEffect } from 'react'
import {
    Box,
    createStyles,
    makeStyles,
    Paper,
    useTheme,
} from '@material-ui/core'
import { MainOverview } from './MainOverview'
import {
    useApplicationLocalRights,
    useApplicationSensitiveFunctions,
    useGroup,
} from 'hooks'
import { useDebounce, useIsSmallScreenFalse, useQuery } from 'packages/core'
import { ItemDetails } from 'components'
import Search from 'components/Filters/Search'
import useSubcomponents from 'useSubcomponents'
import { LocalRights } from './LocalRights'
import { LocalSensitiveFunctions } from './LocalSensitiveFunctions'
import { useTranslation } from 'react-i18next'
import { PageContextProvider } from 'pageContext'
import { useHistory } from 'react-router'
import {
    getAccessibleTabs,
    getTabsBasedOnAccess,
    updateUrlQueryParams,
} from 'utils'
const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
        paperContainer: {
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

const subTabs = [
    {
        name: 'Rights',
        value: 'rights',
        label: 'Rights',
        display: true,
        requireAccess: {
            control: 'ResourceAdmin-Groups-Details-Overview-Rights',
        },
    },
    {
        name: 'Sensitive Functions',
        value: 'localSensitiveFunctions',
        label: 'LocalSensitiveFunctions',
        display: true,
        requireAccess: {
            control: 'ResourceAdmin-Groups-Details-Overview-SensitiveFunctions',
        },
    },
]

export const Overview = (props) => {
    const { groupId, basicAttributes } = props
    const { data: group } = useGroup(groupId)
    const { t } = useTranslation()
    const [callApi, setCallApi] = useState(true)
    const classes = useStyles()
    const isSmallScreen = useIsSmallScreenFalse()
    const theme = useTheme()
    const query = useQuery()
    const { hasAccessToControl } = useSubcomponents()
    const history = useHistory()
    const [subActiveTab, setSubActiveTab] = useState(null)
    const [tempTab, setTempTab] = useState(null)
    const [accessTabs, setAccessTabs] = useState([])

    useEffect(() => {
        if (group) {
            const allTabs = getTabsBasedOnAccess(subTabs, hasAccessToControl)
            const queryActiveSubTab =
                query.get('subActiveTab') ||
                (allTabs?.length ? allTabs[0].value : '')
            if (queryActiveSubTab) {
                const activeTabInfo = getAccessibleTabs(
                    query,
                    history,
                    subTabs,
                    null,
                    queryActiveSubTab,
                    hasAccessToControl,
                    'activeTab',
                    'subActiveTab',
                )
                setAccessTabs(activeTabInfo.allAccessibleTabs)
                setSubActiveTab(activeTabInfo.querySubActiveTab)
                setTempTab(activeTabInfo.querySubActiveTab)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group])

    const [searchKey, setSearchKey] = useState({
        rights: '',
        localSensitiveFunctions: '',
    })

    const debouncedSearchValue = useDebounce(searchKey[subActiveTab])

    let queryData = {
        [subActiveTab]: debouncedSearchValue,
    }
    const handleSearchChange = (keyword) => {
        setSearchKey({ [subActiveTab]: keyword })
    }
    const handleClear = () => {
        setSearchKey({ [subActiveTab]: '' })
    }
    const { list: sensitiveFunctions, totalCount: sensitiveFunctionsCount } =
        useApplicationSensitiveFunctions(
            groupId,
            subTabs.find((x) => x.name === 'localSensitiveFunctions') &&
                Boolean(callApi || subActiveTab === 'localSensitiveFunctions'),
            queryData['localSensitiveFunctions'] ?? '',
        )
    const { list: localRights, totalCount: localRightsCount } =
        useApplicationLocalRights(
            groupId,
            subTabs.find((x) => x.name === 'rights') &&
                Boolean(callApi || subActiveTab === 'rights'),
            queryData['rights'] ?? '',
        )
    useEffect(() => {
        if (callApi && sensitiveFunctions && localRights) {
            setCallApi(false)
        }
    }, [sensitiveFunctions, localRights])

    const getTabCount = (tab) => {
        switch (tab) {
            case 'rights': {
                return localRightsCount
            }
            case 'localSensitiveFunctions': {
                return sensitiveFunctionsCount
            }
            default: {
                return 0
            }
        }
    }

    useEffect(() => {
        if (queryData[subActiveTab] === '') {
            if (subActiveTab !== tempTab) {
                setSubActiveTab(tempTab)
            }
        }
    }, [queryData])

    return (
        <Box>
            <Paper
                className={classes.paperContainer}
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                }}
            >
                <MainOverview
                    groupId={groupId}
                    basicAttributes={basicAttributes}
                />
                {accessTabs.length > 0 && (
                    <Box
                        style={{
                            marginLeft: '-18px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <ItemDetails.Tabs
                            value={subActiveTab}
                            variant={'scrollable'}
                            style={{ border: 'none' }}
                            onChange={(_, value) => {
                                setTempTab(value)
                                updateUrlQueryParams(
                                    query,
                                    history,
                                    [{ key: 'subActiveTab', value: value }],
                                    [],
                                )
                                setSearchKey({
                                    [subActiveTab]: '',
                                })
                            }}
                        >
                            {accessTabs.map((tab, index) => {
                                return (
                                    <ItemDetails.Tabs.Tab
                                        data-protectedsubcomponent={
                                            tab?.requireAccess?.control
                                        }
                                        key={tab.value + index}
                                        value={tab.value}
                                        label={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        marginTop: 5,
                                                        marginRight: 5,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    {t(tab.label)}{' '}
                                                    <Box
                                                        paddingX="7px"
                                                        paddingY="10px"
                                                        style={{
                                                            color:
                                                                subActiveTab ===
                                                                tab.value
                                                                    ? '#fff'
                                                                    : '#8f8f8f',
                                                            backgroundColor:
                                                                subActiveTab ===
                                                                tab.value
                                                                    ? theme
                                                                          ?.palette
                                                                          ?.primary
                                                                          ?.main
                                                                    : '#e7e7e7',
                                                        }}
                                                        className={
                                                            classes.totalCountLabel
                                                        }
                                                    >
                                                        {getTabCount(tab.value)}
                                                    </Box>
                                                </span>
                                            </div>
                                        }
                                    />
                                )
                            })}
                        </ItemDetails.Tabs>
                        {!isSmallScreen && (
                            <Box
                                width="308px"
                                display="flex"
                                alignItems="center"
                            >
                                <Search
                                    handleSearch={handleSearchChange}
                                    placeholder={t('SearchFilterMembers')}
                                    value={
                                        searchKey[subActiveTab]
                                            ? searchKey[subActiveTab]
                                            : ''
                                    }
                                    handleClear={handleClear}
                                    outlined
                                />
                            </Box>
                        )}
                    </Box>
                )}
            </Paper>
            {subActiveTab === 'localSensitiveFunctions' && (
                <PageContextProvider key="RequestViewGroupsLocalSensitiveFunctions">
                    <LocalSensitiveFunctions
                        groupId={groupId}
                        queryData={queryData['localSensitiveFunctions']}
                    />
                </PageContextProvider>
            )}
            {subActiveTab === 'rights' && (
                <PageContextProvider key="RequestViewGroupsLocalRights">
                    <LocalRights
                        groupId={groupId}
                        queryData={queryData['rights']}
                    />
                </PageContextProvider>
            )}
        </Box>
    )
}
