import { FC, useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { Modal } from 'components/Modal'
import { PasswordInput } from 'components/PasswordInput'
import { ItemDetails } from 'components'
import { useTranslation } from 'react-i18next'
import { ValidationMessage } from 'packages/eid-ui'

const TextButton = styled(Box)({
    color: '#307fc1',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '56px',
    '&:hover': {
        cursor: 'pointer',
    },
})

export interface EnterPasswordProps {
    open?: any
    onClose?: any
    onReset?: any
    onCancel?: any
    onSucces?: any
    loading?: boolean
    error?: boolean
}

export const EnterPassword: FC<EnterPasswordProps> = (props) => {
    const { open, onClose, onSucces, onReset, onCancel, loading, error } = props
    const [value, setValue] = useState('')
    const { t } = useTranslation()

    const footerContent = (
        <Box display="flex" width="100%" padding="24px 32px">
            <Box minWidth="88px">
                <ItemDetails.ActionButton
                    fontColor="#ffffff"
                    bgColor={'#307fc1'}
                    width="100%"
                    loading={loading}
                    onClick={() => onSucces(value)}
                >
                    {t('OK')}
                </ItemDetails.ActionButton>
            </Box>

            <Box minWidth="130px" pl="30px">
                <ItemDetails.ActionButton
                    fontColor="#ffffff"
                    bgColor={'#aab0b4'}
                    width="100%"
                    onClick={onCancel}
                >
                    {t('Cancel')}
                </ItemDetails.ActionButton>
            </Box>

            {/* TODO:Enable when reset master password feature introduced
            <TextButton onClick={onReset}>
                {t('ResetPassword')}
            </TextButton> */}
        </Box>
    )

    const handleOnChange = (event: any) => {
        setValue(event.currentTarget.value)
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('EnterYourMasterPassword')}
            footerContent={footerContent}
        >
            <Box>
                <PasswordInput
                    value={value}
                    error={error}
                    onChange={handleOnChange}
                    style={{ fontSize: 18 }}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            onSucces(value)
                        }
                    }}
                />
                {error && (
                    <ValidationMessage message={t('InvalidPasswordMessage')} />
                )}
            </Box>
        </Modal>
    )
}
