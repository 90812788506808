import { Fragment, useEffect } from 'react'
import MailboxesLayout from './MailboxesLayout'
import { useAuthState } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { usePageContext } from 'pageContext'

const allFilters = [
    {
        name: 'OwnedByFilter',
        requireAccess: 'ResourceAdmin-Mailboxes-Filter-OwnedBy',
    },
    {
        name: 'targetSystemFilter',
        requireAccess: 'ResourceAdmin-Mailboxes-Filter-ResourceSystem',
        
    },
    {
        name: 'mailboxType',
        requireAccess: 'ResourceAdmin-Mailboxes-Filter-MailboxType',
    },
    {
        name: 'mailboxAdvancedSearch',
        requireAccess: 'ResourceAdmin-Mailboxes-Filter-AdvancedSearch',
    },
]

const RequestViewAllMailboxes = () => {
    const [, dispatch] = usePageContext()

    const [{ currentUserId, friendlyName }] = useAuthState()

    const { trimControlsBasedOnAccess } = useSubcomponents()

    const filters = trimControlsBasedOnAccess(allFilters).map((f) => f)
    const hasOwnedByFilterAccess =
        filters.map((f) => f.name).indexOf('OwnedByFilter') >= 0
    useEffect(() => {
        if (!hasOwnedByFilterAccess) {
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'ownedBy',
                    value: {
                        id: currentUserId,
                        friendlyName: friendlyName,
                    },
                },
            })
        }
    }, [currentUserId])
    return (
        <Fragment>
            <MailboxesLayout filters={filters} />
        </Fragment>
    )
}

export { RequestViewAllMailboxes }
