import EligibilityListing from 'components/SharedListings/EligibilityListing'

const AppManagementRolesEligibility = ({
    roleId,
    resourceTypeId,
    resourceId,
}) => {
    return (
        <EligibilityListing
            roleId={roleId}
            resourceTypeId={resourceTypeId}
            resourceId={resourceId}
            workflowName={'ManageManagementRoleWizard'}
        />
    )
}

export default AppManagementRolesEligibility
