import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, styled } from '@material-ui/core'
import { HorizontalTabs } from 'packages/eid-ui'
import { DropdownMenu } from 'components/DropdownMenu'
import { useRouteOptions } from 'hooks'
import config from 'config'
import { SubPathLevel } from 'appConfiguration'
import { useTranslation } from 'react-i18next'
import EidButton from 'packages/eid-ui/EidButton'
import { useQuery } from 'packages/core'

const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
        height: '4px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
    },
}))

const SubHeader = ({
    showNavTabs = true,
    subHeaderButton,
    viewSwitcher,
    totalCount,
    showBackButton = false,
    backButtonLabel = 'Back',
}) => {
    const { routeOptions } = useRouteOptions()
    const { t } = useTranslation()
    const history = useHistory()
    const query = useQuery()
    const returnUrl = query.get('returnUrl')
    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]

    let selectedRoute
    let selectedParentRoute

    for (let i = 0; i < routeOptions.length; i++) {
        const foundRoute = routeOptions[i].children.find((c) => {
            return (
                (config.APP_SUBPATH + c.route).toLowerCase() ===
                pathname.toLowerCase()
            )
        })
        if (foundRoute) {
            selectedRoute = foundRoute
            selectedParentRoute = routeOptions[i]
            break
        }
    }
    const selectedTab = pathname.split('/')[2 + SubPathLevel]
        ? pathname.split('/')[2 + SubPathLevel]
        : 'viewAll'

    const getPreviousPathFromUrl = () => {
        return returnUrl || `${config.APP_SUBPATH}${selectedRoute.route}`
    }

    return (
        <Box
            padding="0 0px 0 0px"
            borderBottom="1px solid rgba(0, 0, 0, 0.1)"
            height="60px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
        >
            <Box>
                {/* Drop Down Menu Here */}
                <DropdownMenu
                    routeOptions={routeOptions}
                    selectedRoute={selectedRoute}
                    selectedParentRoute={selectedParentRoute}
                    totalCount={totalCount}
                />
            </Box>

            {subHeaderButton}

            {showNavTabs && (
                <SubHeaderTabs
                    variant="standard"
                    value={selectedTab}
                    onChange={(_, value) => {
                        if (value === 'viewAll') {
                            history.push(`${config.APP_SUBPATH}/${areaName}`)
                        } else {
                            history.push(
                                `${config.APP_SUBPATH}/${areaName}/${value}`,
                            )
                        }
                    }}
                >
                    <HorizontalTabs.Tab
                        key={1}
                        value="viewAll"
                        label={t('Common_ViewAll')}
                    />
                    <HorizontalTabs.Tab
                        key={2}
                        value="workflows"
                        label={t('Workflows')}
                    />
                </SubHeaderTabs>
            )}
            <Box>
                {showBackButton ? (
                    <EidButton.BackButton
                        previousRoute={getPreviousPathFromUrl()}
                        label={t(backButtonLabel)}
                    />
                ) : (
                    viewSwitcher
                )}
            </Box>
        </Box>
    )
}

export default SubHeader
