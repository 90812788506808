import { FC, Fragment } from 'react'
import { Box, createStyles } from '@material-ui/core'
import { ToggleSwitch } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { StartDateTimePicker } from './StartDateTimePicker'
import { EndDateTimePicker } from './EndDateTimePicker'

export interface TimeConstrainedControlProps {
    selectedStartDate: any
    setSelectedStartDate: any
    selectedEndDate: any
    setSelectedEndDate: any
    isEndDateDisabled?: any
    maxEndDate?: any
    hasInvalidDates: any
    validationMessage: any
    toggle?: boolean
    setToggle?: any
    disabled: boolean
}

const useStyles = makeStyles((theme) =>
    createStyles({
        applicationStartDatePickerContainer: {
            width: '157px',
            marginBottom: '25px',

            '@media (max-width:959px)': {
                width: '100%',
                marginLeft: '0px',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
        startDatePickerContainer: {
            marginLeft: '16px',
            width: '157px',


            '@media (max-width:959px)': {
                width: '100%',
                marginLeft: '0px',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
        applicationEndDatePickerContainer: {
            width: '157px',

            '@media (max-width:959px)': {
                width: '100%',
                marginLeft: '0px',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
        endDatePickerContainer: {
            marginLeft: '24px',
            width: '157px',

            '@media (max-width:959px)': {
                width: '100%',
                marginLeft: '0px',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
        },
        policyViolationMessage: {
            position: 'absolute',
            fontSize: '12px',
            color: theme.palette.error.main,
            marginRight: '12px',
            whiteSpace: 'nowrap',
        },
        defaultPicker: {
            display: 'flex',
        },
    }),
)

export const TimeConstrainedControl: FC<TimeConstrainedControlProps> = ({
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    hasInvalidDates,
    validationMessage,
    toggle,
    disabled,
    setToggle,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <>
            {!disabled && (
                <Box>
                    <ToggleSwitch
                        value={toggle}
                        label={t('SetDuration')}
                        labelColor="#5d6870"
                        disabled={disabled}
                        onChange={() => setToggle((prev: any) => !prev)}
                    />
                </Box>
            )}

            {toggle && (
                <Box className={classes.defaultPicker}>
                    <Fragment>
                        <Box className={classes.startDatePickerContainer}>
                            <StartDateTimePicker
                                selectedStartDate={selectedStartDate}
                                setSelectedStartDate={setSelectedStartDate}
                            />
                        </Box>
                        <Box className={classes.endDatePickerContainer}>
                            <EndDateTimePicker
                                selectedEndDate={selectedEndDate}
                                setSelectedEndDate={setSelectedEndDate}
                                hasInvalidDates={hasInvalidDates}
                                validationMessage={validationMessage}
                            />
                        </Box>
                    </Fragment>
                </Box>
            )}
        </>
    )
}
