import { Box, styled } from '@material-ui/core'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useAppManagementRoleRoleDefinitions,
    useUnAssignApplicationRoleDefinition,
} from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import { Button } from 'components'
import { WarningModal } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import useSubcomponents from 'useSubcomponents'
import { getFullPathFromUrl, useDebounce, useQuery } from 'packages/core'
import config from 'config'
import { useHistory } from 'react-router'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})
const canAddNewRightsBundleControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-AddNewRightsBundle-Control'
const canDeleteRoleDefinitionControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-DeleteRoleDefinition-Control'
const canSeeUIActionsButtonControlName =
    'ResourceAdmin-ApplicationDetails-AppManagementRole-RoleDefinitionUIActionButton-Control'

const RoleDefinitions = ({ roleId, applicationResourceId }) => {
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState('')
    const [selectedItemToDelete, setSelectedItemToDelete] = useState(null)
    const query = useQuery()
    const history = useHistory()
    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }
    const { list, loading, loadingMore, pagination } =
        useAppManagementRoleRoleDefinitions(roleId, queryData)
    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useUnAssignApplicationRoleDefinition()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const attributes = [
        {
            name: 'localRoleFriendlyName',
            sortable: true,
            sortBy: 'localRoleFriendlyName',
            label: 'Role',

            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-RoleDefintions-Role',
            },
        },
        {
            name: 'roleTypeFriendlyName',
            sortable: true,
            sortBy: 'roleTypeFriendlyName',
            label: 'RoleType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-RoleDefintions-RoleType',
            },
        },
        {
            name: 'resourceSystemTypeFriendlyName',
            sortable: true,
            sortBy: 'resourceSystemTypeFriendlyName',
            label: 'SystemType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-RoleDefintions-SystemType',
            },
        },
        {
            name: 'resourceSystemFriendlyName',
            sortable: true,
            sortBy: 'resourceSystemFriendlyName',
            label: 'ResourceSystem',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-RoleDefintions-Tenant',
            },
        },
        {
            name: 'description',
            sortable: true,
            sortBy: 'description',
            label: 'Description',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-RoleDefintions-Description',
            },
        },

        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box display={'flex'}>
                            {hasAccessToControl(
                                canDeleteRoleDefinitionControlName,
                            ) && (
                                <DeleteButton>
                                    <Button.Delete
                                        showEndIcon={false}
                                        onClick={() =>
                                            setSelectedItemToDelete(data)
                                        }
                                    />
                                </DeleteButton>
                            )}
                        </Box>
                    )
                },
            },
        },
    ]

    const handleDelete = () => {
        deleteAssignments([selectedItemToDelete])
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="16px 30px"
            >
                {hasAccessToControl(canAddNewRightsBundleControlName) && (
                    <Button
                        onClick={() => {
                            query.delete('appManagementRoleId')
                            query.set('workflowName', 'AssignAzLocalRoleScope')
                            query.set('returnUrl', getFullPathFromUrl())
                            query.set('resourceID', applicationResourceId)
                            query.set('SelectedAssigneeID', roleId)
                            history.push(
                                `${
                                    config.APP_SUBPATH
                                }/managementRoles/workflows?${query.toString()}`,
                            )
                        }}
                        style={{
                            borderRadius: '4px',
                            padding: '0px 20px',
                            backgroundColor: '#307fc1',
                            border: 'solid 1px #307fc1',
                            color: '#ffffff',
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: '#307fc1',
                            },
                        }}
                    >
                        <Box style={{ display: 'flex' }}>
                            <Icon
                                name={'Plus'}
                                fill="white"
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    marginRight: '5px',
                                }}
                            />
                            {t('AddNewRightsBundle')}
                        </Box>
                    </Button>
                )}

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
            {selectedItemToDelete && (
                <WarningModal
                    title={`${t('Delete')} ${
                        selectedItemToDelete?.friendlyName
                    }`}
                    description={t('RoleDefinitionDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemToDelete}
                    cancel={() => setSelectedItemToDelete(null)}
                    proceeding={isDeletingAssignment}
                    proceed={handleDelete}
                />
            )}
        </Box>
    )
}

export default RoleDefinitions
