import { Box } from '@material-ui/core'
import { CardViewLoader, useEidGrid } from 'components'
import { useEffect } from 'react'
import CardView from './CardView'
import { ListTable, ListTableHead, ListTableRow } from './ListView'
import { useAppState } from 'appContext'

const EidTableGrid = ({
    rowSelection = false,
    selected,
    handleSelectAllClick,
    handleClick,
    isLoading,
    list,
    isLoadingMore,
    noDataMessage,
    pagination,
    attributes,
    defaultView,
    selectAllAction,
    onClickDetailButton,
}) => {
    const [state] = useAppState()

    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: noDataMessage,
        pagination,
        attributes: attributes,
        rowSelection: rowSelection,
        selected: selected,
        handleSelectAllClick: handleSelectAllClick,
        handleClick: handleClick,
        selectAllAction: selectAllAction,
        onClickDetailButton: onClickDetailButton,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: CardView,
        },
        tableViewConfig: {
            TableComponent: ListTable,
            TableHeadComponent: ListTableHead,
            TableRowComponent: ListTableRow,
        },
        defaultView,
    })

    useEffect(() => {
        setCurrentView(state?.itemsView || 'table')
    }, [state?.itemsView])

    return <Box>{viewToRender}</Box>
}

export default EidTableGrid
