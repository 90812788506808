import { Box, makeStyles, createStyles, styled } from '@material-ui/core'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useApplicationAppRightsAssignments,
    useDeleteApplicationAppRightsAssignments,
} from 'hooks'
import { PageContextProvider } from 'pageContext'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import AssigneePerson from '../../../AssigneePerson/AssigneePerson'
import { Button } from 'components'
import { WarningModal } from 'packages/eid-ui'
import useSubcomponents from 'useSubcomponents'
import { useIsSmallScreenFalse, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import { Icon } from 'packages/eid-icons'

const AddNewAssignmentButton = styled(Box)({
    '& button': {
        height: '40px',
        border: 'none',
        padding: '12px',
    },
})
const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',
    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const RemoveSelectedItem = styled(Box)({
    width: '290px',
})

const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})

const useButtonStyles = makeStyles(
    createStyles({
        root: (isSmallScreen) => ({
            height: '32px',
            borderRadius: '4px',
            padding: '0px 15px 0px 10px',
            backgroundColor: '#307fc1',
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '14px',
            margin: isSmallScreen ? '16px 16px 0px 16px' : '',
            '&:hover': {
                backgroundColor: '#307fc1',
            },
            '& svg': {
                marginRight: '8px',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)

const ApplicationAssignments = ({ role, roleId }) => {
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const isSmallScreen = useIsSmallScreenFalse()
    const classes = useButtonStyles(isSmallScreen)
    const {
        canAddApplicationAppRightAssignments,
        canBulkDeleteApplicationAppRightAssignments,
        canSelectApplicationAppRightAssignments,
        hasAccessToControl,
    } = useSubcomponents()
    const [selected, setSelected] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [selectedItemsToDelete, setSelectedItemsToDelete] = useState(null)

    const { list, loading, loadingMore, pagination } =
        useApplicationAppRightsAssignments(
            `/api/LocalRights/appRoles/allMembersByAppRight/${roleId}`,
        )

    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useDeleteApplicationAppRightsAssignments(null, roleId)

    const attributes = [
        {
            name: 'assignee',
            sortable: true,
            sortBy: 'assignee',
            label: 'Assignee',
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return <AssigneePerson data={data} />
                },
            },
        },
        {
            name: 'appRight',
            sortable: true,
            sortBy: 'appRight',
            label: 'AppRight',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'rightType',
            sortable: true,
            sortBy: 'rightType',
            label: 'RightType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'assignedTo',
            sortable: true,
            sortBy: 'assignedTo',
            label: 'AssignedTo',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            name: 'application',
            sortable: true,
            sortBy: 'application',
            label: 'Application',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },

        {
            name: 'resourceSystem',
            sortable: true,
            sortBy: 'resourceSystem',
            label: 'ResourceSystem',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        {
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-DeleteApplicationAppRightAssignments-Control',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <DeleteButton>
                            <Button.Delete
                                showEndIcon={false}
                                onClick={() => handleSingleDelete(data)}
                            />
                        </DeleteButton>
                    )
                },
            },
        },
    ]

    const handleSingleDelete = (item) => {
        setSelectedItemsToDelete([item])
    }

    const handleDeleteBulk = () => {
        setSelectedItemsToDelete(selected)
    }

    const handleConfirmDelte = () => {
        deleteAssignments(selectedItemsToDelete).then(() => {
            setSelectedItemsToDelete(null)
            setSelected([])
        })
    }
    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x.id === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            setSelected(list)
        }
    }

    const selectAllAction = canBulkDeleteApplicationAppRightAssignments
        ? {
              style: {
                  padding: '0px 32px',
              },
              render: () => {
                  return (
                      <RemoveSelectedItem
                          data-protectedsubcomponent={
                              canBulkDeleteApplicationAppRightAssignments
                          }
                      >
                          <BulkRemoveButton
                              selectedCount={selected.length}
                              label={t('RemoveSelectedAssignments', {
                                  count: selected.length,
                              })}
                              onClick={handleDeleteBulk}
                          />
                      </RemoveSelectedItem>
                  )
              },
          }
        : null

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="16px 32px"
            >
                <Box>
                    {canAddApplicationAppRightAssignments && (
                        <PageContextProvider key="AddNewIAmShopAssignments">
                            <AddNewAssignmentButton
                                data-protectedsubcomponent={
                                    canAddApplicationAppRightAssignments
                                }
                            >
                                <Button
                                    onClick={() => {
                                        query.delete('applicationId')
                                        query.delete('appRoleId')
                                        query.set(
                                            'workflowName',
                                            'AssignAzLocalRightScope',
                                        )
                                        query.set(
                                            'resourceID',
                                            role?.resourceId,
                                        )

                                        history.push(
                                            `${
                                                config.APP_SUBPATH
                                            }/applications/workflows?${query.toString()}`,
                                        )
                                    }}
                                    className={classes.root}
                                >
                                    {<Icon name="Plus" color="#fff" />}
                                    {t('AddNewAppRightAssignment')}
                                </Button>
                            </AddNewAssignmentButton>
                        </PageContextProvider>
                    )}
                </Box>
                <Box width="240px">
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    selectAllAction={selectAllAction}
                    rowSelection={
                        canSelectApplicationAppRightAssignments || false
                    }
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selected.map((s) => s.id)}
                    handleClick={handleRowSelection}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={attributes.filter(
                        (x) =>
                            !x?.requireAccess?.control ||
                            hasAccessToControl(x?.requireAccess?.control),
                    )}
                    list={list}
                />
            </TableOverView>

            {selectedItemsToDelete && (
                <WarningModal
                    title={
                        selectedItemsToDelete.length === 1
                            ? `${t('Delete')} ${
                                  selectedItemsToDelete[0]?.assignee
                              }`
                            : t('DeleteSelectedItems')
                    }
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemsToDelete}
                    cancel={() => setSelectedItemsToDelete(null)}
                    proceeding={isDeletingAssignment}
                    proceed={handleConfirmDelte}
                />
            )}
        </Box>
    )
}

export default ApplicationAssignments
