import React, { useState } from 'react'
import EidCardList from './EidCardList'
import EidTable from './EidTable'
import { useViewIndicator } from 'packages/eid-ui'
import { useAppState } from 'appContext'

const useEidGrid = ({
    isLoading,
    data,
    isLoadingMore,
    noDataMessage = 'No data available',
    pagination,
    attributes,
    cardViewConfig,
    tableViewConfig,
    handleSelectAllClick,
    defaultView = 'table',
    rowSelection = false,
    selected = [],
    handleClick,
    selectAllAction = {},
    onClickDetailButton,
}) => {
    const [state, dispatch] = useAppState()
    const [currentView, setCurrentView] = useState(
        state && state.itemsView ? state.itemsView : defaultView,
    )

    const { viewIndicator: viewSwitcher } = useViewIndicator({
        view: currentView,
        onCardViewClick: () => {
            setCurrentView('card')

            dispatch({
                type: 'SET_ITEMS_VIEW',
                payload: 'card',
            })
        },
        onListViewClick: () => {
            setCurrentView('table')

            dispatch({
                type: 'SET_ITEMS_VIEW',
                payload: 'table',
            })
        },
    })

    const viewToRender =
        currentView === 'card' ? (
            <EidCardList
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                rowSelection={rowSelection}
                handleSelectAllClick={handleSelectAllClick}
                selected={selected}
                handleClick={handleClick}
                selectAllAction={selectAllAction}
                onClickDetailButton={onClickDetailButton}
                {...cardViewConfig}
            />
        ) : (
            <EidTable
                isLoading={isLoading}
                data={data}
                isLoadingMore={isLoadingMore}
                noDataMessage={noDataMessage}
                pagination={pagination}
                attributes={attributes}
                rowSelection={rowSelection}
                handleSelectAllClick={handleSelectAllClick}
                selected={selected}
                handleClick={handleClick}
                selectAllAction={selectAllAction}
                {...tableViewConfig}
            />
        )

    return { viewSwitcher, currentView, setCurrentView, viewToRender }
}

export { useEidGrid, EidCardList, EidTable }
