import { Box, styled } from '@material-ui/core'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useApplicationAppRightsSensitiveFunctions } from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import { RiskIndicator } from 'packages/eid-ui'
import useSubcomponents from 'useSubcomponents'
import { useDebounce } from 'packages/core'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const RiskLevelBtn = styled(Box)({
    minWidth: '80px',
    maxWidth: '80px',
    width: '100%',
    '& div': {
        fontSize: '11px',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const SensitiveFunctionsListing = ({ roleId, url }) => {
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState('')

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }
    const { list, loading, loadingMore, pagination } =
        useApplicationAppRightsSensitiveFunctions(roleId, url, queryData)
    const { getAccessBasedAttributes } = useSubcomponents()

    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'FunctionName',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-SensitiveFunctions-FunctionName',
            },
        },
        {
            name: 'functionType',
            sortable: true,
            sortBy: 'functionType',
            label: 'FunctionType',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-SensitiveFunctions-FunctionType',
            },
        },
        {
            name: 'description',
            sortable: true,
            sortBy: 'description',
            label: 'Description',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-SensitiveFunctions-Description',
            },
        },
        {
            name: 'riskLevel',
            sortable: true,
            sortBy: 'riskLevel',
            label: 'RiskLevel',

            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-SensitiveFunctions-RiskLevel',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <RiskLevelBtn>
                            <RiskIndicator
                                variant={data.riskLevel}
                                label={t(data.riskLevel)}
                            />
                        </RiskLevelBtn>
                    )
                },
            },
        },
    ]

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
                padding="16px 30px"
            >
                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default SensitiveFunctionsListing
