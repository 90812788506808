import { Fragment } from 'react'
import { Box, Card } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import DynamicComponents from 'components/DynamicComponents'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import DeletePermission from './DeletePermission'
import GenericButton from 'components/Button/GenericButton'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: '16px',
            position: 'unset !important',
            margin: '16px',
            boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
            borderRadius: '8px !important',
        },
        actionArea: {
            width: '100%',
            display: 'flex',
            marginTop: '35px',
            marginBottom: '35px',
        },

        borderBox: {
            marginBottom: '-16px',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0)) !important',
            borderImageSlice: `${1} !important`,
            borderBottom: '1px solid !important',
            opacity: '0.25',
        },
        friendlyName: {
            fontWeight: 'bold',
            fontSize: '14px',
        },
    }),
)

const PermissionCard = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { item: data, loading, attributes } = props
    const history = useHistory()

    if (loading)
        return (
            <Card className={classes.root}>
                <SkeletonLoader />
            </Card>
        )
    const renderComponent = (componentName, item) => {
        const attribute = attributes.find(
            (a) => a.component?.componentName === componentName,
        )

        if (componentName === 'actionButtons') {
            return (
                <Fragment>
                    <GenericButton
                        onClick={() => {
                            history.push(
                                `${history.location.search}&permissionId=${item?.id}`,
                            )
                        }}
                        color="#01ae8f"
                        rootStylesProp={{
                            borderRadius: '4px',
                            width: '170px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                    >
                        {t('Details')}
                    </GenericButton>
                    <span style={{ marginRight: '16px' }} />
                    <DeletePermission
                        item={item}
                        styles={{ borderRadius: '4px', padding: '0px 10px' }}
                        showEndIcon={false}
                    />
                    <span style={{ marginRight: '16px' }} />
                    <UIActionsGearButton item={item} />
                </Fragment>
            )
        } else {
            const DynamicComponentToRender = DynamicComponents[componentName]
            return (
                <DynamicComponentToRender
                    attribute={attribute}
                    data={{ item }}
                />
            )
        }
    }

    return (
        <Card className={classes.root}>
            <Attribute style={{ marginTop: 10 }}>
                <AttributeValue className={classes.friendlyName}>
                    {data.friendlyName}
                </AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('Type')}</AttributeLabel>
                <AttributeValue>{data.typeFriendlyName}</AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('Description')}</AttributeLabel>
                <AttributeValue>{data.description}</AttributeValue>
            </Attribute>

            <Attribute>
                <AttributeLabel>{t('ConsentType')}</AttributeLabel>
                <AttributeValue>{data.consentType}</AttributeValue>
            </Attribute>

            <Box className={classes.actionArea}>
                <Box>
                    <AttributeValue>
                        {renderComponent('actionButtons', data)}
                    </AttributeValue>
                </Box>
            </Box>

            <Box className={classes.borderBox} />
        </Card>
    )
}

const SkeletonLoader = () => {
    return (
        <Fragment>
            <Skeleton animation="wave" variant="rect" height={80} />
            <Box marginTop={2} padding={2} width="100%">
                <Skeleton variant="rect" animation="wave" height={8} />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    style={{ height: 96, marginTop: 8 }}
                />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height={48}
                    style={{ marginTop: 16 }}
                />
            </Box>
        </Fragment>
    )
}
export default PermissionCard
