import { useTranslation } from 'react-i18next'
import { Box, styled } from '@material-ui/core'
import { useIsSmallScreenFalse } from 'packages/core'
import { ListingGrid, GridContainer, Filters } from 'components'
import useSubcomponents from 'useSubcomponents'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { usePbacAppResources } from 'hooks'

import AddNewResource from './AddNewResource'
import PersonDetailsLazy from 'components/PersonDetailsLazy'
import { PageContextProvider } from 'pageContext'
import { useAppState } from 'appContext'
import { Icon } from 'packages/eid-icons'

import DeletePBACResource from './DeletePbacResource'
import { useEffect, useState } from 'react'

const ButtonsBox = styled(Box)({
    display: 'flex',
    paddingRight: '10px',
    justifyContent: 'flex-end',
})

const canAddNewPbacResourceTypeControlName =
    'ResourceAdmin-ApplicationDetails-PbacAppResources-AddNewPbacResource-Control'
const canEditPbacResourceTypeControlName =
    'ResourceAdmin-ApplicationDetails-PbacAppResources-EditPbacResource-Control'
const canDeletePbacResourceTypeControlName =
    'ResourceAdmin-ApplicationDetails-PbacAppResources-DeletePbacResource-Control'

const PbacAppResources = ({ applicationId, resourceSystemModuleId }) => {
    const { t } = useTranslation()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = usePbacAppResources(applicationId, resourceSystemModuleId)

    const [{ devFeaturesEnabled }] = useAppState()
    const isSmallScreen = useIsSmallScreenFalse()
    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            label: 'FriendlyName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Name',
            },
        },
        {
            name: 'description',
            sortable: true,
            label: 'Description',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Description',
            },
        },
        {
            name: 'systemModuleResourceType',
            sortable: true,
            label: 'Type',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Type',
            },
        },
        {
            name: 'ownerFriendlyName',
            hideValue: true,
            sortable: true,
            label: 'Owner',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-PbacAppResources-PbacAppResourcesListing-Owner',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <PersonDetailsLazy
                            personId={data.ownerAssigneeId}
                            friendlyName={data.ownerFriendlyName}
                            imageThumbUrl={data.ownerAssigneeImageUrl}
                        />
                    )
                },
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <ButtonsBox>
                            {hasAccessToControl(
                                canEditPbacResourceTypeControlName,
                            ) &&
                                devFeaturesEnabled && ( //NOTE: Hiding this as this button is removed in latest UX design and is confirmed by Cristiana
                                    <AddNewResource
                                        data={data}
                                        applicationId={applicationId}
                                        resourceSystemModuleId={
                                            resourceSystemModuleId
                                        }
                                        headerKey={'EidPBACResource'}
                                        labelKey={'Edit'}
                                    />
                                )}
                            <Box margin="5px" />

                            {hasAccessToControl(
                                canDeletePbacResourceTypeControlName,
                            ) && (
                                <DeletePBACResource
                                    item={data}
                                    applicationId={applicationId}
                                />
                            )}
                        </ButtonsBox>
                    )
                },
            },
        },
    ]

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                {hasAccessToControl(canAddNewPbacResourceTypeControlName) && (
                    <Box
                        data-protectedsubcomponent={
                            canAddNewPbacResourceTypeControlName
                        }
                    >
                        <PageContextProvider
                            key={applicationId + 'AddNewPbacResource'}
                        >
                            <AddNewResource
                                headerKey={'AddNewPbacResources'}
                                labelKey={'AddNewPbacResources'}
                                applicationId={applicationId}
                                resourceSystemModuleId={resourceSystemModuleId}
                                startIcon={
                                    <Icon
                                        name={'Plus'}
                                        fill="white"
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    />
                                }
                            />
                        </PageContextProvider>
                    </Box>
                )}

                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default PbacAppResources
