import { Box, FormLabel, makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import classNames from 'classnames'
import { usePeopleFormDropDownOption } from 'hooks'
import { isNilOrEmpty } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'

const useStyles = makeStyles({
    dynamicSelectBox: {
        width: '100%',
        '& label': {
            fontSize: '12px',
            color: 'rgb(180, 180, 180)',
            textTransform: 'uppercase',
        },
    },
    dynamicSelect: {
        '& .MuiFormControl-fullWidth': {
            marginTop: '5px !important',
        },
        '& .MuiOutlinedInput-root': {
            width: '100%',
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
            padding: '2px 10px 2px 4px',
            borderColor: ' #d8d8dd',
            borderRadius: '5px',
            '& .MuiSelect-select:focus': {
                backgroundColor: 'white',
            },
            '&:before': {
                content: 'none !important',
            },
        },
        '& .MuiChip-root': {
            background: '#515b63',
            border: '1px solid #515b63',
        },
        '& .MuiChip-label': {
            color: '#fff',
            maxWidth: '100px',
        },
        '& .MuiChip-deleteIcon': {
            color: '#fff',
        },
        '& .MuiAutocomplete-tag': {
            borderRadius: '4px',
        },
    },
    errorLabel: {
        color: 'red !important',
        '& label': {
            color: 'red !important',
        },
    },
    dynamicSelectSearch: {
        position: 'absolute',
        top: '16px',
        left: '8px',
    },
    disabledMultiSelect: {
        '& div': {
            pointerEvents: 'none',
            backgroundColor: '#f5f5f5 !important',
            color: '#c1bcbc !important',
        },
    },
})

const FormMultiSelectDropdownElement = ({
    item,
    bindValue,
    handleDDChange,
    errorMessage,
}) => {
    const { t } = useTranslation()
    const [options, setOptions] = useState([])
    const { data, isLoading } = usePeopleFormDropDownOption(item?.apiUrl)
    const classes = useStyles()
    const { hasAccessToControl } = useSubcomponents()

    const checkAccessToField = (fiedlName) => {
        return hasAccessToControl(
            `ResourceAdmin-PersonDetails-${fiedlName}-Control`,
        )
    }
    useEffect(() => {
        if (data) {
            setOptions(data)
        }
    }, [data])
    const handleChange = (e, values) => {
        const evt = {
            target: {
                name: item.fieldName,
                value: values?.length ? prepareSelectedValues(values) : null,
            },
        }
        handleDDChange(evt)
    }

    const prepareSelectedValues = (values) => {
        const selectValue = values.map((item) => {
            return item.value
        })
        return selectValue.toString()
    }

    const bindOptions = () => {
        const values = bindValue(item.fieldName)
        if (!values) {
            return options
        } else {
            const nonSelectedValues = options.filter((option) => {
                const isValueExist = values
                    .split(',')
                    .find((p) => p === option.value)
                if (!isValueExist) {
                    return option
                }
            })

            return nonSelectedValues
        }
    }
    return (
        <Fragment>
            <Box
                className={classNames(classes.dynamicSelectBox, {
                    [classes.disabledMultiSelect]:
                        item?.disabled || !checkAccessToField(item.fieldName),
                })}
            >
                <FormLabel
                    component="label"
                    className={classNames(classes.customFormLabel, {
                        [classes.errorLabel]: !isNilOrEmpty(errorMessage),
                    })}
                >
                    {(item.localeKey
                        ? t(`${item.localeKey}`)
                        : item.fieldName) +
                        (item.required &&
                        checkAccessToField(item.fieldName) &&
                        !item?.disabled
                            ? ' *'
                            : '')}
                </FormLabel>
                <Box className={classes.dynamicSelectBox}>
                    <Box className={classes.dynamicSelectSearch}>
                        <Icon name="Search" />
                    </Box>

                    <Autocomplete
                        disabled={
                            item?.disabled ||
                            !checkAccessToField(item.fieldName)
                        }
                        loading={isLoading}
                        multiple
                        className={classes.dynamicSelect}
                        id="multiple-limit-tags"
                        name={item.fieldName}
                        options={bindOptions()}
                        getOptionLabel={(option) => t(`${option.localeKey}`)}
                        onChange={handleChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t('Common_Search')}
                            />
                        )}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

export default FormMultiSelectDropdownElement
