import { Box, styled, Typography } from '@material-ui/core'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useApplicationPeopleByResourceId,
    useAssignAppRights,
    useUnAssignAppRights,
} from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import { Icon } from 'packages/eid-icons'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl } from 'utils'
import AddAssignment from 'components/ApplicationBanner/AddAssignments/AddAssignment'
import useSubcomponents from 'useSubcomponents'
import { useDebounce } from 'packages/core'
import { PageContextProvider } from 'pageContext'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const AssignmentHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '16px',
    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})
const RoleSetting = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& button': { height: '32px' },
})

const AssignRightButton = styled(Box)({
    '& button': {
        width: '150px',
    },
})

const AddAssignmentPopup = ({
    applicationId,
    data,
    applicationFriendlyName,
    refetch,
}) => {
    const [assignRights, { isLoading: isAssigning }] =
        useAssignAppRights(refetch)
    const [unAssignRights, { isLoading: isUnAssigning }] =
        useUnAssignAppRights(refetch)

    const handleSubmit = (selectedRights) => {
        const unAssignedIds = selectedRights
            ?.filter((el) => !el.isAssigned)
            .map((i) => i.id)
        const assignedIds = selectedRights
            ?.filter((el) => el.isAssigned)
            .map((i) => i.id)
        if (unAssignedIds.length > 0) {
            assignRights({ assigneeId: data?.id, roleIds: unAssignedIds })
        }
        if (assignedIds.length > 0) {
            unAssignRights({ assigneeId: data?.id, roleIds: assignedIds })
        }
    }
    return (
        <PageContextProvider key={'ApplicationAppRightPeopleAddAssignment'}>
            <AddAssignment
                resourceType={'Applications'}
                labelKey={'Edit'}
                submitButtonLabelKey={'Save'}
                searchUrl={`api/LocalRights/GetEligibleAppRightsByApplicationByAssignee`}
                targetAssigneeId={applicationId}
                searchPlaceHolder="SearchRights"
                queryParams={{
                    applicationId,
                    eligibilityAssigneeId: data?.id,
                }}
                headerText={{
                    dynamicText: applicationFriendlyName,
                    text: 'ChooseAppRights',
                }}
                listLabels={{
                    unselectedListLabel: 'ApplicationRights',
                    selectedListLabel: 'SelectedApplicationRights',
                }}
                onSubmit={handleSubmit}
                submitLoader={isAssigning || isUnAssigning}
                hideDurationSelection={true}
                allowRemovingAssignments={true}
                showAssignmentModal={false}
            />
        </PageContextProvider>
    )
}
const ApplicationAppRightsPeople = ({
    applicationId,
    applicationFriendlyName,
}) => {
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState('')

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
        showLocalRights: true,
    }

    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationPeopleByResourceId(
            applicationId,
            'api/people/allPeopleByResource',
            queryData,
        )

    const { canAssignApplicationAppRightToPeople } = useSubcomponents()

    const attributes = [
        {
            name: 'assignments',
            sortable: false,
            sortBy: 'assignments',
            label: 'Assignments',

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        {data.localRightAssigned ? (
                            <Icon
                                color="green"
                                fill="green"
                                name="FilledCheck"
                                style={{ width: '20px', height: '20px' }}
                            />
                        ) : (
                            <Icon color="#d0021b" name="UncheckedRound" />
                        )}
                    </AssignmentHeader>
                ),
            },
        },
        {
            name: 'friendlyName',
            sortable: false,
            sortBy: 'friendlyName',
            label: 'DisplayName',

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        <Avatar src={getFullImageUrl(data.imageThumbUrl)} />
                        <Typography
                            style={{ fontSize: '13px', fontWeight: 'bold' }}
                        >
                            {data.friendlyName}
                        </Typography>
                    </AssignmentHeader>
                ),
            },
        },
        {
            name: 'login',
            sortable: true,
            sortBy: 'login',
            label: 'Login',
        },
        {
            name: 'company',
            sortable: true,
            sortBy: 'company',
            label: 'Company',
        },
        {
            name: 'email',
            sortable: true,
            sortBy: 'email',
            label: 'Email',
        },
        {
            name: 'enabled',
            sortable: true,
            sortBy: 'enabled',
            label: 'Enabled',

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <Typography
                        style={{ fontSize: '13px', fontWeight: 'normal' }}
                    >
                        {data.enabled ? 'Yes' : 'No'}
                    </Typography>
                ),
            },
        },

        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <RoleSetting>
                            {canAssignApplicationAppRightToPeople && (
                                <AssignRightButton
                                    data-protectedsubcomponent={
                                        canAssignApplicationAppRightToPeople
                                    }
                                >
                                    <AddAssignmentPopup
                                        applicationId={applicationId}
                                        data={data}
                                        applicationFriendlyName={
                                            applicationFriendlyName
                                        }
                                        refetch={refetch}
                                    />
                                </AssignRightButton>
                            )}
                        </RoleSetting>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
                padding="16px 0px"
            >
                <Box width="240px">
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={attributes}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationAppRightsPeople
