import React, { useState, useEffect } from 'react'
import { usePageContext } from 'pageContext'
import { ToggleSwitch, Card } from 'packages/eid-ui'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
const ShowAzureAppsFilter = (props) => {
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const { showOnlyAzureApplications: preShowOnlyAzureApplications } = state
    const [showOnlyAzureApplications, setshowOnlyAzureApps] = useState(
        preShowOnlyAzureApplications,
    )

    const changeSelection = () => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'showOnlyAzureApplications',
                value: showOnlyAzureApplications,
            },
        })
    }

    useEffect(() => {
        changeSelection()
    }, [showOnlyAzureApplications])

    const content = () => {
        return (
            <>
                <Box padding="0px 20px 0px 20px">
                    <ToggleSwitch
                        onChange={() => {
                            setshowOnlyAzureApps((prev) => !prev)
                        }}
                        value={showOnlyAzureApplications}
                        label={'On'}
                    />
                </Box>
            </>
        )
    }

    return (
        <Card
            cardTitle={t('MyIdentity_ShowAzureApplication')}
            color="#fff"
            collapsible
            expanded={showOnlyAzureApplications}
        >
            {content()}
        </Card>
    )
    // TODO: Need to Enable it for Mobile UI
    // return isSmallScreen ? (
    //     <ExpansionPanel
    //         expanded={true}
    //         title={!title ? t('Common_TargetPerson') : title}
    //         disabled={applicationId}
    //     >
    //         <Box padding="20px 0px">{content()}</Box>
    //     </ExpansionPanel>
    // ) : (

    //     <Card
    //         cardTitle={!title ? t('Common_TargetPerson') : title}
    //         color="#fff"
    //         collapsible
    //         expanded={true}
    //         disabled={applicationId}
    //     >
    //         {content()}
    //     </Card>
    // )
}

export default ShowAzureAppsFilter
