import React from 'react'
import Button from './index'
import { Typography, Box } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'

const styles = {
    normal: {
        minWidth: '160px',
        width: '100%',
        backgroundColor: '#ffffff',
        color: '#363636',
        border: 'solid 1px #d2d2d9',

        '& >span': { display: 'flex', flexDirection: 'column' },
        '&:hover': {
            backgroundColor: '#ffffff',
            opacity: 0.95,
        },
        '&:active': {
            backgroundColor: '#ffffff',
        },
    },
    disabled: {
        backgroundColor: '#aab0b4 !important',
        color: '#ffffff !important',
    },

    label: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: 1.15,
    },
    subLabel: {
        fontSize: '11px',
        color: '#8b909a',
        lineHeight: 1.15,
    },
    icon: {
        position: 'absolute',
        top: '9px',
        right: '12px',
    },
}

const SortButton = ({ children, label, subLabel, order, ...rest }) => {
    return (
        <Button
            style={styles.normal}
            size="medium"
            disabledStyles={styles.disabled}
            {...rest}
        >
            {!children ? (
                <>
                    <Box style={{ display: 'flex' }}>
                        <Box>
                            <Typography style={styles.label}>
                                {label}
                            </Typography>

                            <Typography style={styles.subLabel}>
                                {subLabel}
                            </Typography>
                        </Box>
                        {order && (
                            <Box style={styles.icon}>
                                <Icon
                                    name="ArrowLong"
                                    width="16px"
                                    height="16px"
                                    color="#307fc1"
                                    direction={order === 'asc' ? 'down' : 'up'}
                                />
                            </Box>
                        )}
                    </Box>
                </>
            ) : (
                children
            )}
        </Button>
    )
}

export default SortButton
