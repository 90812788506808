import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, createStyles, makeStyles, styled } from '@material-ui/core'
import { getFullPathFromUrl, useDebounce, useIsSmallScreenFalse, useQuery } from 'packages/core'
import { ListingGrid, GridContainer, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import { Icon } from 'packages/eid-icons'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useAppManagementRoleAccessGranted } from 'hooks'
import DateTimeGenericComponent from 'components/DynamicComponents/DateTimeGenericComponent'
import Search from 'components/Filters/Search'
import { useHistory } from 'react-router'
import config from 'config'
import DeleteAccessGranted from './DeleteAccessGranted'

export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '10px',
    },
}))

const canAddNewAccessAssignmentControlName =
    'ResourceAdmin-ManagementRoleDetails-AccessGranted-AddNewAccessAssignment-Control'
const canDeleteAccess =
    'ResourceAdmin-ManagementRoleDetails-AccessGranted-DeleteAccessAssignment-Control'

const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const useButtonStyles = makeStyles(
    createStyles({
        root: () => ({
            height: '40px',
            borderRadius: '4px',
            padding: '0px 15px 0px 10px',
            backgroundColor: '#307fc1',
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: '#307fc1',
            },
            '& svg': {
                marginRight: '8px',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)

const AccessGranted = ({ managementRoleId, resourceTypeId, resourceId }) => {
    const { t } = useTranslation()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const [searchKey, setSearchKey] = useState('')

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }

    const query = useQuery()
    const history = useHistory()
    const classes = useButtonStyles(isSmallScreen)

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        refetch,
    } = useAppManagementRoleAccessGranted(
        managementRoleId,
        resourceTypeId,
        queryData,
    )

    const isSmallScreen = useIsSmallScreenFalse()

    const attributes = [
        {
            name: 'assigneeType',
            sortable: true,
            sortBy: 'assigneeType',
            label: 'AssigneeType',

            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },

            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-AssigneeType',
            },
        },
        {
            name: 'assigneeFriendlyName',
            sortable: true,
            sortBy: 'assigneeFriendlyName',
            label: 'Assignee',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-Assignee',
            },
        },
        {
            name: 'resourceTypeRoleFriendlyName',
            sortable: true,
            sortBy: 'resourceTypeRoleFriendlyName',
            label: 'AccessLevel',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-AccessLevel',
            },
        },
        {
            name: 'resourceFriendlyName',
            sortable: true,
            sortBy: 'resourceFriendlyName',
            label: 'Resource',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-Resource',
            },
        },
        {
            name: 'resourceTypeFriendlyName',
            sortable: true,
            sortBy: 'resourceTypeFriendlyName',
            label: 'ResourceType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-ResourceType',
            },
        },
        {
            label: 'TimeConstraint',
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ManagementRoleDetails-AccessGranted-TimeConstraint',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box style={{ display: 'flex', gap: 20 }}>
                            <Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 10,
                                    }}
                                >
                                    <strong>{t('Starts')}:</strong>
                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'startDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 17,
                                        marginTop: '5px',
                                    }}
                                >
                                    <strong>{t('Ends')}:</strong>

                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'endDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                            </Box>
                            {/* TODO: Unhide this when backend functionality is implemented */}
                            {/* <AddTimeConstraint
                                data={data}
                                iconName={'EditFile'}
                                onSave={(data) => {
                                    console.log(data)
                                }}
                                startTimeProp="startDateUtc"
                                endTimeProp="endDateUtc"
                            /> */}
                        </Box>
                    )
                },
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(canDeleteAccess) && (
                            <DeleteAccessGranted
                                item={data}
                                refetch={refetch}
                            />
                        )
                    )
                },
            },
        },
    ]

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    return (
        <Box>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {hasAccessToControl(
                        canAddNewAccessAssignmentControlName,
                    ) && (
                        <Box
                            data-protectedsubcomponent={
                                canAddNewAccessAssignmentControlName
                            }
                        >
                            <Button
                                onClick={() => {
                                    query.delete('managementRoleId')
                                    query.set(
                                        'workflowName',
                                        'GrantActorAccessWF',
                                    )
                                    query.set(
                                        'TargetAssigneeId',
                                        managementRoleId,
                                    )
                                    query.set('returnUrl', getFullPathFromUrl())
                                    history.push(
                                        `${
                                            config.APP_SUBPATH
                                        }/managementRoles/workflows?${query.toString()}`,
                                    )
                                }}
                                className={classes.root}
                            >
                                {<Icon name="Plus" color="#fff" />}
                                {t('AddNewAccessAssignment')}
                            </Button>
                        </Box>
                    )}

                    <Box width="240px" display="flex" justifyContent="flex-end">
                        <SearchMenuBarFilter>
                            <Search
                                handleSearch={handleSearchChange}
                                placeholder={t('SearchAndFilter')}
                                value={searchKey}
                                handleClear={handleClear}
                                outlined
                            />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '16px 0px',
                        marginTop: '0px',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    <EidTableGrid
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={list}
                    />
                </GridContainer>
            </ListingGrid>
        </Box>
    )
}

export default AccessGranted
