import { Box, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { usePeopleFormDropDownOption } from 'hooks'
import { isNilOrEmpty } from 'packages/core'
import { Select } from 'packages/eid-ui'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSubcomponents from 'useSubcomponents'

const useStyles = makeStyles({
    Dropdownroots: {
        width: '100%',
        '& .MuiSelect-select': {
            width: '100%',
            boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
            padding: '9px 12px',
            borderColor: ' #d8d8dd',
            borderRadius: '5px',
        },
        '& label': {
            fontSize: '12px',
            color: 'rgb(180, 180, 180)',
            textTransform: 'uppercase',
        },
    },
    errorLabel: {
        color: 'red !important',
        '& label': {
            color: 'red !important',
        },
    },
    disabledSelect: {
        '& div': {
            pointerEvents: 'none',
            backgroundColor: '#f5f5f5 !important',
            color: '#c1bcbc !important',
        },
    },
})
const FormDropdownElement = ({ item, handleChange, value, errorMessage }) => {
    const [options, setOptions] = useState([])
    const { data, isLoading } = usePeopleFormDropDownOption(item?.apiUrl)
    const [selected, setSelected] = useState(null)
    const getOptionLabel = (option) => option.value
    const classes = useStyles()
    const { t } = useTranslation()

    const { hasAccessToControl } = useSubcomponents()

    const checkAccessToField = (fiedlName) => {
        return hasAccessToControl(
            `ResourceAdmin-PersonDetails-${fiedlName}-Control`,
        )
    }
    useEffect(() => {
        if (data) {
            const refinedOptions = data.map((option) => {
                return {
                    id: option[item?.dataAttributes?.value],
                    value: option[item?.dataAttributes?.label],
                }
            })
            setOptions(refinedOptions)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        const selectedvalue =
            (options?.length &&
                options.find(
                    (x) => x[item?.dataAttributes?.value] === value,
                )) ||
            null
        setSelected(selectedvalue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, options])

    return (
        <Fragment>
            <Box
                className={classNames(classes.Dropdownroots, {
                    [classes.errorLabel]: !isNilOrEmpty(errorMessage),
                    [classes.disabledSelect]:
                        item?.disabled || !checkAccessToField(item.fieldName),
                })}
            >
                <label>{`${t(item?.localeKey)} ${
                    item?.required &&
                    checkAccessToField(item.fieldName) &&
                    !item?.disabled
                        ? '*'
                        : ''
                }`}</label>
                <Select
                    disabled={
                        item?.disabled || !checkAccessToField(item.fieldName)
                    }
                    options={options}
                    getOptionLabel={getOptionLabel}
                    onChange={(e) => handleChange(e, item?.fieldName)}
                    value={selected}
                    isLoading={isLoading}
                ></Select>
            </Box>
        </Fragment>
    )
}

export default FormDropdownElement
