import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const BooleanIcon = ({ data, attribute }) => {
    const { item } = data
    const reverse = attribute.reverse
    return (
        <>
            {item && item[attribute.name] === null ? (
                '-'
            ) : !reverse ? (
                item[attribute.name] ? (
                    <Icon
                        color="#029435"
                        style={{ width: '20px', height: '20px' }}
                        name="FilledCheck"
                    />
                ) : (
                    <Icon
                        color="#d0021b"
                        style={{ width: '20px', height: '20px' }}
                        name="UncheckedRound"
                    />
                )
            ) : item[attribute.name] ? (
                <Icon
                    color="#d0021b"
                    style={{ width: '20px', height: '20px' }}
                    name="UncheckedRound"
                />
            ) : (
                <Icon
                    color="#029435"
                    style={{ width: '20px', height: '20px' }}
                    name="FilledCheck"
                />
            )}
        </>
    )
}
export default BooleanIcon
