import { Box, styled } from '@material-ui/core'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useApplicationRoleDefinitions } from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import { Button } from 'components'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { Icon } from 'packages/eid-icons'
import { useHistory } from 'react-router'
import { getFullPathFromUrl, useDebounce, useQuery } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import DeleteRoleDefinition from '../Details/DeleteRoleDefinition'
import config from 'config'
const canAddNewRoleDefinitionsControlName =
    'ResourceAdmin-ApplicationDetails-AddNewRoleDefinition-Control'
const canDeleteRoleDefinitionControlName =
    'ResourceAdmin-ApplicationDetails-DeleteRoleDefinition-Control'
const canSeeUIActionsButtonControlName =
    'ResourceAdmin-ApplicationDetails-RoleDefinitionUIActionButton-Control'
const canSeeDetailButtonControlName =
    'ResourceAdmin-ApplicationDetails-RoleDefinitionDetails-Control'
const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const ApplicationRoleDefinitions = ({
    applicationId,
    resourceSystemModuleId,
}) => {
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState('')
    const query = useQuery()
    const history = useHistory()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }

    const { list, loading, loadingMore, pagination } =
        useApplicationRoleDefinitions(
            applicationId,
            resourceSystemModuleId,
            queryData,
        )

    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'Role',

            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-RoleDefintions-Role',
            },
        },
        {
            name: 'roleTypeFriendlyName',
            sortable: true,
            sortBy: 'roleTypeFriendlyName',
            label: 'RoleType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefintions-RoleType',
            },
        },
        {
            name: 'resourceSystemTypeFriendlyName',
            sortable: true,
            sortBy: 'resourceSystemTypeFriendlyName',
            label: 'SystemType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefintions-SystemType',
            },
        },
        {
            name: 'resourceSystemName',
            sortable: true,
            sortBy: 'resourceSystemName',
            label: 'Tenant',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefintions-Tenant',
            },
        },
        {
            name: 'description',
            sortable: true,
            sortBy: 'description',
            label: 'Description',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefintions-Description',
            },
        },

        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                gap: 10,
                                justifyContent: 'end',
                            }}
                        >
                            {hasAccessToControl(
                                canSeeDetailButtonControlName,
                            ) && (
                                <Button.Details
                                    showEndIcon={false}
                                    data-protectedsubcomponent={
                                        canSeeDetailButtonControlName
                                    }
                                    onClick={() => {
                                        history.push(
                                            `${history.location.search}&roleDefinitionId=${data?.id}`,
                                        )
                                    }}
                                />
                            )}
                            {hasAccessToControl(
                                canDeleteRoleDefinitionControlName,
                            ) && <DeleteRoleDefinition item={data} />}
                            {hasAccessToControl(
                                canSeeUIActionsButtonControlName,
                            ) && <UIActionsGearButton item={data} />}
                        </Box>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="16px 0px"
            >
                {hasAccessToControl(canAddNewRoleDefinitionsControlName) && (
                    <Button
                        onClick={() => {
                            query.delete('applicationId')
                            query.delete('roleDefinitionId')
                            query.set('workflowName', 'OnboardAzLocalRole')
                            query.set('returnUrl', getFullPathFromUrl())
                            query.set('SelectedApplicationID', applicationId)
                            history.push(
                                `${
                                    config.APP_SUBPATH
                                }/applications/workflows?${query.toString()}`,
                            )
                        }}
                        data-protectedsubcomponent={
                            canAddNewRoleDefinitionsControlName
                        }
                        style={{
                            borderRadius: '4px',
                            padding: '0px 20px',
                            backgroundColor: '#307fc1',
                            border: 'solid 1px #01ae8f',
                            color: '#ffffff',
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: '#307fc1',
                            },
                        }}
                    >
                        <Box style={{ display: 'flex' }}>
                            <Icon
                                name={'Plus'}
                                fill="white"
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    marginTop: '3px',
                                    marginRight: '5px',
                                }}
                            />
                            {t('AddNewRoleDefinitions')}
                        </Box>
                    </Button>
                )}

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationRoleDefinitions
