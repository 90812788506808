import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@material-ui/core'
import { isNilOrEmpty, useIsSmallScreenFalse } from 'packages/core'
import { ListingGrid, GridContainer, Filters, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from '../../CreateWorkflowButton'
import { ArrowIcon, Icon } from 'packages/eid-icons'
import { EidAutocomplete } from 'packages/eid-controls'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useDeleteAssigneeAssignment,
    useDirectAccessGrantedByApplication,
} from 'hooks'
import DateTimeGenericComponent from 'components/DynamicComponents/DateTimeGenericComponent'
import { useAppState } from 'appContext'
import { WarningModal } from 'packages/eid-ui'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'label',
    labelProp: 'friendlyName',
})
export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '10px',
    },
}))
const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})

const canDeleteAccess =
    'ResourceAdmin-ApplicationDetails-AccessGranted-DeleteAccessAssignment-Control'
const canAddNewAccessAssignmentControlName =
    'ResourceAdmin-ApplicationDetails-AccessGranted-AddNewAccessAssignment-Control'
const canSelectAppManagementRoleControlName =
    'ResourceAdmin-ApplicationDetails-AccessGranted-SelectAppManagementRole-Control'
const EditTimeConstraint = styled(Box)({
    height: '28px',
    width: '28px',
    borderRadius: '4px',
    padding: '4px 7px',
    backgroundColor: '#307fc1',

    '& svg': {
        marginRight: '8px',
        '& path': {
            stroke: '#fff',
        },
    },
})
const DirectAccessGranted = ({ applicationId }) => {
    const { t } = useTranslation()
    const [{ devFeaturesEnabled }] = useAppState()
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const [selectedRole, setSelectedRole] = useState(null)
    const [selectedItemToDelete, setSelectedItemToDelete] = useState(null)
    //NOTE: passing array of keys to refresh listing
    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useDeleteAssigneeAssignment([
            applicationId,
            'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_DIRECT_ACCESS_GRANTED',
        ])
    const queryParams = {
        take: 10,
    }
    const getOptionLabel = (option) => option && option.friendlyName
    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useDirectAccessGrantedByApplication(
        applicationId,
        selectedRole?.id ? selectedRole.id : null,
    )
    const handleDelete = () => {
        deleteAssignments([selectedItemToDelete?.id]).then(() => {
            setSelectedItemToDelete(null)
        })
    }
    const isSmallScreen = useIsSmallScreenFalse()
    const attributes = [
        {
            name: 'assigneeFriendlyName',
            sortable: true,
            label: 'ManagementRoleName',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-ManagementRoleName',
            },
        },
        {
            name: 'resourceTypeRoleFriendlyName',
            sortable: true,
            label: 'AccessLevel',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-AccessLevel',
            },
        },
        {
            name: 'resourceFriendlyName',
            sortable: true,
            label: 'Resource',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-Resource',
            },
        },
        {
            name: 'resourceTypeFriendlyName',
            sortable: true,
            label: 'ResourceType',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-ResourceType',
            },
        },
        {
            name: 'resourceSystemFriendlyName',
            sortable: true,
            label: 'ResourceSystem',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-ResourceSystem',
            },
        },

        {
            label: 'TimeConstraint',
            hideValue: true,

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-AppManagementRole-AccessGranted-TimeConstraint',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box style={{ display: 'flex', gap: 20 }}>
                            <Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 10,
                                    }}
                                >
                                    <strong>{t('Starts')}:</strong>
                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'startDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 17,
                                        marginTop: '5px',
                                    }}
                                >
                                    <strong>{t('Ends')}:</strong>

                                    <span>
                                        <DateTimeGenericComponent
                                            data={data}
                                            attribute={{ name: 'endDateUtc' }}
                                            format={'lll'}
                                        />
                                    </span>
                                </Box>
                            </Box>

                            {/* TODO: Adding to dev flag as api to add/update time constraint is not ready */}
                            {devFeaturesEnabled && (
                                <EditTimeConstraint>
                                    <Icon name={'EditFile'} />
                                </EditTimeConstraint>
                            )}
                        </Box>
                    )
                },
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(canDeleteAccess) && (
                            <DeleteButton>
                                <Button.Delete
                                    showEndIcon={false}
                                    onClick={() =>
                                        setSelectedItemToDelete(data)
                                    }
                                />
                            </DeleteButton>
                        )
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <ListingGrid>
                <Box
                    display="flex"
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                >
                    {/* TODO: Adding to dev flag as workflow is not yet ready */}
                    {hasAccessToControl(canAddNewAccessAssignmentControlName) &&
                        devFeaturesEnabled && (
                            <Box
                                data-protectedsubcomponent={
                                    canAddNewAccessAssignmentControlName
                                }
                            >
                                <CreateWorkflowButton
                                    label={t('AddNewAccessAssignment')}
                                    workflowName="Test" //Note: This needs to be changed
                                    applicationId={applicationId}
                                    iconComponent={
                                        <Icon
                                            name={'Plus'}
                                            fill="white"
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                marginRight: '5px',
                                            }}
                                        />
                                    }
                                />
                            </Box>
                        )}

                    <Box
                        style={{
                            margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                            display: 'flex',
                            gap: 20,
                        }}
                    >
                        {hasAccessToControl(
                            canSelectAppManagementRoleControlName,
                        ) && (
                            <Box
                                data-protectedsubcomponent={
                                    canSelectAppManagementRoleControlName
                                }
                                width={'100%'}
                            >
                                <EidAutocomplete.New
                                    placeholder={t('AppManagementRole')}
                                    url={`api/ManagementRoles/application/${applicationId}`}
                                    queryParams={queryParams}
                                    dedupingInterval={60000}
                                    value={selectedRole ? selectedRole : null}
                                    getOptionLabel={getOptionLabel}
                                    endAdornmentComponent={() =>
                                        isNilOrEmpty(selectedRole) && (
                                            <ArrowIcon
                                                direction="down"
                                                color="#959598"
                                            />
                                        )
                                    }
                                    clearIcon={
                                        !isNilOrEmpty(selectedRole) && (
                                            <Icon
                                                name="Close"
                                                color="#959598"
                                                onClick={() =>
                                                    setSelectedRole(null)
                                                }
                                            />
                                        )
                                    }
                                    onChange={(_, data) => {
                                        setSelectedRole(
                                            data?.value === 'all' ? null : data,
                                        )
                                    }}
                                    rootStyleProps={{
                                        height: '40px !important',
                                    }}
                                    inputWrapperStyleProps={{
                                        height: '36px',
                                    }}
                                    optionComponent={OptionComponent}
                                    clearFilterOption={{
                                        friendlyName: 'All',
                                        value: 'all',
                                    }}
                                />
                            </Box>
                        )}
                        <Filters.TextSearch outlined />
                    </Box>
                </Box>
                <GridContainer
                    style={{
                        padding: '16px 0px',
                        marginTop: '0px',
                        backgroundColor: isSmallScreen
                            ? 'white'
                            : 'transparent',
                    }}
                >
                    <EidTableGrid
                        noDataMessage={t('NoDataMessage')}
                        pagination={pagination}
                        isLoading={isLoading}
                        isLoadingMore={isLoadingMore}
                        attributes={getAccessBasedAttributes(attributes)}
                        list={list}
                    />
                </GridContainer>
            </ListingGrid>
            {selectedItemToDelete && (
                <WarningModal
                    title={t('DeleteAssignment', {
                        resourceFriendlyName:
                            selectedItemToDelete?.resourceFriendlyName,
                        resourceTypeRoleFriendlyName:
                            selectedItemToDelete?.resourceTypeRoleFriendlyName,
                        assigneeFriendlyName:
                            selectedItemToDelete?.assigneeFriendlyName,
                    })}
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemToDelete}
                    cancel={() => setSelectedItemToDelete(null)}
                    proceeding={isDeletingAssignment}
                    proceed={handleDelete}
                />
            )}
        </Box>
    )
}

export default DirectAccessGranted
