import React from 'react'
import { Box } from '@material-ui/core'

const CardFieldGroup = ({ borderBelow, children }) => {
    return (
        <Box style={{ padding: '0 20px' }}>
            <Box
                style={{
                    borderBottom: borderBelow ? '1px solid #efeff1' : '',
                }}
                display="flex"
            >
                {children}
            </Box>
        </Box>
    )
}

export default CardFieldGroup
