import { Fragment } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import {
    useAccessRequestPolicy,
    useOwnersApproversDeputies,
    useMailboxDirectAssignedLocations,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { MOBILE_SCREEN_WIDTH } from 'packages/core/utils/constants'
import { PeopleListCard } from 'components/PeopleListCard/PeopleListCard'
import { ResponsibleParty } from 'components/ApplicationBanner/ResponsibleParties'
import { convertListToFriendlyNameCsv } from 'utils'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        section: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            flexWrap: 'wrap',
            width: '100%',
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                display: 'none',
            },
        },

        basicInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        content: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            [`@media (max-width:${MOBILE_SCREEN_WIDTH - 1}px)`]: {
                paddingBottom: theme.spacing(2),
            },
            [`@media (max-width:345px)`]: {
                flexDirection: 'column-reverse',
            },
        },
    }),
)

export const Overview = (props) => {
    const classes = useStyles()

    const { data, isLoading } = props

    const policy = useAccessRequestPolicy(data && data.requestPolicyId)
    const { data: ownersAndApprovers, isLoading: loading } =
        useOwnersApproversDeputies(data?.id)

    const { data: locations, loading: isLoadingLocations } =
        useMailboxDirectAssignedLocations(data?.id)

    const { t } = useTranslation()

    const firstSection = (
        <Fragment>
            <Box className={classes.basicInfo}>
                <Attribute>
                    <AttributeLabel>{t('FriendlyName')}</AttributeLabel>
                    <AttributeValue>{data && data.friendlyName}</AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('TechnicalName')}</AttributeLabel>
                    <AttributeValue>{data && data.name}</AttributeValue>
                </Attribute>
                <Attribute>
                    <AttributeLabel>{t('Alias')}</AttributeLabel>
                    <AttributeValue>
                        <AttributeValue>{data && data.alias}</AttributeValue>
                    </AttributeValue>
                </Attribute>
                <Attribute>
                    <AttributeLabel>{t('PrimaryEmail')}</AttributeLabel>
                    <AttributeValue>
                        {data && data.primaryEmailAddress}
                    </AttributeValue>
                </Attribute>
                <Attribute>
                    <AttributeLabel>{t('LogonName')}</AttributeLabel>
                    <AttributeValue>{data && data.logonName}</AttributeValue>
                </Attribute>
                <Attribute>
                    <AttributeLabel>{t('EmpowerIDLogin')}</AttributeLabel>
                    <AttributeValue>
                        {data && data.empowerIdLogIn}
                    </AttributeValue>
                </Attribute>
            </Box>
        </Fragment>
    )

    const secondSection = data && (
        <Box>
            <Box display="flex" flexWrap="wrap">
                <Box paddingRight="32px">
                    {data.mailboxTypeName && (
                        <Attribute>
                            <AttributeLabel>{t('MailboxType')}</AttributeLabel>
                            <AttributeValue>
                                {data.mailboxTypeName}
                            </AttributeValue>
                        </Attribute>
                    )}
                    <Attribute>
                        <AttributeLabel>{t('PublishInIAMShop')}</AttributeLabel>
                        <AttributeValue>
                            {data?.requestable ? t('Yes') : t('No')}
                        </AttributeValue>
                    </Attribute>

                    {data.resourceSystemFriendlyName && (
                        <Attribute>
                            <AttributeLabel>
                                {t('ResourceSystem')}
                            </AttributeLabel>
                            <AttributeValue>
                                {data.resourceSystemFriendlyName}
                            </AttributeValue>
                        </Attribute>
                    )}

                    <Attribute>
                        <AttributeLabel>
                            {t('DirectAssignedLocations')}
                        </AttributeLabel>
                        <AttributeValue>
                            {locations &&
                                convertListToFriendlyNameCsv(locations)}
                        </AttributeValue>
                    </Attribute>

                    {policy && (
                        <Attribute>
                            <AttributeLabel>
                                {t('AccessRequestPolicy')}
                            </AttributeLabel>
                            <AttributeValue>
                                {policy.friendlyName}
                            </AttributeValue>
                        </Attribute>
                    )}

                    {data.notes && (
                        <Attribute>
                            <AttributeLabel>{t('Notes')}</AttributeLabel>
                            <AttributeValue>{data.notes}</AttributeValue>
                        </Attribute>
                    )}
                </Box>
            </Box>
        </Box>
    )

    const renderContent = () => (
        <Fragment>
            <Box className={classes.content}>
                <Box
                    data-protectedsubcomponent={''}
                    className={classes.section}
                >
                    {firstSection}
                </Box>
                <Box
                    data-protectedsubcomponent={''}
                    className={classes.section}
                >
                    {secondSection}
                </Box>

                <Box className={classes.section}>
                    <Attribute>
                        <AttributeLabel>{t('Mailboxes_Owners')}</AttributeLabel>
                        <PeopleListCard
                            data={ownersAndApprovers?.owners}
                            isLoading={loading}
                            pageSize={3}
                        />
                    </Attribute>
                    <Attribute>
                        <AttributeLabel>{t('ResponsibleParty')}</AttributeLabel>
                        <ResponsibleParty
                            responsiblePersonId={data?.responsiblePartyId}
                        />
                    </Attribute>
                </Box>
            </Box>
        </Fragment>
    )

    return (
        <>
            {isLoading ? (
                <Box width={'100%'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <Fragment>
                    <Box className={classes.section}>{renderContent()}</Box>
                </Fragment>
            )}
        </>
    )
}
