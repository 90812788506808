import NullableBooleanFilter from '../NullableBooleanFilter'

const IncludeBasicClaimSetBooleanFilter = () => {
    const cardTitleLocaleKey = 'IncludeBasicClaimSet'
    const filterTitleLocaleKey = 'IncludeBasicClaimSet'
    const optionsConfig = {
        type: 'label',
        labelProp: 'name',
        trueLabelKey: 'Yes',
        falseLabelKey: 'No',
        nullLabelKey: 'All',
    }

    return (
        <NullableBooleanFilter
            cardTitleLocaleKey={cardTitleLocaleKey}
            filterTitleLocaleKey={filterTitleLocaleKey}
            contextProp={'includeBasicClaimSet'}
            optionsConfig={optionsConfig}
        />
    )
}

export default IncludeBasicClaimSetBooleanFilter
