import { Box, styled, Typography } from '@material-ui/core'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useApplicationPeopleByResourceId,
    useAssignRoleDefinitionForPeople,
    useUnassignRoleDefinitionsForPeople,
} from 'hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import { Icon } from 'packages/eid-icons'
import { Avatar } from 'packages/eid-ui'

import { getFullImageUrl, imagesDisabled } from 'utils'
import AddAssignment from 'components/ApplicationBanner/AddAssignments/AddAssignment'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import useSubcomponents from 'useSubcomponents'

import { ApplicationTypes } from 'utils'
import { useDebounce } from 'packages/core'
import config from 'config'
import { getFullPathFromUrl, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import { Button } from 'components'
import { PageContextProvider } from 'pageContext'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const AssignmentHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& div': {
        boxShadow: 'rgb(110, 110, 111) 0px 0px 8px 0px',
        '& img': {
            width: '30px',
            height: '30px',
        },
    },
})

const RoleSetting = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '16px',
    '& button': { height: '32px', lineHeight: '16px' },
})
const canAssignNewRoleDefinitions =
    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-AssignRoleDefinition-Control'

const AddAssignmentButton = ({
    applicationId,
    data,
    applicationType,
    resourceId,
    applicationFriendlyName,
    refetch,
}) => {
    const query = useQuery()
    const history = useHistory()

    const [assignRoles, { isLoading: isAssigning }] =
        useAssignRoleDefinitionForPeople(refetch)
    const [unAssignRoles, { isLoading: isUnAssigning }] =
        useUnassignRoleDefinitionsForPeople(refetch)

    const { t } = useTranslation()

    const handleSubmit = (selectedRights) => {
        const unAssignedIds = selectedRights
            ?.filter((el) => !el.isAssigned)
            .map((i) => i.id)
        const assignedIds = selectedRights
            ?.filter((el) => el.isAssigned)
            .map((i) => i.id)
        if (assignedIds.length > 0) {
            unAssignRoles({
                assigneeIds: [data?.id],
                localRoleIds: assignedIds,
            })
        }
        if (unAssignedIds.length > 0) {
            assignRoles({
                assigneeIds: [data?.id],
                localRoleIds: unAssignedIds,
                assigneeLocalRoleScopeIds: [],
            })
        }
    }

    const AssignmentWorkflow = (
        <Button
            onClick={() => {
                query.set('workflowName', 'AssignAzLocalRoleScope')
                query.set('returnUrl', getFullPathFromUrl())
                query.set('resourceID', resourceId)
                query.set('SelectedAssigneeID', data.id)

                history.push(
                    `${
                        config.APP_SUBPATH
                    }/applications/workflows?${query.toString()}`,
                )
            }}
            style={{
                borderRadius: '4px',
                padding: '0px 20px',
                backgroundColor: '#307fc1',
                border: 'solid 1px #307fc1',
                color: '#ffffff',
                fontSize: '14px',
                '&:hover': {
                    backgroundColor: '#307fc1',
                },
            }}
        >
            {t('RoleDefinitions')}
        </Button>
    )

    const AssignmentPopup = (
        <PageContextProvider
            key={'RoleDefinitionSharedAddAssignment' + applicationId}
        >
            <AddAssignment
                labelKey={'Edit'}
                submitButtonLabelKey={'Save'}
                searchUrl={`/api/AzureRoles/GetEligibleAppRolesByApplicationByAssignee`}
                targetAssigneeId={applicationId}
                queryParams={{
                    applicationId,
                    eligibilityAssigneeId: data?.id,
                }}
                headerText={{
                    dynamicText: applicationFriendlyName,
                    text: 'ChooseRoleDefinitions',
                }}
                listLabels={{
                    unselectedListLabel: 'RoleDefinitions',
                    selectedListLabel: 'SelectedRoleDefintions',
                }}
                searchPlaceHolder={'SearchFilterRoleDefinitions'}
                onSubmit={handleSubmit}
                submitLoader={isAssigning || isUnAssigning}
                allowRemovingAssignments={true}
            />
        </PageContextProvider>
    )

    if (
        applicationType ===
            ApplicationTypes.PbacHasAppResourcesWithoutFieldType ||
        applicationType === ApplicationTypes.PbacHasAppResourcesWithFieldType
    ) {
        return AssignmentWorkflow
    } else {
        return AssignmentPopup
    }
}

const ApplicationRoleDefinitionPeople = ({
    applicationId,
    applicationFriendlyName,
    applicationType,
    resourceId,
}) => {
    const { t } = useTranslation()
    const [searchKey, setSearchKey] = useState('')
    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const queryFilters = {
        showLocalRoles: true,
        searchTerm: debouncedSearchValue,
    }

    const { list, loading, loadingMore, pagination, refetch } =
        useApplicationPeopleByResourceId(
            applicationId,
            'api/people/allPeopleByResource',
            queryFilters,
        )

    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const attributes = [
        {
            sortable: false,
            sortBy: 'assigned',
            label: 'Assignments',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-Assignments',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        {data?.localRoleAssigned ? (
                            <Icon
                                color={'#029435'}
                                name="FilledCheck"
                                style={{ width: '20px', height: '20px' }}
                            />
                        ) : (
                            <Icon color={'#d0021b'} name="UncheckedRound" />
                        )}
                    </AssignmentHeader>
                ),
            },
        },
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'DisplayName',

            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-DisplayName',
            },
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <AssignmentHeader>
                        {!imagesDisabled && (
                            <Avatar src={getFullImageUrl(data.imageThumbUrl)} />
                        )}
                        <Typography
                            style={{ fontSize: '13px', fontWeight: 'bold' }}
                        >
                            {data.friendlyName}
                        </Typography>
                    </AssignmentHeader>
                ),
            },
        },
        {
            name: 'login',
            sortable: true,
            sortBy: 'login',
            label: 'Login',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-Login',
            },
        },
        {
            name: 'email',
            sortable: true,
            sortBy: 'email',
            label: 'Email',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-Email',
            },
        },
        {
            name: 'enabled',
            sortable: true,
            sortBy: 'enabled',
            label: 'Enabled',
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-RoleDefinitions-People-Enabled',
            },

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <Typography
                        style={{ fontSize: '13px', fontWeight: 'normal' }}
                    >
                        {data.enabled ? t('Yes') : t('No')}
                    </Typography>
                ),
            },
        },

        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <RoleSetting>
                            {hasAccessToControl(
                                canAssignNewRoleDefinitions,
                            ) && (
                                <AddAssignmentButton
                                    applicationId={applicationId}
                                    data={data}
                                    applicationType={applicationType}
                                    resourceId={resourceId}
                                    applicationFriendlyName={
                                        applicationFriendlyName
                                    }
                                    refetch={refetch}
                                />
                            )}
                        </RoleSetting>
                    )
                },
            },
        },
    ]

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="flex-end"
                padding="16px 0px"
            >
                <Box width="240px">
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default ApplicationRoleDefinitionPeople
