import React from 'react'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'
import { Box, styled } from '@material-ui/core'
import { mobileScreenWidth } from 'utils'
import { useIsSmallScreenFalse } from 'packages/core'

const Container = styled(Box)({
    marginTop: '24px',
    [`@media (max-width:${mobileScreenWidth}) `]: {
        display: 'none',
    },
})

const AppGlobalMenu = ({ hideSideNavigation }) => {
    const isSmallScreen = useIsSmallScreenFalse()
    return isSmallScreen ? (
        <MobileMenu hideMSAppsMenu={hideSideNavigation} />
    ) : (
        !hideSideNavigation && (
            <Container>
                <DesktopMenu />
            </Container>
        )
    )
}

export default AppGlobalMenu
