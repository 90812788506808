import {
    Box,
    Typography,
    styled,
    Dialog,
    makeStyles,
    FormControlLabel,
} from '@material-ui/core'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { useGetAllCountries, useManageNationPersistence } from 'hooks'
import { Checkbox, Select, TextInput } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useState } from 'react'

export const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-24px',
        right: '-24px',
        cursor: 'pointer',
    },
})

const CountryModal = styled(Box)({
    maxWidth: '450px',
    width: '100%',
    background: '#fff',
    border: 'solid 1px #ebebed',
    padding: '40px',

    '& h2': {
        fontSize: '24px',
        color: '#282828',
    },
})

export const ModalFooter = styled(Box)({
    display: 'flex',
    marginTop: '40px',
    '& button': {
        border: 'solid 1px #307fc1',
        padding: '4px 16px 4px 13px',
        width: '100%',
        borderRadius: '4px',
        outline: 'none',
        height: '32px',
        fontSize: '14px',
        '& svg': {
            marginLeft: '8px',
        },
        '&:nth-child(1)': {
            background: '#307fc1',
            color: '#fff',
            maxWidth: '130px',
        },
        '&:nth-child(2)': {
            color: '#307fc1',
            marginLeft: '16px',
            maxWidth: '90px',
        },
    },
})

export const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '450px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})
const useFormControlStyles = makeStyles(() => ({
    label: {
        fontSize: '13px',
    },
}))

const CountrySelect = styled(Box)({
    marginTop: '35px',
    '& .Mui-disabled': {
        backgroundColor: '#ebebed !important',
        boxShadow: 'none',
    },
})

const AddCountry = ({
    data,
    labelKey,
    startIcon,
    headerKey,
    isEditMode = false,
    mailboxId = '',
}) => {
    const { t } = useTranslation()

    const classes = useFormControlStyles()
    const [openModal, setOpenModal] = useState(false)
    const [country, setCountry] = useState(isEditMode ? data : null)
    const [isDenied, setIsDenied] = useState(
        isEditMode ? Boolean(data?.denied) : false,
    )
    const { list: countries, loading } = useGetAllCountries(
        openModal && !isEditMode,
    )

    const [updateNation, { isLoading: isUpdating }] =
        useManageNationPersistence()
    const handleModalClose = () => {
        setOpenModal(false)
        if (!isEditMode) {
            setCountry(null)
            setIsDenied(false)
        }
    }
    const handleSaveChanges = () => {
        if (isEditMode) {
            updateNation([
                {
                    nationId: country?.nationId,
                    action: 'Update',
                    denied: isDenied,
                    resourceId: country?.resourceId,
                },
            ]).then(() => handleModalClose())
        } else {
            updateNation([
                {
                    nationId: country?.id,
                    action: 'Insert',
                    denied: isDenied,
                    resourceId: mailboxId,
                },
            ]).then(() => handleModalClose())
        }
    }

    return (
        <>
            <Button
                onClick={() => setOpenModal(true)}
                style={{
                    borderRadius: '4px',
                    backgroundColor: '#307fc1',
                    color: '#ffffff',
                    fontSize: '14px',
                    height: '100%',
                    '&:hover': {
                        backgroundColor: '#307fc1',
                    },
                }}
            >
                <Box style={{ display: 'flex' }}>
                    {startIcon}
                    {t(labelKey)}
                </Box>
            </Button>
            <CustomDialog
                open={openModal}
                onClose={() => handleModalClose()}
                title={t('')}
            >
                <CountryModal>
                    <CloseIconeBox onClick={() => handleModalClose()}>
                        <Icon
                            name="Close"
                            width="12px"
                            height="13px"
                            color="#959598"
                        />
                    </CloseIconeBox>

                    <Typography variant="h2">{t(headerKey)}</Typography>
                    {
                        <CountrySelect>
                            {!isEditMode ? (
                                <Select
                                    options={countries}
                                    onChange={(data) => {
                                        setCountry(data)
                                    }}
                                    value={country}
                                    getOptionLabel={(option) =>
                                        option.friendlyName
                                    }
                                    label={t('SelectCountry')}
                                    isLoading={loading}
                                    placeholder={t('SelectCountry')}
                                />
                            ) : (
                                <TextInput
                                    value={country.nationFriendlyName}
                                    disabled
                                />
                            )}
                            <Box marginLeft={'12px'} marginTop={'16px'}>
                                <FormControlLabel
                                    classes={{ label: classes.label }}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={(e) => {
                                                const checked = e.target.checked
                                                setIsDenied(checked)
                                            }}
                                            checked={isDenied}
                                        />
                                    }
                                    label={t('Denied')}
                                />
                            </Box>
                        </CountrySelect>
                    }

                    <ModalFooter>
                        <Button
                            loading={isUpdating}
                            onClick={() => {
                                handleSaveChanges()
                            }}
                            style={{
                                height: '48px !important',
                            }}
                            backgroundColor="#307fc1"
                            disabledStyles={{
                                backgroundColor: '#aab0b4 !important',
                                color: '#ffffff !important',
                                borderColor: '#aab0b4 !important',
                            }}
                            disabled={
                                (isEditMode && data?.denied === isDenied) ||
                                (!isEditMode && !country?.id)
                            }
                        >
                            {isEditMode ? t('Save') : t('AddCountry')}
                        </Button>
                        <Button
                            style={{
                                height: '48px !important',
                            }}
                            onClick={() => handleModalClose()}
                        >
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </CountryModal>
            </CustomDialog>
        </>
    )
}

export default AddCountry
