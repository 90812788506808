import { useAxios } from 'packages/core'
import { usePageContext } from 'pageContext'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import { useQuery, useMutation, queryCache } from 'react-query'
import { useNotification } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

export const MANAGEMENT_ROLES_KEY = 'MANAGEMENT ROLES'

export const useResourceAccessGranted = (
    id: any,
    resourceTypeId: any,
    isEnabled: true,
    queryFilters: any,
) => {
    const callApi = useAxios()
    const queryData: any = {}

    if (queryFilters) {
        queryData.searchTerm = queryFilters
    }
    return useApiWithInfiniteScroll(
        [MANAGEMENT_ROLES_KEY, 'ACCESS_GRANTED', id, queryFilters],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/AssigneeAssignments/accessGrantedResources/${id}/${resourceTypeId}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: isEnabled,
        },
    )
}

export const useManagementRoleLinkedApplications = (
    id: any,
    isEnabled: true,
    queryFilters: any,
) => {
    const callApi = useAxios()
    const queryData: any = {}

    if (queryFilters) {
        queryData.searchTerm = queryFilters
    }
    return useApiWithInfiniteScroll(
        [MANAGEMENT_ROLES_KEY, 'LINKED_APPLICATIONS', id, queryFilters],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/ManagementRoles/linkedApplications/${id}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: isEnabled,
        },
    )
}

export const refreshManagementRoleMembersListing = (
    managementRoleId: any,
    applicationId: any,
) => {
    if (applicationId) {
        queryCache.invalidateQueries(
            (c) =>
                c.queryKey.includes(
                    'LIST_MY_APPLICATION_MANAGEMENT_ROLES_MEMBERS_BY_APP_ID',
                ) && c.queryKey.includes(applicationId),
        )
    } else {
        queryCache.invalidateQueries(
            (c) =>
                (c.queryKey.includes(MANAGEMENT_ROLES_KEY) &&
                    c.queryKey.includes('MEMBERS') &&
                    c.queryKey.includes(managementRoleId)) ||
                (c.queryKey.includes(
                    'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_MEMBERS',
                ) &&
                    c.queryKey.includes(managementRoleId)),
        )
    }
}
export const refreshListing = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes(`LIST_MY_APPLICATION_MANAGEMENT_ROLES`),
    )
}

export const useManagementRoleAllMembers = (id: any, queryFilters: any) => {
    const callApi = useAxios()
    const queryData: any = {}

    if (queryFilters.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }
    if (queryFilters.sorting) {
        queryData.sortBy = queryFilters.sorting.sortBy
        queryData.desc =
            queryFilters.sorting.sortOrder === 'desc' ? true : false
    }

    return useApiWithInfiniteScroll(
        [`${MANAGEMENT_ROLES_KEY}_ALLMEMBERS`, id, queryData],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/ManagementRoles/members/${id}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useDeleteManagementRoleMember = (
    managementRoleId: any,
    applicationId: any,
) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (personMgmtRolesToRemove) =>
            callApi({
                method: 'POST',
                url: `/api/ManagementRoles/removeMember`,
                data: { personMgmtRolesToRemove },
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('PersonsDeleted'))
                refreshManagementRoleMembersListing(
                    managementRoleId,
                    applicationId,
                )
            },
            onError: () => {
                showWarningMessage(t('PersonsNotDeleted'))
            },
        },
    )
}

export const useManagementRoleDirectMembers = (
    id: any,
    isDirect: boolean,
    queryFilters: any,
) => {
    const callApi = useAxios()
    const queryData: any = {}

    if (queryFilters.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }
    if (queryFilters.sorting) {
        queryData.sortBy = queryFilters.sorting.sortBy
        queryData.desc =
            queryFilters.sorting.sortOrder === 'desc' ? true : false
    }

    const endpoint = isDirect
        ? `/api/ManagementRoles/DirectMembers/${id}`
        : `/api/ManagementRoles/members/${id}`

    return useApiWithInfiniteScroll(
        [MANAGEMENT_ROLES_KEY, 'MEMBERS', id, isDirect, queryData],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useManagementRole = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [MANAGEMENT_ROLES_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ManagementRoles/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useManagementRoles = () => {
    const [state]: any = usePageContext()
    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        status,
        itemType,
        accountStore,
        accountStoreUsageType,
        ownedBy,
        location,
        businessFunction,
        rights,
        application,
        shouldLoadTags,
        advancedSearchTags,
        showOnlyAzureApplications,
        enableOwnedByApiCall,
    } = state
    const ownerPersonId = ownedBy?.id
    const description = state['advancedSearch.forms.description']
    const friendlyName = state['advancedSearch.forms.friendlyName']
    const callApi = useAxios()
    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (accountStoreUsageType) {
        queryData.accountStoreUsageTypeId = accountStoreUsageType.id
    }
    if (accountStore) {
        queryData.accountStoreId = accountStore.id
    }
    if (application) {
        queryData.applicationId = application.id
    }
    if (location) {
        queryData.locationId = location.id
    }
    if (businessFunction) {
        queryData.azGlobalFunctions = businessFunction
            .map((x: any) => x.id)
            .join()
    }
    if (rights) {
        queryData.rights = rights.id
    }
    if (shouldLoadTags) {
        queryData.includeTags = true
    }

    if (advancedSearchTags) {
        queryData.tags = advancedSearchTags
    }

    const advancedSearch = []
    if (description) {
        advancedSearch.push({
            name: 'Description',
            type: 'string',
            value: description,
        })
    }
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }

    queryData.advancedSearch = advancedSearch

    const endpoint = ownerPersonId
        ? `/api/ManagementRoles/owned/${ownerPersonId}`
        : `api/ManagementRoles/all`

    return useApiWithInfiniteScroll(
        [
            MANAGEMENT_ROLES_KEY,
            'LIST_MY_MANAGEMENTROLES',
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            status,
            itemType,
            description,
            friendlyName,
            ownerPersonId,
            application,
            location,
            businessFunction,
            rights,
            accountStore,
            accountStoreUsageType,
            advancedSearchTags,
            shouldLoadTags,
            showOnlyAzureApplications,
            enableOwnedByApiCall,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    showOnlyAzureApplications,
                },
            })
        },
        {
            enabled: enableOwnedByApiCall,
        },
    )
}
export const useDeleteManagementRole = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (roleId) =>
            callApi({
                method: 'DELETE',
                url: `api/ManagementRoles/deleteManagementRole/${roleId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ManagementRoleDeleted'))
                refreshListing()
            },
            onError: () => {
                showWarningMessage(t('ManagementRoleNotDeleted'))
            },
        },
    )
}

export const useAppManagementRoleTotalAccessGranted = (roleId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `api/ManagementRoles/totalAccessGranted/${roleId}`

    return useApiWithInfiniteScroll(
        [
            MANAGEMENT_ROLES_KEY,
            'TOTAL_ACCESS_GRANTED',
            sortBy,
            sortOrder,
            searchTerm,
            roleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}
