import { PersonDetails } from 'components/ApplicationBanner/PersonDetails'

const tableAttributes = [
    {
        name: 'enabled',
        hideValue: true,
        label: 'Enabled',
        component: {
            componentName: 'BooleanIcon',
        },
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-People-List-Enabled',
        },
    },
    {
        name: 'friendlyName',
        sortable: true,
        sortBy: 'friendlyName',
        label: 'DisplayName',
        moreInfoIcon: true,
        hideValue: true,
        isHeader: true,
        showInCard: false,

        component: {
            componentName: 'renderer',
            render: (data) => {
                return (
                    <PersonDetails
                        size="medium"
                        person={{
                            imageThumbUrl: data.imageThumbUrl,
                            friendlyName: data.friendlyName,
                            email: data.email,
                            telephone: data.telephone,
                        }}
                    />
                )
            },
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
            fontWeight: 'bold',
        },
        requireAccess: {
            control: 'ResourceAdmin-People-List-DisplayName',
        },
    },
    {
        name: 'businessRole',
        sortable: true,
        sortBy: 'businessRole',
        label: 'Role',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-People-List-Role',
        },
    },
    {
        name: 'managerId',
        sortable: true,
        sortBy: 'managerId',
        label: 'Manager',
        moreInfoIcon: true,
        hideValue: true,
        component: {
            componentName: 'renderer',
            render: (data) => {
                return data.managerId ? (
                    <PersonDetails
                        size="medium"
                        person={{
                            imageThumbUrl: data.managerImageThumbUrl,
                            friendlyName: data.managerFriendlyName,
                            email: data.managerEmail,
                            telephone: data.managerTelephone,
                        }}
                    />
                ) : (
                    '-'
                )
            },
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
            fontWeight: 'bold',
        },
        requireAccess: {
            control: 'ResourceAdmin-People-List-ManagerId',
        },
    },
    {
        name: 'login',
        sortable: true,
        sortBy: 'login',
        label: 'Login',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-People-List-Login',
        },
    },
    {
        name: 'email',
        sortable: true,
        sortBy: 'email',
        label: 'Email',
        headerCellStyles: {
            fontSize: '13px',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
        },
        requireAccess: {
            control: 'ResourceAdmin-People-List-Email',
        },
    },
    {
        hideValue: true,
        showInCard: false,
        component: {
            componentName: 'actionButtons',
        },
    },
]

//TODO: Add detail page attributes once detail page is ready
const detailAttributes = [
    {
        label: 'FriendlyName',
        value: 'friendlyName',
        firstPane: true,
    },
]

const accessRequestAttributes = {
    general: [
        {
            label: 'FriendlyName',
            value: 'friendlyName',
        },
        {
            label: 'DisplayName',
            value: 'displayName',
        },
        {
            label: 'Description',
            value: 'description',
        },
        {
            label: 'AllowAccessRequests',
            value: 'isRequestAllowed',
        },
        {
            label: 'ApprovalPolicy',
            value: 'aprovalPolicy',
        },
        {
            label: 'FullfillmentDelay(Hrs)',
            value: 'delay',
        },
    ],
    timeRestriction: [
        {
            label: 'TimeRestrictAccess',
            value: 'isTimeConstrained',
        },
        {
            label: 'DefaultAccessDuration(Min)',
            value: 'defaultValueInMinutes',
        },
        {
            label: 'MaxDuration(Min)',
            value: 'maxDuration',
        },
        {
            label: 'AllowDurationSelection',
            value: 'isDurationSelectable',
        },
    ],
}

const attributes = {
    tableAttributes,
    detailAttributes,
    accessRequestAttributes,
}

export default attributes
