import { useTranslation } from 'react-i18next'
import { AttributeSections } from 'components/AttributeSections'

export const MailboxSettings = (props) => {
    const { t } = useTranslation()
    const attributes = [
        // NOTE: Changing the structure of attributes to divide them in sections as per design
        [
            {
                label: 'Requestable',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.requestable ? t('True') : t('False')
                    },
                },
            },
            {
                label: 'OWAEnabled',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.owaEnabled ? t('True') : t('False')
                    },
                },
            },
            {
                label: 'POPEnabled',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.popEnabled ? t('True') : t('False')
                    },
                },
            },
        ],
        [
            {
                label: 'AutoAcceptCalendar',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.autoAcceptCalendar ? t('True') : t('False')
                    },
                },
            },
            {
                label: 'HideInGal',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.hideInGAL ? t('True') : t('False')
                    },
                },
            },
            {
                label: 'ImapEnabled',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.imapEnabled ? t('True') : t('False')
                    },
                },
            },
        ],
        [
            {
                label: 'ActiveSyncEnabled',
                component: {
                    componentName: 'renderer',
                    render: (data) => {
                        return data?.activeSyncEnabled ? t('True') : t('False')
                    },
                },
            },
            {
                name: 'capacity',
                label: 'Capacity',
            },
            {
                label: 'mapiEnabled',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.mapi ? t('True') : t('False')
                    },
                },
            },
        ],
    ]

    const { mailbox } = props

    return <AttributeSections data={mailbox} attributes={attributes} />
}
