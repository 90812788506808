import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { ListingGrid, GridContainer, Filters, Button } from 'components'
import useSubcomponents from 'useSubcomponents'
import { Icon } from 'packages/eid-icons'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useDeleteCountry, useMailBoxAllowedDeniedCountries } from 'hooks'
import AddCountry from './AddNewCountry'
import { PageContextProvider } from 'pageContext'
import { WarningModal } from 'packages/eid-ui'
import { useState } from 'react'

const canDeleteRCountryControlName =
    'ResourceAdmin-MailboxDetails-DeleteCountry-Control'
const canSeeEditButtonControlName =
    'ResourceAdmin-MailboxDetails-ViewDetailCountry-Control'

const DeleteCountry = ({ data }) => {
    const [showConfirm, setConfirm] = useState(false)
    const [deleteCountry, { isLoading: isDeleting }] = useDeleteCountry()
    const handleDelete = () => {
        deleteCountry(data?.id)
    }
    const { t } = useTranslation()
    return (
        <>
            <Button.Delete
                showEndIcon={false}
                data-protectedsubcomponent={canDeleteRCountryControlName}
                onClick={() => setConfirm(true)}
            />
            <WarningModal
                title={t('Delete') + ' ' + data?.nationFriendlyName}
                description={t('PermissionDeleteMessage')} //its a common message
                yesLabel={t('Delete')}
                noLabel={t('Cancel')}
                open={showConfirm}
                cancel={() => setConfirm(false)}
                proceeding={isDeleting}
                proceed={handleDelete}
            />
        </>
    )
}

const AllowedDeniedCountries = (props) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes, hasAccessToControl } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useMailBoxAllowedDeniedCountries(props?.mailBoxId)

    const attributes = [
        {
            name: 'nationFriendlyName',
            label: 'NationDisplayName',
            sortable: true,

            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-AllowedDeniedCountries-NationDisplayName',
            },
        },
        {
            sortable: true,
            sortBy: 'denied',
            label: 'Allowed',
            hideValue: true,
            requireAccess: {
                control:
                    'ResourceAdmin-MailboxDetails-AllowedDeniedCountries-Denied',
            },
            component: {
                componentName: 'renderer',
                render: (data) => (
                    <>
                        {!data?.denied ? (
                            <Icon
                                style={{ height: '20px', width: '20px' }}
                                color={'#029435'}
                                name="FilledCheck"
                            />
                        ) : (
                            <Icon color={'#d0021b'} name="UncheckedRound" />
                        )}
                    </>
                ),
            },
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box
                            style={{
                                display: 'flex',
                                gap: 10,
                                justifyContent: 'end',
                            }}
                        >
                            {hasAccessToControl(
                                canSeeEditButtonControlName,
                            ) && (
                                <PageContextProvider
                                    key={data.id + data.denied}
                                >
                                    {data && (
                                        <AddCountry
                                            data={data}
                                            labelKey={'Edit'}
                                            headerKey={'EditCountry'}
                                            isEditMode={true}
                                        />
                                    )}
                                </PageContextProvider>
                            )}
                            {hasAccessToControl(
                                canDeleteRCountryControlName,
                            ) && <DeleteCountry data={data} />}
                        </Box>
                    )
                },
            },
        },
    ]

    return (
        <ListingGrid>
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <PageContextProvider key={'AddNewCountry'}>
                        <AddCountry
                            labelKey={'AddNewCountry'}
                            headerKey={'AddNewCountry'}
                            mailboxId={props?.mailBoxId}
                            startIcon={
                                <Icon
                                    name={'Plus'}
                                    fill="white"
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '5px',
                                    }}
                                />
                            }
                        />
                    </PageContextProvider>
                </Box>
                <Box width="308px">
                    <Filters.TextSearch
                        outlined
                        placeholder={'SearchAndFilter'}
                    />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: 'transparent',
                }}
            >
                <EidTableGrid
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </GridContainer>
        </ListingGrid>
    )
}

export default AllowedDeniedCountries
