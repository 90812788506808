import React, { Fragment } from 'react'
import {
    TableHead,
    TableSortLabel,
    TableRow,
    TableCell,
    Box,
    makeStyles,
    styled,
    Table,
} from '@material-ui/core'
import { Checkbox, Tooltip } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import { useTranslation } from 'react-i18next'
import { TextFormatter } from 'components'
import { Icon } from 'packages/eid-icons'
import DynamicComponents from 'components/DynamicComponents'

const maxCharacters = 100

const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
    black: '#282828',
}

const useHeaderRowStyles = makeStyles({
    root: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        padding: '23px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
        borderBottom: '1px solid',
    },
})

const useSortLabelStyles = makeStyles({
    root: {
        color: '#7d7c7c !important',
        '& svg': {
            color: `#D2D2D9 !important`,
            height: '14px',
            width: '14px',
        },
        '&:hover': {
            color: `${colors.primary} !important`,

            '& svg': {
                color: `${colors.primary} !important`,
            },
        },
    },

    active: {
        '& svg': {
            color: `${colors.primary} !important`,
        },
    },
    icon: {
        opacity: `${1} !important`,
    },
})

const useHeaderCellStyles = makeStyles({
    root: () => ({
        padding: '15px 10px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: 'normal',
        height: '62px',
        fontSize: '13px',
        '&:first-child': {
            paddingLeft: '20px',
        },
        '&:last-child': {
            paddingRight: '20px',
        },
    }),
})
const headercheckbox = {
    padding: '15px 10px',
    paddingLeft: '20px',
    textAlign: 'center',
}

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

export const ListTableHead = ({
    attributes,
    data,
    handleSelectAllClick,
    selected,
    rowSelection,
}) => {
    const { t } = useTranslation()
    const [{ sortBy, sortOrder }, dispatch] = usePageContext()

    const headerRowClasses = useHeaderRowStyles()
    const headerCellClasses = useHeaderCellStyles()
    const sortLabelClasses = useSortLabelStyles()

    const handleSort = (heading) => {
        const sortByName = heading.sortBy ? heading.sortBy : heading.name
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: {
                sortBy: sortByName,
                sortOrder:
                    sortBy !== sortByName
                        ? 'desc'
                        : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                        ? 'desc'
                        : 'asc',
            },
        })
    }

    return (
        <TableHead>
            <TableRow classes={headerRowClasses}>
                {rowSelection && (
                    <TableCell padding="checkbox" style={headercheckbox}>
                        <Checkbox
                            icon={<Icon name="CheckBox" />}
                            disabled={!data?.length}
                            checkedIcon={<Icon name="CheckedBox" />}
                            checked={
                                data?.length > 0 &&
                                selected.length === data?.length
                            }
                            onChange={handleSelectAllClick}
                        />
                    </TableCell>
                )}
                {attributes.map((heading, index) => {
                    const sortByName = heading.sortBy
                        ? heading.sortBy
                        : heading.name
                    return (
                        <TableCell
                            data-protectedsubcomponent={
                                heading?.requireAccess?.control
                                    ? heading.requireAccess.control
                                    : 'NotProtected'
                            }
                            key={'pd-head-cell-' + index}
                            classes={headerCellClasses}
                            align="left"
                            style={heading.headerCellStyles}
                        >
                            {!heading.sortable ? (
                                <span
                                    style={{
                                        display: 'flex',
                                        color: '#7d7c7c',
                                    }}
                                >
                                    {t(heading.label)}
                                </span>
                            ) : (
                                <span style={{ display: 'flex' }}>
                                    <Tooltip
                                        title={
                                            sortBy === sortByName &&
                                            sortOrder === 'desc'
                                                ? t('SortAscending')
                                                : t('SortDescending')
                                        }
                                    >
                                        <TableSortLabel
                                            classes={sortLabelClasses}
                                            active={sortBy === sortByName}
                                            direction={
                                                sortBy === sortByName
                                                    ? sortOrder
                                                    : 'asc'
                                            }
                                            onClick={() => handleSort(heading)}
                                        >
                                            {t(heading.label)}
                                        </TableSortLabel>
                                    </Tooltip>
                                </span>
                            )}
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}
const useRowStyles = makeStyles({
    root: {
        width: '150px',
        backgroundColor: '#ffffff',
        borderTopLeftRadius: '8px !important',
        borderTopRightRadius: '8px !important',
        minHeight: '40px',
        border: ' solid 1px #efeff1',

        '&:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
})

const useRowImageStyles = makeStyles({
    image: {
        maxHeight: '48px',
    },
})

const useCellStyles = makeStyles({
    root: () => ({
        padding: '15px 10px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        lineHeight: 'normal',
        height: '62px',
        '&:first-child': {
            paddingLeft: '20px',
        },
        '&:last-child': {
            paddingRight: '20px',
        },
    }),
})

const StyledTable = styled(Table)({
    border: ' solid 1px #efeff1 !important',
    borderCollapse: 'separate',
})

const contentCheckbox = {
    padding: '15px 10px',
    paddingLeft: '20px',
    textAlign: 'center',
}

export const ListTable = StyledTable

export const ListTableRow = ({
    attributes,
    item,
    rowSelection,
    selected,
    handleClick,
}) => {
    const rowClasses = useRowStyles()
    const imageClasses = useRowImageStyles()
    const cellClasses = useCellStyles()

    const renderComponent = (a, item, index) => {
        if (a.component.componentName === 'renderer') {
            return <Fragment>{a.component.render(item, index)}</Fragment>
        } else {
            const DynamicComponentToRender =
                DynamicComponents[a.component.componentName]
            return (
                <DynamicComponentToRender
                    attribute={a}
                    data={{ item }}
                    imageClass={imageClasses.image}
                />
            )
        }
    }

    const isSelected = (id) => selected.indexOf(id) !== -1
    return (
        <React.Fragment>
            <TableRow classes={rowClasses}>
                {rowSelection && (
                    <TableCell padding="checkbox" style={contentCheckbox}>
                        <Checkbox
                            icon={<Icon name="CheckBox" />}
                            checkedIcon={<Icon name="CheckedBox" />}
                            checked={isSelected(item.id)}
                            onClick={() => handleClick(item)}
                        />
                    </TableCell>
                )}
                {attributes.map((a, index) => {
                    return (
                        <TableCell
                            key={item.id + `${index}`}
                            classes={cellClasses}
                            style={a.styles}
                            link={a.link}
                            url={a.url}
                            sx={{ padding: '23px' }}
                        >
                            <Box
                                display="block"
                                alignItems="center"
                                width={a.styles?.width}
                            >
                                {!a.hideValue && (
                                    <TextFormatter
                                        value={item[a.name]}
                                        tooltipProps={tooltipProps}
                                        style={{
                                            fontSize: '13px',
                                            ...a.styles,
                                        }}
                                        maxCharacters={maxCharacters}
                                    />
                                )}

                                {a.component && renderComponent(a, item, index)}
                            </Box>
                        </TableCell>
                    )
                })}
            </TableRow>
        </React.Fragment>
    )
}
