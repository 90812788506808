/* eslint-disable default-case, no-param-reassign */

import { getKeyFromSessionStorage } from 'packages/core'

export const initialState = {
    masterPassword: getKeyFromSessionStorage('MasterPassword'),
    searchKey: '',
    appConfig: null,
    devFeaturesEnabled: false,
    itemsView: 'table',
    currentResource: null,
    targetResource: null,
}
const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                ...payload,
            }

        case 'SET_PROP':
            return {
                ...state,
                [payload.key]: payload.value,
            }
        case 'SET_MASTER_PASSWORD':
            sessionStorage.setItem('MasterPassword', payload)
            return {
                ...state,
                masterPassword: payload,
            }
        case 'SET_APP_CONFIG':
            return {
                ...state,
                appConfig: payload,
            }
        case 'SET_TARGET_PERSON':
            return {
                ...state,
                targetPerson: payload,
            }
        case 'ENABLE_DEV_FEATURES':
            return {
                ...state,
                devFeaturesEnabled: true,
            }
        case 'SET_ITEMS_VIEW':
            return {
                ...state,
                itemsView: payload,
            }
        case 'SET_CURRENT_RESOURCE':
            return {
                ...state,
                currentResource: payload,
            }
        case 'SET_TARGET_RESOURCE':
            return {
                ...state,
                targetResource: payload,
            }
        default:
            return state
    }
}

export default reducer
