import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, makeStyles, styled } from '@material-ui/core'
import {
    useDeleteManagementRoleMember,
    useManagementRoleDirectMembers,
} from 'hooks'
import { EidButton, Avatar, ToggleSwitch, WarningModal } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useDebounce } from 'packages/core'
import TableGrid from 'components/TableGrid'
import { ActionsButton } from 'components'
import { getFullImageUrl, imagesDisabled } from 'utils'
import DateTimeGenericComponent from './../../../../components/DynamicComponents/DateTimeGenericComponent'
import Search from 'components/Filters/Search'
import PeopleDetails from 'components/DynamicComponents/PeopleDetails'
import { PageContextProvider } from 'pageContext'
import AddAssignment from 'components/ApplicationBanner/AddAssignments/AddAssignment'
import useSubcomponents from 'useSubcomponents'

const canChangeBetweenDirectAndResultantMembersControlName =
    'ResourceAdmin-ManagementRoleDetails-Members-ChangeMembersType-Control'

const useTableStyles = makeStyles({
    headGrey: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
        height: '80px',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 16px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: '#7d7c7c',
    },
})

export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '10px',
    },
}))

export const PeopleAsMembers = ({ managementRole }) => {
    const { t } = useTranslation()
    const { hasAccessToControl } = useSubcomponents()
    const tableClasses = useTableStyles()
    const [selected, setSelected] = useState([])
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [searchKey, setSearchKey] = useState('')
    const [isDirect, setIsDirect] = useState(true)

    const [sorting, setSorting] = useState({ sortOrder: null, sortBy: null })
    const selectedCount = selected.length
    const isSelected = selectedCount > 0

    const [deleteMember, { isLoading: isDeletingMember }] =
        useDeleteManagementRoleMember(managementRole?.id)

    const handleSort = (sorting) => {
        setSorting(sorting)
    }

    const handleSelect = (selectedArray) => {
        setSelected(selectedArray)
    }

    const handleBulkAction = () => {
        setShowWarningModal(true)
    }

    const handleDelete = () => {
        let personMgmtRolesToRemove = []
        selected.forEach((personId) =>
            personMgmtRolesToRemove.push({
                personID: personId,
                managementRoleID: managementRole?.id,
            }),
        )
        deleteMember(personMgmtRolesToRemove)
        setShowWarningModal(false)
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
        sorting,
    }

    const {
        list,
        loading: isLoading,
        pagination,
    } = useManagementRoleDirectMembers(managementRole.id, isDirect, queryData)

    useEffect(() => {
        setSelected([])
    }, [list])

    const attributes = [
        {
            label: t(''),
            name: 'image',
            image: true,
            style: {
                width: '40px',
            },
            resolve: (item) => (
                <Avatar
                    size="medium"
                    src={getFullImageUrl(item.imageThumbUrl)}
                    style={{ boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px' }}
                />
            ),
        },
        {
            label: t('DisplayName'),
            name: 'friendlyName',
            sortable: true,
            style: {
                width: '200px',
                fontWeight: 'bold',
            },
            showText: true,
            resolve: (item, attribute) => (
                <PeopleDetails data={item} attribute={attribute} />
            ),
        },
        {
            label: t('Login'),
            name: 'login',
            sortable: true,
            style: {
                width: '160px',
            },
        },
        {
            name: 'company',
            label: t('Company'),
            sortable: true,
            style: {
                width: '100px',
            },
        },
        {
            name: 'email',
            label: t('Email'),
            sortable: true,
        },
        {
            name: 'enabled',
            label: t('Enabled'),
            sortable: true,
            resolve: (item) =>
                item.enabled === null ? '-' : item.enabled ? t('Yes') : t('No'),
        },
        {
            name: 'lastAttestationDateTimeUtc',
            label: t('LastCertified'),
            sortable: true,
            resolve: (item) => (
                <DateTimeGenericComponent
                    data={item}
                    attribute={{ name: 'lastAttestationDateTimeUtc' }}
                />
            ),
        },
        {
            label: t(''),
            name: 'actions',
            resolve: (item) => <ActionsButton item={item} disabled />,
            style: {
                width: '60px',
            },
        },
    ]

    const filteredAttributes = attributes.filter(
        (x) => !x.image || !imagesDisabled,
    )
    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                <PageContextProvider key="AddNewMembersManagementRoles">
                    <AddAssignment
                        resourceType={'ManagementRoles'}
                        labelKey={'AddNewPeopleAsMembers'}
                        searchUrl={`api/people`}
                        targetAssigneeId={managementRole.id}
                    />
                </PageContextProvider>

                <Box display="flex">
                    {hasAccessToControl(
                        canChangeBetweenDirectAndResultantMembersControlName,
                    ) && (
                        <Box
                            data-protectedsubcomponent={
                                canChangeBetweenDirectAndResultantMembersControlName
                            }
                            style={{ marginRight: '20px' }}
                        >
                            <SwitchContainer value={!isDirect}>
                                <Typography className="Label">
                                    {t('Direct')}
                                </Typography>
                                <ToggleSwitch
                                    value={!isDirect}
                                    onChange={() => {
                                        setIsDirect((p) => !p)
                                    }}
                                />

                                <Typography className="SubLabel">
                                    {t('Resultant')}
                                </Typography>
                            </SwitchContainer>
                        </Box>
                    )}

                    <Box width="308px" display="flex" alignItems="center">
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchFilterMembers')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </Box>
                </Box>
            </Box>

            <TableGrid
                loading={isLoading}
                data={list}
                attributes={filteredAttributes}
                headings={filteredAttributes.map((a) => a.label)}
                sorting={sorting}
                onSort={handleSort}
                onSelect={handleSelect}
                selected={selected}
                tableClasses={tableClasses}
                rowSelection={isDirect}
            />

            <Box
                display="flex"
                paddingTop="40px"
                justifyContent="space-between"
            >
                <Box width="300px">
                    {isDirect && (
                        <EidButton.DrawerActionButton
                            disabled={!isSelected}
                            fontColor="#ffffff"
                            bgColor="#E40000"
                            width="100%"
                            onClick={handleBulkAction}
                            loading={isDeletingMember}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                height={'12px'}
                                width={'12px'}
                            >
                                <Icon
                                    name="DeleteX"
                                    fill="#ffffff"
                                    color="#ffffff"
                                />
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                marginLeft="10px"
                            >
                                <Typography style={{ color: '#ffffff' }}>
                                    {t('RemoveSelectedMembers', {
                                        count: selectedCount,
                                    })}
                                </Typography>
                            </Box>
                        </EidButton.DrawerActionButton>
                    )}
                </Box>
                <Box style={{ marginBottom: '60px' }}>{pagination}</Box>
            </Box>
            <WarningModal
                title={t('DeleteMembers')}
                description={t('MembersDeleteMessage')}
                yesLabel={t('Delete')}
                noLabel={t('Cancel')}
                open={showWarningModal}
                cancel={() => setShowWarningModal(false)}
                proceeding={isDeletingMember}
                proceed={handleDelete}
            />
        </>
    )
}
