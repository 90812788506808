import { RiskIndicator } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import TableGrid from 'components/TableGrid'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import { useApplicationSensitiveFunctions } from 'hooks'
import { useEffect } from 'react'
import { usePageContext } from 'pageContext'
import { useIsSmallScreenFalse } from 'packages/core'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '80px',
        },
    }),
)
export const LocalSensitiveFunctions = ({ managementRoleId, queryData }) => {
    const { t } = useTranslation()
    const [_, dispatch] = usePageContext()
    const classes = useStyles()
    const attributes = [
        {
            name: 'name',
            label: t('FriendlyName'),
        },
        {
            name: 'functionType',
            label: t('FunctionType'),
        },
        {
            name: 'description',
            label: t('Description'),
        },
        {
            label: t('RiskLevel'),

            resolve: (item) => (
                <RiskIndicator
                    label={item.riskLevel}
                    variant={item.riskLevel}
                    className={classes.root}
                />
            ),
        },
    ]

    const {
        list: sensitiveFunctions,
        loading: sensitiveFunctionsLoading,
        pagination: sensitiveFunctionsPagination,
    } = useApplicationSensitiveFunctions(
        managementRoleId,
        true,
        queryData ?? '',
    )
    const isSmallScreen = useIsSmallScreenFalse()

    useEffect(() => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
    }, [queryData])

    return (
        <>
            <TableGrid
                loading={sensitiveFunctionsLoading}
                data={sensitiveFunctions}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
                isScrollable={isSmallScreen}
            />
            <Box style={{ justfyContent: 'center', display: 'flex' }}>
                {sensitiveFunctionsPagination}
            </Box>
        </>
    )
}
