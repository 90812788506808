import { Box, Typography, styled, Dialog } from '@material-ui/core'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { TextInput, Select } from 'packages/eid-ui'
import { ArrowIcon, Icon } from 'packages/eid-icons'
import { useState } from 'react'
import { useCreateOrUpdatePBACAppResource, usePbacResourceTypes } from 'hooks'
import { compareObjects } from 'utils'
import { EidAutocomplete } from 'packages/eid-controls'
import { imagesDisabled } from 'utils'
import config from 'config'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    cdnUrl: config.BASE_EID_URL,
    disableImage: imagesDisabled,
})

const queryParams = {
    take: 10,
}
export const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-24px',
        right: '-24px',
        cursor: 'pointer',
    },
})

const PopupModal = styled(Box)({
    maxWidth: '450px',
    width: '100%',
    background: '#fff',
    border: 'solid 1px #ebebed',
    padding: '40px',

    '& h2': {
        fontSize: '24px',
        color: '#282828',
    },
})

export const ModalFooter = styled(Box)({
    display: 'flex',
    marginTop: '40px',
    '& button': {
        border: 'solid 1px #307fc1',
        padding: '4px 16px 4px 13px',
        width: '100%',
        borderRadius: '4px',
        outline: 'none',
        height: '32px',
        fontSize: '14px',
        '& svg': {
            marginLeft: '8px',
        },
        '&:nth-child(1)': {
            background: '#307fc1',
            color: '#fff',
            width: 'auto',
        },
        '&:nth-child(2)': {
            color: '#307fc1',
            marginLeft: '16px',
            maxWidth: '90px',
        },
    },
})

export const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '450px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const AddResource = ({
    data,
    labelKey,
    startIcon,
    headerKey,
    applicationId,
    resourceSystemModuleId,
}) => {
    const { t } = useTranslation()
    const isEditMode = Boolean(data?.id) && false //NOTE: The edit functionality is not required for now confirmed by @Cristiana so setting it false delibrately
    const [openModal, setOpenModal] = useState(false)
    const [selectedResourceType, setSelectedResourceType] = useState(null)
    const [owner, setOwner] = useState(null)

    const [resource, setResource] = useState({
        name: data?.name ?? '',
        friendlyName: data?.friendlyName ?? '',
        description: data?.description ?? '',
    })
    const { list: resourceTypes, loading: loadingResourceTypes } =
        usePbacResourceTypes(
            applicationId,
            resourceSystemModuleId,
            Boolean(applicationId && resourceSystemModuleId),
        )
    const [createResource, { isLoading: creatingAppResources }] =
        useCreateOrUpdatePBACAppResource([
            'LIST_PBAC_APP_RESOURCES',
            applicationId,
        ])

    const handleSave = () => {
        const data = {
            systemModuleResourceTypeId: selectedResourceType?.id,
            ownerAssigneeId: owner?.id,
            ...resource,
        }
        createResource([data]).then(() => {
            handleModalClose()
        })
    }
    const handleModalClose = () => {
        setOpenModal(false)
        setResource({})
        setSelectedResourceType(null)
        setOwner(null)
    }
    const checkValidations = () => {
        if (isEditMode) {
            if (
                selectedResourceType &&
                compareObjects(resource, data, [
                    'name',
                    'friendlyName',
                    'description',
                ]).length !== 0 &&
                resource.name &&
                resource.friendlyName &&
                resource.description
            ) {
                return false
            } else {
                return true
            }
        } else if (
            resource.name &&
            resource.friendlyName &&
            owner &&
            selectedResourceType
        ) {
            return false
        } else return true
    }

    return (
        <>
            <Box
                title={
                    resourceTypes &&
                    resourceTypes.length <= 0 &&
                    t('CreateResourceTypeMessage')
                }
            >
                <Button
                    onClick={() => setOpenModal(true)}
                    style={{
                        borderRadius: '4px',
                        backgroundColor: '#307fc1',
                        color: '#ffffff',
                        fontSize: '14px',
                        height: '100%',
                        '&:hover': {
                            backgroundColor: '#307fc1',
                        },
                    }}
                    disabledStyles={{
                        backgroundColor: '#aab0b4 !important',
                        color: '#ffffff !important',
                        borderColor: '#aab0b4 !important',
                    }}
                    disabled={
                        loadingResourceTypes ||
                        (resourceTypes && resourceTypes.length <= 0)
                    }
                >
                    <Box style={{ display: 'flex' }}>
                        {startIcon}
                        {t(labelKey)}
                    </Box>
                </Button>
            </Box>
            <CustomDialog
                open={openModal}
                onClose={() => handleModalClose()}
                title={t('')}
            >
                <PopupModal>
                    <CloseIconeBox onClick={() => handleModalClose()}>
                        <Icon
                            name="Close"
                            width="12px"
                            height="13px"
                            color="#959598"
                        />
                    </CloseIconeBox>

                    <Typography variant="h2">{t(headerKey)}</Typography>

                    <Box marginTop={'10px'}>
                        <Typography variant="subtitle1">{t('Name')}</Typography>
                        <TextInput
                            placeholder={t('Name')}
                            value={resource.name}
                            handleChange={(e) =>
                                setResource({
                                    ...resource,
                                    name: e.target.value,
                                })
                            }
                        />
                    </Box>

                    <Box marginTop={'10px'}>
                        <Typography variant="subtitle1">
                            {t('DisplayName')}
                        </Typography>
                        <TextInput
                            placeholder={t('DisplayName')}
                            value={resource.friendlyName}
                            handleChange={(e) =>
                                setResource({
                                    ...resource,
                                    friendlyName: e.target.value,
                                })
                            }
                        />
                    </Box>

                    <Box marginTop={'10px'}>
                        <Typography variant="subtitle1">
                            {t('PBACResourceType')}
                        </Typography>

                        <Select
                            options={resourceTypes ?? []}
                            onChange={(data) => {
                                setSelectedResourceType(data)
                            }}
                            value={
                                isEditMode
                                    ? resourceTypes.find(
                                          (r) =>
                                              r.id ===
                                              data?.azResourceSystemModuleResourceTypeId,
                                      )
                                    : selectedResourceType
                            }
                            getOptionLabel={(option) => option.friendlyName}
                            isLoading={loadingResourceTypes}
                            placeholder={'SelectType'}
                        />
                    </Box>

                    <Box marginTop={'10px'}>
                        <Typography variant="subtitle1">
                            {t('Owner')}
                        </Typography>
                        <EidAutocomplete.New
                            placeholder={t('SelectPerson')}
                            url={'api/people'}
                            queryParams={queryParams}
                            dedupingInterval={60000}
                            value={owner}
                            getOptionLabel={(option) => option.friendlyName}
                            endAdornmentComponent={() => (
                                <ArrowIcon direction="down" color="#aab0b4" />
                            )}
                            optionComponent={OptionComponent}
                            onChange={(_, data) => {
                                setOwner(data)
                            }}
                            inputWrapperStyleProps={{ fontSize: '16px' }}
                        />
                    </Box>

                    <Box marginTop={'10px'}>
                        <Typography variant="subtitle1">
                            {t('Description')}
                        </Typography>
                        <TextInput
                            placeholder={t('Description')}
                            value={resource.description}
                            handleChange={(e) =>
                                setResource({
                                    ...resource,
                                    description: e.target.value,
                                })
                            }
                        />
                    </Box>

                    <ModalFooter>
                        <Button
                            loading={creatingAppResources}
                            onClick={() => {
                                handleSave()
                            }}
                            style={{
                                height: '32px !important',
                                overflowWrap: 'anywhere',
                            }}
                            backgroundColor="#307fc1"
                            disabledStyles={{
                                backgroundColor: '#aab0b4 !important',
                                color: '#ffffff !important',
                                borderColor: '#aab0b4 !important',
                            }}
                            disabled={checkValidations()}
                        >
                            {isEditMode ? t('Save') : t('AddNewPbacResources')}
                        </Button>
                        <Button
                            style={{
                                height: '32px !important',
                            }}
                            onClick={() => handleModalClose()}
                        >
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </PopupModal>
            </CustomDialog>
        </>
    )
}

export default AddResource
