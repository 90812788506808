import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { useIsSmallScreenFalse } from 'packages/core'
import { useApplicationScopes } from 'hooks'
import {
    useEidGrid,
    CardViewLoader,
    ListingGrid,
    GridContainer,
    Filters,
} from 'components'
import { ScopeRow, ScopesTable, ScopesTableHead } from './ScopesTableComponent'
import scopesAttributes from './scopesAttributes'
import ScopeCard from './ScopesCard'
import useSubcomponents from 'useSubcomponents'
import { CreateWorkflowButton } from '../CreateWorkflowButton'

const ApplicationScopes = ({ id, resourceId }) => {
    const { t } = useTranslation()
    const { canAddScope } = useSubcomponents()

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useApplicationScopes(id)

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? 'card' : 'table'
    const { viewToRender, setCurrentView } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoScopesMessage'),
        pagination,
        attributes: scopesAttributes,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: ScopeCard,
        },
        tableViewConfig: {
            TableComponent: ScopesTable,
            TableHeadComponent: ScopesTableHead,
            TableRowComponent: ScopeRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    return (
        <ListingGrid>
            <Box
                display="flex"
                flexDirection={isSmallScreen ? 'column' : 'row'}
            >
                {canAddScope && (
                    <Box data-protectedsubcomponent={canAddScope}>
                        <CreateWorkflowButton
                            label={t('AddScope')}
                            workflowName="CreateAzureAppScope"
                            resourceId={resourceId}
                        />
                    </Box>
                )}
                <Box
                    style={{
                        margin: isSmallScreen ? '16px' : '0px 0px 0px auto',
                        width: isSmallScreen ? 'auto' : '308px',
                    }}
                >
                    <Filters.TextSearch outlined />
                </Box>
            </Box>
            <GridContainer
                style={{
                    padding: '16px 0px',
                    marginTop: '0px',
                    backgroundColor: isSmallScreen ? 'white' : 'transparent',
                }}
            >
                {viewToRender}
            </GridContainer>
        </ListingGrid>
    )
}

export default ApplicationScopes
