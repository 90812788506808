import { useTranslation } from 'react-i18next'
import { AttributeSections } from 'components/AttributeSections'

export const SendReceiveLimits = (props) => {
    const { t } = useTranslation()
    const attributes = [
        // NOTE: Changing the structure of attributes to divide them in sections as per design
        [
            {
                label: 'MaxSendingMessageSizeKb',
                component: {
                    componentName: 'renderer',

                    render: (data) => {
                        return data?.maximumSendingMessageSize
                            ? t('True')
                            : t('False')
                    },
                },
            },
            {
                name: 'maximumReceivingMessageSize',
                label: 'MaxReceivingMessageSizeKb',
            },
            {
                name: 'maximumRecipientsPerMessage',
                label: 'MaximumRecipientsPerMessage',
            },
        ],
        [
            {
                name: 'forwardToEmailAddress',
                label: 'ForwardToEmailAddress',
            },
            {
                name: 'forwardToDN',
                label: 'ForwardToDN',
            },
            {
                label: 'DeliverToForwardingAddressAndMailbox',
                component: {
                    componentName: 'renderer',
                    render: (data) => {
                        return data?.deliverToForwardingAddressAndMailbox
                            ? t('True')
                            : t('False')
                    },
                },
            },
        ],
    ]

    const { mailbox } = props

    return <AttributeSections data={mailbox} attributes={attributes} />
}
