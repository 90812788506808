import { Box, styled } from '@material-ui/core'
import { Button } from 'components'
import StatusInfoMessage from 'components/Popups/StatusMessage/StatusInfoMessage'
import { useDeleteAssigneeAssignment } from 'hooks'
import { WarningModal } from 'packages/eid-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const DeleteButton = styled(Box)({
    paddingRight: '10px',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})

const DeleteAccessGranted = ({ item, refetch }) => {
    const { t } = useTranslation()
    const [showModal, setShowModal] = useState(false)
    const [updateInfoMessages, setUpdateInfoMessages] = useState([])

    const [deleteAssignments, { isLoading: isDeletingAssignment }] =
        useDeleteAssigneeAssignment()

    const handleDelete = () => {
        deleteAssignments([item?.id]).then((data) => {
            setShowModal(false)
            const updatedResponse = data?.data
            if (updatedResponse?.length) {
                setUpdateInfoMessages(updatedResponse)
            }
        })
    }

    return (
        <Box>
            <DeleteButton>
                <Button.Delete
                    showEndIcon={false}
                    onClick={() => setShowModal(true)}
                />
            </DeleteButton>
            {showModal && (
                <WarningModal
                    title={t('DeleteAssignment', {
                        resourceFriendlyName: item?.resourceFriendlyName,
                        resourceTypeRoleFriendlyName:
                            item?.resourceTypeRoleFriendlyName,
                        assigneeFriendlyName: item?.assigneeFriendlyName,
                    })}
                    description={t('AppRoleDeleteMessage')}
                    yesLabel={t('Yes')}
                    noLabel={t('Cancel')}
                    open={showModal}
                    cancel={() => setShowModal(false)}
                    proceeding={isDeletingAssignment}
                    proceed={handleDelete}
                />
            )}
            {updateInfoMessages?.length ? (
                <StatusInfoMessage
                    messages={updateInfoMessages}
                    title={'DeleteAssignmentsStatus'}
                    handleClose={() => {
                        refetch()
                        setUpdateInfoMessages([])
                    }}
                />
            ) : null}
        </Box>
    )
}

export default DeleteAccessGranted
