import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles } from '@material-ui/core'
import { useResourceOwnersPaginated } from 'hooks'
import Search from 'components/Filters/Search'
import useSubcomponents from 'useSubcomponents'
import TableGrid from 'components/TableGrid'
import { useDebounce } from 'packages/core'

const useTableStyles = makeStyles({
    headGrey: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
        height: '80px',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 16px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: '#7d7c7c',
    },
})

export const AccessManagers = ({ sharedFolderId }) => {
    const { t } = useTranslation()
    const { getAccessBasedAttributes } = useSubcomponents()

    const [searchKey, setSearchKey] = useState('')
    const [sorting, setSorting] = useState({ sortOrder: null, sortBy: null })
    const tableClasses = useTableStyles()

    const handleSort = (sorting) => {
        setSorting(sorting)
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
        sorting,
    }
    const {
        list,
        loading: isLoading,
        pagination,
    } = useResourceOwnersPaginated(sharedFolderId, queryData)

    const attributes = [
        {
            label: 'DisplayName',
            name: 'friendlyName',
            sortable: true,
        },
        {
            label: 'Login',
            name: 'login',
            sortable: true,
        },
        {
            label: 'Email',
            name: 'email',
            sortable: true,
        },
        {
            label: 'BusinessRoleAndLocation',
            name: 'businessRole',
            sortable: true,
        },
        {
            label: 'Department',
            name: 'department',
            sortable: true,
        },
        {
            label: 'Title',
            name: 'title',
            sortable: true,
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                <Box width="308px" display="flex" alignItems="center">
                    <Search
                        handleSearch={handleSearchChange}
                        placeholder={t('SearchAndFilter')}
                        value={searchKey}
                        handleClear={handleClear}
                        outlined
                    />
                </Box>
            </Box>

            <TableGrid
                loading={isLoading}
                data={list}
                attributes={getAccessBasedAttributes(attributes)}
                headings={getAccessBasedAttributes(attributes).map(
                    (a) => a.label,
                )}
                sorting={sorting}
                onSort={handleSort}
                tableClasses={tableClasses}
            />

            <Box display="flex" paddingTop="40px" justifyContent="center">
                <Box>{pagination}</Box>
            </Box>
        </>
    )
}
