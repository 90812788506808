import React from 'react'
import {
    styled,
    Box,
    Typography,
    InputBase,
    withStyles,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { DatePicker } from '@material-ui/pickers'
import { isNilOrEmpty } from 'packages/core'
import { useTranslation } from 'react-i18next'

const iconContainer = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
}
const Container = styled(Box)({
    height: '40px',
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 15px',
    position: 'relative',
    cursor: 'pointer',
})

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
        marginRight: '12px',
        'label + &': {
            marginTop: theme.spacing(3),
        },
        cursor: 'pointer',
    },
    input: {
        position: 'relative',
        boxShadow: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: 16,
        color: '#5d6870',
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)

const InputWithLabel = (props) => {
    const { label, value, onClick, helperText, handleClear } = props

    return (
        <Box>
            <Typography
                style={{
                    color: '#b4b4b4',
                    fontSize: '10px',
                    textTransform: 'uppercase',
                    minWidth: '60px',
                    padding: '10px 10px',
                }}
            >
                {label}
            </Typography>

            <Container
                padding="0px 5px !important"
                boxShadow="none !important"
                onClick={onClick}
                className="Input-Container"
            >
                <Box style={iconContainer} padding="0 5px" marginRight="12px">
                    <Icon name="Calendar" color="#959598" />
                </Box>
                <StyledInput value={value} placeholder={helperText} />

                {!isNilOrEmpty(value) && (
                    <Box
                        style={iconContainer}
                        padding="0 12px"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleClear()
                        }}
                    >
                        <Icon name="Close" color="#959598" />
                    </Box>
                )}
            </Container>
        </Box>
    )
}

const InputComponent = (props) => {
    const { label, value, onClick, handleClear, helperText } = props
    return (
        <Container className="Input-Container" onClick={onClick}>
            <Box>
                {label && (
                    <Typography
                        style={{
                            color: '#b4b4b4',
                            fontSize: '10px',
                            textTransform: 'uppercase',
                            minWidth: '60px',
                            marginRight: '12px',
                        }}
                    >
                        {label}
                    </Typography>
                )}
            </Box>

            <StyledInput value={value} placeholder={helperText} />

            {!isNilOrEmpty(value) && (
                <Box
                    style={iconContainer}
                    height="40px !important"
                    width="40px"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleClear()
                    }}
                    padding="0 12px"
                >
                    <Icon name="Close" color="#959598" />
                </Box>
            )}

            <Box style={iconContainer} paddingRight="15px">
                <Icon name="Tasks" color="#D8D8DD" />
            </Box>
        </Container>
    )
}

const DateTimeFilter = ({
    label,
    showLabelWithin,
    value,
    placeholder,
    onChange,
    ...rest
}) => {
    const { t } = useTranslation()

    return (
        <DatePicker
            label={label}
            format="D MMMM YYYY"
            TextFieldComponent={
                showLabelWithin ? InputComponent : InputWithLabel
            }
            onChange={(date) => {
                onChange(date)
            }}
            value={value}
            okLabel={t('Ok')}
            cancelLabel={t('Cancel')}
            helperText={placeholder}
            {...rest}
        />
    )
}

export default DateTimeFilter
