import { Box, Paper } from '@material-ui/core'
import { useAccessRequestPolicyToFolder } from 'hooks'
import { useIsSmallScreenFalse } from 'packages/core'
import { useEffect, useState } from 'react'
import { MainAccessRequestPolicyView } from './MainAccessRequestPolicyView'

export const AccessRequestPolicy = (props) => {
    const { sharedFolderId, accessRequestAttributes, sharedFolder } = props
    const [requestPolicyData, setRequestPolicyData] = useState(null)
    const { isLoading, data } = useAccessRequestPolicyToFolder()

    useEffect(() => {
        if (data) {
            const selectedPolicy = data.find(
                (x) => x.id === sharedFolder.requestPolicyId,
            )
            if (selectedPolicy) {
                setRequestPolicyData(selectedPolicy)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const isSmallScreen = useIsSmallScreenFalse()

    return (
        <Box>
            <Paper
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                }}
            >
                <MainAccessRequestPolicyView
                    acccessRequestPolicy={requestPolicyData}
                    detailsLoading={isLoading || !requestPolicyData}
                    accessRequestAttributes={accessRequestAttributes}
                    sharedFolderId={sharedFolderId}
                />
            </Paper>
        </Box>
    )
}
