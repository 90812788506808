import { Box, Typography, styled, Dialog } from '@material-ui/core'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useState } from 'react'
import { compareObjects } from 'utils'
import {
    useCreatePBACAppResourceTypes,
    useUpdatePBACAppResourceTypes,
} from 'hooks'

export const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-24px',
        right: '-24px',
        cursor: 'pointer',
    },
})

const PopupModal = styled(Box)({
    maxWidth: '450px',
    width: '100%',
    background: '#fff',
    border: 'solid 1px #ebebed',
    padding: '40px',

    '& h2': {
        fontSize: '24px',
        color: '#282828',
    },
})

export const ModalFooter = styled(Box)({
    display: 'flex',
    marginTop: '40px',
    '& button': {
        border: 'solid 1px #307fc1',
        padding: '4px 16px 4px 13px',
        width: '100%',
        borderRadius: '4px',
        outline: 'none',
        height: '32px',
        fontSize: '14px',
        '& svg': {
            marginLeft: '8px',
        },
        '&:nth-child(1)': {
            background: '#307fc1',
            color: '#fff',
            maxWidth: '185px',
        },
        '&:nth-child(2)': {
            color: '#307fc1',
            marginLeft: '16px',
            maxWidth: '90px',
        },
    },
})

export const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '450px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const AddNewResourceType = ({
    data,
    labelKey,
    startIcon,
    headerKey,
    refetch,
    resourceSystemModuleId,
}) => {
    const { t } = useTranslation()

    const isEditMode = Boolean(data?.id)
    const [openModal, setOpenModal] = useState(false)
    const [resource, setResource] = useState(isEditMode ? data : {})

    const handleModalClose = () => {
        setResource({})
        setOpenModal(false)
    }
    const [createResourceType, { isLoading: creatingResourcesType }] =
        useCreatePBACAppResourceTypes(refetch)
    const [updateResourceType, { isLoading: updatingResourcesType }] =
        useUpdatePBACAppResourceTypes(data?.id, refetch)
    const checkValidations = () => {
        if (isEditMode) {
            if (
                compareObjects(resource, data, [
                    'name',
                    'friendlyName',
                    'description',
                ]).length !== 0 &&
                resource.name &&
                resource.friendlyName
            ) {
                return false
            } else {
                return true
            }
        } else if (resource.name && resource.friendlyName) {
            return false
        } else return true
    }

    const handleCreateNewResourceType = () => {
        const data = {
            resourceSystemModuleId,
            ...resource,
        }
        createResourceType(data).then(() => {
            handleModalClose()
        })
    }
    const handleUpdateNewResourceType = () => {
        const data = {
            resourceSystemModuleId,
            ...resource,
        }
        updateResourceType(data).then(() => {
            setOpenModal(false)
        })
    }
    return (
        <>
            <Button
                onClick={() => setOpenModal(true)}
                style={{
                    borderRadius: '4px',
                    backgroundColor: '#307fc1',
                    color: '#ffffff',
                    fontSize: '14px',
                    height: '100%',
                    '&:hover': {
                        backgroundColor: '#307fc1',
                    },
                }}
            >
                <Box style={{ display: 'flex' }}>
                    {startIcon}
                    {t(labelKey)}
                </Box>
            </Button>
            <CustomDialog
                open={openModal}
                onClose={() => handleModalClose()}
                title={t('')}
            >
                <PopupModal>
                    <CloseIconeBox onClick={() => handleModalClose()}>
                        <Icon
                            name="Close"
                            width="12px"
                            height="13px"
                            color="#959598"
                        />
                    </CloseIconeBox>

                    <Typography variant="h2">{t(headerKey)}</Typography>

                    <Box marginTop={'10px'}>
                        <Typography variant="subtitle1">{t('Name')}</Typography>
                        <TextInput
                            placeholder={'Name'}
                            value={resource.name}
                            handleChange={(e) =>
                                setResource({
                                    ...resource,
                                    name: e.target.value,
                                })
                            }
                        />
                    </Box>

                    <Box marginTop={'10px'}>
                        <Typography variant="subtitle1">
                            {t('DisplayName')}
                        </Typography>
                        <TextInput
                            placeholder={t('DisplayName')}
                            value={resource.friendlyName}
                            handleChange={(e) =>
                                setResource({
                                    ...resource,
                                    friendlyName: e.target.value,
                                })
                            }
                        />
                    </Box>

                    <Box marginTop={'10px'}>
                        <Typography variant="subtitle1">
                            {t('Description')}
                        </Typography>
                        <TextInput
                            placeholder={'Description'}
                            value={resource.description}
                            handleChange={(e) =>
                                setResource({
                                    ...resource,
                                    description: e.target.value,
                                })
                            }
                        />
                    </Box>

                    <ModalFooter>
                        <Button
                            loading={
                                creatingResourcesType || updatingResourcesType
                            }
                            onClick={() => {
                                isEditMode
                                    ? handleUpdateNewResourceType()
                                    : handleCreateNewResourceType()
                            }}
                            style={{
                                height: '48px !important',
                                overflowWrap: 'anywhere',
                            }}
                            backgroundColor="#307fc1"
                            disabledStyles={{
                                backgroundColor: '#aab0b4 !important',
                                color: '#ffffff !important',
                                borderColor: '#aab0b4 !important',
                            }}
                            disabled={checkValidations()}
                        >
                            {t('Save')}
                        </Button>
                        <Button
                            style={{
                                height: '48px !important',
                            }}
                            onClick={() => handleModalClose()}
                        >
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </PopupModal>
            </CustomDialog>
        </>
    )
}

export default AddNewResourceType
