import React from 'react'
import { Card, Box, Link, makeStyles } from '@material-ui/core'
import { EditIcon } from 'packages/eid-icons'
import Slope from '../../../../assets/images/slope.svg'
import config from 'config'
const useStyles = makeStyles(() => ({
    root: (props) => ({
        overflow: 'visible',
        maxWidth: props.width && props.width,
    }),
}))

const useStyles2 = makeStyles({
    root: { display: 'flex', justifyContent: 'center' },

    iconBox: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '-2.285em',
        backgroundColor: '#eef0f4',
        height: '68px',
        width: '95px',
        borderRadius: '50%',
        alignItems: 'center',
    },

    link: {
        height: '45px',
        width: '45px',
        backgroundColor: '#FFF',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 5px 4px 0 rgba(0, 0, 0, 0.1)',
        cursor: 'default',
    },
})

const GenericCard = ({
    editIcon,
    width,
    onClick,
    editable,
    children,
    showEditOption,
}) => {
    const classes = useStyles({
        width,
    })

    const classes2 = useStyles2()
    if (editIcon) {
        var Icon = React.createElement(editIcon, {
            color: editable ? '#307fc1' : '#b4b4b4',
        })
    }
    return (
        <Card classes={classes}>
            {children}

            {showEditOption && (
                <Box className={classes2.root}>
                    <img
                        style={{ position: 'absolute' }}
                        src={`${config.APP_SUBPATH}${Slope}`}
                        alt="Slope"
                    />
                    <Box className={classes2.iconBox}>
                        <Link
                            className={classes2.link}
                            component="button"
                            onClick={onClick}
                        >
                            {editIcon ? (
                                Icon
                            ) : (
                                <EditIcon
                                    color={editable ? '#307fc1' : '#b4b4b4'}
                                />
                            )}
                        </Link>
                    </Box>
                </Box>
            )}
        </Card>
    )
}

export default GenericCard
