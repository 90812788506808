import { Fragment, useState } from 'react'
import { PersonDetails } from './ApplicationBanner/PersonDetails'
import { usePerson } from 'hooks'

export interface IPersonDetailsLazyProps {
    size?: any
    friendlyName: any
    imageThumbUrl: any
    personId: any
    enabled?: boolean
    loading?: boolean
}

const PersonDetailsLazy = (props: IPersonDetailsLazyProps) => {
    const { personId, imageThumbUrl, friendlyName, size } = props
    const [enabled, setEnabled] = useState(false)
    const { data: person, isLoading } = usePerson(
        personId,
        Boolean(personId) && enabled,
    )

    return (
        <Fragment>
            {friendlyName ? (
                <div
                    onMouseEnter={() => {
                        setEnabled(true)
                    }}
                >
                    <PersonDetails
                        size={size || 'medium'}
                        enabled={enabled}
                        loading={isLoading}
                        person={{
                            imageThumbUrl:
                                imageThumbUrl || person?.imageThumbUrl,
                            friendlyName: friendlyName,
                            email: person && person.email,
                            telephone: person && person.telephone,
                        }}
                    />
                </div>
            ) : (
                '-'
            )}
        </Fragment>
    )
}
export default PersonDetailsLazy
