const tableAttributes = [
    {
        name: 'shareName',
        sortable: true,
        sortBy: 'shareName',
        label: 'SharedName',
        moreInfoIcon: true,
        hideValue: 'true',
        isHeader: true,
        showInCard: false,

        component: {
            componentName: 'HighlightedClickableLabel',
            queryParam: 'sharedFolderId',
        },
        styles: {
            color: '#282828',
            fontSize: '13px',
            fontWeight: 'bold',
        },
    },

    {
        name: 'uncPath',
        sortable: true,
        sortBy: 'uncPath',
        label: 'UNCPath',

        headerCellStyles: {},
        styles: {
            fontSize: '13px',
        },
    },
    {
        hideValue: true,
        showInCard: false,
        component: {
            componentName: 'actionButtons',
        },
    },
]

//TODO: Add detail page attributes once detail page is ready
const detailAttributes = [
    {
        label: 'FriendlyName',
        value: 'friendlyName',
        firstPane: true,
    },
    {
        label: 'Computer',
        value: 'computerFriendlyName',
        firstPane: true,
    },
    {
        label: 'UNCPath',
        value: 'uncPath',
        firstPane: true,
    },
    {
        label: 'SharedName',
        value: 'shareName',
        firstPane: true,
    },
    {
        label: 'DNSHostName',
        value: 'dnsHostName',
        firstPane: true,
    },
    {
        label: 'LocalPath',
        value: 'localPath',
        firstPane: true,
    },
    {
        label: 'DFSPath',
        value: 'dfsPath',
        firstPane: true,
    },
    {
        label: 'ResourceSystem',
        value: 'resourceSystemFriendlyName',
    },
    {
        label: 'CreatedDate',
        value: 'createdDateUtc',
        name: 'createdDateUtc',
        hideValue: true,
        component: {
            componentName: 'DateTimeGenericComponent',
        },
    },
    {
        label: 'ModifiedDate',
        value: 'modifiedDateUtc',
        name: 'modifiedDateUtc',
        hideValue: true,
        component: {
            componentName: 'DateTimeGenericComponent',
        },
    },
    {
        label: 'Description',
        value: 'description',
    },
]

const accessRequestAttributes = {
    general: [
        {
            label: 'FriendlyName',
            value: 'friendlyName',
        },
        {
            label: 'DisplayName',
            value: 'name',
        },
        {
            label: 'Description',
            value: 'description',
        },
        {
            label: 'AllowAccessRequests',
            value: 'isRequestAllowed',
        },
        {
            label: 'ApprovalPolicy',
            value: 'aprovalPolicy',
        },
        {
            label: 'FullfillmentDelay(Hrs)',
            value: 'delay',
        },
    ],
    timeRestriction: [
        {
            label: 'TimeRestrictAccess',
            value: 'isTimeConstrained',
        },
        {
            label: 'DefaultAccessDuration(Min)',
            value: 'defaultValueInMinutes',
        },
        {
            label: 'MaxDuration(Min)',
            value: 'maximumValueInMinutes',
        },
        {
            label: 'AllowDurationSelection',
            value: 'isDurationSelectable',
        },
    ],
}

const attributes = {
    tableAttributes,
    detailAttributes,
    accessRequestAttributes,
}

export default attributes
