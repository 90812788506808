import EligibilityListing from 'components/SharedListings/EligibilityListing'

const RoleDefinitionsEligibility = ({ role, resourceId }) => {
    return (
        <EligibilityListing
            roleId={role.id}
            resourceTypeId={role.resourceTypeId}
            resourceId={resourceId}
            workflowName={'ManageAzLocalRoleWizard'}
        />
    )
}

export default RoleDefinitionsEligibility
