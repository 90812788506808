import { Box, Typography, styled, Dialog, makeStyles } from '@material-ui/core'
import { Button } from 'components'
import { Loader, Pagination, Select, Tooltip } from 'packages/eid-ui'
import { ArrowSmallIcon, Icon } from 'packages/eid-icons'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TimeConstrainedControl } from 'components/TimeConstrainedControl'
import moment from 'moment'
import MemberIAMShopAssigment from '../IAMShopAssignments/MemberIAMShopAssigment'
import Search from 'components/Filters/Search'
import {
    useAddItemsToCart,
    useCart,
    useConfigSettings,
    useIAmShopAssignees,
    useModalSearch,
} from 'hooks'
import { useDebounce } from 'packages/core'
import classNames from 'classnames'
import cartHelpers from 'containers/Cart/cartHelpers'
import { PageContextProvider, usePageContext } from 'pageContext'
import { paginate } from 'utils'

export const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-24px',
        right: '-24px',
        cursor: 'pointer',
    },
})

const AssignmentsModal = styled(Box)({
    maxWidth: '700px',
    width: '100%',
    background: '#fff',
    border: 'solid 1px #ebebed',
    padding: '40px',

    '& h2': {
        fontSize: '24px',
        color: '#282828',
    },
})

const showCount = false

export const Divider = styled(Box)({
    background: '#d8d8d8 !important',
    height: '1px',
    margin: '24px 0',
})
export const InputBox = styled(Box)({
    '& input': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #ebebed',
        height: '30px',
        width: '100%',
        borderRadius: '5px',
        outline: 'none',
        padding: '5px 15px',
    },
    '& .MuiSelect-select.MuiSelect-select': {
        width: '100%',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #ebebed',
        height: '30px',
        borderRadius: '5px',
        outline: 'none',
        padding: '5px 15px',
        background: '#fff !important',
    },

    '& .MuiInputBase-fullWidth': {
        maxWidth: '360px',
    },
    '& MuiSelect-selectMenu': {
        fontSize: '14px',
    },

    '& .MuiInput-underline': {
        '&:after': {
            border: 'none !important',
        },
        '&:before': {
            border: 'none !important',
        },
    },
})
export const ModalFooter = styled(Box)({
    display: 'flex',
    '& button': {
        border: 'solid 1px #307fc1',
        padding: '4px 16px 4px 13px',
        width: '100%',
        borderRadius: '4px',
        outline: 'none',
        height: '32px',
        fontSize: '14px',
        '& svg': {
            marginLeft: '8px',
        },
        '&:nth-child(1)': {
            background: '#307fc1',
            color: '#fff',
            maxWidth: '240px',
        },
        '&:nth-child(2)': {
            color: '#307fc1',
            marginLeft: '16px',
            maxWidth: '90px',
        },
    },
})

export const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '830px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const useStyles = makeStyles(() => ({
    ActiceSection: {
        background: 'rgba(48, 127, 193, 0.07)',
        borderLeft: '2px solid #307fc1',
    },
    ButtonAddSection: {
        marginTop: '70px',
        '& button': {
            '& svg': {
                position: 'relative',
                left: '30px',
            },
        },
    },
    ButtonRemoveSection: {
        '& button': {
            '&:nth-child(1)': {
                marginBottom: '20px',
                '& svg': {
                    position: 'relative',
                    right: '20px',
                },
            },
            '&:nth-child(2)': {
                marginBottom: '20px',
                '& svg': {
                    position: 'relative',
                    right: '15px',
                },
            },
        },
    },
    SearchPeople: {
        '& p': {
            fontSize: '16px',
            marginBottom: '16px',
        },
        '& svg': {
            marginLeft: '0 !important',
        },
    },
    TimeLabelSection: {
        display: 'flex',
        maxWidth: '100%',
        '& .MuiBox-root': {
            maxWidth: 'fit-content !important',
        },
    },
    activeButton: {
        backgroundColor: '#01ae8f',
        color: '#fff !important',
        borderColor: '#01ae8f !important ',
        '&:hover': {
            backgroundColor: '#01ae8fcc !important',
        },
        '& svg': {
            color: '#fff !important',
            fill: '#fff !important',
        },
    },
    searchBar: {
        width: '260px !important',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
        '& div': {
            borderRadius: '4px',
        },
    },
    activeButtonForRemove: {
        backgroundColor: '#ff4d5f',
        color: '#fff !important',
        borderColor: '#ff4d5f !important ',
        '&:hover': {
            backgroundColor: '#ff4d5fcc !important',
        },
        '& svg': {
            color: '#fff !important',
            fill: '#fff !important',
        },
    },
}))

const SelectAssignmentSection = styled(Box)({
    border: 'solid 1px #ebebed  !important',
    background: '#fff !important',
    borderRadius: '5px !important',
    maxWidth: '260px !important',
    overflow: 'hidden',
    height: '300px',
    overflowY: 'auto',
    padding: '5px',
    '&::-webkit-scrollbar ': {
        display: 'none',
    },
})
const PaginationMain = styled(Box)({
    maxWidth: '265px !important',
    marginTop: '16px',
    '& ul': {
        height: '30px',
        borderRadius: '20px',
        '& li': {
            '& button': {
                padding: '0px 2px',
            },
        },
    },
})
const ButtonSection = styled(Box)({
    marginTop: '20px',
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '170px !important',
    gap: '125px',
    '& button': {
        maxWidth: '125px',
        width: '100%',
        fontSize: '14px',
        borderRadius: '4px',
        height: '31px',
        '&:nth-child(1)': {
            border: 'solid 1px #8b909a',
            color: '#8b909a',
        },
        '&:nth-child(2)': {
            border: 'solid 1px #ff4d5f',
            color: '#ff4d5f',
        },
    },
})
//Below page size is for Client Side pagination
const pageSize = 5

export const PermissionLevels = ({
    value,
    permissionLevelProps,
    onChange,
    targetAssigneeId,
}) => {
    const { t } = useTranslation()

    const { list: accessLevels } = useIAmShopAssignees(targetAssigneeId, {
        take: 10000,
    })
    const { permissionLabelProp } = permissionLevelProps
    const getOptionLabel = (option) => option[permissionLabelProp]

    return (
        <InputBox>
            <Typography variant="subtitle1">
                {t('IAmShopPermissionLevel')}
            </Typography>
            <Select
                value={value}
                options={accessLevels?.length > 0 ? accessLevels : []}
                placeholder={t('GetPermissionLevel')}
                getOptionLabel={getOptionLabel}
                onChange={onChange}
                required
            />
        </InputBox>
    )
}
const AddAssignment = ({
    searchUrl,
    identifier = 'id',
    labelKey,
    showPermissionLevel,
    filterWithAccessLevel = false,
    targetAssigneeResource,
    targetAssigneeId,
    startIcon,
    headerText,
    queryParams = {},
    listLabels = {
        unselectedListLabel: 'People',
        selectedListLabel: 'SelectedPeople',
    },
    searchPlaceHolder = 'SearchFilterMembers',
    onSubmit,
    submitLoader,
    submitButtonLabelKey,
    hideDurationSelection = false,
    allowRemovingAssignments = false,
    permissionLevelProps = {
        permissionLabelProp: 'value',
        permissionIdetifierProp: 'id',
    },
    handleClose = null,
    assignedPeopleList = null,
    closeOnSubmit = false,
    showAssignmentModal = false,
    // NOTE: Need to refine with Shahid. Adding for now in order not to break old logic
    addSelectedOnly = false,
    secondaryAttributes = ['email'],
    resourceTypeRoleId_ConfigName,
}) => {
    const [openAssigneeModal, setOpenAssigneeModal] =
        useState(showAssignmentModal)
    const [poilicyAssignee, setPolicyAssignee] = useState(null)
    const [timeConstrained, setTimeConstrained] = useState(false)
    const { t } = useTranslation()
    const classes = useStyles({})
    const [searchKey, setSearchKey] = useState('')
    const currentDatetime = moment.utc().second(0)
    const [peopleAddedList, setPeopleAddedList] = useState([])
    const [selectedAddedAssignments, setSelectedAddedAssignments] = useState([])
    const [selectedAssignments, setSelectedAssignments] = useState([])
    const debouncedSearchValue = useDebounce(searchKey)
    const [alreadyAssignedValues, setAlreadyAssignedValues] = useState([])
    const [addItemsToCart, { isLoading }] = useAddItemsToCart()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [, dispatch] = usePageContext()
    useEffect(() => {
        if (isSubmitting && !submitLoader) {
            handleModalClose()
        }
    }, [isSubmitting, submitLoader])
    const { data: cart } = useCart()
    const queryData = {
        searchTerm: debouncedSearchValue,
        // passing 10000 so that we can filter out for alreadyAssigned values on first hand otherwise it will just filter from first 16
        take: allowRemovingAssignments ? 10000 : 5,
        accessLevelId:
            showPermissionLevel && filterWithAccessLevel
                ? poilicyAssignee?.id
                : undefined,
        ...queryParams,
    }

    const { list, loading, pagination } = useModalSearch(
        searchUrl,
        queryData,
        filterWithAccessLevel
            ? Boolean(poilicyAssignee?.id)
            : openAssigneeModal,
        showCount,
    )
    const { data: configSettings, isLoading: configSettingsLoading } =
        useConfigSettings(
            resourceTypeRoleId_ConfigName,
            Boolean(resourceTypeRoleId_ConfigName) && openAssigneeModal,
        )

    //Pagination for Left Side Items
    const [page_LeftSide, setPage_LeftSide] = useState(1)
    const filteredData_LeftSide = list?.filter(
        (el) => !peopleAddedList.some((s) => s[identifier] === el[identifier]),
    )
    const paginatedData_LeftSide =
        list && allowRemovingAssignments
            ? paginate(filteredData_LeftSide, page_LeftSide, pageSize)
            : filteredData_LeftSide

    const numberOfPages_LeftSide =
        filteredData_LeftSide && allowRemovingAssignments
            ? Math.ceil(filteredData_LeftSide?.length / pageSize)
            : 0

    //Pagination for Right side items
    const [page_RightSide, setPage_RightSide] = useState(1)
    const paginatedData_RightSide = paginate(
        peopleAddedList,
        page_RightSide,
        pageSize,
    )
    const numberOfPages_RightSide = peopleAddedList
        ? Math.ceil(peopleAddedList?.length / pageSize)
        : 0
    useEffect(() => {
        if (list && allowRemovingAssignments) {
            const _alreadyAssigned = list.filter((l) => l.isAssigned)
            if (!addSelectedOnly) {
                setAlreadyAssignedValues(_alreadyAssigned)
                setPeopleAddedList(_alreadyAssigned)
            } else {
                setAlreadyAssignedValues(
                    assignedPeopleList ? assignedPeopleList : _alreadyAssigned,
                )
                setPeopleAddedList(
                    assignedPeopleList ? assignedPeopleList : _alreadyAssigned,
                )
            }
        }
    }, [list, assignedPeopleList])

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const [{ selectedStartDate, selectedEndDate }, setSelectedDate] = useState({
        selectedStartDate: currentDatetime,
        selectedEndDate: null,
    })

    const handleStartDate = (startDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedStartDate: startDateToSet }
        })
    }

    const handleEndDate = (endDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedEndDate: endDateToSet }
        })
    }

    const handlePolicyAssigneChange = (evt) => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
        setPolicyAssignee(evt)
    }

    const handleTimeConstrainedChange = () => {
        setTimeConstrained(!timeConstrained)
    }

    const handleModalClose = () => {
        setOpenAssigneeModal(false)
        setSearchKey('')
        setSelectedAssignments([])
        setPeopleAddedList([])
        setSelectedAddedAssignments([])
        setPolicyAssignee(null)
        setTimeConstrained(false)
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
        if (handleClose) {
            handleClose()
        }
    }

    const checkIfSelected = (item) => {
        let selectedAttribute = {
            isSelected: false,
            alreadyAdded: false,
        }
        if (checkIfAlreadyAddedToSelectedList(item)) {
            selectedAttribute.alreadyAdded = true
        }
        if (isSelectedToAdd(item, selectedAssignments)) {
            selectedAttribute.isSelected = true
        }
        return selectedAttribute
    }

    const handleSelectPeople = (item) => {
        // Check if already added
        if (
            checkIfAlreadyAddedToSelectedList(item) ||
            checkIfAlreadyAddedToCart(item)
        ) {
            return
        }
        // If first selection add to list only
        if (!selectedAssignments?.length) {
            setSelectedAssignments([item])
            return
        }

        //  Check if item already added then update list
        setSelectedAssignments(
            addRemoveOnSelectionFromList(item, selectedAssignments),
        )
    }

    const isSelectedToAdd = (item, list) => {
        return list.find((x) => x[identifier] === item[identifier])
            ? true
            : false
    }

    const checkIfAlreadyAddedToSelectedList = (item) => {
        return peopleAddedList.find((x) => x[identifier] === item[identifier])
            ? true
            : false
    }

    const handleAddSelectedPeople = () => {
        setPeopleAddedList([...peopleAddedList, ...selectedAssignments])
        setSelectedAssignments([])
    }

    const checkIfAddedSelected = (item) => {
        let selectedAttribute = {
            isSelected: false,
            alreadyAdded: false,
        }
        if (isSelectedToAdd(item, selectedAddedAssignments)) {
            selectedAttribute.isSelected = true
        }
        return selectedAttribute
    }

    const handleAddedPeopleChange = (item) => {
        if (item?.isInherited && item?.isAssigned) {
            return
        }
        setSelectedAddedAssignments(
            addRemoveOnSelectionFromList(item, selectedAddedAssignments),
        )
    }

    const addRemoveOnSelectionFromList = (item, list) => {
        let selectedValues = [...list]
        const memberIndex = selectedValues.findIndex(
            (x) => x[identifier] === item[identifier],
        )
        if (memberIndex < 0) {
            selectedValues.push(item)
        } else {
            selectedValues.splice(memberIndex, 1)
        }

        return selectedValues
    }

    const handleRemove = (actionType) => {
        if (actionType === 'all') {
            setPeopleAddedList((pre) =>
                pre.filter((i) => i.isAssigned && i.isInherited),
            )
            setSelectedAddedAssignments([])
        } else {
            const listAfterSelectedRemoved = peopleAddedList.filter((item) => {
                const isItemSelected = selectedAddedAssignments.find(
                    (x) => x[identifier] === item[identifier],
                )
                if (!isItemSelected) {
                    return item
                }
            })
            setPeopleAddedList(listAfterSelectedRemoved)
            setSelectedAddedAssignments([])
        }
    }

    const postAdd = () => {
        handleModalClose()
    }

    const preAdd = () => {
        const itemToAdd = cartHelpers.assignResourceToAssignee({
            assignmentType: 'Add',
            targetAssigneeId: targetAssigneeId,
            selectedList: peopleAddedList,
            timeConstrained: timeConstrained,
            resource: targetAssigneeResource,
            startDateUtc: selectedStartDate ? selectedStartDate : null,
            endDateUtc: selectedEndDate ? selectedEndDate : null,
            identifier,
            resourceTypeRoleId: resourceTypeRoleId_ConfigName
                ? configSettings?.value
                : poilicyAssignee?.id,
        })

        if (!itemToAdd) return undefined
        return itemToAdd
    }

    const handleAddToCart = () => {
        if (!onSubmit) {
            const itemToAdd = preAdd()
            if (itemToAdd === undefined) return
            addItemsToCart(itemToAdd).then(() => postAdd())
        } else {
            if (!allowRemovingAssignments) {
                onSubmit(peopleAddedList)
            } else {
                if (addSelectedOnly) {
                    const _alreadyAssigned = list.filter((l) => l.isAssigned)
                    onSubmit(peopleAddedList, _alreadyAssigned)
                } else {
                    const filtered = alreadyAssignedValues.filter(
                        (el) =>
                            !peopleAddedList.some(
                                (i) => i[identifier] === el[identifier],
                            ),
                    )
                    onSubmit([
                        ...peopleAddedList.filter((i) => !i.isAssigned),
                        ...filtered,
                    ])
                    setIsSubmitting(true)
                }
                if (closeOnSubmit) {
                    handleModalClose()
                }
            }
        }
    }

    const checkIfAlreadyAddedToCart = (item) => {
        const cartItems = cart?.cartItems || []
        if (cartItems?.length) {
            return cartItems.find(
                (x) => x.requestableResourceId === item[identifier],
            )
                ? true
                : false
        }
        return false
    }
    const getTooltipTitle = () => {
        if (showPermissionLevel && !poilicyAssignee) {
            return t('PermissionLevelRequired')
        }
        if (!allowRemovingAssignments && peopleAddedList.length <= 0) {
            return t('NoChangeInAssignment')
        } else {
            const anyChangeinAssignment = peopleAddedList.every((first) =>
                alreadyAssignedValues.some(
                    (second) => first[identifier] === second[identifier],
                ),
            )
            if (
                peopleAddedList.length === alreadyAssignedValues.length &&
                anyChangeinAssignment
            ) {
                return t('NoChangeInAssignment')
            }
        }
        return ''
    }
    const hasTooltipValue = getTooltipTitle() !== '' || configSettingsLoading

    const handleDoubleClickForAdding = (e, item) => {
        if (
            checkIfAlreadyAddedToSelectedList(item) ||
            checkIfAlreadyAddedToCart(item)
        ) {
            return
        }
        setPeopleAddedList([...peopleAddedList, item])
        setSelectedAddedAssignments((s) =>
            s.filter((el) => el[identifier] !== item[identifier]),
        )
    }
    const handleDoubleClickForRemoving = (e, item) => {
        if (item?.isInherited && item?.isAssigned) {
            return
        }
        const listAfterSelectedRemoved = peopleAddedList.filter((el) => {
            return item[identifier] !== el[identifier]
        })
        setPeopleAddedList(listAfterSelectedRemoved)
        setSelectedAssignments((s) =>
            s.filter((el) => el[identifier] !== item[identifier]),
        )
    }
    useEffect(() => {
        if (numberOfPages_RightSide < page_RightSide) {
            setPage_RightSide(
                numberOfPages_RightSide > 0 ? numberOfPages_RightSide : 1,
            )
        }
    }, [peopleAddedList])
    return (
        <>
            {(labelKey || startIcon) && (
                <Button
                    onClick={() => setOpenAssigneeModal(true)}
                    style={{
                        borderRadius: '4px',
                        backgroundColor: '#307fc1',
                        color: '#ffffff',
                        fontSize: '14px',
                        height: '100%',
                        '&:hover': {
                            backgroundColor: '#307fc1',
                        },
                    }}
                >
                    <Box style={{ display: 'flex' }}>
                        {startIcon}
                        {t(labelKey)}
                    </Box>
                </Button>
            )}
            <CustomDialog
                open={openAssigneeModal}
                onClose={() => handleModalClose()}
                title={t('')}
            >
                <AssignmentsModal>
                    <CloseIconeBox onClick={() => handleModalClose()}>
                        <Icon
                            name="Close"
                            width="12px"
                            height="13px"
                            color="#959598"
                        />
                    </CloseIconeBox>

                    <Typography variant="h6">
                        {headerText ? (
                            <span>
                                <strong>{headerText.dynamicText}</strong>
                                {` - ${t(headerText.text)}`}
                            </span>
                        ) : (
                            t(labelKey)
                        )}
                    </Typography>
                    <Box marginTop="10px">
                        <PageContextProvider key={'PermissionLevelSelection'}>
                            {showPermissionLevel && openAssigneeModal && (
                                <>
                                    <PermissionLevels
                                        value={poilicyAssignee}
                                        permissionLevelProps={
                                            permissionLevelProps
                                        }
                                        onChange={(e) =>
                                            handlePolicyAssigneChange(e)
                                        }
                                        targetAssigneeId={targetAssigneeId}
                                    />
                                    <Divider />
                                </>
                            )}
                        </PageContextProvider>
                        {(!showPermissionLevel || poilicyAssignee) && (
                            <Fragment>
                                <Box classes={{ root: classes.SearchPeople }}>
                                    <Box className={classes.searchBar}>
                                        <Search
                                            handleSearch={handleSearchChange}
                                            placeholder={t(searchPlaceHolder)}
                                            value={searchKey}
                                            handleClear={handleClear}
                                            outlined
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex">
                                    <Box>
                                        <Typography
                                            style={{
                                                fontSize: '10px',
                                                color: '#b4b4b4',
                                                marginTop: '10px',
                                            }}
                                        >
                                            {t(listLabels.unselectedListLabel)}
                                        </Typography>
                                        <Box marginTop="10px">
                                            <SelectAssignmentSection>
                                                {loading && (
                                                    <Box
                                                        style={{
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <Loader />
                                                    </Box>
                                                )}
                                                {paginatedData_LeftSide?.length
                                                    ? paginatedData_LeftSide.map(
                                                          (item, index) => (
                                                              <MemberIAMShopAssigment
                                                                  isAlreadyAddedToCart={checkIfAlreadyAddedToCart(
                                                                      item,
                                                                  )}
                                                                  handleSelectPeople={
                                                                      handleSelectPeople
                                                                  }
                                                                  isSelectedAttribute={checkIfSelected(
                                                                      item,
                                                                  )}
                                                                  isUnAssigned={
                                                                      item?.isAssigned
                                                                  }
                                                                  item={item}
                                                                  index={index}
                                                                  handleDoubleClick={
                                                                      handleDoubleClickForAdding
                                                                  }
                                                                  isLeftSide={
                                                                      true
                                                                  }
                                                                  secondaryAttributes={
                                                                      secondaryAttributes
                                                                  }
                                                              />
                                                          ),
                                                      )
                                                    : null}
                                            </SelectAssignmentSection>
                                        </Box>
                                        <PaginationMain>
                                            {allowRemovingAssignments &&
                                            numberOfPages_LeftSide > 1 ? (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    width="100%"
                                                    justifyContent="center"
                                                    padding="5px 11px"
                                                >
                                                    <Pagination
                                                        count={
                                                            numberOfPages_LeftSide
                                                        }
                                                        size="large"
                                                        siblingCount={1}
                                                        page={page_LeftSide}
                                                        onChange={(
                                                            _,
                                                            value,
                                                        ) => {
                                                            setPage_LeftSide(
                                                                value,
                                                            )
                                                        }}
                                                        variant={'rounded'}
                                                    />
                                                </Box>
                                            ) : (
                                                pagination
                                            )}
                                        </PaginationMain>
                                    </Box>

                                    <ButtonSection>
                                        <Box
                                            classes={{
                                                root: classes.ButtonAddSection,
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleAddSelectedPeople()
                                                }
                                                disabled={
                                                    !selectedAssignments?.length
                                                }
                                                className={classNames({
                                                    [classes.activeButton]:
                                                        selectedAssignments?.length,
                                                })}
                                                style={{ borderRadius: '5px' }}
                                            >
                                                {t('Add')}
                                                <ArrowSmallIcon
                                                    color="#8b909a"
                                                    fill="#8b909a"
                                                    direction="right"
                                                />
                                            </Button>
                                        </Box>
                                        <Box
                                            classes={{
                                                root: classes.ButtonRemoveSection,
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleRemove('selected')
                                                }
                                                disabled={
                                                    !selectedAddedAssignments?.length
                                                }
                                                className={classNames({
                                                    [classes.activeButtonForRemove]:
                                                        selectedAddedAssignments?.length,
                                                })}
                                            >
                                                <ArrowSmallIcon
                                                    color="#8b909a"
                                                    fill="#8b909a"
                                                    direction="left"
                                                />
                                                {t('Remove')}
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    handleRemove('all')
                                                }
                                                disabled={
                                                    !peopleAddedList?.length
                                                }
                                                backgroundColor="#307fc1"
                                            >
                                                <ArrowSmallIcon
                                                    color="#ff4d5f"
                                                    fill="#ff4d5f"
                                                    direction="left"
                                                />
                                                {t('RemoveAll')}
                                            </Button>
                                        </Box>
                                    </ButtonSection>
                                    <Box>
                                        <Typography
                                            style={{
                                                fontSize: '10px',
                                                color: '#b4b4b4',
                                                marginTop: '20px',
                                            }}
                                        >
                                            {t(listLabels.selectedListLabel)}
                                        </Typography>
                                        <Box marginTop="10px">
                                            <SelectAssignmentSection>
                                                {paginatedData_RightSide?.length
                                                    ? paginatedData_RightSide.map(
                                                          (item, index) => (
                                                              <MemberIAMShopAssigment
                                                                  isAlreadyAddedToCart={checkIfAlreadyAddedToCart(
                                                                      item,
                                                                  )}
                                                                  handleSelectPeople={
                                                                      handleAddedPeopleChange
                                                                  }
                                                                  isSelectedAttribute={checkIfAddedSelected(
                                                                      item,
                                                                  )}
                                                                  isUnAssigned={
                                                                      item?.isAssigned
                                                                  }
                                                                  item={item}
                                                                  index={index}
                                                                  isRightSide={
                                                                      true
                                                                  }
                                                                  handleDoubleClick={
                                                                      handleDoubleClickForRemoving
                                                                  }
                                                                  secondaryAttributes={
                                                                      secondaryAttributes
                                                                  }
                                                                  isInherited={
                                                                      item?.isInherited &&
                                                                      item?.isAssigned
                                                                  }
                                                              />
                                                          ),
                                                      )
                                                    : null}
                                            </SelectAssignmentSection>
                                        </Box>
                                        <PaginationMain>
                                            {numberOfPages_RightSide > 1 && (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    width="100%"
                                                    justifyContent="center"
                                                    padding="5px 11px"
                                                >
                                                    <Pagination
                                                        count={
                                                            numberOfPages_RightSide
                                                        }
                                                        size="large"
                                                        siblingCount={1}
                                                        page={page_RightSide}
                                                        onChange={(
                                                            _,
                                                            value,
                                                        ) => {
                                                            setPage_RightSide(
                                                                value,
                                                            )
                                                        }}
                                                        variant={'rounded'}
                                                    />
                                                </Box>
                                            )}
                                        </PaginationMain>
                                    </Box>
                                </Box>

                                <Divider />
                            </Fragment>
                        )}
                        {!hideDurationSelection && (
                            <>
                                <Box
                                    style={{ height: '40px' }}
                                    classes={{ root: classes.TimeLabelSection }}
                                >
                                    <TimeConstrainedControl
                                        selectedStartDate={selectedStartDate}
                                        setSelectedStartDate={handleStartDate}
                                        selectedEndDate={selectedEndDate}
                                        setSelectedEndDate={handleEndDate}
                                        maxEndDate={null}
                                        hasInvalidDates={true}
                                        validationMessage={''}
                                        toggle={timeConstrained}
                                        setToggle={() =>
                                            handleTimeConstrainedChange()
                                        }
                                        disabled={false}
                                        isEndDateDisabled={false}
                                    />
                                </Box>
                                <Divider />
                            </>
                        )}
                        <ModalFooter>
                            <Tooltip title={getTooltipTitle()}>
                                <div>
                                    <Button
                                        loading={isLoading || submitLoader}
                                        onClick={() => handleAddToCart()}
                                        backgroundColor="#307fc1"
                                        disabled={hasTooltipValue}
                                        disabledStyles={{
                                            backgroundColor:
                                                '#aab0b4 !important',
                                            borderColor: '#aab0b4 !important',
                                            color: '#ffffff !important',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {submitButtonLabelKey
                                            ? t(submitButtonLabelKey, {
                                                  count: peopleAddedList.length,
                                              })
                                            : t(labelKey)}
                                    </Button>
                                </div>
                            </Tooltip>
                            <Button onClick={() => handleModalClose()}>
                                {t('Cancel')}
                            </Button>
                        </ModalFooter>
                    </Box>
                </AssignmentsModal>
            </CustomDialog>
        </>
    )
}

export default AddAssignment
