import CheckInButton from './CheckIn'
import CheckOutButton from './CheckOut'
import { StatusIndicator } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreenFalse, useUrlGenerator } from 'packages/core'
import { useAllApplications } from 'hooks'
import { Button, TextFormatter } from 'components'
import { Box } from '@material-ui/core'

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

const displayMessageStyle = {
    minWidth: '130px',
    maxWidth: '130px',
    width: '130px',
    color: '#282828',
    fontSize: '12px',
}

const displayMessageStyleSmallScreen = {
    minWidth: '220px',
    maxWidth: '220px',
    width: '220px',
    color: '#282828',
    fontSize: '12px',
    paddingLeft: '16px',
}

const CredentialStatusButton = ({ data }) => {
    const { item } = data
    const { t } = useTranslation()
    const { getItShopUrl } = useUrlGenerator(useAllApplications)
    const isSmallScreen = useIsSmallScreenFalse()

    let displayMessage

    const checkedOutBySomeoneElse = item.isCheckedOutBySomeoneElse

    if (item.canRequestAccess) {
        displayMessage = t('CanRequestAccessMessage')
    } else if (item.createdFromAzure) {
        displayMessage = t('CredentialCreatedFromAzureMessage')
    } else if (!item.createdFromEIDAndVaulted) {
        displayMessage = t('CredentialNotVaultedMessage')
    }

    if (item.isExpired) {
        return (
            <StatusIndicator
                className="Status-Indicator"
                variant="Expired"
                label={t('Expired')}
                size="medium"
                minWidth="120px"
            />
        )
    }

    if (item.canCheckIn) {
        return <CheckInButton data={data} drawerView={false} />
    } else if (item.canCheckOut || checkedOutBySomeoneElse) {
        return (
            <CheckOutButton
                data={data}
                drawerView={false}
                checkedOutBySomeoneElse={checkedOutBySomeoneElse}
            />
        )
    } else if (isSmallScreen) {
        return (
            <Box display="flex">
                {item.canRequestAccess && (
                    <Button.RequestAccess
                        label={t('RequestAccess')}
                        parentStyle={{ minWidth: '100px' }}
                        redirectURL={getItShopUrl(
                            `/Credentials?selected=${item.id}`,
                        )}
                    />
                )}
                <TextFormatter
                    value={displayMessage}
                    tooltipProps={tooltipProps}
                    maxCharacters={100}
                    style={displayMessageStyleSmallScreen}
                />
            </Box>
        )
    }
    return (
        <Box display="flex">
            <TextFormatter
                value={displayMessage}
                tooltipProps={tooltipProps}
                maxCharacters={100}
                style={displayMessageStyle}
            />
            {item.canRequestAccess && (
                <Button.RequestAccess
                    label={t('RequestAccess')}
                    parentStyle={{ minWidth: '100px', marginLeft: '25px' }}
                    redirectURL={getItShopUrl(
                        `/Credentials?selected=${item.id}`,
                    )}
                />
            )}
        </Box>
    )
}

export default CredentialStatusButton
