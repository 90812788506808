import { useState, useEffect } from 'react'
import { Box, createStyles, makeStyles, Paper } from '@material-ui/core'
import { useDebounce, useIsSmallScreenFalse, useQuery } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { useManagementRole } from 'hooks'
import Search from 'components/Filters/Search'
import { ItemDetails } from 'components'
import { LocalRights } from './LocalRights'
import { LocalSensitiveFunctions } from './LocalSensitiveFunctions'
import { LinkedApplications } from './LinkedApplications'
import { MainOverview } from './MainOverview'
import { useTranslation } from 'react-i18next'
import { PageContextProvider } from 'pageContext'
import Eligibility from './Eligibility'
import { useHistory } from 'react-router'
import {
    getAccessibleTabs,
    getTabsBasedOnAccess,
    updateUrlQueryParams,
} from 'utils'
const smallScreenWidth = 960

const useStyles = makeStyles(() =>
    createStyles({
        totalCountLabel: {
            borderRadius: '8px',
            minWidth: '29px',
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '7px',
            marginTop: '3px',
            marginLeft: '5px',
            fontSize: '11px',
        },
        paperContainer: {
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                overflow: 'hidden',
            },
        },
    }),
)

const subTabs = [
    {
        name: 'Eligibility',
        value: 'eligibility',
        labelKey: 'Eligibility',
        display: true,
        requireAccess: {
            control:
                'ResourceAdmin-ManagementRole-Details-Overview-AccessGranted',
        },
    },
    {
        name: 'Sensitive Functions',
        value: 'localSensitiveFunctions',
        labelKey: 'SensitiveFunctions',
        display: true,
        requireAccess: {
            control:
                'ResourceAdmin-ManagementRole-Details-Overview-SensitiveFunctions',
        },
    },
    {
        name: 'Rights',
        value: 'localRights',
        labelKey: 'Rights',
        display: true,
        requireAccess: {
            control: 'ResourceAdmin-ManagementRole-Details-Overview-Rights',
        },
    },

    {
        name: 'Applications',
        value: 'applications',
        labelKey: 'Applications',
        display: true,
        requireAccess: {
            control:
                'ResourceAdmin-ManagementRole-Details-Overview-Applications',
        },
    },
]

export const Overview = (props) => {
    const { managementRoleId, basicAttributes } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()
    const query = useQuery()
    const { hasAccessToControl } = useSubcomponents()
    const history = useHistory()
    const [allAccessibleTabs, setAllAccessibleTabs] = useState([])
    const [subActiveTab, setSubActiveTab] = useState(null)
    const [tempTab, setTempTab] = useState(null)

    const { isLoading: detailsLoading, data: managementRole } =
        useManagementRole(managementRoleId)

    useEffect(() => {
        if (managementRole) {
            const allTabs = getTabsBasedOnAccess(subTabs, hasAccessToControl)
            const queryActiveSubTab =
                query.get('subActiveTab') ||
                (allTabs?.length ? allTabs[0].value : '')
            if (queryActiveSubTab) {
                const activeTabInfo = getAccessibleTabs(
                    query,
                    history,
                    subTabs,
                    null,
                    queryActiveSubTab,
                    hasAccessToControl,
                    'activeTab',
                    'subActiveTab',
                )
                setAllAccessibleTabs(activeTabInfo.allAccessibleTabs)
                setSubActiveTab(activeTabInfo.querySubActiveTab)
                setTempTab(activeTabInfo.querySubActiveTab)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managementRole])

    const [searchKey, setSearchKey] = useState({
        localRights: '',
        localSensitiveFunctions: '',
        applications: '',
        eligibility: '',
    })

    const debouncedSearchValue = useDebounce(searchKey[subActiveTab])

    let queryData = {
        [subActiveTab]: debouncedSearchValue,
    }
    const handleSearchChange = (keyword) => {
        setSearchKey({ [subActiveTab]: keyword })
    }
    const handleClear = () => {
        setSearchKey({ [subActiveTab]: '' })
    }

    useEffect(() => {
        if (queryData[subActiveTab] === '') {
            if (subActiveTab !== tempTab) {
                setSubActiveTab(tempTab)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryData])

    return (
        <Box>
            <Paper
                className={classes.paperContainer}
                style={{
                    padding: isSmallScreen ? '23px' : '32px',
                    paddingBottom: 0,
                }}
            >
                <MainOverview
                    managementRole={managementRole}
                    detailsLoading={detailsLoading}
                    basicAttributes={basicAttributes}
                />
                {allAccessibleTabs.length > 0 && (
                    <Box
                        style={{
                            marginLeft: '-18px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 15,
                        }}
                    >
                        <ItemDetails.Tabs
                            value={subActiveTab}
                            variant={'scrollable'}
                            style={{ border: 'none' }}
                            onChange={(_, value) => {
                                setTempTab(value)
                                updateUrlQueryParams(
                                    query,
                                    history,
                                    [{ key: 'subActiveTab', value: value }],
                                    [],
                                )
                                setSearchKey({
                                    [subActiveTab]: '',
                                })
                            }}
                        >
                            {allAccessibleTabs.map((tab, index) => {
                                return (
                                    <ItemDetails.Tabs.Tab
                                        data-protectedsubcomponent={
                                            tab.requireAccess.control
                                        }
                                        key={tab.value + index}
                                        value={tab.value}
                                        label={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        marginTop: 5,
                                                        marginRight: 5,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    {t(tab.labelKey)}
                                                </span>
                                            </div>
                                        }
                                    />
                                )
                            })}
                        </ItemDetails.Tabs>
                        {!isSmallScreen && subActiveTab !== 'eligibility' && (
                            <Box
                                width="308px"
                                display="flex"
                                alignItems="center"
                            >
                                <Search
                                    handleSearch={handleSearchChange}
                                    placeholder={t('SearchAndFilter')}
                                    value={
                                        searchKey[subActiveTab]
                                            ? searchKey[subActiveTab]
                                            : ''
                                    }
                                    handleClear={handleClear}
                                    outlined
                                />
                            </Box>
                        )}
                    </Box>
                )}
            </Paper>
            {subActiveTab === 'eligibility' && (
                <PageContextProvider key="RequestViewManagementRolesEligbility">
                    {managementRole?.resourceTypeId && (
                        <Eligibility
                            roleId={managementRoleId}
                            resourceTypeId={managementRole?.resourceTypeId}
                            resourceId={managementRole.resourceId}
                        />
                    )}
                </PageContextProvider>
            )}
            {subActiveTab === 'localSensitiveFunctions' && (
                <PageContextProvider key="RequestViewManagementRolesLocalSensitiveFunctions">
                    <LocalSensitiveFunctions
                        managementRoleId={managementRoleId}
                        queryData={queryData['localSensitiveFunctions']}
                    />
                </PageContextProvider>
            )}
            {subActiveTab === 'localRights' && (
                <PageContextProvider key="RequestViewManagementRolesLocalRights">
                    <LocalRights
                        managementRoleId={managementRoleId}
                        queryData={queryData['localRights']}
                    />
                </PageContextProvider>
            )}
            {subActiveTab === 'applications' && (
                <PageContextProvider key="RequestViewManagementRolesLinkedApplications">
                    <LinkedApplications
                        managementRoleId={managementRoleId}
                        queryData={queryData['applications']}
                    />
                </PageContextProvider>
            )}
        </Box>
    )
}
