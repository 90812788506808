import { Box, Typography, styled } from '@material-ui/core'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import {
    useAppManagementRoleMembers,
    useDeleteManagementRoleMember,
} from 'hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import { Avatar, ToggleSwitch, WarningModal } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { getFullImageUrl, imagesDisabled } from 'utils'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { PageContextProvider } from 'pageContext'
import AddAssignment from 'components/ApplicationBanner/AddAssignments/AddAssignment'
import useSubcomponents from 'useSubcomponents'
import { useDebounce } from 'packages/core'

const canAddNewAccessAssignmentControlName =
    'ResourceAdmin-ApplicationDetails-Members-AddNewAccessAssignment-Control'
const canChangeBetweenDirectAndResultantMembersControlName =
    'ResourceAdmin-ApplicationDetails-Members-ChangeMembersType-Control'
const canRemoveMultipleMembersControlName =
    'ResourceAdmin-ApplicationDetails-Members-RemoveMultipleMembers-Control'
const hasAccessToUIActionsControlName =
    'ResourceAdmin-ApplicationDetails-Members-UIActionsButton-Control'

export const SwitchContainer = styled(({ value, ...rest }) => (
    <Box {...rest} />
))(({ value }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: value ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: value ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '10px',
    },
}))
const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})
const RemoveSelectedItem = styled(Box)({
    width: '290px',
    '& button': {
        '& div': {
            height: '40px !important',
        },
    },
})
const Members = ({ roleId }) => {
    const { t } = useTranslation()
    const [selected, setSelected] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [isDirect, setIsDirect] = useState(true)
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }
    const { list, loading, loadingMore, pagination } =
        useAppManagementRoleMembers(roleId, !isDirect, queryData)
    const [deleteMember, { isLoading: isDeletingMember, isSuccess }] =
        useDeleteManagementRoleMember(roleId)

    useEffect(() => {
        setSelected([])
    }, [list])

    const attributes = [
        {
            label: 'DisplayName',
            name: 'friendlyName',
            sortable: true,
            hideValue: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-DisplayName',
            },
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box style={{ display: 'flex', gap: 10 }}>
                            {!imagesDisabled && (
                                <Avatar
                                    src={getFullImageUrl(data.imageThumbUrl)}
                                />
                            )}
                            <Typography style={{ fontSize: '13px' }}>
                                {data.friendlyName}
                            </Typography>
                        </Box>
                    )
                },
            },
        },
        {
            label: 'Login',
            name: 'login',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-Login',
            },
        },
        {
            name: 'company',
            label: 'Company',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-Company',
            },
        },
        {
            name: 'email',
            label: 'Email',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-Email',
            },
        },
        {
            hideValue: true,
            label: 'Enabled',
            sortable: true,
            component: {
                componentName: 'renderer',
                render: (data) => (data.enabled === true ? t('Yes') : t('No')),
            },
            requireAccess: {
                control: 'ResourceAdmin-ApplicationDetails-Members-Enabled',
            },
        },

        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        hasAccessToControl(hasAccessToUIActionsControlName) && (
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                }}
                            >
                                <UIActionsGearButton item={data} />
                            </Box>
                        )
                    )
                },
            },
        },
    ]

    const handleDelete = () => {
        let personMgmtRolesToRemove = []
        selected.forEach((personId) =>
            personMgmtRolesToRemove.push({
                personID: personId,
                managementRoleID: roleId,
            }),
        )
        deleteMember(personMgmtRolesToRemove).then(() => {
            setShowWarningModal(false)
        })
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredList = list.map((item) => item.id)
            setSelected(filteredList)
        }
    }

    const selectAllAction = hasAccessToControl(
        canRemoveMultipleMembersControlName,
    )
        ? {
              render: () => {
                  return (
                      <Box padding={'0px 32px'}>
                          <RemoveSelectedItem
                              data-protectedsubcomponent={
                                  canRemoveMultipleMembersControlName
                              }
                          >
                              <BulkRemoveButton
                                  selectedCount={selected.length}
                                  label={t('RemoveSelectedMembers', {
                                      count: selected.length,
                                  })}
                                  onClick={handleBulkAction}
                              />
                          </RemoveSelectedItem>
                      </Box>
                  )
              },
          }
        : null

    const handleBulkAction = () => {
        setShowWarningModal(true)
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                padding="16px 30px"
            >
                <PageContextProvider key="AddNewMembersManagementRolesOverview">
                    {hasAccessToControl(
                        canAddNewAccessAssignmentControlName,
                    ) && (
                        <Box
                            data-protectedsubcomponent={
                                canAddNewAccessAssignmentControlName
                            }
                        >
                            <AddAssignment
                                resourceType={'ManagementRoles'}
                                labelKey={'AddNewPeopleAsMembers'}
                                searchUrl={`api/people`}
                                targetAssigneeId={roleId}
                                startIcon={
                                    <Icon
                                        name={'Plus'}
                                        fill="white"
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    />
                                }
                            />
                        </Box>
                    )}
                </PageContextProvider>
                <Box
                    style={{
                        margin: '0px 0px 0px auto',
                        display: 'flex',
                        gap: 20,
                    }}
                >
                    {hasAccessToControl(
                        canChangeBetweenDirectAndResultantMembersControlName,
                    ) && (
                        <Box
                            data-protectedsubcomponent={
                                canChangeBetweenDirectAndResultantMembersControlName
                            }
                        >
                            <SwitchContainer value={isDirect}>
                                <Typography className="Label">
                                    {t('Resultant')}
                                </Typography>
                                <ToggleSwitch
                                    value={isDirect}
                                    onChange={() => {
                                        setIsDirect((p) => !p)
                                    }}
                                />
                                {t('Direct')}

                                <Typography className="SubLabel"></Typography>
                            </SwitchContainer>
                        </Box>
                    )}
                    <Box
                        width="240px"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <SearchMenuBarFilter>
                            <Search
                                handleSearch={handleSearchChange}
                                placeholder={t('SearchAndFilter')}
                                value={searchKey}
                                handleClear={handleClear}
                                outlined
                            />
                        </SearchMenuBarFilter>
                    </Box>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    selectAllAction={isDirect && selectAllAction}
                    rowSelection={isDirect}
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selected}
                    handleClick={handleRowSelection}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
            <WarningModal
                title={t('DeleteMembers')}
                description={t('MembersDeleteMessage')}
                yesLabel={t('Delete')}
                noLabel={t('Cancel')}
                open={showWarningModal}
                cancel={() => setShowWarningModal(false)}
                proceeding={isDeletingMember}
                proceed={handleDelete}
            />
        </Box>
    )
}

export default Members
