import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles } from '@material-ui/core'
import { useMembershipChanges } from 'hooks'
import { useDebounce } from 'packages/core'
import TableGrid from 'components/TableGrid'
import GroupChangeType from 'components/DynamicComponents/GroupChangeType'
import CalculateTimeString from 'components/DynamicComponents/CalculateTimeString'
import BooleanValue from 'components/DynamicComponents/BooleanValue'
import Search from 'components/Filters/Search'

const useTableStyles = makeStyles({
    headGrey: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
        height: '80px',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 16px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
    },
    tableHeaderCell: {
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: '#7d7c7c',
    },
})

export const MembershipChanges = ({ groupId }) => {
    const { t } = useTranslation()
    const tableClasses = useTableStyles()

    const [sorting, setSorting] = useState({ sortOrder: null, sortBy: null })
    const [searchKey, setSearchKey] = useState('')

    const handleSort = (sorting) => {
        setSorting(sorting)
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
        sorting,
    }

    const {
        list,
        loading: isLoading,
        pagination,
    } = useMembershipChanges(groupId, queryData)

    const attributes = [
        {
            label: t('ChangeType'),
            name: 'groupAccountChangeType',
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
            resolve: (item) => (
                <GroupChangeType
                    data={{ item }}
                    attribute={{ name: 'groupAccountChangeType' }}
                />
            ),
        },
        {
            label: t('WhenAgo'),
            name: 'when',
            sortable: true,
            sortBy: 'modifiedDate',
            style: {
                color: '#282828',
                fontSize: '13px',
                width: '180px',
            },
            resolve: (item) => (
                <CalculateTimeString
                    data={{ item }}
                    attribute={{ name: 'when' }}
                />
            ),
        },
        {
            label: t('SourceOfChange'),
            name: 'changeSourceName',
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            name: 'accountFriendlyName',
            label: t('AccountDisplayName'),
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            name: 'isHighSecurityGroup',
            label: t('IsHighSecurityGroup'),
            sortable: true,
            style: {
                color: '#282828',
                fontSize: '13px',
                width: '100px',
            },
            resolve: (item) => (
                <BooleanValue
                    data={{ item }}
                    attribute={{ name: 'isHighSecurityGroup ' }}
                />
            ),
        },
        {
            name: 'groupAccountStoreFriendlyName',
            label: t('AccountDirectory'),
            sortable: true,
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="end"
                paddingBottom="16px"
            >
                <Box width="308px" display="flex" alignItems="center">
                    <Search
                        handleSearch={handleSearchChange}
                        placeholder={t('SearchFilterMembers')}
                        value={searchKey}
                        handleClear={handleClear}
                        outlined
                    />
                </Box>
            </Box>

            <TableGrid
                loading={isLoading}
                data={list}
                attributes={attributes}
                tableClasses={tableClasses}
                sorting={sorting}
                onSort={handleSort}
            />

            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '40px',
                }}
            >
                <Box>{pagination}</Box>
            </Box>
        </>
    )
}
