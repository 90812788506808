import { Box, makeStyles, createStyles } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import React, { Fragment } from 'react'
import FormTextFieldElement from './TextFields'
const useStyles = makeStyles((theme) =>
    createStyles({
        textFeildContainer: {
            width: '100%',
            '& label': {
                fontSize: '12px',
                color: 'rgb(180, 180, 180)',
                textTransform: 'uppercase',
            },
        },
        textFeildIcon: {
            position: 'absolute',
            top: '28px',
            right: '0',
            borderRadius: '0 4px 4px 0',
            height: '38px',
            width: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme?.palette?.primary?.main,
        },
    }),
)
const FormTextFieldWithIconElement = ({
    item,
    handleChange,
    value,
    errorMessage,
}) => {
    const classes = useStyles()
    return (
        <Fragment>
            <Box
                data-protectedsubcomponent={`ResourceAdmin-PersonDetails-${item.fieldName}-Control`}
                className={classes.textFeildContainer}
            >
                <Box position="relative">
                    <FormTextFieldElement
                        handleChange={handleChange}
                        value={value}
                        item={item}
                        errorMessage={errorMessage}
                    />
                    <Box className={classes.textFeildIcon}>
                        <Icon name="Edit" color="#fff" />
                    </Box>
                </Box>
            </Box>
        </Fragment>
    )
}

export default FormTextFieldWithIconElement
