import React from 'react'
import AppManagementRoles from 'components/ApplicationBanner/Applications Management Roles/App Management Roles/AppManagementRoles'
import ApplicationRoleDefinitions from 'components/ApplicationBanner/RoleDefinitions/RoleDefinitions'
import ApplicationRights from 'components/ApplicationBanner/AzureApplicationAppRights/ApplicationRights'
import PbacResourceTypes from './ResourceTypes/index'
import { PageContextProvider } from 'pageContext'

const PbacDefinitinos = ({
    subActiveTab,
    applicationId,
    resourceSystemModuleId,
    id,
    resourceId,
}) => {
    const renderContent = () => {
        if (subActiveTab === 'appRights') {
            return (
                <PageContextProvider key="RequestViewApplicationPbacDefinitionAppRights">
                    <ApplicationRights
                        applicationId={id}
                        resourceId={resourceId}
                        isPbacApplication={true}
                    />
                </PageContextProvider>
            )
        } else if (subActiveTab === 'roleDefinitions') {
            return (
                <PageContextProvider key="RequestViewApplicationPbacDefinitionRoleDefinitions">
                    <ApplicationRoleDefinitions
                        applicationId={applicationId}
                        resourceSystemModuleId={resourceSystemModuleId}
                    />
                </PageContextProvider>
            )
        } else if (subActiveTab === 'appManagementRoles') {
            return (
                <PageContextProvider key="RequestViewApplicationPbacDefinitionManagementRoles">
                    <AppManagementRoles applicationId={applicationId} />
                </PageContextProvider>
            )
        }
        if (subActiveTab === 'pbacResourceTypes') {
            return (
                <PageContextProvider key="RequestViewApplicationPbacDefinitionResourceTypes">
                    <PbacResourceTypes
                        applicationId={applicationId}
                        resourceSystemModuleId={resourceSystemModuleId}
                    />
                </PageContextProvider>
            )
        }
        return <></>
    }
    return renderContent()
}

export default PbacDefinitinos
