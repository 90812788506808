import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import moment from 'moment'
import {
    useCheckOutCredential,
    useCurrentPerson,
    useAccessRequestPolicy,
    useCheckIfMasterPasswordExist,
} from 'hooks'
import { EidButton, useNotification } from 'packages/eid-ui'
import { CheckOutAdvanced } from 'components/CheckOutAdvanced'
import { MasterPasswordLock } from 'components'
import { isNilOrEmpty } from 'packages/core'

const CheckOut = ({ data, drawerView, checkedOutBySomeoneElse }) => {
    const { item } = data
    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()
    const [checkOutCredential, { isLoading: isCheckingOut }] =
        useCheckOutCredential(item.id)
    const accessRequestPolicy = useAccessRequestPolicy(item.requestPolicyId)

    const [showAdvancedCheckout, setShowAdvancedCheckout] = useState(false)
    const [startDateTime, setStartDateTime] = useState(
        (checkedOutBySomeoneElse && item.currentCheckOutEndTimeUtc) ||
            moment(new Date()).utc().format(),
    )
    const [duration, setDuration] = useState('')
    const [justification, setJustification] = useState('')

    const { masterPasswordExists } = useCheckIfMasterPasswordExist()
    const { showWarningMessage } = useNotification()

    useEffect(() => {
        if (accessRequestPolicy) {
            setDuration(accessRequestPolicy.defaultValueInMinutes)
        }
    }, [accessRequestPolicy])

    const violatesPolicy =
        accessRequestPolicy?.maximumValueInMinutes &&
        duration > accessRequestPolicy?.maximumValueInMinutes
    const hasInvalidDates =
        isNilOrEmpty(startDateTime) ||
        duration < 1 ||
        violatesPolicy ||
        (checkedOutBySomeoneElse &&
            moment
                .utc(item.currentCheckOutEndTimeUtc)
                .isAfter(moment.utc(startDateTime)))

    let invalidDatesMessage = ''
    if (duration < 1) {
        invalidDatesMessage = t('InvalidDuration')
    }
    if (isNilOrEmpty(startDateTime)) {
        invalidDatesMessage = t('InvalidStartDateTime')
    }
    if (
        checkedOutBySomeoneElse &&
        moment
            .utc(item.currentCheckOutEndTimeUtc)
            .isAfter(moment.utc(startDateTime))
    ) {
        invalidDatesMessage =
            t('StartDateGreaterError') +
            moment
                .utc(item.currentCheckOutEndTimeUtc)
                .clone()
                .local()
                .format('L LT')
    }
    if (violatesPolicy) {
        invalidDatesMessage = t('PolicyAllowsMaxDurationInMinError', {
            count: accessRequestPolicy?.maximumValueInMinutes,
        })
    }

    const handleCheckOut = () => {
        const checkOutData = {
            id: item.id,
            targetPersonId: currentPerson.id,
            startDateTime,
            endDateTime: moment(startDateTime)
                .clone()
                .add(duration, 'm')
                .utc()
                .format(),
            justification,
        }
        if (!masterPasswordExists()) {
            showWarningMessage(t('MasterPasswordDoesNotExist'))
            return
        }
        checkOutCredential(checkOutData)
        setShowAdvancedCheckout(false)
    }

    let checkOutOptions = [
        {
            name: 'Now',
            onClick: handleCheckOut,
            label: t('Now'),
        },
        {
            name: 'AdvancedMode',
            onClick: () => {
                setShowAdvancedCheckout(true)
            },
            label: t('AdvancedMode'),
        },
    ]

    if (checkedOutBySomeoneElse)
        checkOutOptions = checkOutOptions.filter((x) => x.name !== 'Now')

    const handleChangeStartDate = (startDate) => {
        setStartDateTime(startDate)
    }

    const handleChangeDuration = (duration) => {
        setDuration(duration)
    }

    return (
        <>
            {!drawerView ? (
                <Box>
                    <Box style={{ height: '24px' }}>
                        <EidButton.CheckOutListing
                            loading={isCheckingOut}
                            checkedOutBySomeoneElse={checkedOutBySomeoneElse}
                            label={
                                checkedOutBySomeoneElse
                                    ? t('CheckedOut')
                                    : t('CheckOut')
                            }
                            onButtonClick={
                                !checkedOutBySomeoneElse
                                    ? handleCheckOut
                                    : undefined
                            }
                            checkoutOptions={checkOutOptions}
                        />
                    </Box>
                    {checkedOutBySomeoneElse && (
                        <Typography
                            style={{
                                fontSize: '12px',
                                marginTop: '8px',
                                lineHeight: '1.0',
                            }}
                        >
                            by {item.currentCheckedOutByFriendlyName}
                        </Typography>
                    )}
                </Box>
            ) : (
                <Box minWidth="170px" height="48px">
                    <MasterPasswordLock
                        style={{
                            width: '215px',
                            height: '88px',
                            marginTop: '-20px',
                            marginLeft: '-15px',
                        }}
                    />
                    <EidButton.CheckOutDetailButton
                        loading={isCheckingOut}
                        label={t('CheckOut')}
                        onButtonClick={handleCheckOut}
                        checkoutOptions={checkOutOptions}
                        disabled={!item.canCheckOut}
                    />
                </Box>
            )}
            <CheckOutAdvanced
                onSucces={handleCheckOut}
                loading={isCheckingOut}
                open={showAdvancedCheckout}
                onClose={() => setShowAdvancedCheckout(false)}
                requestPolicy={accessRequestPolicy}
                selectedStartDate={startDateTime}
                setSelectedStartDate={handleChangeStartDate}
                minStartDate={
                    checkedOutBySomeoneElse && item.currentCheckOutEndTimeUtc
                }
                selectedDuration={duration}
                setSelectedDuration={handleChangeDuration}
                justification={justification}
                setJustification={setJustification}
                hasInvalidDates={hasInvalidDates}
                invalidDatesMessage={invalidDatesMessage}
            />
        </>
    )
}

CheckOut.defaultProps = {
    drawerView: false,
    checkedOutBySomeoneElse: false,
}

export default CheckOut
