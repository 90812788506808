import React, { Fragment, useEffect } from 'react'
import ApplicationsLayout from './ApplicationsLayout'
import { useAuthState } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import { usePageContext } from 'pageContext'

const allFilters = [
    {
        name: 'OwnedByFilter',
        requireAccess: 'ResourceAdmin-OwnedApplications-Control',
        tabs: {
            applications: true,
            claimsMappingPolicies: false,
        },
    },
    {
        name: 'showOnlyAzureApplications',
        requireAccess: 'ResourceAdmin-showOnlyAzureApplications-Control',
        tabs: {
            applications: true,
            claimsMappingPolicies: false,
        },
    },
    {
        name: 'targetSystemFilter',
        requireAccess: 'ResourceAdmin-ApplicationsTargetSystem-Control',
        tabs: {
            applications: true,
            claimsMappingPolicies: true,
        },
    },
    {
        name: 'includeBasicClaimSet',
        requireAccess: 'ResourceAdmin-ApplicationsTargetSystem-Control',
        tabs: {
            applications: false,
            claimsMappingPolicies: true,
        },
    },
    {
        name: 'applicationsAdvancedSearch',
        requireAccess: 'ResourceAdmin-ApplicationsAdvancedSearch-Control',
        tabs: {
            applications: true,
            claimsMappingPolicies: false,
        },
    },
    {
        name: 'claimMappingPoliciessAdvancedSearch',
        requireAccess: 'ResourceAdmin-ApplicationsAdvancedSearch-Control',
        tabs: {
            applications: false,
            claimsMappingPolicies: true,
        },
    },
]

const RequestViewAllApplications = () => {
    const [, dispatch] = usePageContext()
    const [{ currentUserId, friendlyName }] = useAuthState()

    const { trimControlsBasedOnAccess } = useSubcomponents()

    const filters = trimControlsBasedOnAccess(allFilters)
    const hasOwnedByFilterAccess =
        filters.map((f) => f.name).indexOf('OwnedByFilter') >= 0
    useEffect(() => {
        if (!hasOwnedByFilterAccess) {
            dispatch({
                type: 'SET_MULTIPLE_PROPS',
                payload: {
                    ownedBy: {
                        id: currentUserId,
                        friendlyName: friendlyName,
                    },
                    enableOwnedByApiCall: true,
                },
            })
        } else {
            dispatch({
                type: 'SET_PROP',
                payload: {
                    key: 'enableOwnedByApiCall',
                    value: true,
                },
            })
        }
    }, [currentUserId])

    return (
        <Fragment>
            <ApplicationsLayout _filters={filters} />
        </Fragment>
    )
}

export { RequestViewAllApplications }
