import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { useGroupMembers, useDeleteGroupMember, useGroup } from 'hooks'
import { EidButton, Avatar } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useDebounce } from 'packages/core'
import TableGrid from 'components/TableGrid'
import { ActionsButton } from 'components'
import { getFullImageUrl, imagesDisabled } from 'utils'
import DateTimeGenericComponent from '../../../../components/DynamicComponents/DateTimeGenericComponent'
import Search from 'components/Filters/Search'
import { PageContextProvider } from 'pageContext'
import AddAssignment from 'components/ApplicationBanner/AddAssignments/AddAssignment'

const useTableStyles = makeStyles({
    headGrey: {
        backgroundColor: '#fbfbfd',
        height: '32px',
        boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.05)',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
        height: '80px',
    },
    tableCell: {
        overflowWrap: 'break-word',
        maxWidth: '220px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        padding: '14px 16px',
        fontSize: '13px',
        border: 'none',
        borderBottom: '0',
        '&:first-child': {
            paddingLeft: '31px',
        },
    },
    tableHeaderCell: {
        border: 'none !important',
        padding: '16px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        color: '#7d7c7c',
    },
})

export const Members = ({ groupId }) => {
    const { t } = useTranslation()
    const tableClasses = useTableStyles()
    const [selected, setSelected] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const { data: group } = useGroup(groupId)
    const [sorting, setSorting] = useState({ sortOrder: null, sortBy: null })
    const selectedCount = selected.length
    const isSelected = selectedCount > 0

    const [deleteMember, { isLoading: isDeletingMember, isSuccess }] =
        useDeleteGroupMember(groupId)

    useEffect(() => {
        if (isSuccess) {
            setSelected([])
        }
    }, [isSuccess, isDeletingMember])
    const handleSort = (sorting) => {
        setSorting(sorting)
    }

    const handleSelect = (selectedArray) => {
        setSelected(selectedArray)
    }

    const handleDelete = () => {
        deleteMember(selected)
    }

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
        sorting,
    }

    const {
        list,
        loading: isLoading,
        pagination,
    } = useGroupMembers(groupId, queryData)

    const attributes = [
        {
            label: t(''),
            name: 'image',
            image: true,
            style: {
                width: '40px',
            },
            resolve: (item) => (
                <Avatar
                    size="medium"
                    src={getFullImageUrl(item.imageThumbUrl)}
                    style={{ boxShadow: 'rgb(110 110 111) 0px 0px 8px 0px' }}
                />
            ),
        },
        {
            label: t('AccountUserName'),
            name: 'accountPreviewName',
            sortable: true,
            style: {
                width: '200px',
                fontWeight: 'bold',
            },
        },
        {
            label: t('RbacAssigned'),
            name: 'rbacAssigned',
            sortable: true,
            style: {
                width: '160px',
            },
            resolve: (item) =>
                item.rbacAssigned === null
                    ? '-'
                    : item.rbacAssigned
                    ? t('Yes')
                    : t('No'),
        },
        {
            name: 'accountStoreName',
            label: t('AccountDomain'),
            sortable: true,
            style: {
                width: '100px',
            },
        },
        {
            name: 'accountFriendlyName',
            label: t('AccountDisplayName'),
            sortable: true,
        },
        {
            name: 'createdFromAccountStore',
            label: t('AddedInAccountStore'),
            sortable: true,
            resolve: (item) =>
                item.createdFromAccountStore === null
                    ? '-'
                    : item.createdFromAccountStore
                    ? t('Yes')
                    : t('No'),
        },
        {
            name: 'lastAttestationDateTimeUtc',
            label: t('LastCertified'),
            sortable: true,
            resolve: (item) => (
                <DateTimeGenericComponent
                    data={item}
                    attribute={{ name: 'lastAttestationDateTimeUtc' }}
                />
            ),
        },
        // TODO: Need in near future, Hiding for now so commented the code instead of deleting
        // {
        //     label: t(''),
        //     name: 'actions',
        //     resolve: (item) => <ActionsButton item={item} disabled />,
        //     style: {
        //         width: '60px',
        //     },
        // },
    ]

    const filteredAttributes = attributes.filter(
        (x) => !x.image || !imagesDisabled,
    )

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                <PageContextProvider key="AddNewMembersGroups">
                    <AddAssignment
                        resourceType={'Groups'}
                        labelKey={'AddNewMembers'}
                        searchUrl={`api/applicationRoles/assignableMembers/${groupId}`}
                        showPermissionLevel={false}
                        identifier={'accountId'}
                        targetAssigneeId={groupId}
                        targetAssigneeResource={group}
                        secondaryAttributes={["accountStoreFriendlyName", "logonName"]}
                    />
                </PageContextProvider>

                <Box width="308px" display="flex" alignItems="center">
                    <Search
                        handleSearch={handleSearchChange}
                        placeholder={t('SearchFilterMembers')}
                        value={searchKey}
                        handleClear={handleClear}
                        outlined
                    />
                </Box>
            </Box>
            <TableGrid
                loading={isLoading}
                data={list}
                attributes={filteredAttributes}
                headings={filteredAttributes.map((a) => a.label)}
                sorting={sorting}
                onSort={handleSort}
                onSelect={handleSelect}
                selected={selected}
                tableClasses={tableClasses}
                primaryKey={'accountId'}
                rowSelection
            />
            <Box
                display="flex"
                paddingTop="40px"
                justifyContent="space-between"
            >
                <Box width="300px">
                    <EidButton.DrawerActionButton
                        disabled={!isSelected}
                        fontColor="#ffffff"
                        bgColor="#E40000"
                        width="100%"
                        onClick={handleDelete}
                        loading={isDeletingMember}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            height={'12px'}
                            width={'12px'}
                        >
                            <Icon
                                name="DeleteX"
                                fill="#ffffff"
                                color="#ffffff"
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography style={{ color: '#ffffff' }}>
                                {t('RemoveSelectedMembers', {
                                    count: selectedCount,
                                })}
                            </Typography>
                        </Box>
                    </EidButton.DrawerActionButton>
                </Box>
                <Box style={{ marginBottom: '60px' }}>{pagination}</Box>
            </Box>
        </>
    )
}
