import {
    Box,
    Button,
    createStyles,
    makeStyles,
    styled,
} from '@material-ui/core'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { useEligibilityAssignments } from 'hooks'
import { PageContextProvider } from 'pageContext'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from 'components/Filters/Search'
import useSubcomponents from 'useSubcomponents'
import { getFullPathFromUrl, useDebounce, useIsSmallScreenFalse, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import config from 'config'
import { Icon } from 'packages/eid-icons'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})
const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: 'solid 1px #ebebed',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#aab0b4',
    },
})

const useButtonStyles = makeStyles(
    createStyles({
        root: (isSmallScreen) => ({
            height: '32px',
            borderRadius: '4px',
            padding: '0px 15px 0px 10px',
            backgroundColor: '#307fc1',
            color: '#ffffff',
            textTransform: 'none',
            fontSize: '14px',
            margin: isSmallScreen ? '16px 16px 0px 16px' : '',
            '&:hover': {
                backgroundColor: '#307fc1',
            },
            '& svg': {
                marginRight: '8px',
                '& path': {
                    stroke: '#fff',
                },
            },
        }),
    }),
)

const canConfigureEligibilityControlName =
    'ResourceAdmin-ApplicationDetails-Eligibility-ConfigureEligibility-Control'

const EligibilityListing = ({
    roleId,
    resourceTypeId,
    resourceId,
    workflowName,
}) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreenFalse()

    const query = useQuery()
    const history = useHistory()
    const classes = useButtonStyles(isSmallScreen)

    const dataObject = {
        assignmentPointId: roleId,
        ResourceTypeId: resourceTypeId,
    }
    const [searchKey, setSearchKey] = useState('')

    const debouncedSearchValue = useDebounce(searchKey)

    const queryData = {
        searchTerm: debouncedSearchValue,
    }
    const { list, loading, loadingMore, pagination } =
        useEligibilityAssignments(dataObject, queryData)
    const { hasAccessToControl, getAccessBasedAttributes } = useSubcomponents()
    const attributes = [
        {
            name: 'eligibilityType',
            sortable: true,
            sortBy: 'eligibilityType',
            label: 'EligibilityType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Eligibility-EligibilityType',
            },
        },
        {
            name: 'assigneeType',
            sortable: true,
            sortBy: 'assigneeType',
            label: 'AssigneeType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Eligibility-AssigneeType',
            },
        },
        {
            name: 'assigneeFriendlyName',
            sortable: true,
            sortBy: 'assigneeFriendlyName',
            label: 'Assignee',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Eligibility-Assignee',
            },
        },
        {
            name: 'resourceTypeFriendlyName',
            sortable: true,
            sortBy: 'resourceTypeFriendlyName',
            label: 'ResourceType',
            headerCellStyles: {
                fontSize: '13px',
                fontWeight: 'normal',
            },
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Eligibility-ResourceType',
            },
        },

        {
            name: 'eligibilityAssignmentType',
            sortable: true,
            sortBy: 'eligibilityAssignmentType',
            label: 'AssignmentType',
            styles: {
                fontSize: '13px',
                fontWeight: 'normal',
                color: '#282828',
            },
            requireAccess: {
                control:
                    'ResourceAdmin-ApplicationDetails-Eligibility-AssignmentType',
            },
        },
    ]

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }

    const handleClear = () => {
        setSearchKey('')
    }

    return (
        <Box>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="16px 32px"
            >
                {hasAccessToControl(canConfigureEligibilityControlName) && (
                    <PageContextProvider key="ConfigureEligibility">
                        <Box
                            data-protectedSubcomonent={
                                canConfigureEligibilityControlName
                            }
                        >
                            <Button
                                onClick={() => {
                                    query.delete('applicationId')
                                    query.delete('roleDefinitionId')
                                    query.delete('appRoleId')
                                    query.set('workflowName', workflowName)
                                    query.set('returnUrl', getFullPathFromUrl())
                                    query.set('resourceID', resourceId)
                                    query.set(
                                        'WizardAction',
                                        'edititshopsetting',
                                    )
                                    history.push(
                                        `${
                                            config.APP_SUBPATH
                                        }/applications/workflows?${query.toString()}`,
                                    )
                                }}
                                className={classes.root}
                            >
                                {<Icon name="EditFile" color="#fff" />}
                                {t('ConfigureEligibility')}
                            </Button>
                        </Box>
                    </PageContextProvider>
                )}

                <Box
                    width="240px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>
            <TableOverView>
                <EidTableGrid
                    rowSelection={false}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={loading}
                    isLoadingMore={loadingMore}
                    attributes={getAccessBasedAttributes(attributes)}
                    list={list}
                />
            </TableOverView>
        </Box>
    )
}

export default EligibilityListing
