import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react'
import { Collapse, Box } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import DomPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import SharedFolderCard from './SharedFoldersCard'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
    useGetControlsAccess,
} from 'packages/core'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import {
    SharedFoldersTable,
    SharedFoldersTableHead,
    SharedFoldersRow,
} from './SharedFoldersTableComponents'
import {
    AppLayout,
    useEidGrid,
    CardViewLoader,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
} from 'components'
import {
    useSharedFolder,
    useApplicationSettings,
    useSharedFolders,
} from 'hooks'
import { usePageContext } from 'pageContext'
import { SubPathLevel } from 'appConfiguration'
import useSubcomponents from 'useSubcomponents'
import config from 'config'
import { SharedFolderDetails } from './Shared Folder Details'
import { useAppState } from 'appContext'
import { Skeleton } from '@material-ui/lab'

const SharedFoldersLayout = ({ filters }) => {
    const filterContainer = useRef(null)
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const [filterWidth, setFilterWidth] = useState(0)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [{ devFeaturesEnabled }, _dispatch] = useAppState()
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        rest,
    } = useSharedFolders()

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )
    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])
    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? 'card' : 'table'

    const { tableAttributes, detailAttributes, accessRequestAttributes } =
        attributes
    const { getAccessBasedAttributes } = useSubcomponents()
    const accessBasedAttributesForGroups =
        getAccessBasedAttributes(tableAttributes)

    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('No_SharedFolders_Message'),
        pagination,
        attributes: accessBasedAttributesForGroups,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: SharedFolderCard,
        },
        tableViewConfig: {
            TableComponent: SharedFoldersTable,
            TableHeadComponent: SharedFoldersTableHead,
            TableRowComponent: SharedFoldersRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    const query = useQuery()
    const controls = useGetControlsAccess()
    useEffect(() => {
        if (!sharedFolderId) {
            _dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: null,
            })
        }
    }, [sharedFolderId])
    const hasAccessToCreateAzureApplicationWorkflow =
        controls.findIndex(
            (c) =>
                c.name ===
                'ResourceAdmin-CreateAzureApplicationWorkflow-Control',
        ) >= 0

    const hasAccessToParsedHtmlMoreInfo =
        controls.findIndex(
            (c) => c.name === 'ResourceAdmin-ParsedHtmlMoreInformation-Control',
        ) >= 0

    const workflowName = query.get('workflow')
    const sharedFolderId = query.get('sharedFolderId')
    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig
    const onboardAzureApplicationWF =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) => w.name === 'OnboardAzureApplication',
              )
            : []
    const { data: sharedFolder } = useSharedFolder(sharedFolderId)
    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const detailPageTabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
        },
        {
            label: t('IAmShopAssignments'),
            value: 'assignments',
            icon: <Icon name="AzureRbacRolesSmall" />,
            display: true,
        },
        {
            label: t('IAmShopAssignees'),
            value: 'assignees',
            icon: <Icon name="ResultantMembers" />,
            display: true,
        },
        {
            label: t('AcessAssignmentsToFolder'),
            value: 'assignmentsFolder',
            icon: <Icon name="ManagementRolesSmall" />,
            display: devFeaturesEnabled,
        },
        {
            label: t('InventoriedPermissions'),
            value: 'inventoriedPermissions',
            icon: <Icon name="Regulations" />,
            // TODO: Display true when backend is implemented
            display: devFeaturesEnabled,
        },
        {
            label: t('AccessManagers(Owners)'),
            value: 'accessManagers',
            icon: <Icon name="ExternalCredential" />,
            display: true,
        },
        {
            label: t('DirectAssignedLocations'),
            value: 'directAssignedLocations',
            icon: <Icon name="Claims" />,
            display: true,
        },
        {
            label: t('AccessRequestPolicy'),
            value: 'accessRequestPolicy',
            icon: <Icon name="ClientCertificate" />,
            display: true,
        },
    ]

    if (sharedFolderId) {
        if (isSmallScreen) {
            return (
                <AppLayout
                    totalCount={totalCount}
                    showBackButton={Boolean(sharedFolder)}
                >
                    {!sharedFolder ? (
                        <Fragment>
                            <Skeleton height={40} />
                            <Skeleton height={40} />
                            <Skeleton height={40} />
                        </Fragment>
                    ) : (
                        <SharedFolderDetails
                            tabs={detailPageTabs}
                            sharedFolderId={sharedFolderId}
                            basicAttributes={detailAttributes}
                            accessRequestAttributes={accessRequestAttributes}
                        />
                    )}
                </AppLayout>
            )
        }
        return (
            <AppLayout
                totalCount={totalCount}
                showNavTabs={!sharedFolderId}
                showBackButton={Boolean(sharedFolder)}
            >
                <DesktopContainer>
                    <Box paddingRight={'8px'} marginBottom="16px" width="100%">
                        {!sharedFolder ? (
                            <Fragment>
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                                <Skeleton height={40} />
                            </Fragment>
                        ) : (
                            <SharedFolderDetails
                                tabs={detailPageTabs}
                                sharedFolderId={sharedFolderId}
                                basicAttributes={detailAttributes}
                                accessRequestAttributes={
                                    accessRequestAttributes
                                }
                            />
                        )}
                    </Box>
                </DesktopContainer>
            </AppLayout>
        )
    }

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    let parsedHtmlMoreInfo
    try {
        parsedHtmlMoreInfo = ReactHtmlParser(
            DomPurify.sanitize(t('SharedFolders_MoreInfo_Html'), {
                ADD_ATTR: ['target'],
            }),
        )
    } catch (error) {}

    let content
    if (isSmallScreen) {
        content = (
            <SmallScreenContainer>
                <Box display={showFilters ? '' : 'none'}>
                    {mobileFiltersScreen}
                </Box>

                <Box display={!showFilters ? '' : 'none'}>
                    <Box display={''}>
                        <StickyContainer>
                            <Box width="100%">{mobileStickyContentListing}</Box>
                        </StickyContainer>
                        {viewToRender}
                    </Box>
                </Box>
            </SmallScreenContainer>
        )
    } else {
        content = (
            <DesktopContainer>
                <Fragment>
                    {filters && filters.length > 0 && (
                        <FiltersColumn ref={filterContainer}>
                            <FilterContainer>
                                <Box padding="16px 0px 0px 0px" display="flex">
                                    <Box width="100%">
                                        <Card.CardHeader
                                            collapsible
                                            fontColor="black"
                                            cardTitle={t('Show_My_Filters')}
                                            handleExpandClick={() =>
                                                setOpenFilters((prev) => !prev)
                                            }
                                            expanded={openFilters}
                                            icon={
                                                <Icon
                                                    name="AdvancedSearch"
                                                    color="#959598"
                                                />
                                            }
                                            style={styles.filterCardHeader}
                                        />
                                    </Box>
                                </Box>
                            </FilterContainer>

                            <Collapse
                                in={openFilters}
                                timeout={300}
                                unmountOnExit
                            >
                                {!sharedFolderId &&
                                    hasAccessToCreateAzureApplicationWorkflow &&
                                    onboardAzureApplicationWF.length > 0 && (
                                        <Box>
                                            <EidWorkflowButton
                                                title={t(
                                                    onboardAzureApplicationWF[0]
                                                        .displayName_LocaleKey,
                                                )}
                                                name={
                                                    onboardAzureApplicationWF[0]
                                                        .requestWorkflowName
                                                }
                                                path={`/sharedFolders/workflows`}
                                                disabled={workflowName}
                                            />
                                        </Box>
                                    )}
                                {hasAccessToParsedHtmlMoreInfo &&
                                    parsedHtmlMoreInfo && (
                                        <Box
                                            style={{
                                                margin: '0 0 15px 0px',
                                            }}
                                        >
                                            <Card
                                                cardTitle={t(
                                                    'More_Information',
                                                )}
                                                icon={<Icon name="MoreInfo" />}
                                                collapsible
                                                expanded
                                                disabled={sharedFolderId}
                                            >
                                                <Box
                                                    padding="0px 10px"
                                                    maxWidth="350px"
                                                    overflow="scroll"
                                                >
                                                    {parsedHtmlMoreInfo}
                                                </Box>
                                            </Card>
                                        </Box>
                                    )}
                                <>
                                    {filters.map((f) => {
                                        const FilterToRender =
                                            Filters.filtersMap[f.name]
                                        return (
                                            <FilterContainer
                                                data-protectedsubcomponent={
                                                    f.requireAccess
                                                }
                                                key={f.name}
                                            >
                                                <FilterToRender />
                                            </FilterContainer>
                                        )
                                    })}
                                </>
                            </Collapse>
                        </FiltersColumn>
                    )}

                    <ListingGrid>
                        <Box padding="16px 0px 0px 0px" display="flex">
                            <Box width="100%">
                                <Filters.TextSearch />
                            </Box>
                        </Box>
                        <GridContainer
                            filterWidth={filterWidth}
                            openFilters={openFilters}
                        >
                            {viewToRender}
                        </GridContainer>
                    </ListingGrid>
                </Fragment>
            </DesktopContainer>
        )
    }

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={viewSwitcher}
            showNavTabs={true}
        >
            <Fragment>{content}</Fragment>
        </AppLayout>
    )
}

export default SharedFoldersLayout
