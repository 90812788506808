import {
    MenuItem,
    makeStyles,
    ListItemText,
    ListItemIcon,
    Box,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import useSubcomponents from 'useSubcomponents'
import { defaultDataProtectedSubcomponent } from 'utils'

const useStyles = makeStyles(() => ({
    root: (props) => ({
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
        backgroundColor: props.isSubTabItem
            ? 'rgba(48, 127, 193, 0.07)'
            : 'unset',
        color: props.isSubTabItem ? '#307fc1' : 'black',
        borderLeft: props.isSubTabItem ? '2px solid #307fc1' : 'none',
    }),
    icon: {
        minWidth: '40px',
        color: 'black',
    },
    selected: (props) => ({
        backgroundColor: props.isSubTabItem
            ? '#307fc1 !important'
            : 'rgba(48, 127, 193, 0.07) !important',
        borderLeft: '2px solid #307fc1',
        color: props.isSubTabItem ? 'white' : 'black',
        '& img': {
            opacity: 1,
            filter: 'grayscale(0%) ',
        },
    }),
}))

const TabsItem = ({
    label,
    icon,
    selected,
    onClick,
    subTabs,
    isSubTabItem,
    onSubTabChange,
    subActiveTab,
    protectedSubcomonent,
}) => {
    const classes = useStyles({
        hasSubTabs: subTabs && subTabs.length > 0,
        isSubTabItem,
    })
    const { hasAccessToControl } = useSubcomponents()
    return (
        <>
            <MenuItem
                onClick={onClick}
                classes={classes}
                selected={selected}
                data-protectedsubcomponent={
                    protectedSubcomonent || defaultDataProtectedSubcomponent
                }
            >
                {icon && (
                    <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                )}
                <ListItemText>
                    <Box
                        style={{
                            marginLeft: isSubTabItem ? '40px' : '0px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {label}
                        {subTabs && subTabs.length > 0 && (
                            <Box>
                                <Icon
                                    style={{
                                        height: '16px',
                                        width: '16px',
                                    }}
                                    name={'Arrow'}
                                    direction={selected ? 'up' : 'down'}
                                    color="#307fc1"
                                />
                            </Box>
                        )}
                    </Box>
                </ListItemText>
            </MenuItem>
            {selected &&
                subTabs &&
                subTabs
                    .filter(
                        (t) =>
                            t.display &&
                            (!t?.requireAccess?.control ||
                                hasAccessToControl(t?.requireAccess?.control)),
                    )
                    .map((subTab, index) => (
                        <TabsItem
                            key={subTab.label + index}
                            label={subTab.label}
                            selected={subActiveTab === subTab.value}
                            onClick={() => onSubTabChange(subTab.value)}
                            isSubTabItem
                            protectedSubcomonent={
                                subTab?.requireAccess?.control
                            }
                        />
                    ))}
        </>
    )
}

const LeftPaneTabs = ({
    active,
    onChange,
    tabs,
    onSubTabChange,
    subActiveTab,
}) => {
    const { controls } = useSubcomponents()

    const isAccessible = (tab) => {
        return controls.includes(tab.requireAccess?.control)
    }

    return (
        <>
            {tabs &&
                tabs
                    .filter((x) => x.display === true)
                    .filter((y) => {
                        if (y.requireAccess) {
                            return isAccessible(y)
                        } else {
                            return true
                        }
                    })
                    .map((tab, index) => (
                        <TabsItem
                            key={`${tab.value}_${index}`}
                            label={tab.label}
                            onClick={() => {
                                onChange(tab.value)
                            }}
                            selected={active === tab.value}
                            icon={tab.icon}
                            subTabs={tab.subTabs}
                            onSubTabChange={onSubTabChange}
                            subActiveTab={subActiveTab}
                            protectedSubcomonent={tab?.requireAccess?.control}
                        />
                    ))}
        </>
    )
}

export default LeftPaneTabs
