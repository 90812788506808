import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@material-ui/core'
import { useDirectAssignedLocations } from 'hooks'
import Search from 'components/Filters/Search'
import { PageContextProvider, usePageContext } from 'pageContext'
import AddDirectAssignedLocation from './AddDirectAssignedLocation'
import { WarningModal } from 'packages/eid-ui'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import { Button } from 'components'
import { BulkRemoveButton } from 'components/BulkActionsButtons/BulkRemoveButton'
import { useDebounce } from 'packages/core'
import { useAppState } from 'appContext'

const DeleteButton = styled(Box)({
    paddingRight: '10px',
    textAlign: 'end',
    '& button': {
        padding: '8px 12px',
        borderRadius: ' 4px',
        border: 'solid 1px #ff4d5f',
        backgroundColor: '#fff',
    },
})

const RemoveSelectedItem = styled(Box)({
    width: '350px',
    '& button': {
        '& div': {
            height: '40px !important',
        },
    },
})

const SearchMenuBarFilter = styled(Box)({
    width: '100%',

    '& svg': {
        color: ' #aab0b4',
        marginRight: '3px',
    },
    '& div:nth-child(1)': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1) !important',
        border: ' solid 1px #d8d8dd',
        paddingLeft: '0',
        height: '40px',
    },
    '& ::placeholder': {
        color: '#a7a7a9',
    },
})

export const DirectAssignedLocations = ({ id }) => {
    const { t } = useTranslation()
    const [, dispatch] = usePageContext()
    const [selected, setSelected] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [{ devFeaturesEnabled }] = useAppState()
    const [selectedItemToDelete, setSelectedItemToDelete] = useState(null)
    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }

    const debouncedSearchValue = useDebounce(searchKey)

    useEffect(() => {
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: {
                searchTerm: debouncedSearchValue,
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchValue])

    const {
        list,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
    } = useDirectAssignedLocations(id)

    const attributes = [
        {
            label: t('LocationName'),
            name: 'friendlyName',
            sortable: true,
        },
        {
            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <DeleteButton>
                            {devFeaturesEnabled && (
                                <Button.Delete
                                    showEndIcon={false}
                                    onClick={() =>
                                        setSelectedItemToDelete(data)
                                    }
                                />
                            )}
                        </DeleteButton>
                    )
                },
            },
        },
    ]

    const handleDelete = () => {
        // TODO: API integration to delete
        console.log('selectedItemToDelete', selectedItemToDelete)
    }

    const handleRowSelection = (item) => {
        let selectedItems = [...selected]
        const selectedIndex = selected.findIndex((x) => x === item.id)
        if (selectedIndex >= 0) {
            const filteredList = selectedItems.filter((x) => {
                return x !== item.id
            })
            setSelected(filteredList)
        } else {
            setSelected([...selectedItems, item.id])
        }
    }

    const handleSelectAllClick = () => {
        if (selected.length === list.length) {
            setSelected([])
        } else {
            const filteredList = list.map((item) => item.id)
            setSelected(filteredList)
        }
    }

    const selectAllAction = {
        render: () => {
            return (
                <RemoveSelectedItem>
                    {devFeaturesEnabled && (
                        <BulkRemoveButton
                            selectedCount={selected.length}
                            label={t('RemoveSelectedAssignedLocations', {
                                count: selected.length,
                            })}
                            onClick={handleBulkAction}
                        />
                    )}
                </RemoveSelectedItem>
            )
        },
    }

    const handleBulkAction = () => {
        // TODO: Need to integarte the api call
        console.log('Selected Item to delete', selected)
    }

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                paddingBottom="16px"
            >
                {devFeaturesEnabled && (
                    <PageContextProvider key="AddAccessAssignmentsFodler">
                        <AddDirectAssignedLocation />
                    </PageContextProvider>
                )}

                <Box width="309px" display="flex" alignItems="center">
                    <SearchMenuBarFilter>
                        <Search
                            handleSearch={handleSearchChange}
                            placeholder={t('SearchAndFilter')}
                            value={searchKey}
                            handleClear={handleClear}
                            outlined
                        />
                    </SearchMenuBarFilter>
                </Box>
            </Box>

            <Box>
                <EidTableGrid
                    selectAllAction={selectAllAction}
                    rowSelection={list?.length > 0}
                    handleSelectAllClick={handleSelectAllClick}
                    selected={selected}
                    handleClick={handleRowSelection}
                    noDataMessage={t('NoDataMessage')}
                    pagination={pagination}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    attributes={attributes}
                    list={list}
                />
            </Box>
            {selectedItemToDelete && (
                <WarningModal
                    title={`${t('Delete')} ${
                        selectedItemToDelete?.friendlyName
                    }`}
                    description={t(
                        'SharedFolderDirectAssignedLocationsDeleteMessage',
                    )}
                    yesLabel={t('Delete')}
                    noLabel={t('Cancel')}
                    open={selectedItemToDelete}
                    cancel={() => setSelectedItemToDelete(null)}
                    // proceeding={isDeleting}
                    proceed={handleDelete}
                />
            )}
        </>
    )
}
